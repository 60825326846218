import React from 'react';

import { AiChat } from '~/components/AiChat/AiChat';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { useLob } from '~/components/hooks/useLob';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import cn from '~/Utils/cn';

import { isPolicyNotFound } from '../../../Utils';
import { getLobDescription, getLobIcon } from '../../../Utils/lobUtils';
import { useClaim } from '../../ClaimContainer';
import ClaimOwnerWithEdit from '../../ClaimOwnerWithEdit';
import { ClaimActionButtonContainer, PreferredContactContainer, TimeOfLossContainer } from '../../ClaimPage';
import CloseClaimContainer from '../../CloseClaimContainer';
import { ContactEntity } from '../../Contact';
import DateOfReportContainer from '../../Fnol/NewFnolUI/IncidentDetails/DateOfReportContainer';
import { usePolicy } from '../../PolicyContainer';
import { useClaimSearch } from '../../SystemConfiguration/Integrations/ClaimSearch/hooks/useClaimSearch';

import ClaimAlerts from './ClaimAlerts';
import ClaimNumberContainer from './ClaimNumberContainer';
import DividerCell from './DividerCell';

import { useStyles } from '../../../../src/assets/styles';
import styles from './claimPageBar.module.scss';

const NewUIClaimPageBar = () => {
  const classes = useStyles();
  const { lob } = useLob();
  const { lobConfigurationsDict } = useLobConfiguration();
  const { policy } = usePolicy();
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { isEnabled: isClaimSearchEnabled, getClaimSearchScoreRoute } = useClaimSearch();

  const lobName = getLobDescription(lob, lobConfigurationsDict);
  const subOrganization = policy?.sub_organization;
  const isClaimsMade = policy?.is_claims_made || false;

  return (
    <div className={classes.claimPageBar}>
      <Grid className={styles.fnolHeader}>
        <Grid className={styles.fnolHeaderRow}>
          <Grid item className={cn(styles.tableCell, styles.tableCellFull)}>
            <div className={cn(styles.headline, styles.fullSizeCell)}>
              <ClaimNumberContainer />
            </div>
          </Grid>

          <DividerCell />
          <Grid item className={cn(styles.tableCell, styles.tableCellFull)}>
            <div className={cn(styles.headline, styles.fullSizeCell)}>
              <span className={styles.lobIcon}>{getLobIcon({ lob, lobConfigurationsDict })}</span>
              {lobName}
              {' Policy'}
            </div>
            <div className={styles.policyNumber}>
              {isPolicyNotFound(claim) ? 'Policy Not Found' : policy.policy_number}
            </div>
          </Grid>

          {subOrganization && (
            <>
              <DividerCell />
              <Grid item className={styles.tableCell}>
                <div className={cn(styles.headline, styles.subOrgHeadeline)}>Sub Organization</div>
                <div className={styles.subOrg}>{subOrganization.name}</div>
              </Grid>
            </>
          )}

          <DividerCell />
          <Grid item className={styles.tableCell}>
            <div className={styles.headline}>
              <span className={styles.policyHolder}>Policyholder</span>
            </div>
            <ContactEntity
              classes={classes}
              contactId={policy.insured_contact_id}
              contactDisplayName={policy.insured_contact_full_name}
            />
          </Grid>

          <DividerCell />
          <Grid item className={styles.tableCell}>
            <div className={styles.headline}>Preferred Contact</div>
            <PreferredContactContainer claim={claim} onClaimUpdate={onAsyncClaimUpdate} />
          </Grid>

          <DividerCell />
          <Tooltip title={`${claim.days_open} days open`} placement="top" arrow>
            <span>
              <Grid className={styles.tableCell}>
                <div className={styles.headline}>{isClaimsMade ? 'Date of Report' : 'Time of Loss'}</div>
                {isClaimsMade ? (
                  <DateOfReportContainer claim={claim} onUpdate={onAsyncClaimUpdate} withoutLabelOnReadOnly />
                ) : (
                  <TimeOfLossContainer claim={claim} onClaimUpdate={onAsyncClaimUpdate} />
                )}
              </Grid>
            </span>
          </Tooltip>

          <DividerCell />
          <Grid item className={styles.tableCell}>
            <div className={styles.headline}>File Owner</div>
            <span>
              <ClaimOwnerWithEdit classes={classes} claim={claim} />
            </span>
          </Grid>
          {isClaimSearchEnabled ? (
            <ClaimAlerts claimSearchScoreRoute={getClaimSearchScoreRoute(claim.id)} claim={claim} />
          ) : null}

          <Grid container className="items-start justify-end gap-20 overflow-clip">
            <Grid item className="mt-[9px]">
              <ClaimActionButtonContainer />
            </Grid>
            <Grid item className="mt-16">
              <CloseClaimContainer />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AiChat />
    </div>
  );
};

export default NewUIClaimPageBar;
