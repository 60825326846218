import React from 'react';

export interface ProgressBarProps {
  percent?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percent = 0 }) => {
  const progressPercent = Math.min(100, Math.max(0, percent));

  return (
    <div className="h-8 w-full rounded-full bg-slate-500">
      <div
        className="h-8 rounded-full bg-teal-600 transition-all duration-300 ease-in-out"
        style={{ width: `${progressPercent}%` }}
      />
    </div>
  );
};

export default ProgressBar;
