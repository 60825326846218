import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { omit } from 'lodash';
import moment from 'moment/moment';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';

import CardDialog from '../../../../CardDialog';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { isLicensesEnabled, OTHERS_FIELD_ID, RESIDENCY_FIELD_ID } from '../utils';

import { AdjusterFragment } from './AdjusterFragment';

import { useStyles } from '../../../../../assets/styles';

const getAdjusterInitialValues = (user, organization) => {
  const licensesEnabled = isLicensesEnabled(organization);

  return {
    ...user,
    is_org_level_supervisor: !!user['is_org_level_supervisor'], // prevent undefined values
    extra_settings: {
      ...user.extra_settings,
      email_notification_assignment: !!user.extra_settings.email_notification_assignment,
    },
    supervisor_id: user.supervisor_id || '',
    title: user.title || '', // prevent title from being null in validationScheme, so there weill be a nice message from Yup.String()
    ...(licensesEnabled
      ? {
          [RESIDENCY_FIELD_ID]: user.licenses?.filter((license) => license.is_residency_license) || [],
          [OTHERS_FIELD_ID]: user.licenses?.filter((license) => !license.is_residency_license) || [],
        }
      : {}),
  };
};

const getAdjusterValidationSchema = (organization) => {
  const licensesEnabled = isLicensesEnabled(organization);

  const licensesSchema = Yup.array().of(
    Yup.object().shape({
      license_id: Yup.string().nullable(),
      state: Yup.string().required('Required'),
      expiration_date: Yup.date()
        .test('expiration_date', 'Expiration date should be later than the effective date', function (value) {
          const { effective_date } = this.parent;
          return moment(value).isAfter(moment(effective_date));
        })
        .required('Required'),
      effective_date: Yup.date().required('Required'),
    })
  );

  const getLimitsSchema = () => {
    if (organization.are_payment_limits_split) {
      return {
        indemnity_reserves_limit: Yup.number().required('required'),
        indemnity_payments_limit: Yup.number().required('required'),
        expenses_reserves_limit: Yup.number().required('required'),
        expenses_payments_limit: Yup.number().required('required'),
      };
    } else {
      return {
        reserves_limit: Yup.number().required('required'),
        payments_limit: Yup.number().required('required'),
      };
    }
  };

  return Yup.object().shape({
    title: Yup.string('Required').required('required'),
    ...getLimitsSchema(),
    supervisor_id: Yup.string().nullable(),
    is_org_level_supervisor: Yup.bool().required('Required'),
    extra_settings: Yup.object().shape({
      email_notification_assignment: Yup.bool(),
    }),
    ...(licensesEnabled
      ? {
          [RESIDENCY_FIELD_ID]: licensesSchema,
          [OTHERS_FIELD_ID]: licensesSchema.test(
            OTHERS_FIELD_ID,
            'Residency license must be set to add other licenses',
            function (value) {
              const residencyArray = this.parent[RESIDENCY_FIELD_ID];
              return !(value.length > 0 && residencyArray.length !== 1);
            }
          ),
        }
      : {}),
  });
};

function AdjusterEditDialog({ user, onSaveAdjusterDetails, onEditCancel, integrationsExtraDetailsFields }) {
  const { organization: sysConfigOrganization } = useSysconfig();
  const classes = useStyles();

  return (
    <Formik
      initialValues={getAdjusterInitialValues(user, sysConfigOrganization)}
      validationSchema={getAdjusterValidationSchema(sysConfigOrganization)}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          const processedValues = omit(
            {
              ...values,
              licenses: [...(values[RESIDENCY_FIELD_ID] ?? []), ...(values[OTHERS_FIELD_ID] ?? [])],
            },
            [OTHERS_FIELD_ID, RESIDENCY_FIELD_ID]
          );
          await onSaveAdjusterDetails(processedValues);
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        const buttonsComponentEdit = (
          <Fragment>
            <CancelButton disabled={isSubmitting} onClick={onEditCancel} />
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Save
            </Button>
          </Fragment>
        );

        return (
          <CardDialog
            isDialog={true}
            title="Edit Adjuster"
            maxWidth="md"
            onClose={onEditCancel}
            preventClose={isSubmitting}
            footerActions={buttonsComponentEdit}
          >
            <AdjusterFragment
              {...formikProps}
              classes={classes}
              user={user}
              integrationsExtraDetailsFields={integrationsExtraDetailsFields}
            />
          </CardDialog>
        );
      }}
    </Formik>
  );
}

AdjusterEditDialog.propTypes = {
  user: PropTypes.object.isRequired,
  onSaveAdjusterDetails: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  integrationsExtraDetailsFields: PropTypes.array,
};
export { AdjusterEditDialog };
