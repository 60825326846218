import React from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import AnomalousPaymentBanner from '~/components/exposures/PaymentRequestContainer/MakePaymentRequestDialog/AnomalousPaymentBanner';
import useFetchPaymentPossibility from '~/components/exposures/PaymentRequestContainer/MakePaymentRequestDialog/useFetchPaymentRequestPossibility';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { capitalize } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { LoadingSwitch, Text } from '../../core';
import { CurrencyFormatterContextProvider, useCurrencyFormatter } from '../../CurrencyFormatterContext';
import { usePaymentsLimits } from '../../hooks/usePaymentLimits';
import { useExposureSubReserves } from '../../hooks/useSubReserves';
import { MonetaryValueTextFieldFormik, TextFieldFormik } from '../../TextFieldFormik';
import ToggleButtonGroupFormik from '../../ToggleButtonGroupFormik';
import { isLimitExists } from '../ExposureUtils';

import {
  getAnomalousPaymentBannerProps,
  getPaymentsAboveReserveIssuesAndSeverities,
  shouldDisablePayment,
} from './paymentContainerUtils';
import PaymentRequestDisplay from './PaymentRequestDisplay';

import { useStyles } from '../../../assets/styles';
import styles from './PaymentRequestApprovalDialog.module.scss';

function PaymentRequestApprovalDialog(props) {
  const {
    paymentRequest,
    payableWithReserve,
    exposure,
    onDecidePaymentRequest,
    cardDialogProps,
    onCancel,
    claim,
    payableType,
  } = props;

  return (
    <Formik
      initialValues={{
        ...paymentRequest,
        note: '',
        decision: '',
        decision_amount: '',
        ...(paymentRequest?.request_amount_orig_currency
          ? { request_amount: paymentRequest?.request_amount_orig_currency }
          : {}),
      }}
      validationSchema={Yup.object().shape({
        note: Yup.string().required('Required'),
        decision: Yup.string().required('Required').oneOf(['approve', 'approve_different', 'decline']),
        decision_amount: Yup.number().when('decision', {
          is: 'approve_different',
          then: Yup.number().required('Required'),
        }),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onDecidePaymentRequest(values);
          formikProps.resetForm();
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      <PaymentRequestApprovalDialogFormikInner
        paymentRequest={paymentRequest}
        payableWithReserve={payableWithReserve}
        exposure={exposure}
        cardDialogProps={cardDialogProps}
        onCancel={onCancel}
        claim={claim}
        payableType={payableType}
      />
    </Formik>
  );
}

PaymentRequestApprovalDialog.propTypes = {
  cardDialogProps: PropTypes.object.isRequired,
  paymentRequest: PropTypes.object.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  payableType: PropTypes.string.isRequired,
  onDecidePaymentRequest: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
};

function PaymentRequestApprovalDialogFormikInner({
  paymentRequest,
  payableWithReserve,
  exposure,
  cardDialogProps,
  onCancel,
  claim,
  payableType,
}) {
  const classes = useStyles();
  const {
    shouldDisablePaymentExceedingReserve,
    adjustReserveIncludesDeductible,
    isPaymentLimitExistsForPayableType,
    getPaymentLimitForPayableType,
  } = usePaymentsConfiguration();
  const { currencyFormatter } = useCurrencyFormatter();
  const { userExposureLimit, isUserExposureLimitLoading } = usePaymentsLimits(
    claim,
    exposure,
    payableType,
    'payment',
    true
  );
  const { isSubReservesConfigEnabled, isMultipleSubReservePaymentsEnabled, subReservesConfigMap } =
    useExposureSubReserves(payableType, exposure);

  const { isSubmitting, handleSubmit, values, handleReset } = useFormikContext();

  // don't nullify amount on decline to continue show all warnings etc applicable if approved
  let amount = paymentRequest?.request_amount_orig_currency ? paymentRequest?.request_amount : values['request_amount'];
  if (values['decision'] === 'approve_different') {
    amount = values['decision_amount'] || 0;
  }

  const { isLoadingPaymentIssues, policyLimitBreaches, isPaymentIssuePreventingPayment } = useFetchPaymentPossibility(
    claim.id,
    exposure.id,
    payableType,
    amount
  );

  const reserveAdjustmentAmount =
    adjustReserveIncludesDeductible && values['deductible_considered_amount']
      ? amount + values['deductible_considered_amount']
      : amount;

  const checkIsExceedsClosedExposurePaymentLimit = () => {
    return (
      (exposure.is_closed || claim.is_closed) &&
      isPaymentLimitExistsForPayableType(payableType) &&
      getPaymentLimitForPayableType(payableType) < amount
    );
  };

  const issuesAndSeveritiesForPaymentsAboveReserves = getPaymentsAboveReserveIssuesAndSeverities({
    isSubReservesConfigEnabled,
    isMultipleSubReservePaymentsEnabled,
    payableWithReserve,
    paymentAmount: reserveAdjustmentAmount,
    amountWithoutDeductible: amount,
    subReserveAmounts: values['sub_reserve_amounts'],
    subReserveKeyForAdjustReserveDeductible: values['sub_reserve_key_for_adjust_reserve_deductible'],
    shouldDisablePaymentExceedingReserve,
    subReservesConfigMap,
    currencyFormatter,
  });

  const newPaidSum = payableWithReserve.paid_sum + amount;
  const checkIsExceedsUserAuthority = () => isLimitExists(userExposureLimit) && userExposureLimit < amount;
  const disablePayment = shouldDisablePayment({
    shouldDisablePaymentExceedingReserve,
    issuesAndSeveritiesForPaymentsAboveReserves,
    isSubmitting,
    checkIsExceedsClosedExposurePaymentLimit,
    isLoadingPaymentIssues,
    isPaymentIssuePreventingPayment,
  });

  function switchAmountField() {
    switch (values['decision']) {
      case 'approve':
        return <MonetaryValueTextFieldFormik key="approve" id="request_amount" label="Payment" showOnly />;
      case 'approve_different':
        return (
          <MonetaryValueTextFieldFormik
            key="approve_different"
            id="decision_amount"
            label="Payment"
            InputLabelProps={{ shrink: true }}
          />
        );
      case 'decline':
        return <MonetaryValueTextFieldFormik key="decline" id="request_amount" label="Payment" value={0} showOnly />;
      default:
        return (
          <div style={{ visibility: 'hidden' }}>
            <MonetaryValueTextFieldFormik
              key="placeholder"
              id="request_amount"
              label="New reserve"
              value={0}
              showOnly
            />
          </div>
        );
    }
  }

  function switchSubmitButtonName() {
    if (!values['decision']) {
      return 'Approve Request';
    }

    switch (values['decision']) {
      case 'approve':
        return checkIsExceedsUserAuthority() ? 'Request Supervisor Approval' : 'Approve Request';
      case 'approve_different':
        return checkIsExceedsUserAuthority() ? 'Request Supervisor Approval' : 'Approve Different Request';
      case 'decline':
        return 'Decline Request';
      default:
        throw Error(`Unknown value for decision: ${values['decision']}`);
    }
  }

  const requestActionsOptions = [
    {
      key: 'approve',
      value: 'approve',
      label: (
        <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
          Approve
        </Text>
      ),
    },

    {
      key: 'decline',
      value: 'decline',
      label: (
        <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
          Decline
        </Text>
      ),
    },
  ];

  if (!(paymentRequest?.request_currency !== claim?.policy?.policy_currency || isSubReservesConfigEnabled)) {
    requestActionsOptions.splice(1, 0, {
      key: 'approve_different',
      value: 'approve_different',
      label: (
        <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
          Approve different sum
        </Text>
      ),
    });
  }

  return (
    <CardDialog
      title={`${capitalize(payableType)} Payment Approval`}
      {...cardDialogProps}
      onClose={() => {
        handleReset();
        onCancel();
      }}
    >
      <LoadingSwitch isLoading={isUserExposureLimitLoading}>
        <AnomalousPaymentBanner
          {...getAnomalousPaymentBannerProps({
            checkIsExceedsUserAuthority,
            checkIsExceedsClosedExposurePaymentLimit,
            issuesAndSeveritiesForPaymentsAboveReserves,
            policyLimitBreaches,
            isPaymentIssuePreventingPayment,
          })}
        />
        <PaymentRequestDisplay
          paymentRequest={paymentRequest}
          exposure={exposure}
          claim={claim}
          payableType={payableType}
          newPaidSum={newPaidSum}
          payableWithReserve={payableWithReserve}
        />
        {Object.keys(paymentRequest.method_specific_approval_requests || {}).map((methodSpecificApprovalRequestKey) => (
          <div className={styles?.alertBannerContainer} key={methodSpecificApprovalRequestKey}>
            <AlertBanner
              alertType={AlertBanner.ALERT_TYPES.WARNING}
              title={paymentRequest.method_specific_approval_requests[methodSpecificApprovalRequestKey].message}
              withIcon
            />
          </div>
        ))}
        <ToggleButtonGroupFormik id="decision" options={requestActionsOptions} />
        <CurrencyFormatterContextProvider currency={paymentRequest?.request_currency}>
          {switchAmountField()}
        </CurrencyFormatterContextProvider>
        <div className={classes.inputContainer}>
          <TextFieldFormik id="note" label="Note" rows="2" className={classes.textField} fullWidth multiline />
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={disablePayment || !values['decision']}
          >
            {switchSubmitButtonName()}
          </Button>
        </div>
      </LoadingSwitch>
    </CardDialog>
  );
}

PaymentRequestApprovalDialogFormikInner.propTypes = {
  cardDialogProps: PropTypes.object.isRequired,
  paymentRequest: PropTypes.object.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  payableType: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
};

export default PaymentRequestApprovalDialog;
