import React from 'react';
import { Remarkable } from 'remarkable';

import { MessageReferences } from '~/components/AiChat/components/Message/MessageReferences';
import { RoundedPanel } from '~/components/AiChat/SideBarDialog/components/RoundedPanel/RoundedPanel';
import { INSIGHTS_ICONS, replaceDatesWithLocaleString } from '~/components/AiChat/SideBarDialog/utils';
import type { AiChatReference, Insight } from '~/components/AiChat/types';
import { MainCard } from '~/components/core';
import cn from '~/Utils/cn';

import '../MarkdownTemplate.css';

interface InsightPanelProps {
  insight: Insight;
  references: Record<string, AiChatReference>;
}

export const InsightPanel: React.FC<InsightPanelProps> = ({ insight, references }) => {
  const { insight_text, insight_type, title, sub_title, related_documents } = insight;
  const referencesIds = related_documents ? new Set(related_documents.map((doc) => doc?.document_id || '')) : [];
  const [isOpen, setIsOpen] = React.useState(false);
  const md = new Remarkable({ breaks: true });
  const parsedText = md.render(replaceDatesWithLocaleString(insight_text));

  return (
    <div
      className={cn('animate-fadeIn gap-10 relative flex w-full items-center bg-white', { ['bg-slate-100']: isOpen })}
    >
      <MainCard
        key="contained"
        collapsible
        title={title}
        type="contained"
        isOpen={isOpen}
        onCollapseClick={(cardState) => setIsOpen(cardState)}
        titleAction={
          <div className="ml-auto flex h-[36px] w-[36px] shrink-0 items-center justify-center rounded-md bg-pink-200/70">
            {INSIGHTS_ICONS[insight_type]}
          </div>
        }
      >
        <div className="relative flex w-full flex-col gap-12">
          <span className="text-md rounded-lg bg-white px-16 py-8" hidden={!sub_title}>
            {sub_title}
          </span>
          <div className="flex flex-col gap-12 ">
            <RoundedPanel variant="clear">
              {insight_text ? (
                <div
                  className="task-markdown additional-actions text-sm"
                  dangerouslySetInnerHTML={{ __html: parsedText }}
                />
              ) : null}
            </RoundedPanel>
          </div>
        </div>

        <MessageReferences referencesIds={[...referencesIds]} references={references} />
      </MainCard>
    </div>
  );
};
