import React from 'react';
import type { DropzoneRef } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

import Button from '~/components/core/Atomic/Buttons/Button';
import { reportAxiosError } from '~/Utils';

import CardDialog from '../CardDialog';
import ChooseFilesSection from '../Documents/UploadDocumentsCard/ChooseFilesSection/ChooseFilesSection';
import { uploadStoredFile } from '../Documents/UploadDocumentsCard/utils';
import { useCms } from '../hooks/useCms';
import LoadingIndicator from '../LoadingIndicator';

const USUAL_FNOL_MAX_PROCESSING_DURATION = 75000;

interface FnolAiScreenProps {
  inputMethod: 'text' | 'audio';
}

const FnolAiScreen: React.FC<FnolAiScreenProps> = ({ inputMethod }) => {
  const history = useHistory();
  const { userOrganization } = useCms() as { userOrganization: { id: number } };

  const [fnolText, setFnolText] = React.useState('');
  const [selectedAudioFilename, setSelectedAudioFilename] = React.useState('');
  const [fnolAudioFileStorageFilename, setFnolAudioFileStorageFilename] = React.useState('');
  const [isUploadingAudioFile, setIsUploadingAudioFile] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isTakingLongerThanExpected, setIsTakingLongerThanExpected] = React.useState(false);

  const dropzoneRef = React.createRef() as React.RefObject<DropzoneRef>;

  const handleSubmit = React.useCallback(async () => {
    setIsSubmitting(true);
    const timer = setTimeout(() => {
      setIsTakingLongerThanExpected(true);
    }, USUAL_FNOL_MAX_PROCESSING_DURATION);
    try {
      const response = await axios.post('/api/v1/claims/fnol_ai', {
        submission_mode: 'chatbot',
        incident_description: inputMethod === 'text' ? fnolText : undefined,
        incident_description_audio_storage_filename: inputMethod === 'audio' ? fnolAudioFileStorageFilename : undefined,
      });
      history.push(`/claims/${response.data.id}`);
    } catch (error) {
      reportAxiosError(error);
    } finally {
      clearTimeout(timer);
      setIsSubmitting(false);
      setIsTakingLongerThanExpected(false);
    }
  }, [inputMethod, fnolText, fnolAudioFileStorageFilename, history]);

  const uploadAudioFile = async (files: File[]) => {
    if (!files.length) return;
    if (files.length > 1) {
      throw new Error('Only one file can be uploaded at a time');
    }
    const file = files[0];
    setIsUploadingAudioFile(true);
    try {
      const storageFileName = (await uploadStoredFile({
        urlPrefix: `/api/v1/organizations/${userOrganization.id}/stored_files`,
        file,
        onUpdatePercent: (/*progressEvent: ProgressEvent*/) => {
          return;
        },
      })) as string;
      setFnolAudioFileStorageFilename(storageFileName);
      setSelectedAudioFilename(file.name);
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsUploadingAudioFile(false);
    }
  };

  return (
    <div className="mt-12 flex w-full flex-col px-12">
      <CardDialog
        title="First Notice of Loss"
        subheader="Tell us what happened - add all relevant incident details such as date of loss, type of loss, involved parties etc"
      >
        <div className="mt-32 flex w-full flex-col gap-8">
          <span className="text-base font-semibold text-slate-800">Incident Description</span>
          {inputMethod === 'text' ? (
            <TextField
              id="incidentDescription"
              multiline
              rows={5}
              rowsMax={30}
              disabled={isSubmitting}
              variant="outlined"
              value={fnolText}
              onChange={(e) => setFnolText(e.target.value)}
            />
          ) : (
            <>
              <Dropzone onDrop={uploadAudioFile} ref={dropzoneRef}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <ChooseFilesSection
                      getInputProps={getInputProps}
                      getRootProps={getRootProps}
                      dropzoneRef={dropzoneRef}
                      isDragActive={isDragActive}
                      disabled={isUploadingAudioFile}
                    />
                  );
                }}
              </Dropzone>
              <div>
                {fnolAudioFileStorageFilename && (
                  <div>
                    <span className="text-base font-semibold">
                      <span className="text-slate-800">Selected audio file: </span>
                      {selectedAudioFilename}
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
          <div className="mt-20 flex w-full">
            {isTakingLongerThanExpected && (
              <span className="text-base text-slate-700">This is taking longer than usual, please stand by...</span>
            )}
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={
                isSubmitting ||
                (inputMethod === 'text' ? !fnolText : inputMethod === 'audio' ? !fnolAudioFileStorageFilename : false)
              }
              classes={{ root: 'ml-auto', label: 'flex items-center h-20 gap-8' }}
              onClick={handleSubmit}
            >
              <span className="shrink-0">Submit FNOL</span>{' '}
              {isSubmitting && (
                <LoadingIndicator size={16} classes={{ loading: 'flex items-center, justify-middle' }} />
              )}
            </Button>
          </div>
        </div>
      </CardDialog>
    </div>
  );
};

export default FnolAiScreen;
