/* eslint-disable react/display-name */
import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { openTopLevelDialog } from '../CmsMain/globals';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import { getDocumentsTableColumnData } from '../Documents/DocumentsContainer';
import DocumentTextFieldFormik from '../Documents/DocumentTextFieldFormik';
import { useCms } from '../hooks/useCms';
import PencilIcon from '../icons/PencilIcon';
import InlineIconButton from '../InlineIconButton';
import useOrganization from '../OrganizationContext';
import PlainTable from '../PlainTable';
import { MonetaryValueTextFieldFormik, TextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const addOrEditAssessmentDialogFields = {
  document_id: null,
  amount: 0,
  note: '',
};

function AddOrEditAssessmentDialog(props) {
  const { onCancel, onAttach, assessmentData } = props;

  const classes = useStyles();

  const initialValues = { ...addOrEditAssessmentDialogFields, ...assessmentData };
  const title = assessmentData ? 'Edit Assessment' : 'Add Assessment';

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={Yup.object().shape({
        document_id: Yup.number().required('Required'),
        amount: Yup.number().required('Required'),
        note: Yup.string().nullable(),
      })}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        try {
          await onAttach(values);
          resetForm();
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;
        return (
          <CardDialog title={title} isDialog maxWidth="xs" fullWidth onClose={onCancel} preventClose={isSubmitting}>
            <Grid container>
              <Grid item xs={12}>
                <DocumentTextFieldFormik id="document_id" label="Assessment Document" />
              </Grid>
              <Grid item xs={12}>
                <MonetaryValueTextFieldFormik
                  id="amount"
                  label="Amount"
                  allowNegative={false}
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormik id="note" label="Note" fullWidth className={classes.textField} />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Add
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

AddOrEditAssessmentDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAttach: PropTypes.func.isRequired,
  assessmentData: PropTypes.object,
};

function TravelAssessmentDocumentsContainer(props) {
  const { assessmentDocumentsMetaData, onUpdateAssessmentDocumentMetaData, showOnly, maxHeight } = props;
  const classes = useStyles();
  const { claim } = useClaim();
  const [showAttachDocumentDialog, setShowAttachDocumentDialog] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const { documentTypesDict } = useOrganization();
  const { currencyFormatter } = useCurrencyFormatter();
  const { userOrganization } = useCms();

  const addSingleDocument = (documentMetaData, itemToRemove) => {
    const dataWithoutRemovedItem = assessmentDocumentsMetaData.filter(
      (document) => document.document_id !== itemToRemove?.document_id
    );
    if (dataWithoutRemovedItem.find((document) => document.document_id === documentMetaData.document_id)) {
      throw Error('The selected file already exists in this damage assessment');
    }
    onUpdateAssessmentDocumentMetaData([...dataWithoutRemovedItem, documentMetaData]);
  };

  const handleRemoveDocument = (documentId) => {
    const newData = assessmentDocumentsMetaData.filter((document) => document.document_id !== documentId);
    onUpdateAssessmentDocumentMetaData(newData);
  };

  const handleEditDocument = (documentId) => {
    const selectedItem = assessmentDocumentsMetaData.filter((document) => document.document_id === documentId)[0];
    setItemToEdit(selectedItem);
    setShowAttachDocumentDialog(true);
  };

  let columnData = [
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'view_action'),
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'id'),
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'type'),
    {
      id: 'note',
      label: 'Note',
      disableSort: true,
      specialCell: (document) => document.note,
    },
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'document_name'),
    {
      id: 'amount',
      label: 'Amount',
      disableSort: true,
      specialCell: (document) => currencyFormatter.format(document.amount),
    },
    getDocumentsTableColumnData(documentTypesDict, userOrganization).find((c) => c.id === 'document_date'),
    {
      id: 'remove_attachment',
      disableSort: true,
      specialCell: (document) => (
        <InlineIconButton
          icon={DeleteIcon}
          className={classes.textIcon}
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveDocument(document.id);
          }}
        />
      ),
    },
    {
      id: 'edit_row',
      disableSort: true,
      specialCell: (document) => (
        <InlineIconButton
          icon={PencilIcon}
          className={classes.textIcon}
          onClick={(e) => {
            e.stopPropagation();
            handleEditDocument(document.id);
          }}
        />
      ),
    },
  ];

  if (showOnly) {
    columnData = columnData.filter((column) => column.id !== 'remove_attachment' && column.id !== 'edit_row');
  }

  const getDocumentsWithAmountsFromMetaData = (documentsMetaData) => {
    const getDocumentMetaDataByDocumentId = (documentId) => {
      return documentsMetaData?.find((documentMetaData) => documentMetaData.document_id === documentId);
    };

    return claim.documents
      .filter((document) => getDocumentMetaDataByDocumentId(document.id) !== undefined)
      .map((document) => ({
        ...document,
        amount: getDocumentMetaDataByDocumentId(document.id).amount,
        note: getDocumentMetaDataByDocumentId(document.id).note,
      }));
  };

  return (
    <>
      <Grid container>
        {!showOnly && (
          <>
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" item xs={6}>
              <Grid item>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    setItemToEdit(null);
                    setShowAttachDocumentDialog(true);
                  }}
                >
                  <AddIcon className={classes.leftButtonIcon} /> Add Documents
                </Button>
              </Grid>
            </Grid>
            <Grid container direction="column" justify="flex-start" alignItems="flex-end" item xs={6} />
          </>
        )}
        <Grid item xs={12}>
          <PlainTable
            classes={classes}
            columns={columnData}
            rows={getDocumentsWithAmountsFromMetaData(assessmentDocumentsMetaData)}
            maxHeight={maxHeight}
            stickyHeader
          />
        </Grid>
      </Grid>
      {showAttachDocumentDialog && (
        <AddOrEditAssessmentDialog
          assessmentData={itemToEdit}
          onCancel={() => {
            setItemToEdit(null);
            setShowAttachDocumentDialog(false);
          }}
          onAttach={(documentData) => {
            try {
              addSingleDocument(documentData, itemToEdit);
              setShowAttachDocumentDialog(false);
            } catch (error) {
              openTopLevelDialog({ title: 'Error', message: error.message });
            }
          }}
          maxHeight="550px"
        />
      )}
    </>
  );
}

TravelAssessmentDocumentsContainer.propTypes = {
  assessmentDocumentsMetaData: PropTypes.array.isRequired,
  showOnly: PropTypes.bool,
  onUpdateAssessmentDocumentMetaData: requiredIf(PropTypes.func, (props) => !props.showOnly),
  maxHeight: PropTypes.string,
};

export default TravelAssessmentDocumentsContainer;
