import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import SmsContentTextFieldFormik from '~/components/communications/SmsCommunicationCard/SmsContentTextFieldFormik';
import { SMS_FORM_FORMIK_IDS } from '~/components/communications/SmsCommunicationCard/utils';
import Button from '~/components/core/Atomic/Buttons/Button';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import FailedTokensWarningBanner from '~/components/GenericTemplates/FailedTokensWarningBanner';
import GenericTemplateSelectionContainerFormik from '~/components/GenericTemplates/FromTemplate/GenericTemplateSelectionContainerFormik';
import { useCms } from '~/components/hooks/useCms';
import TextFieldFormik from '~/components/TextFieldFormik';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import cn from '../../../Utils/cn';

function SmsCommunicationSpecificBody(props) {
  const { userOrganization } = useCms();

  const classes = useStyles();
  const { communicationInitialFields, isDocument, isNew, communication, onUpdate, signature } = props;

  const [isFromTemplateDialogOpen, setIsFromTemplateDialogOpen] = useState(false);
  const [failedTokens, setFailedTokens] = useState(null);
  const isView = !isNew && !isDocument;
  const isNewUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.COMMUNICATION_UI_2);

  const handleOpenFromTemplateDialog = () => {
    setIsFromTemplateDialogOpen(true);
  };

  const handleCloseFromTemplateDialog = () => {
    setIsFromTemplateDialogOpen(false);
  };

  return (
    <>
      {!isView ? (
        <div>
          {communicationInitialFields.communicationContact &&
          communicationInitialFields.communicationContact.id &&
          communicationInitialFields.communicationContact.phones.length > 0 ? (
            <div className={cn('flex items-center', { ['justify-center']: !isNewUIEnabled })}>
              {communicationInitialFields.communicationContact.phones.length === 1 ? (
                <Typography display="block" variant="subtitle2">
                  {communicationInitialFields.communicationContact.phones[0].phone}
                </Typography>
              ) : (
                <div className={classes.inputContainer}>
                  <TextFieldFormik
                    id="phone_id"
                    label="Phone"
                    className={classes.textField}
                    style={{ width: '280px' }}
                    select
                  >
                    {communicationInitialFields.communicationContact.phones.map((phone) => (
                      <MenuItem key={phone.id} value={phone.id}>
                        {phone.phone}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </div>
              )}{' '}
            </div>
          ) : (
            <></>
          )}

          <div className="my-16 flex items-center justify-end">
            <Button color="primary" onClick={handleOpenFromTemplateDialog}>
              <AddIcon />
              From template
            </Button>
          </div>

          {isFromTemplateDialogOpen ? (
            <GenericTemplateSelectionContainerFormik
              handleClose={handleCloseFromTemplateDialog}
              templateType="sms"
              bodyFieldId={SMS_FORM_FORMIK_IDS.MESSAGE_CONTENT}
              shouldConvertBodyHtmlToText
              signature={signature}
              setFailedTokens={setFailedTokens}
            />
          ) : null}
        </div>
      ) : (
        // isView:
        communication.is_delivery_issue_error_exists && (
          <Typography display="block" color="secondary" variant="caption">
            Sms Delivery Failed
            {communication.delivery_error_reason ? ` - ${communication.delivery_error_reason}` : ''}
          </Typography>
        )
      )}
      <SmsContentTextFieldFormik communication={communication} isView={isView} isNew={isNew} onUpdate={onUpdate} />
      <FailedTokensWarningBanner failedTokens={failedTokens} />
    </>
  );
}

SmsCommunicationSpecificBody.propTypes = {
  communication: requiredIf(PropTypes.object, (props) => !props.isNew && !props.isDocument),
  onUpdate: requiredIf(PropTypes.func, (props) => !props.isNew && !props.isDocument),
  isNew: PropTypes.bool,
  isDocument: PropTypes.bool,
  communicationInitialFields: PropTypes.shape({
    communicationChannel: PropTypes.string.isRequired,
    communicationContact: PropTypes.object.isRequired,
    communicationDirection: requiredIf(PropTypes.string, (props) => props.isNew),
  }),
  signature: PropTypes.string,
};

export default SmsCommunicationSpecificBody;
