import React from 'react';
import { ReactTyped } from 'react-typed';
import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { Theme } from '@mui/material';
import { Alert, createStyles } from '@mui/material';
import { Remarkable } from 'remarkable';

import { useStyles } from '~/assets/styles';
import { AiChatDisclaimer } from '~/components/AiChat/AiChatContainer/AiChatDisclaimer';
import { MessageActions } from '~/components/AiChat/components/Message/MessageActions';
import {
  NegativeFeedbackIconButton,
  PositiveFeedbackIconButton,
} from '~/components/AiChat/components/Message/MessageFeedbackIconButton';
import { MessageReferences } from '~/components/AiChat/components/Message/MessageReferences';
import type { BaseMessageProps } from '~/components/AiChat/components/Message/MessageTypes';
import { useInterval } from '~/components/AiChat/components/Message/useInterval';
import { MessageLoader } from '~/components/AiChat/components/MessageLoader/MessageLoader';
import { useChatContext } from '~/components/AiChat/hooks/useChat';
import { FsIconButton, Text } from '~/components/core';
import { RefreshIcon } from '~/components/icons';
import { serverDateTimeToLocal } from '~/DateTimeUtils';
import cn from '~/Utils/cn';

const useAvatarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(5.8),
      height: theme.spacing(5.8),
    },
  })
);

export const BaseMessage: React.FC<BaseMessageProps> = ({
  text,
  avatar,
  sender,
  isLoading,
  onUserFeedback,
  onRegenerate,
  withActions,
  withFeedback,
  withTypeEffect,
  onType,
  onTypeEnd,
  exchangeId,
  currentUserFeedback,
  messageContainer,
  referencesIds,
  showDisclaimer = false,
  createdAt,
}) => {
  const classes = useStyles() as unknown as { hoverableIcon: string; hoverableNonFilledIcon: string };
  const styles = useStyles() as unknown as { avatar: string };
  const avatarStyles = useAvatarStyles();
  const { references = {} } = useChatContext();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const avatarClasses = cn(avatarStyles.small, styles.avatar);
  const modifiedAvatar = React.cloneElement(avatar, {
    className: cn(avatarClasses, avatar.props.className),
  });

  const [snackBarText, setSnackBarText] = React.useState<string | null>('');
  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState<boolean>(
    typeof currentUserFeedback === 'number' && currentUserFeedback !== 0
  );

  const { start: startTyping, stop: stopTyping } = useInterval(onType, 250);

  const handleTypingEnd = () => {
    stopTyping();
    if (onTypeEnd) {
      onTypeEnd();
    }
  };

  const showSnackBar = (text: string) => {
    setSnackBarText(text);
  };

  const closeSnackbar = () => {
    setSnackBarText(null);
  };

  const handleUserFeedback = (exchangeId: number, feedbackScore: number, feedbackText?: string) => {
    if (!onUserFeedback) return;
    setFeedbackSubmitted(true);
    onUserFeedback(exchangeId, feedbackScore, feedbackText || '');

    showSnackBar(
      feedbackScore > 0
        ? 'Thank you for your feedback!'
        : 'Thank you for your feedback, we will try to do better next time!'
    );
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(text);
    showSnackBar('Copied to clipboard');
  };

  const firstColumnClass = 'block min-w-[40px]';

  const md = new Remarkable();
  const parsedText = md.render(text);

  const MessageContainer = messageContainer;

  return (
    <div className="flex w-full flex-col">
      <MessageContainer className={cn('flex w-full flex-col rounded-xl')}>
        <div className="flex w-full flex-col rounded-xl p-12">
          <div className="__HEADER__ flex w-full items-center">
            <div className={firstColumnClass}>{modifiedAvatar}</div>
            <Text variant={Text.VARIANTS.SM} className="font-bold">
              {sender}
            </Text>
            {withActions ? (
              <div className="__CONTENT_ACTIONS__ flex w-full justify-end">
                <MessageActions type="outlined" onCopy={handleCopyToClipboard} />
              </div>
            ) : null}
          </div>
          <div className="__TEXT_CONTAINER__ flex w-full">
            <div className="flex w-full flex-col pl-[40px]">
              {isLoading && <MessageLoader />}
              {!isLoading && (
                <div className="flex w-full">
                  <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                    {withTypeEffect && onType && (
                      <ReactTyped
                        strings={[parsedText]}
                        typeSpeed={3}
                        cursorChar=""
                        onBegin={startTyping}
                        onStart={startTyping}
                        onComplete={handleTypingEnd}
                      />
                    )}
                    {(!withTypeEffect || !onType) && <span dangerouslySetInnerHTML={{ __html: parsedText }} />}
                  </Text>
                </div>
              )}
            </div>
          </div>
          <div className="ml-40">
            <MessageReferences referencesIds={referencesIds} references={references} />
          </div>

          <div className="__MESSAGE_FOOTER__ flex w-full ">
            <div className="ml-auto mr-4 text-xs text-slate-700">{createdAt && serverDateTimeToLocal(createdAt)}</div>
          </div>
        </div>
      </MessageContainer>
      <div className="__MANAGEMENT_ACTIONS__ mt-2 flex w-full justify-end gap-2">
        {showDisclaimer && (
          <span className="mr-auto">
            <AiChatDisclaimer />
          </span>
        )}
        {withActions && (
          <>
            {onRegenerate && (
              <FsIconButton
                className={cn(classes.hoverableNonFilledIcon, 'ml-[-2px]')}
                tooltipText="Regenerate Response"
                onClick={onRegenerate}
              >
                <RefreshIcon size={20} />
              </FsIconButton>
            )}
            {onUserFeedback && exchangeId && withFeedback && (
              <>
                <NegativeFeedbackIconButton
                  onClick={handleUserFeedback}
                  exchangeId={exchangeId}
                  currentScore={currentUserFeedback}
                  disabled={feedbackSubmitted}
                />
                <PositiveFeedbackIconButton
                  onClick={handleUserFeedback}
                  exchangeId={exchangeId}
                  currentScore={currentUserFeedback}
                  disabled={feedbackSubmitted}
                />
              </>
            )}
          </>
        )}
      </div>
      <Snackbar open={!!snackBarText} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="success">
          {snackBarText}
        </Alert>
      </Snackbar>
    </div>
  );
};
