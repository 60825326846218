import React from 'react';

import type { ActionFooterProps } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionTypes';
import { ExecuteButton } from '~/components/AiChat/SideBarDialog/Actions/Action/ExecuteButton';

export const ActionFooter: React.FC<ActionFooterProps> = ({ onExecute, isDone }) => {
  return (
    <div className="flex">
      <ExecuteButton onExecute={onExecute} isDone={isDone} />
    </div>
  );
};
