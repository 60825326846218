import React from 'react';

import type { CoverageIssue } from '~/components/AiChat/types';

interface CoverageTestResultProps {
  testDescription: string;
  testPassed: boolean;
  issue?: CoverageIssue;
}

export const CoverageTestResult: React.FC<CoverageTestResultProps> = ({ testDescription, testPassed, issue }) => {
  const colorClass = testPassed ? 'bg-green-700 text-white' : 'bg-yellow-700 ';
  const subHeadingClass = 'min-w-fit font-medium pt-2 pb-1';

  return (
    <div className="gap-1 flex flex-col text-sm">
      <div className="gap-3 flex items-baseline gap-12">
        <span className="test-description w-fit max-w-[80%] text-base font-bold">{testDescription}</span>
        <span className={`badge rounded-full text-sm ${colorClass} h-fit w-fit px-12 py-2 font-bold`}>
          {testPassed ? 'PASSED' : 'ISSUE'}
        </span>
      </div>
      {!testPassed && issue ? (
        <div className="px-3 gap-1 flex flex-col">
          <div className="flex flex-col gap-8">
            <span className={subHeadingClass}>Coverage Issue:</span>
            <ul>
              <li className="flex w-full flex-col">
                {issue.issue_description}
                <span> {issue.explanation}</span>
              </li>
            </ul>

            <div hidden={!issue.quote_from_policy}>
              <span className={subHeadingClass}>Statement from policy:</span>
              <blockquote className="text-gray-900 text-sm italic">&quot;{issue.quote_from_policy}&quot;</blockquote>
            </div>

            <div hidden={!issue.quote_from_incident}>
              <span className={subHeadingClass}>Relevant claim information:</span>
              <span className="ml-2">{issue.quote_from_incident}</span>
            </div>
          </div>
          <span className="b mt-12 flex w-fit gap-12 rounded-full bg-pink-100 px-12 py-8">
            <span className="min-w-fit font-bold ">Recommended Action: </span>
            <span className="font-bold text-slate-900">
              {issue.recommended_action === 'deny' ? 'Consider Coverage' : 'Approve Coverage'}
            </span>
          </span>
        </div>
      ) : null}
    </div>
  );
  //
};
