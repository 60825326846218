import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';
import { noop } from 'lodash';

import cn from '~/Utils/cn';

import OverflowTextWithToolTip from '../../../../OverflowTextWithToolTip';
import { DisabledChildrenWrapperWithTooltip } from '../../../';
import { getColumnPadding } from '../utils';

import { useStyles } from '../../../../../assets/styles';
import styles from './sortableTableCell.module.scss';

const SortableTableCell = ({
  column,
  row,
  isSelected,
  sortDirection,
  maxWidth,
  isBold,
  isGreyedOut,
  onRowClick = noop,
  isRestricted = false,
  restrictedContentTooltipText = '',
}) => {
  const classes = useStyles();

  const shouldHideContent = isRestricted && column.hideRestrictedContent;
  const getCellContent = () => {
    if (shouldHideContent) {
      return '';
    }

    const content = column.specialCell ? column.specialCell(row) : row[column.id];

    return isRestricted ? (
      <DisabledChildrenWrapperWithTooltip tooltipText={restrictedContentTooltipText}>
        {content}
      </DisabledChildrenWrapperWithTooltip>
    ) : (
      content
    );
  };

  const className = cn(classes.tableCell, styles.tableCellContainer, {
    [styles.selectedCell]: isSelected,
    [styles.boldCell]: isBold,
    [styles.disabledCell]: isGreyedOut && !isRestricted,
    [styles.activeCell]: !isGreyedOut && !isRestricted,
    [styles.restrictedCell]: isRestricted,
  });

  return (
    <TableCell
      style={{
        width: column.width,
        ...getColumnPadding(column),
        ...row.styles,
      }}
      key={`${column.id}_${row.id}`}
      align={column.align || 'left'}
      sortDirection={sortDirection}
      onClick={() => onRowClick(row.id)}
      className={className}
    >
      <OverflowTextWithToolTip disabled={!column.overflow} maxWidth={maxWidth}>
        <span className={column.nowrap && styles.wrappedTooltip}>{getCellContent()}</span>
      </OverflowTextWithToolTip>
    </TableCell>
  );
};

SortableTableCell.propTypes = {
  column: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  row: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isBold: PropTypes.bool,
  isGreyedOut: PropTypes.bool,
  highlightOnHover: PropTypes.bool,
  isRestricted: PropTypes.bool,
  restrictedContentTooltipText: PropTypes.string,
};

export default SortableTableCell;
