import type { ReactElement } from 'react';
import React, { useState } from 'react';

import cn from '~/Utils/cn';

import { ChevronUpIcon } from '../../../icons';

import type { CollapsibleCardProps } from './types';

interface UseCollapsibleProps extends CollapsibleCardProps {
  chevronClassName?: string;
}

export const useCollapsible = ({
  collapsible,
  isOpen,
  openByDefault,
  onCollapseClick,
}: UseCollapsibleProps): {
  onClick: () => void;
  getChevron: ({ iconColor, className }: { iconColor?: string; className?: string }) => React.ReactNode | null;
  isOpenCombined: boolean;
} => {
  const [isCollapseOpenInternal, setIsCollapseOpenInternal] = useState(openByDefault);
  const isOpenCombined = Boolean(isOpen ?? isCollapseOpenInternal);

  const onClick = () => {
    if (!collapsible) {
      return;
    }
    setIsCollapseOpenInternal(!isCollapseOpenInternal);
    if (onCollapseClick) {
      onCollapseClick(!isOpen);
    }
  };

  const getChevron = ({ iconColor, className }: { iconColor?: string; className?: string }) =>
    getCollapsibleChevron(isOpenCombined, iconColor, className, collapsible);

  return {
    onClick,
    getChevron,
    isOpenCombined,
  };
};

export const getCollapsibleChevron = (
  isOpen: boolean,
  iconColor?: string,
  className?: string,
  collapsible?: boolean
): null | ReactElement => {
  if (!collapsible) {
    return null;
  }

  return (
    <ChevronUpIcon
      className={cn(className, 'rotate-180 transition-transform duration-200', { ['rotate-0']: isOpen })}
      iconColor={iconColor}
    />
  );
};
