import React from 'react';
import { useFormikContext } from 'formik';
import { get, isEqual } from 'lodash';

import { calcExposureIds } from '~/components/communications/CommunicationCard/utils';
import type { ContactFullModel } from '~/components/Contacts/types';
import ExposureMultiSelectTextFieldFormik from '~/components/ExposureMultiSelectTextFieldFormik';
import { useCms } from '~/components/hooks/useCms';
import { useFetchClaim } from '~/Utils/ClaimUtils';

import type { DocumentMetadata } from '../types';

interface PartialFormikValues {
  claim_id: number;
  contact?: ContactFullModel;
  attached_documents?: DocumentMetadata[];
  exposure_ids?: number[];
  exposure_ids_specific_requested?: number[];
}

interface CommunicationExposureLabelsProps {
  isView?: boolean;
  isEditing?: boolean;
  onEdit?: () => void;
}

const CommunicationExposureLabels: React.FC<CommunicationExposureLabelsProps> = ({ isView, isEditing, onEdit }) => {
  const { values, setFieldValue } = useFormikContext<PartialFormikValues>();
  const [claim, isLoading, isError] = useFetchClaim(values.claim_id);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = useCms();
  const [shouldSetExposuresAutomatically, setShouldSetExposuresAutomatically] = React.useState(
    !isView && !get(values, 'exposure_ids_specific_requested')
  );

  const currentExposureIds = get(values, 'exposure_ids', []);
  const exposureIdsCombined = calcExposureIds(claim, user, get(values, 'contact'), get(values, 'attached_documents'));

  React.useEffect(() => {
    if (!shouldSetExposuresAutomatically) {
      return;
    }

    if (isView && !isEditing) {
      return;
    }

    if (!isEqual(exposureIdsCombined, currentExposureIds)) {
      setFieldValue('exposure_ids', exposureIdsCombined);
    }
  }, [currentExposureIds, exposureIdsCombined, setFieldValue, isView, isEditing, shouldSetExposuresAutomatically]);

  if (isLoading || isError || !claim) {
    return null;
  }

  return (
    <ExposureMultiSelectTextFieldFormik
      claim={claim}
      showOnly={isView && !isEditing}
      onEdit={onEdit}
      onChange={(value: number[]) => {
        setFieldValue('exposure_ids', value);
        setShouldSetExposuresAutomatically(false);
      }}
    />
  );
};

export default CommunicationExposureLabels;
