import React from 'react';

import type { AiChatReference, Insight } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import { useCms } from '~/components/hooks/useCms';
import useDataFetcher from '~/components/useDataFetcher';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

export interface IInsightsContextData {
  insights: Insight[];
  references: Record<string, AiChatReference>;
  isLoading: boolean;
  isError: boolean;
  reloadData: () => Promise<void>;
}

const InsightsContext = React.createContext<IInsightsContextData>({
  insights: [],
  references: {},
  isLoading: true,
  isError: false,
  reloadData: Promise.resolve,
});

const keyToLevel: { [key: string]: number } = {
  inconsistency: 1,
  liability_implications: 2,
  risk_factors: 3,
};

function orderInsightsByKey(a: Insight, b: Insight) {
  try {
    const aLevel = keyToLevel[a.insight_key];
    const bLevel = keyToLevel[b.insight_key];
    if (aLevel === bLevel) return 0;
    return aLevel > bLevel ? 1 : -1;
  } catch (e) {
    console.error('An error occurred while sorting the insights', e);
    return 0;
  }
}

export const InsightsContextProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const { claim } = useClaim();
  const itcEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.ITC_PRESENTATION);
  const {
    isLoading,
    isError,
    data: responseData,
    reloadData,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/chat/insights`, {}, itcEnabled);

  const { insights, references }: { insights: Insight[]; references: Record<string, AiChatReference> } =
    responseData || {};

  const orderedInsights = insights?.sort(orderInsightsByKey);

  return (
    <InsightsContext.Provider value={{ insights: orderedInsights, references, isLoading, isError, reloadData }}>
      {children}
    </InsightsContext.Provider>
  );
};

export const useInsights = (): IInsightsContextData => {
  return React.useContext(InsightsContext);
};
