import React from 'react';

import { useClaim } from '~/components/ClaimContainer';
import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context/index';
import NavigationBlocker from '~/components/core/NavigationBlocker';

const ClaimPageNavigationBlocker: React.FC = ({ children }) => {
  const { claim } = useClaim();
  const { isDialogContextEmpty } = useMinimizedDialogs();

  return (
    <NavigationBlocker
      shouldPreventNavigation={(pathname) => {
        return claim?.id && !isDialogContextEmpty() && !pathname.includes(`/claims/${claim.id}`);
      }}
      getConfirmationMessage={() =>
        'There are unsaved active dialog which are currently minimized.\n\n Leaving the claim will discard any unsaved changes.\n\n' +
        'Are you sure you want to continue?'
      }
    >
      {children}
    </NavigationBlocker>
  );
};

export default ClaimPageNavigationBlocker;
