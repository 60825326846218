import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import queryString from 'query-string';

import { CriticalityLevel, SafeComponentPage } from '~/components/core/SafeComponent';
import { SafeRoute } from '~/components/core/SafeComponent/SafeRoute';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';
import { ReportsPage } from '~/components/ReportsPage/ReportsPage';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import {
  isSysConfigEditor,
  isUserFiveSigma,
  isUserImpersonated,
  isUserITManagerRole,
  isUserOshaRep,
  isUserSuperUser,
} from '~/UserUtils';
import { isFeatureEnabled, isHospitalityUser, isMgmUser, isProductionEnv, isQoverUser, isRwUser } from '~/Utils';

import AdvancedChecksSearchPage from '../AdvancedChecksSearchPage';
import AdvancedSearchPage from '../AdvancedSearch/AdvancedSearchPage';
import CalendarContainer from '../CalendarContainer';
import ChecksPage from '../ChecksPage';
import ClaimContainer from '../ClaimContainer';
import { ClaimPage } from '../ClaimPage';
import { ClaimQaDashboard } from '../ClaimQA';
import ClaimsScreenContainer from '../ClaimsScreen';
import LossReportPage from '../ClientSpecific/Inshur/LossReport';
import MgmMedicalBillsPage from '../ClientSpecific/Mgm/MgmMedicalBillsPage';
import CmsBar from '../CmsBar';
import CmsDrawer from '../CmsDrawer';
import AdjusterCommunications from '../communications/AdjusterCommunicationsPage';
import { AttachCommunicationPage } from '../communications/AttachCommunicationContainer';
import CmsCallCenter from '../communications/CmsCallCenter';
import PhoneCallsDashboardPage from '../communications/PhoneCallsDashboardPage';
import OrganizationContactsScreen from '../Contacts/OrganizationContactsScreen/OrganizationContactsScreen';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, PermissionDeniedPage, PermissionProtectedRoute } from '../core';
import { isPermissionsEnabled } from '../core/Permissions/PermissionUtils';
import CustomClaimConfigurations from '../CustomClaim/CustomClaimConfigurations';
import DamageAssessmentReinspectionsScreen from '../exposures/DamageAssessmentReinspectionsScreen';
import FieldInspectionQueue from '../FieldInspectionQueue';
import FileClassificationPage from '../FileClassificationPage';
import FinanceApprovalTab from '../Finances/FinanceApprovalsTab';
import FnolContainer from '../Fnol/FnolContainer';
import GeneralEmailClassification from '../GeneralEmailClassification';
import HomeScreenContainer from '../HomeScreen';
import { useCms } from '../hooks/useCms';
import useCurrentUserRoles from '../hooks/useCurrentUserRoles';
import { UpdatePasswordPage } from '../Login';
import MgmAdvancedSearchPage from '../MgmAdvancedSearchPage';
import NotificationsPage from '../NotificationsPage';
import useOrganization from '../OrganizationContext';
import OshaReports from '../OshaReports';
import PoliciesTab from '../PoliciesTab';
import QoverClaimsPageContainer from '../Qover/QoverClaimsPage';
import RecoveriesPage from '../RecoveriesPage';
import ReportsDashboardPage from '../ReportsDashboardsPage/ReportsDashboardPage';

import { openTopLevelDialog } from './globals';

export const SystemConfigurationScreen = lazy(() =>
  import(
    /* webpackChunkName: "systemConfiguration" */ '../SystemConfiguration/SystemConfiguration/ExternalConfiguration'
  )
);
export const AdminRoute = lazy(() => import(/* webpackChunkName: "adminRoute" */ '../Admin/AdminRoute'));
export const AdminPage = lazy(() => import(/* webpackChunkName: "adminPage" */ '../Admin/AdminPage'));
export const AppStatsPage = lazy(() => import(/* webpackChunkName: "appStatsPage" */ '../Admin/AppStatsPage'));

function CmsMainAuthenticatedInner({ classes, user, location, history, callInProgress, setCallInProgress }) {
  const [openUpdatePasswordDialog, setOpenUpdatePasswordDialog] = useState(false);
  const { userOrganization } = useCms();
  const { isApprovalPaymentsScreenEnabled, isPaymentsSubmissionScreenEnabled } = usePaymentsConfiguration();
  const { isGeneralEmailQueueEnabled } = useOrganization();

  const { isUserAdjuster } = useCurrentUserRoles();

  const wasDefaultTitleSet = React.useRef(false);
  if (!wasDefaultTitleSet.current) {
    wasDefaultTitleSet.current = true;
    document.title = 'Five Sigma CMS'; // set the document.title to the general default, we expect tabs to change it
  }

  useEffect(() => {
    if (callInProgress) {
      if (location.pathname.startsWith('/call_in_progress')) {
        const routerQueryDict = queryString.parse(location.search);
        let newCallInProgress = {
          ...callInProgress,
          callSid: routerQueryDict.callSid,
          currCallSid: routerQueryDict.currCallSid,
          communicationId: routerQueryDict.communicationId,
        };
        if (routerQueryDict.claimId) {
          newCallInProgress['claimId'] = routerQueryDict.claimId;
          history.push(`/claims/${routerQueryDict.claimId}`);
        }

        setCallInProgress(newCallInProgress);
      }
    }
  }, [location, history, callInProgress, setCallInProgress]);

  const getSwitch = () => {
    if (isUserOshaRep(user)) {
      return (
        <Switch>
          <Redirect exact from="/" to="/osha_reports" />
          <Route path="/osha_reports" component={OshaReports} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return (
      <Switch>
        {(isPermissionsEnabled(userOrganization) || isSysConfigEditor(user) || isUserSuperUser(user)) && (
          <PermissionProtectedRoute
            path="/sysconfig"
            action={PERMISSION_ACTIONS.SYSTEM_CONFIGURATION}
            verb={PERMISSION_VERBS.FULL}
            component={SystemConfigurationScreen}
          />
        )}
        {
          isUserITManagerRole(user) && !isPermissionsEnabled(userOrganization) && (
            <Redirect to="/sysconfig" />
          ) /* IT Manager is only allowed to /sysconfig, so redirect her */
        }
        <Redirect exact from="/" to="/home" />
        {isUserFiveSigma(user) && <Redirect from="/claims" to="/home" />}
        <PermissionProtectedRoute
          path="/claims/:claimId"
          action={PERMISSION_ACTIONS.CLAIM}
          verb={PERMISSION_VERBS.READ}
          render={(routerProps) => (
            <ClaimContainer claimId={Number(routerProps.match.params.claimId)}>
              <ClaimPage />
            </ClaimContainer>
          )}
          safeConfig={{ criticalityLevel: CriticalityLevel.HIGHEST }}
        />
        <SafeRoute
          path="/communications/:communicationId"
          render={(routerProps) => (
            <AttachCommunicationPage communicationId={Number(routerProps.match.params.communicationId)} />
          )}
        />
        <SafeRoute
          path="/claims"
          component={
            isQoverUser(user) &&
            !isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.MIGRATE_QOVER_TO_STANDARD)
              ? QoverClaimsPageContainer
              : ClaimsScreenContainer
          }
        />
        <SafeRoute
          path="/home"
          component={HomeScreenContainer}
          safeConfig={{ criticalityLevel: CriticalityLevel.HIGHEST }}
        />
        <PermissionProtectedRoute
          path="/notifications"
          action={PERMISSION_ACTIONS.NOTIFICATION}
          verb={PERMISSION_VERBS.READ}
          component={NotificationsPage}
        />
        <PermissionProtectedRoute
          path="/fnol"
          action={PERMISSION_ACTIONS.CREATE_FNOL}
          verb={PERMISSION_VERBS.WRITE}
          component={FnolContainer}
        />
        <SafeRoute path="/classification" component={FileClassificationPage} />
        <SafeRoute path="/calendar" render={() => <CalendarContainer isDialog={false} />} />
        {/*Ugly HACK to not crash in production when we enter the dashboard */}
        {isUserFiveSigma(user) && isProductionEnv() && (
          <SafeRoute path="/phone_calls_dashboard" component={PhoneCallsDashboardPage}>
            <Redirect to="/organizations/5/phone_calls_dashboard" />
          </SafeRoute>
        )}
        {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.PHONE_CALLS_DASHBOARD) &&
          (user.is_org_level_supervisor || user.is_manager) && (
            <SafeRoute path="/phone_calls_dashboard" component={PhoneCallsDashboardPage} />
          )}
        {isUserFiveSigma(user) && (
          <SafeRoute
            path="/organizations/:organizationId/phone_calls_dashboard"
            render={(routerProps) => (
              <PhoneCallsDashboardPage organizationId={Number(routerProps.match.params.organizationId)} />
            )}
          />
        )}
        {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.LOSS_REPORT_TEMP) && (
          <SafeRoute path="/loss_report" component={LossReportPage} />
        )}
        {(isUserFiveSigma(user) || isMgmUser(user)) && <Route path="/medical_bills" component={MgmMedicalBillsPage} />}
        {isMgmUser(user) &&
        isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.T__MGM__ADVANCED_CHECKS_SEARCH) ? (
          <SafeRoute path="/organization_checks" component={AdvancedChecksSearchPage} />
        ) : (
          <SafeRoute path="/organization_checks" component={ChecksPage} />
        )}
        {isMgmUser(user) &&
        isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.T__MGM__ADVANCED_CHECKS_SEARCH) ? (
          <SafeRoute path="/organization_gl_checks" component={AdvancedChecksSearchPage} />
        ) : (
          <SafeRoute path="/organization_gl_checks" component={ChecksPage} />
        )}
        <SafeRoute path="/recoveries" component={RecoveriesPage} />
        <PermissionProtectedRoute
          path="/contacts"
          component={OrganizationContactsScreen}
          action={PERMISSION_ACTIONS.CONTACT}
          verb={PERMISSION_VERBS.READ}
        />
        {(isApprovalPaymentsScreenEnabled || isPaymentsSubmissionScreenEnabled) && (
          <SafeRoute path="/finance_approvals" component={FinanceApprovalTab} />
        )}
        <SafeRoute path="/communications" component={AdjusterCommunications} />
        <SafeRoute path="/osha_reports" component={OshaReports} />
        <SafeRoute path="/custom_configurations" component={CustomClaimConfigurations} />
        {(isMgmUser(user) || isRwUser(user) || isHospitalityUser(user)) &&
          isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.T__MGM__ADVANCED_SEARCH) && (
            <SafeRoute path="/advanced_search" component={MgmAdvancedSearchPage} />
          )}
        {isGeneralEmailQueueEnabled && (
          <PermissionProtectedRoute
            path="/general_email_classification"
            action={PERMISSION_ACTIONS.GENERAL_EMAIL_CLASSIFICATION}
            verb={PERMISSION_VERBS.READ}
            component={GeneralEmailClassification}
          />
        )}
        <SafeRoute path="/field_inspection_queue" component={FieldInspectionQueue} />
        {(isPermissionsEnabled(userOrganization) || user.is_org_level_supervisor || user.is_manager) &&
          isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.QA_DASHBOARD) && (
            <PermissionProtectedRoute
              path="/qa-dashboard"
              action={PERMISSION_ACTIONS.QA}
              verb={PERMISSION_VERBS.READ}
              component={ClaimQaDashboard}
            />
          )}
        {user.is_org_level_supervisor &&
          isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.DA_REINSPECTION) && (
            <SafeRoute path="/da-reinspection" component={DamageAssessmentReinspectionsScreen} />
          )}
        {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.POLICIES_SEARCH_PAGE) && (
          <SafeRoute path="/policies" component={PoliciesTab} />
        )}
        {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.FF_TABLEAU_REPORTS) && (
          <SafeRoute path="/reports" component={ReportsDashboardPage} />
        )}
        {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.FF_TABLEAU_SELF_SERVICE) && (
          <SafeRoute path="/reports_v2" component={ReportsPage} />
        )}
        {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.ADVANCED_SEARCH_DIALOG) && (
          <SafeRoute path="/advanced_search" component={AdvancedSearchPage} />
        )}
        <AdminRoute path="/admin" component={AdminPage} />
        <AdminRoute path="/app_stats" component={AppStatsPage} />
        {/* Permission denied routes */}
        <SafeRoute
          path="/permission_denied/:action/:verb"
          render={(routerProps) => (
            <PermissionDeniedPage action={routerProps.match.params.action} verb={routerProps.match.params.verb} />
          )}
        />
        <SafeRoute path="/permission_denied" component={PermissionDeniedPage} />
        <Redirect to="/" />
      </Switch>
    );
  };

  return (
    <div className={classes.root}>
      <CmsDrawer onUpdatePassword={() => setOpenUpdatePasswordDialog(true)} />
      <CmsBar onUpdatePassword={() => setOpenUpdatePasswordDialog(true)} />
      <div className={classes.cmsInner}>
        <SafeComponentPage location="cms_main_inner" criticalityLevel={CriticalityLevel.HIGHEST}>
          <Suspense fallback={<CircularProgress />}>{getSwitch()}</Suspense>
          {isUserAdjuster && user.is_call_center_allowed && !(isProductionEnv() && isUserImpersonated(user)) && (
            <CmsCallCenter />
          )}
        </SafeComponentPage>
      </div>
      {openUpdatePasswordDialog && (
        <UpdatePasswordPage
          onClose={() => setOpenUpdatePasswordDialog(false)}
          onPasswordUpdated={() => {
            setOpenUpdatePasswordDialog(false);
            openTopLevelDialog({
              title: 'Password has been updated successfully',
              message: '',
            });
          }}
        />
      )}
    </div>
  );
}

CmsMainAuthenticatedInner.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  callInProgress: PropTypes.object,
  setCallInProgress: PropTypes.func,
  history: PropTypes.object.isRequired, // comes from withRouter
  location: PropTypes.object.isRequired, // comes from withRouter
};

export default CmsMainAuthenticatedInner;
