import React, { useState } from 'react';
import { useCurrentEditor } from '@tiptap/react';

import type { onUploadFinishFunc } from '~/components/core/Templates/UploadImageDialog';
import UploadImageDialog from '~/components/core/Templates/UploadImageDialog';
import { GalleryIcon } from '~/components/icons';

import ToolbarButton from '../ToolbarButton';

interface ImagesProps {
  onUploadFinish?: onUploadFinishFunc;
}

const Images: React.FC<ImagesProps> = ({ onUploadFinish }) => {
  const [isUploadImageOpen, setIsUploadImageOpen] = useState(false);
  const { editor } = useCurrentEditor();

  return (
    <>
      <ToolbarButton onClick={() => setIsUploadImageOpen(true)}>
        <GalleryIcon />
      </ToolbarButton>
      {isUploadImageOpen ? (
        <UploadImageDialog
          onClose={() => setIsUploadImageOpen(false)}
          onFileUploaded={(url) => {
            if (url) {
              editor?.chain().focus().setImage({ src: url }).run();
              setIsUploadImageOpen(false);
            }
          }}
          onUploadFinish={onUploadFinish}
        />
      ) : null}
    </>
  );
};

export default Images;
