import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import axios from 'axios';
import { Formik } from 'formik';
import { capitalize, get } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import cn from '~/Utils/cn';

import { getClaimantLocationInAutoIncident } from '../../AutoClaimUtils';
import { isoDateToUs } from '../../DateTimeUtils';
import {
  AUTO_LIABILITY_PERCENTAGE_OF_INSURE_LIABILITY_LIST,
  COUNTRIES_DICT,
  COUNTRY_TO_STATE_MAP,
  EXTENT_OF_INJURY_TYPES_DICT,
  LIABILITY_LIEN_TYPES_DICT,
  TREATMENT_SURGERIES_DICT,
} from '../../Types';
import { isInshurClaim, isTscClaim, reportAxiosError, reportErrorInProductionOrThrow } from '../../Utils';
import { getFirstPartyVehicleFromClaim } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { getAllSearchableContactRoles } from '../communications/ContactUtils';
import { ContactEntity } from '../Contact';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { SortableTable } from '../core';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import DisableableToolTip from '../DisableableTooltip';
import DocumentLink from '../Documents/DocumentLink';
import DocumentTextFieldFormik from '../Documents/DocumentTextFieldFormik';
import { isDocumentAPhoto } from '../Documents/DocumentUtils';
import { getInitialFaultAssessmentPredefinedFields } from '../Fnol/NewFnolUI/AdditionalInformation/Fragments/InitialFaultAssessmentFragment/index';
import PhotosLink from '../Gallery/PhotosLink';
import { lossLocationToString } from '../GlobalLossLocation';
import { useCms } from '../hooks/useCms';
import { useIncidentConfiguration } from '../hooks/useIncidentConfiguration';
import HoverActionField from '../HoverActionField';
import HoverChangeField, { SelectHoverChangeField } from '../HoverChangeField';
import { CustomField } from '../IncidentConfiguration/ConfiguredFields';
import InlineIconButton from '../InlineIconButton';
import { REPRESENTATIVE_CONTACT_TYPES } from '../InvolvedPerson';
import LoadingDialog from '../LoadingDialog';
import { ExposureNotesCard } from '../Note';
import useOrganization from '../OrganizationContext';
import {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  MultiSelectTextFieldFormik,
  ShowOnlyTextField,
  TextFieldFormik,
} from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import ExposureTooltipedLock from './ExposureTooltipedLock';
import { isExposureWriteDisabled } from './ExposureUtils';
import IcdCodesFieldContainer from './IcdCodesContainer';
import { ExposureLitigationStatusDialog } from './LitigationStatusDialog';

import { useStyles } from '../../assets/styles';

function LiabilityDashboardContainer({ claimId, exposure, viewOnly }) {
  const { user } = useCms();
  const { claim: claimInContext, onClaimUpdate } = useClaim();
  const [showDashboardDialog, setShowDashboardDialog] = useState(false);

  if (!claimInContext || claimInContext.id !== claimId) {
    reportErrorInProductionOrThrow('LiabilityDashboardContainer should be within claim context');
    return <></>;
  }

  // if gl_claim skip the next check
  if (exposure.is_coverage_issue_exists && claimInContext.type !== 'gl_claim') {
    return <ExposureTooltipedLock exposure={exposure} />;
  }

  const viewOnlyBiDashboard = viewOnly || isExposureWriteDisabled(exposure, user);

  return (
    <>
      <HoverActionField icon={OpenInNewIcon} onAction={() => setShowDashboardDialog(true)} permanent />
      {showDashboardDialog && (
        <LiabilityDashboardDialog
          claim={claimInContext}
          onUpdate={onClaimUpdate}
          exposure={exposure}
          onClose={() => setShowDashboardDialog(false)}
          viewOnly={viewOnlyBiDashboard}
        />
      )}
    </>
  );
}

LiabilityDashboardContainer.propTypes = {
  claimId: PropTypes.number.isRequired,
  exposure: PropTypes.object.isRequired,
  viewOnly: PropTypes.bool,
};

function LiabilityDashboardDialog({ claim, exposure, onUpdate, onClose, viewOnly }) {
  const classes = useStyles();
  const [showNegotiationAndLitigationDialog, setShowNegotiationAndLitigationDialog] = useState(false);
  // PDEBT - old code for TSC and Inshur relied on coverage_collision and coverage_pd to find relevant vehicles. Future version will probably remove entirely
  const shouldShowParties = isInshurClaim(claim) || isTscClaim(claim);

  // For now, the default is Auto Claim, and we add special treat for GL Claim. Maybe later we split the code...
  const isGlClaim = 'gl_claim' === claim.type;
  const firstPartyVehicle = shouldShowParties ? getFirstPartyVehicleFromClaim(claim) : undefined;
  const {
    isLoading,
    isError,
    data: liabilityDashboardDetails,
    reloadData,
  } = useDataFetcher(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/dashboard_details`);

  const handleUpdate = async () => {
    if (!exposure.was_liability_dashboard_touched) {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/touch`);
    }

    await reloadData();
    await onUpdate();
  };

  async function updateInsuredAttorney(insuredAttorneyContactId) {
    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/insured_attorney_contact`, {
        insured_attorney_contact_id: insuredAttorneyContactId,
      });
      await handleUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  function getInsuredCollisionExposure() {
    const insuredCollisionExposures = claim.exposures.filter(
      (exposure) => exposure.coverage_type === 'coverage_collision' && exposure.involved_property
    );

    if (insuredCollisionExposures.length !== 1) {
      return;
    }

    return insuredCollisionExposures[0];
  }

  const insuredCollisionExposure = getInsuredCollisionExposure();
  const insuredVehicleEstimationDocuments =
    insuredCollisionExposure && getExposureEstimationDocuments(claim, insuredCollisionExposure);
  const insuredCollisionPhotos = insuredCollisionExposure ? getExposurePhotos(claim, insuredCollisionExposure) : [];

  if (isLoading || isError) {
    return (
      <LoadingDialog isError={isError} onClose={onClose} track="Loading Liability Dashboard - Damage Management" />
    );
  }

  let subheader = `Claim #: ${claim.claim_id_display}`;
  if (!isGlClaim) {
    subheader += `, Policy #: ${claim.policy.policy_number}`;
  }

  return (
    <>
      <CardDialog
        title={
          <span>
            Liability Dashboard - Damage Management
            {exposure.is_medicare_eligible === 'Positive' && (
              <span>
                <Tooltip title="Medicare Eligibility Confirmed">
                  <VerifiedUserIcon style={{ marginLeft: '10px' }} color="primary" />
                </Tooltip>
              </span>
            )}
          </span>
        }
        trackAlt="Liability Dashboard - Damage Management"
        subheader={subheader}
        onClose={onClose}
        action={
          <Button color="primary" onClick={() => setShowNegotiationAndLitigationDialog(true)}>
            Negotiation & Litigation
          </Button>
        }
        isDialog
        maxWidth="xl"
        fullWidth
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <LiabilityDashboardIncidentDetails
              claim={claim}
              exposure={exposure}
              involvedPerson={liabilityDashboardDetails.involved_person}
              onUpdate={handleUpdate}
              viewOnly={viewOnly}
            />
          </Grid>
          {shouldShowParties && (
            <Grid item xs={4}>
              <CardDialog title="Insured" outlinedCard>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ContactShowOnlyTextField
                      label="Insured"
                      contactId={claim.policy.insured_contact_id}
                      contactDisplayName={claim.policy.insured_contact_full_name}
                    />
                  </Grid>
                  {firstPartyVehicle && (
                    <>
                      <Grid item xs={12}>
                        <Typography>
                          <u>Vehicle Details:</u>
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <ShowOnlyTextField
                          classes={classes}
                          label="Year"
                          showOnlyValueComponent={firstPartyVehicle.involved_vehicle.vehicle.year}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ShowOnlyTextField
                          classes={classes}
                          label="Make"
                          showOnlyValueComponent={firstPartyVehicle.involved_vehicle.vehicle.make}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <ShowOnlyTextField
                          classes={classes}
                          label="Model"
                          showOnlyValueComponent={firstPartyVehicle.involved_vehicle.vehicle.model}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ShowOnlyTextField
                          classes={classes}
                          label="Series"
                          showOnlyValueComponent={firstPartyVehicle.involved_vehicle.vehicle.series}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <ShowOnlyTextField
                          label="Damage Estimate"
                          classes={classes}
                          showOnlyValueComponent={
                            insuredVehicleEstimationDocuments &&
                            insuredVehicleEstimationDocuments.map((document) => (
                              <DocumentLink document={document} key={document.id} text={document.document_name} />
                            ))
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <ShowOnlyTextField
                          label="Photos"
                          classes={classes}
                          showOnlyValueComponent={
                            insuredCollisionPhotos.length !== 0 && (
                              <PhotosLink
                                photos={insuredCollisionPhotos}
                                text="Photos"
                                galleryDialogTitle="Insured Photos"
                                disableExposuresFilter
                              />
                            )
                          }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6}>
                    <HoverChangeField
                      name="insured_attorney_contact"
                      value={exposure.liability_insured_attorney_contact_id || ''}
                      label="Defense Attorney"
                      specialFieldType="contact"
                      onUpdate={async ({ insured_attorney_contact_id }) =>
                        await updateInsuredAttorney(insured_attorney_contact_id)
                      }
                      disabled={viewOnly}
                      specialFieldAdditionalProps={{ acceptedRoles: ['attorney'] }}
                    >
                      <ContactShowOnlyTextField
                        label="Defense Attorney"
                        contactId={exposure.liability_insured_attorney_contact_id}
                        contactDisplayName={exposure.liability_insured_attorney_contact_full_name}
                      />
                    </HoverChangeField>
                  </Grid>
                </Grid>
              </CardDialog>
            </Grid>
          )}
          {shouldShowParties &&
            claim.incident.vehicle_parties
              .filter((party) => !party.is_first_party)
              .map((thirdParty, index) => (
                <Grid item xs={4} key={thirdParty.id}>
                  <LiabilityDashboardThirdPartyDetails
                    claim={claim}
                    exposure={exposure}
                    thirdPartyVehicle={thirdParty}
                    vehicleIndex={index + 1}
                  />
                </Grid>
              ))}
          <Grid item xs={7}>
            <LiabilityDashboardEvaluationSummaryCard
              claim={claim}
              exposure={exposure}
              liabilityDashboardDetails={liabilityDashboardDetails}
              onUpdate={handleUpdate}
              viewOnly={viewOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <LiabilityDashboardLiensSummaryCard
              claim={claim}
              exposure={exposure}
              liens={liabilityDashboardDetails.liability_liens}
              onUpdate={handleUpdate}
            />
          </Grid>
        </Grid>
      </CardDialog>
      {showNegotiationAndLitigationDialog && (
        <NegotiationAndLitigationDialog
          claim={claim}
          exposure={exposure}
          liabilityDashboardDetails={liabilityDashboardDetails}
          onUpdate={handleUpdate}
          onClose={() => setShowNegotiationAndLitigationDialog(false)}
        />
      )}
    </>
  );
}

LiabilityDashboardDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

function getThirdPartyPdExposure(claim, vehicleThirdParty) {
  const thirdPartyVehiclePdExposures = claim.exposures.filter(
    (exposure) =>
      exposure.coverage_type === 'coverage_physical_damage' &&
      exposure.involved_property &&
      vehicleThirdParty.involved_vehicle &&
      exposure.involved_property.id === vehicleThirdParty.involved_vehicle.id
  );

  if (thirdPartyVehiclePdExposures.length !== 1) {
    return;
  }

  return thirdPartyVehiclePdExposures[0];
}

function getExposureEstimationDocuments(claim, exposure) {
  const damageEstimateDocIds =
    exposure.damage_assessment &&
    exposure.damage_assessment.is_estimation_report_exists &&
    exposure.damage_assessment.estimation_reports_ids;

  if (!damageEstimateDocIds) {
    return;
  }

  return claim.documents.filter((doc) => damageEstimateDocIds.includes(doc.id) && doc.type === 'damage_estimate');
}

function getExposurePhotos(claim, exposure) {
  return claim.documents.filter((doc) => doc.exposure_ids.includes(exposure.id) && isDocumentAPhoto(doc));
}

function LiabilityDashboardThirdPartyDetails({ claim, thirdPartyVehicle, vehicleIndex }) {
  const classes = useStyles();

  const thirdPartyPdExposure = getThirdPartyPdExposure(claim, thirdPartyVehicle);
  const claimantVehicleEstimationDocuments =
    thirdPartyPdExposure && getExposureEstimationDocuments(claim, thirdPartyPdExposure);
  const claimantPdPhotos = thirdPartyPdExposure ? getExposurePhotos(claim, thirdPartyPdExposure) : [];

  return (
    <CardDialog title={`Third Party - ${vehicleIndex}`} outlinedCard>
      <Grid container spacing={1}>
        {thirdPartyVehicle.involved_vehicle ? (
          <>
            <Grid item xs={12}>
              <ContactShowOnlyTextField
                label="Claimant"
                contactId={thirdPartyVehicle.involved_vehicle.owner_contact_id}
                contactDisplayName={thirdPartyVehicle.involved_vehicle.owner_contact_full_name}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <u>Vehicle Details:</u>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <ShowOnlyTextField
                classes={classes}
                label="Year"
                showOnlyValueComponent={thirdPartyVehicle.involved_vehicle.vehicle.year}
              />
            </Grid>
            <Grid item xs={3}>
              <ShowOnlyTextField
                classes={classes}
                label="Make"
                showOnlyValueComponent={thirdPartyVehicle.involved_vehicle.vehicle.make}
              />
            </Grid>
            <Grid item xs={4}>
              <ShowOnlyTextField
                classes={classes}
                label="Model"
                showOnlyValueComponent={thirdPartyVehicle.involved_vehicle.vehicle.model}
              />
            </Grid>
            <Grid item xs={3}>
              <ShowOnlyTextField
                classes={classes}
                label="Series"
                showOnlyValueComponent={thirdPartyVehicle.involved_vehicle.vehicle.series}
              />
            </Grid>
            <Grid item xs={6}>
              <ShowOnlyTextField
                label="Damage Estimate"
                classes={classes}
                showOnlyValueComponent={
                  claimantVehicleEstimationDocuments &&
                  claimantVehicleEstimationDocuments.map((document) => (
                    <DocumentLink document={document} key={document.id} text={document.document_name} />
                  ))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <ShowOnlyTextField
                label="Photos"
                classes={classes}
                showOnlyValueComponent={
                  claimantPdPhotos.length !== 0 && (
                    <PhotosLink
                      photos={claimantPdPhotos}
                      text="Photos"
                      galleryDialogTitle="Third Party Photos"
                      disableExposuresFilter
                    />
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <ContactShowOnlyTextField
                classes={classes}
                label="Claimant Attorney"
                contactId={thirdPartyVehicle.involved_vehicle.attorney_contact_id}
                contactDisplayName={thirdPartyVehicle.involved_vehicle.attorney_contact_full_name}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1">Missing Vehicle Details</Typography>
          </Grid>
        )}
      </Grid>
    </CardDialog>
  );
}

LiabilityDashboardThirdPartyDetails.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  thirdPartyVehicle: PropTypes.object.isRequired,
  vehicleIndex: PropTypes.number.isRequired,
};

function LiabilityDashboardIncidentDetails({ claim, exposure, involvedPerson, onUpdate, viewOnly }) {
  const classes = useStyles();
  const [showEditInLitigation, setShowEditInLitigation] = useState(false);

  // For now, the default is Auto Claim, and we add special treat for GL Claim. Maybe later we split the code...
  const isGlClaim = 'gl_claim' === claim.type;

  async function updatePercentageOfClaimantLiability(percentageOfClaimantLiability) {
    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/percentage_of_claimant_liability`, {
        percentage_of_claimant_liability: percentageOfClaimantLiability,
      });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function updateLossDescriptionNote(lossDescriptionNote) {
    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/loss_description_note`, {
        liability_loss_description_note: lossDescriptionNote,
      });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateVenue(venue) {
    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/venue`, { venue });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateAttorney(attorney_contact_id) {
    try {
      await axios.post(`/api/v1/claims/${claim.id}/exposures/${exposure.id}/involved_person/attorney`, {
        attorney_contact_id,
      });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateRepresentative(representative_contact_id) {
    try {
      await axios.post(`/api/v1/claims/${claim.id}/exposures/${exposure.id}/involved_person/representative`, {
        representative_contact_id,
      });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  const liabilityPercentageComponent = (
    <Grid item xs={2}>
      <SelectHoverChangeField
        label="% of claimant liability"
        fieldId="percentage_of_claimant_liability"
        value={exposure.percentage_of_claimant_liability || ''}
        keys={AUTO_LIABILITY_PERCENTAGE_OF_INSURE_LIABILITY_LIST}
        displayValueFunc={(key) => key}
        disabled={viewOnly}
        onUpdate={async ({ percentage_of_claimant_liability }) =>
          await updatePercentageOfClaimantLiability(percentage_of_claimant_liability)
        }
        overrideOnEdit
      >
        <ShowOnlyTextField
          classes={classes}
          showOnlyValueComponent={exposure.percentage_of_claimant_liability}
          label="% of claimant liability"
        />
      </SelectHoverChangeField>
    </Grid>
  );

  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getInitialFaultAssessmentPredefinedFields(incidentConfiguration, () => true);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardDialog title="Claimant Details" outlinedCard>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <ContactShowOnlyTextField
                  contactId={involvedPerson.contact.id}
                  contactDisplayName={involvedPerson.contact.full_name}
                  label="Claimant"
                  fullWidth
                  showOnly
                />
              </Grid>
              <Grid item xs={3}>
                {!involvedPerson.is_company && (
                  <div>
                    <Typography display="block" color="inherit" variant="caption">
                      DoB: {involvedPerson.contact.date_of_birth && isoDateToUs(involvedPerson.contact.date_of_birth)}
                    </Typography>
                    <Typography display="block" color="inherit" variant="caption">
                      Gender: {involvedPerson.contact.sex}
                    </Typography>
                    <Typography display="block" color="inherit" variant="caption">
                      SSN: {involvedPerson.contact?.government_id}
                    </Typography>
                  </div>
                )}
              </Grid>
              {isGlClaim && liabilityPercentageComponent}
              {!isGlClaim && (
                <Grid item xs={3}>
                  <ShowOnlyTextField
                    classes={classes}
                    label="Claimant Location"
                    showOnlyValueComponent={getClaimantLocationInAutoIncident(claim, exposure)}
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <HoverChangeField
                  name="representative_contact_id"
                  customFieldIdName="representative_contact"
                  value={involvedPerson.representative_contact_id || ''}
                  label="Representative"
                  onUpdate={async ({ representative_contact_id }) =>
                    await handleUpdateRepresentative(representative_contact_id)
                  }
                  disabled={viewOnly}
                  specialFieldType="contact"
                  specialFieldAdditionalProps={{ acceptedRoles: REPRESENTATIVE_CONTACT_TYPES }}
                >
                  <ContactShowOnlyTextField
                    contactId={involvedPerson.representative_contact_id || null}
                    contactDisplayName={involvedPerson.representative_contact_full_name}
                    label="Representative"
                    fullWidth
                    showOnly
                  />
                </HoverChangeField>
              </Grid>
              <Grid item xs={3}>
                <HoverChangeField
                  name="attorney_contact_id"
                  customFieldIdName="attorney_contact"
                  value={involvedPerson.attorney_contact_id || ''}
                  label="Attorney"
                  onUpdate={async ({ attorney_contact_id }) => await handleUpdateAttorney(attorney_contact_id)}
                  disabled={viewOnly}
                  specialFieldType="contact"
                  specialFieldAdditionalProps={{ acceptedRoles: ['attorney'] }}
                >
                  <ContactShowOnlyTextField
                    contactId={involvedPerson.attorney_contact_id}
                    contactDisplayName={involvedPerson.attorney_contact_full_name}
                    label="Attorney"
                    fullWidth
                    showOnly
                  />
                </HoverChangeField>
              </Grid>
              <Grid item xs={3}>
                <ShowOnlyTextField
                  label="In Suit?"
                  classes={classes}
                  showOnlyValueComponent={
                    exposure.is_in_litigation !== null &&
                    (exposure.is_in_litigation ? (exposure.is_litigation_closed ? 'Closed' : 'Yes') : 'No')
                  }
                  onEdit={!viewOnly ? () => setShowEditInLitigation(true) : undefined}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <HoverChangeField
                  name="venue"
                  value={exposure.venue || ''}
                  label="Venue"
                  onUpdate={async ({ venue }) => await handleUpdateVenue(venue)}
                  disabled={viewOnly}
                >
                  <ShowOnlyTextField classes={classes} label="Venue" showOnlyValueComponent={exposure.venue || ''} />
                </HoverChangeField>
              </Grid>
              {!isGlClaim && (
                <Grid item xs={12}>
                  <IcdCodesFieldContainer
                    onUpdate={onUpdate}
                    viewOnly={viewOnly}
                    involvedPerson={involvedPerson}
                    claimId={exposure.claim_id}
                  />
                </Grid>
              )}
            </Grid>
          </CardDialog>
        </Grid>
        {!isGlClaim && (
          <Grid item xs={12}>
            <CardDialog title="Incident Details" outlinedCard>
              <Grid container spacing={1}>
                <Grid item xs={5} container direction="column" justify="center">
                  <Grid item>
                    <Typography display="block" color="inherit" variant="body2">
                      State of Loss:{' '}
                      {claim.incident.loss_location.country === 'US'
                        ? COUNTRY_TO_STATE_MAP['US'][claim.incident.loss_location.state]
                        : ''}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography display="block" color="inherit" variant="body2">
                      Loss Country: {COUNTRIES_DICT[claim.incident.loss_location.country]}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  {claim.type !== 'general_claim' && (
                    <CustomField
                      field={get(preDefinedFields, 'claim_handler_fault_assessment')}
                      value={claim.incident.claim_handler_fault_assessment}
                      inline
                      readOnly
                    />
                  )}
                </Grid>
                <Grid item xs={5}>
                  <ShowOnlyTextField
                    classes={classes}
                    showOnlyValueComponent={claim.incident.description}
                    label="Description of loss"
                    maxHeight="3.5em" // ~ 2 line
                    width="100%"
                  />
                </Grid>
                <Grid item xs={5} style={{ paddingRight: '15px' }}>
                  <ShowOnlyTextField
                    classes={classes}
                    label="Location of Loss"
                    showOnlyValueComponent={lossLocationToString(claim.incident.loss_location)}
                  />
                </Grid>

                {liabilityPercentageComponent}

                <Grid item xs={5}>
                  <HoverChangeField
                    name="liability_loss_description_note"
                    value={exposure.liability_loss_description_note || ''}
                    label="Notes"
                    onUpdate={async ({ liability_loss_description_note }) =>
                      await updateLossDescriptionNote(liability_loss_description_note)
                    }
                    disabled={viewOnly}
                  >
                    <ShowOnlyTextField
                      classes={classes}
                      label="Notes"
                      showOnlyValueComponent={exposure.liability_loss_description_note || ''}
                    />
                  </HoverChangeField>
                </Grid>
              </Grid>
            </CardDialog>
          </Grid>
        )}
      </Grid>
      {showEditInLitigation && (
        <ExposureLitigationStatusDialog
          exposure={exposure}
          onUpdate={onUpdate}
          onClose={() => setShowEditInLitigation(false)}
        />
      )}
    </>
  );
}

LiabilityDashboardIncidentDetails.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  involvedPerson: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

function LiabilityDashboardEvaluationSummaryCard({ claim, exposure, liabilityDashboardDetails, onUpdate, viewOnly }) {
  const classes = useStyles();
  const [showEvaluationDialog, setShowEvaluationDialog] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isGlClaim = 'gl_claim' === claim.type;
  const { currencyFormatter } = useCurrencyFormatter();
  const involvedPerson = liabilityDashboardDetails.involved_person;

  async function handleUpdateShouldConsiderSpecialDamages(shouldConsider) {
    try {
      setIsSubmitting(true);
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/consider_special_damages`, {
        liability_should_consider_special_damages: shouldConsider,
      });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
    setIsSubmitting(false);
  }

  async function handleUpdateManualPaymentAmount(manualAmountKey, manualAmountValue) {
    try {
      setIsSubmitting(true);
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/${manualAmountKey}`, {
        [manualAmountKey]: manualAmountValue,
      });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
    setIsSubmitting(false);
  }

  return (
    <>
      <CardDialog
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {`Evaluation Summary - ${involvedPerson.contact.full_name}`}
            <FormControlLabel
              style={{ paddingLeft: 16 }}
              control={
                <Checkbox
                  checked={exposure.liability_should_consider_special_damages}
                  disabled={isSubmitting || viewOnly}
                  onChange={(event) => handleUpdateShouldConsiderSpecialDamages(event.target.checked)}
                />
              }
              label={<Typography variant="subtitle2">Consider Damages</Typography>}
            />
            <InlineIconButton
              className={classes.inlineEditIcon}
              disabled={isSubmitting}
              icon={OpenInNewIcon}
              onClick={() => setShowEvaluationDialog(true)}
            />
          </div>
        }
        outlinedCard
      >
        <Table className={cn(classes.table, classes.fixedTable)}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell} style={{ width: '100px' }}>
                Low
              </TableCell>
              <TableCell className={classes.tableCell} style={{ width: '100px' }}>
                High
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exposure.liability_should_consider_special_damages && (
              <>
                <TableRow>
                  <TableCell className={classes.tableCell}>Medical Bills</TableCell>
                  <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                    <EditablePaymentAmount
                      exposure={exposure}
                      onUpdate={handleUpdateManualPaymentAmount}
                      label="Medical Bills - Low"
                      automaticAmount={liabilityDashboardDetails.total_calculated_low_medical_bills}
                      manualAmountKey="liability_manual_medical_damage_low_amount"
                      viewOnly={viewOnly}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                    <EditablePaymentAmount
                      exposure={exposure}
                      onUpdate={handleUpdateManualPaymentAmount}
                      label="Medical Bills - High"
                      automaticAmount={liabilityDashboardDetails.total_calculated_high_medical_bills}
                      manualAmountKey="liability_manual_medical_damage_high_amount"
                      viewOnly={viewOnly}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCell}>Lost Wages</TableCell>
                  <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                    <EditablePaymentAmount
                      exposure={exposure}
                      onUpdate={handleUpdateManualPaymentAmount}
                      label="Lost Wages - Low"
                      automaticAmount={liabilityDashboardDetails.total_calculated_low_lost_wages}
                      manualAmountKey="liability_manual_lost_wages_low_amount"
                      viewOnly={viewOnly}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                    <EditablePaymentAmount
                      exposure={exposure}
                      onUpdate={handleUpdateManualPaymentAmount}
                      label="Lost Wages - High"
                      automaticAmount={liabilityDashboardDetails.total_calculated_high_lost_wages}
                      manualAmountKey="liability_manual_lost_wages_high_amount"
                      viewOnly={viewOnly}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCell}>Other Expenses</TableCell>
                  <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                    <EditablePaymentAmount
                      exposure={exposure}
                      onUpdate={handleUpdateManualPaymentAmount}
                      label="Other Expenses - Low"
                      automaticAmount={liabilityDashboardDetails.total_calculated_low_other_expenses}
                      manualAmountKey="liability_manual_other_expenses_low_amount"
                      viewOnly={viewOnly}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                    <EditablePaymentAmount
                      exposure={exposure}
                      onUpdate={handleUpdateManualPaymentAmount}
                      label="Other Expenses - High"
                      automaticAmount={liabilityDashboardDetails.total_calculated_high_other_expenses}
                      manualAmountKey="liability_manual_other_expenses_high_amount"
                      viewOnly={viewOnly}
                    />
                  </TableCell>
                </TableRow>
                {isGlClaim && (
                  <TableRow>
                    <TableCell className={classes.tableCell}>Punitive Damages</TableCell>
                    <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                      {currencyFormatter.format(liabilityDashboardDetails.total_low_punitive_damages)}
                    </TableCell>
                    <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                      {currencyFormatter.format(liabilityDashboardDetails.total_high_punitive_damages)}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
            <TableRow>
              <TableCell className={classes.tableCell}>General Damages</TableCell>
              <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                {currencyFormatter.format(liabilityDashboardDetails.total_low_general_damages)}
              </TableCell>
              <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                {currencyFormatter.format(liabilityDashboardDetails.total_high_general_damages)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>
                CLMT Comparative Negligence (
                {exposure.percentage_of_claimant_liability
                  ? exposure.percentage_of_claimant_liability
                  : 'liability percentage not defined'}
                )
              </TableCell>
              <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                {exposure.percentage_of_claimant_liability &&
                  currencyFormatter.format(
                    (parseFloat(exposure.percentage_of_claimant_liability) * liabilityDashboardDetails.total_low) / 100
                  )}
              </TableCell>
              <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                {exposure.percentage_of_claimant_liability &&
                  currencyFormatter.format(
                    (parseFloat(exposure.percentage_of_claimant_liability) * liabilityDashboardDetails.total_high) / 100
                  )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Net Evaluation</TableCell>
              <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                {exposure.percentage_of_claimant_liability &&
                  currencyFormatter.format(
                    ((100 - parseFloat(exposure.percentage_of_claimant_liability)) *
                      liabilityDashboardDetails.total_low) /
                      100
                  )}
              </TableCell>
              <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                {exposure.percentage_of_claimant_liability &&
                  currencyFormatter.format(
                    ((100 - parseFloat(exposure.percentage_of_claimant_liability)) *
                      liabilityDashboardDetails.total_high) /
                      100
                  )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardDialog>
      {showEvaluationDialog && (
        <BiEvaluationDialog
          claim={claim}
          exposure={exposure}
          liabilityDashboardDetails={liabilityDashboardDetails}
          onUpdate={onUpdate}
          onClose={() => setShowEvaluationDialog(false)}
          viewOnly={viewOnly}
        />
      )}
    </>
  );
}

LiabilityDashboardEvaluationSummaryCard.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  liabilityDashboardDetails: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

function BiEvaluationDialog({ claim, exposure, liabilityDashboardDetails, onUpdate, onClose }) {
  const classes = useStyles();
  const { bodyPartsDict } = useOrganization();
  const { currencyFormatter } = useCurrencyFormatter();
  const involvedPerson = liabilityDashboardDetails.involved_person;

  const [showNewGeneralDamageDialog, setShowNewGeneralDamageDialog] = useState(false);
  const [generalDamageToEdit, setGeneralDamageToEdit] = useState(false);
  const [showNewMedicalDamageDialog, setShowNewMedicalDamageDialog] = useState(false);
  const [medicalDamageToEdit, setMedicalDamageToEdit] = useState(null);
  const [showNewWagesAndOtherExpensesDialog, setShowNewWagesAndOtherExpensesDialog] = useState(false);
  const [wagesAndOtherExpensesToEdit, setWagesAndOtherExpensesToEdit] = useState(null);
  const [showNewOtherExpensesDialog, setShowNewOtherExpensesDialog] = useState(false);
  const [otherExpensesToEdit, setOtherExpensesToEdit] = useState(null);
  const [showNewPunitiveDamageDialog, setShowNewPunitiveDamageDialog] = useState(false);
  const [punitiveDamageToEdit, setPunitiveDamageToEdit] = useState(false);

  const [showTables, setShowTables] = useState({
    medical: true,
    wages: true,
    general: true,
    otherExpense: true,
    punitive: true,
  });

  const exposurePhotos = claim.documents.filter(
    (doc) => doc.exposure_ids.includes(exposure.id) && isDocumentAPhoto(doc)
  );

  const isGlClaim = 'gl_claim' === claim.type;

  async function handleAddMedicalDamage(values) {
    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/medical_damages`, values);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateMedicalDamage(values) {
    try {
      await axios.put(
        `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/medical_damages/${medicalDamageToEdit.id}`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleAddWagesAndOtherExpenses(values) {
    try {
      await axios.post(
        `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/lost_wage_other_expenses`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateWagesAndOtherExpenses(values, wagesAndOtherExpensesToEdit) {
    try {
      await axios.put(
        `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/lost_wage_other_expenses/${wagesAndOtherExpensesToEdit.id}`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleAddGeneralDamage(values) {
    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/general_damages`, values);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateGeneralDamage(values) {
    try {
      await axios.put(
        `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/general_damages/${generalDamageToEdit.id}`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleAddPunitiveDamage(values) {
    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/punitive_damages`, values);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdatePunitiveDamage(values) {
    try {
      await axios.put(
        `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/punitive_damages/${punitiveDamageToEdit.id}`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  let medicalDamagesColumns = [
    { id: 'internal_id', label: '#', width: 10 },
    { id: 'provider', label: 'Provider' },
    {
      id: 'medical_record_bill_document_id',
      label: 'Medical Info',
      // eslint-disable-next-line react/display-name
      specialCell: (medicalDamage) => {
        // eslint-disable-next-line react/prop-types
        const document = claim.documents.find((d) => d.id === medicalDamage.medical_record_bill_document_id);
        return document && <DocumentLink document={document} text={document.document_name} />;
      },
    },
    {
      id: 'dates_of_treatment',
      label: 'Dates of Treatments',
      specialCell: (medicalDamage) =>
        `${medicalDamage.start_date_of_treatment ? isoDateToUs(medicalDamage.start_date_of_treatment) : 'N/A'}-${
          medicalDamage.end_date_of_treatment ? isoDateToUs(medicalDamage.end_date_of_treatment) : 'N/A'
        }`,
    },
    { id: 'number_of_visits', label: 'Number of Visits' },
    { id: 'disability_assessment', label: 'Disability Assessment' },
    {
      id: 'disability_period',
      label: 'Disability Period',
      specialCell: (medicalDamage) =>
        medicalDamage.start_date_of_disability && medicalDamage.end_date_of_disability
          ? `${isoDateToUs(medicalDamage.start_date_of_disability)}-${isoDateToUs(
              medicalDamage.end_date_of_disability
            )}`
          : '',
    },
    { id: 'rationale', label: isGlClaim ? 'Description of Treatment' : 'Rationale' },
    {
      id: 'amount_submitted',
      label: 'Amount Submitted',
      disablePadding: true,
      specialCell: (medicalDamage) => currencyFormatter.format(medicalDamage.amount_submitted),
    },
    {
      id: 'amount_considered',
      label: 'Amount Considered',
      disablePadding: true,
      specialCell: (medicalDamage) => currencyFormatter.format(medicalDamage.amount_considered),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'edit',
      label: '',
      width: 10,
      disableSort: true,
      disablePadding: true,
      specialCell: (medicalDamage) => (
        <HoverActionField onAction={() => setMedicalDamageToEdit(medicalDamage)} permanent />
      ),
    },
  ];

  if (isGlClaim) {
    medicalDamagesColumns = medicalDamagesColumns.filter(
      (col) => !['disability_assessment', 'disability_period'].includes(col.id)
    );

    const numOfVisitsId = medicalDamagesColumns.findIndex((column) => column.id === 'number_of_visits');
    medicalDamagesColumns.splice(numOfVisitsId + 1, 0, { id: 'treatment', label: 'Treatment' });

    const rationaleId = medicalDamagesColumns.findIndex((column) => column.id === 'rationale');
    medicalDamagesColumns.splice(rationaleId + 1, 0, { id: 'time_type', label: 'Type' });
  }

  let wagesAndOtherExpensesColumns = [
    { id: 'internal_id', label: '#', width: 10 },
    { id: 'type', label: 'Type', width: 110 },
    { id: 'employer_oe_provider', label: isGlClaim ? 'Employer' : 'Employer/OE Provider' },
    {
      id: 'lost_wage_other_expense_record_document_id',
      label: 'Wages/OE Documentation',
      // eslint-disable-next-line react/display-name
      specialCell: (wagesAndOtherExpenses) => {
        // eslint-disable-next-line react/prop-types
        const document = claim.documents.find(
          (d) => d.id === wagesAndOtherExpenses.lost_wage_other_expense_record_document_id
        );
        return document && <DocumentLink document={document} text={document.document_name} />;
      },
    },
    { id: 'expense_type', label: 'Expense Type' },
    {
      id: 'date_range',
      label: 'Date Range',
      specialCell: (wagesAndOtherExpenses) =>
        `${
          wagesAndOtherExpenses.start_date_of_request ? isoDateToUs(wagesAndOtherExpenses.start_date_of_request) : 'N/A'
        }-${
          wagesAndOtherExpenses.end_date_of_request ? isoDateToUs(wagesAndOtherExpenses.end_date_of_request) : 'N/A'
        }`,
    },
    { id: 'number_of_days', label: 'Number of Days' },
    { id: 'rationale', label: isGlClaim ? 'Occupation' : 'Rationale' },
    {
      id: 'amount_submitted',
      label: 'Amount Submitted',
      disablePadding: true,
      specialCell: (wagesAndOtherExpenses) => currencyFormatter.format(wagesAndOtherExpenses.amount_submitted),
    },
    {
      id: 'amount_considered',
      label: 'Amount Considered',
      disablePadding: true,
      specialCell: (wagesAndOtherExpenses) => currencyFormatter.format(wagesAndOtherExpenses.amount_considered),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'edit',
      label: '',
      width: 10,
      disableSort: true,
      disablePadding: true,
      specialCell: (wagesAndOtherExpenses) => (
        <HoverActionField onAction={() => setWagesAndOtherExpensesToEdit(wagesAndOtherExpenses)} permanent />
      ),
    },
  ];

  if (isGlClaim) {
    wagesAndOtherExpensesColumns = wagesAndOtherExpensesColumns.filter(
      (col) => !['type', 'expense_type'].includes(col.id)
    );

    const numOfVisitsId = wagesAndOtherExpensesColumns.findIndex((column) => column.id === 'date_range');
    wagesAndOtherExpensesColumns.splice(numOfVisitsId + 1, 0, {
      id: 'work_life_expectancy',
      label: 'Work-Life expectancy',
    });

    const rationaleId = wagesAndOtherExpensesColumns.findIndex((column) => column.id === 'number_of_days');
    wagesAndOtherExpensesColumns.splice(rationaleId + 1, 0, { id: 'time_type', label: 'Type' });
  }

  let otherExpensesColumns = undefined;
  if (isGlClaim) {
    otherExpensesColumns = [
      { id: 'internal_id', label: '#', width: 10 },
      {
        id: 'provider',
        label: 'Provider',
        // eslint-disable-next-line react/display-name
        specialCell: (row) =>
          row.provider_contact_id ? (
            <ContactEntity contactId={row.provider_contact_id} contactDisplayName={row.provider_contact_full_name} />
          ) : null,
      },
      {
        id: 'lost_wage_other_expense_record_document_id',
        label: 'Other Expense Documentation',
        // eslint-disable-next-line react/display-name
        specialCell: (otherExpenses) => {
          // eslint-disable-next-line react/prop-types
          const document = claim.documents.find(
            (d) => d.id === otherExpenses.lost_wage_other_expense_record_document_id
          );
          return document && <DocumentLink document={document} text={document.document_name} />;
        },
      },
      {
        id: 'date_range',
        label: 'Date Range',
        specialCell: (otherExpenses) =>
          `${otherExpenses.start_date_of_request ? isoDateToUs(otherExpenses.start_date_of_request) : 'N/A'}-${
            otherExpenses.end_date_of_request ? isoDateToUs(otherExpenses.end_date_of_request) : 'N/A'
          }`,
      },
      { id: 'rationale', label: 'Description' },
      {
        id: 'amount_submitted',
        label: 'Amount Submitted',
        disablePadding: true,
        specialCell: (otherExpenses) => currencyFormatter.format(otherExpenses.amount_submitted),
      },
      {
        id: 'amount_considered',
        label: 'Amount Considered',
        disablePadding: true,
        specialCell: (otherExpenses) => currencyFormatter.format(otherExpenses.amount_considered),
      },
      // eslint-disable-next-line react/display-name
      {
        id: 'edit',
        label: '',
        width: 10,
        disableSort: true,
        disablePadding: true,
        specialCell: (otherExpenses) => (
          <HoverActionField onAction={() => setOtherExpensesToEdit(otherExpenses)} permanent />
        ),
      },
    ];
  }

  let generalDamagesColumns = [
    { id: 'internal_id', label: '#', width: 10 },
    {
      id: 'body_part',
      label: 'Body Part',
      specialCell: (generalDamage) =>
        generalDamage.body_part === 'other'
          ? `${bodyPartsDict[generalDamage.body_part]['desc']} - ${generalDamage.other_body_part}`
          : bodyPartsDict[generalDamage.body_part]['desc'],
    },
    { id: 'extent_of_injury_desc', label: 'Injury Type' },
    { id: 'treatment_surgery_desc', label: 'Treatment' },
    { id: 'threshold_type', label: 'Threshold Type' },
    { id: 'threshold_assessment', label: 'Threshold Assessment' },
    { id: 'rationale', label: 'Rationale' },
    {
      id: 'low_range',
      label: 'Low',
      disablePadding: true,
      specialCell: (generalDamage) => currencyFormatter.format(generalDamage.low_range),
    },
    {
      id: 'high_range',
      label: 'High',
      disablePadding: true,
      specialCell: (generalDamage) => currencyFormatter.format(generalDamage.high_range),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'edit',
      label: '',
      disableSort: true,
      width: 10,
      disablePadding: true,
      specialCell: (generalDamage) => (
        <HoverActionField onAction={() => setGeneralDamageToEdit(generalDamage)} permanent />
      ),
    },
  ];

  if (isGlClaim) {
    generalDamagesColumns = generalDamagesColumns.filter(
      (col) =>
        ![
          'body_part',
          'extent_of_injury_desc',
          'treatment_surgery_desc',
          'threshold_type',
          'threshold_assessment',
        ].includes(col.id)
    );
  }

  const punitiveDamagesColumns = [
    { id: 'internal_id', label: '#', width: 10 },
    { id: 'rationale', label: 'Rationale' },
    {
      id: 'low_range',
      label: 'Low',
      disablePadding: true,
      specialCell: (punitiveDamage) => currencyFormatter.format(punitiveDamage.low_range),
    },
    {
      id: 'high_range',
      label: 'High',
      disablePadding: true,
      specialCell: (punitiveDamage) => currencyFormatter.format(punitiveDamage.high_range),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'edit',
      label: '',
      disableSort: true,
      width: 10,
      disablePadding: true,
      specialCell: (punitiveDamage) => (
        <HoverActionField onAction={() => setPunitiveDamageToEdit(punitiveDamage)} permanent />
      ),
    },
  ];

  const lostWageAndOtherExpenseRows = isGlClaim
    ? liabilityDashboardDetails.liability_lost_wages_and_other_expenses.filter((row) => row.type === 'Lost Wage')
    : liabilityDashboardDetails.liability_lost_wages_and_other_expenses;
  // Relevant only for GlClaim
  const otherExpenseRows = liabilityDashboardDetails.liability_lost_wages_and_other_expenses.filter(
    (row) => row.type === 'Other Expense'
  );

  return (
    <>
      <CardDialog
        isDialog
        title={`Evaluation Summary - ${involvedPerson.contact.full_name}`}
        onClose={onClose}
        maxWidth="xl"
        fullWidth
      >
        {exposure.liability_should_consider_special_damages && (
          <>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showTables.medical}
                    onClick={(event) => setShowTables({ ...showTables, medical: event.target.checked })}
                  />
                }
                label="Show Medical Damages Summary"
              />
            </div>
            {showTables.medical && (
              <div className={classes.cardDivRow}>
                <CardDialog title="Medical Damages Summary" outlinedCard>
                  <Button color="primary" onClick={() => setShowNewMedicalDamageDialog(true)}>
                    <AddIcon className={classes.leftButtonIcon} />
                    Add New
                  </Button>
                  <SortableTable
                    rows={addInternalIdToObjectsArray(liabilityDashboardDetails.liability_medical_damages)}
                    columns={medicalDamagesColumns}
                    defaultOrderColumn={0}
                    footerRow={
                      <TableRow>
                        <TableCell colSpan={7} />
                        <TableCell align="center" padding="none">
                          <strong>Total:</strong>
                        </TableCell>
                        <TableCell align="left" padding="none">
                          <strong>
                            {currencyFormatter.format(
                              liabilityDashboardDetails.liability_medical_damages.reduce(
                                (acc, curr) => acc + curr.amount_submitted,
                                0
                              )
                            )}
                          </strong>
                        </TableCell>
                        <TableCell align="left" padding="none">
                          <strong>
                            {currencyFormatter.format(
                              liabilityDashboardDetails.liability_medical_damages.reduce(
                                (acc, curr) => acc + curr.amount_considered,
                                0
                              )
                            )}
                          </strong>
                        </TableCell>
                      </TableRow>
                    }
                  />
                </CardDialog>
              </div>
            )}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showTables.wages}
                    onClick={(event) => setShowTables({ ...showTables, wages: event.target.checked })}
                  />
                }
                label={isGlClaim ? 'Show Wages Summary' : 'Show Wages and Other Damages Summary'}
              />
            </div>
            {showTables.wages && (
              <div className={classes.cardDivRow}>
                <CardDialog title={isGlClaim ? 'Wages Summary' : 'Wages and Other Expenses Summary'} outlinedCard>
                  <Button color="primary" onClick={() => setShowNewWagesAndOtherExpensesDialog(true)}>
                    <AddIcon className={classes.leftButtonIcon} />
                    Add New
                  </Button>
                  <SortableTable
                    rows={addInternalIdToObjectsArray(lostWageAndOtherExpenseRows)}
                    columns={wagesAndOtherExpensesColumns}
                    defaultOrderColumn={0}
                    footerRow={
                      <TableRow>
                        <TableCell colSpan={7} />
                        <TableCell align="center" padding="none">
                          <strong>Total:</strong>
                        </TableCell>
                        <TableCell align="left" padding="none">
                          <strong>
                            {currencyFormatter.format(
                              lostWageAndOtherExpenseRows.reduce((acc, curr) => acc + curr.amount_submitted, 0)
                            )}
                          </strong>
                        </TableCell>
                        <TableCell align="left" padding="none">
                          <strong>
                            {currencyFormatter.format(
                              lostWageAndOtherExpenseRows.reduce((acc, curr) => acc + curr.amount_considered, 0)
                            )}
                          </strong>
                        </TableCell>
                      </TableRow>
                    }
                  />
                </CardDialog>
              </div>
            )}
            {isGlClaim && (
              <>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showTables.otherExpense}
                        onClick={(event) => setShowTables({ ...showTables, otherExpense: event.target.checked })}
                      />
                    }
                    label="Show Other Damages Summary"
                  />
                </div>
                {showTables.otherExpense && (
                  <div className={classes.cardDivRow}>
                    <CardDialog title="Other Expenses Summary" outlinedCard>
                      <Button color="primary" onClick={() => setShowNewOtherExpensesDialog(true)}>
                        <AddIcon className={classes.leftButtonIcon} />
                        Add New
                      </Button>
                      <SortableTable
                        rows={addInternalIdToObjectsArray(otherExpenseRows)}
                        columns={otherExpensesColumns}
                        defaultOrderColumn={0}
                        footerRow={
                          <TableRow>
                            <TableCell colSpan={7} />
                            <TableCell align="center" padding="none">
                              <strong>Total:</strong>
                            </TableCell>
                            <TableCell align="left" padding="none">
                              <strong>
                                {currencyFormatter.format(
                                  otherExpenseRows.reduce((acc, curr) => acc + curr.amount_submitted, 0)
                                )}
                              </strong>
                            </TableCell>
                            <TableCell align="left" padding="none">
                              <strong>
                                {currencyFormatter.format(
                                  otherExpenseRows.reduce((acc, curr) => acc + curr.amount_considered, 0)
                                )}
                              </strong>
                            </TableCell>
                          </TableRow>
                        }
                      />
                    </CardDialog>
                  </div>
                )}
              </>
            )}
            {isGlClaim && (
              <>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showTables.punitive}
                        onClick={(event) => setShowTables({ ...showTables, punitive: event.target.checked })}
                      />
                    }
                    label="Show Punitive Damages Summary"
                  />
                </div>
                {showTables.punitive && (
                  <div className={classes.cardDivRow}>
                    <CardDialog title="Punitive Summary" outlinedCard>
                      <Button color="primary" onClick={() => setShowNewPunitiveDamageDialog(true)}>
                        <AddIcon className={classes.leftButtonIcon} />
                        Add New
                      </Button>
                      <SortableTable
                        rows={addInternalIdToObjectsArray(liabilityDashboardDetails.liability_punitive_damages)}
                        columns={punitiveDamagesColumns}
                        defaultOrderColumn={0}
                        footerRow={
                          <TableRow>
                            <TableCell colSpan={7} />
                            <TableCell align="center" padding="none">
                              <strong>Total:</strong>
                            </TableCell>
                            <TableCell align="left" padding="none">
                              <strong>
                                {currencyFormatter.format(
                                  liabilityDashboardDetails.liability_punitive_damages.reduce(
                                    (acc, curr) => acc + curr.amount_submitted,
                                    0
                                  )
                                )}
                              </strong>
                            </TableCell>
                            <TableCell align="left" padding="none">
                              <strong>
                                {currencyFormatter.format(
                                  liabilityDashboardDetails.liability_punitive_damages.reduce(
                                    (acc, curr) => acc + curr.amount_considered,
                                    0
                                  )
                                )}
                              </strong>
                            </TableCell>
                          </TableRow>
                        }
                      />
                    </CardDialog>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {exposure.liability_should_consider_special_damages && (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showTables.general}
                  onClick={(event) => setShowTables({ ...showTables, general: event.target.checked })}
                />
              }
              label="Show General Damages Summary"
            />
          </div>
        )}
        {showTables.general && (
          <div className={classes.cardDivRow}>
            <CardDialog
              title="General Damages Summary"
              subheader={
                <PhotosLink
                  photos={exposurePhotos}
                  text="BI Photos"
                  galleryDialogTitle="Exposure Photos"
                  disableExposuresFilter
                />
              }
              outlinedCard
            >
              <Button color="primary" onClick={() => setShowNewGeneralDamageDialog(true)}>
                <AddIcon className={classes.leftButtonIcon} />
                Add New
              </Button>
              <SortableTable
                rows={addInternalIdToObjectsArray(liabilityDashboardDetails.liability_general_damages)}
                columns={generalDamagesColumns}
                footerRow={
                  <TableRow>
                    <TableCell colSpan={6} />
                    <TableCell align="center" padding="none">
                      <strong>Total:</strong>
                    </TableCell>
                    <TableCell align="left" padding="none">
                      <strong>
                        {currencyFormatter.format(
                          liabilityDashboardDetails.liability_general_damages.reduce(
                            (acc, curr) => acc + curr.low_range,
                            0
                          )
                        )}
                      </strong>
                    </TableCell>
                    <TableCell align="left" padding="none">
                      <strong>
                        {currencyFormatter.format(
                          liabilityDashboardDetails.liability_general_damages.reduce(
                            (acc, curr) => acc + curr.high_range,
                            0
                          )
                        )}
                      </strong>
                    </TableCell>
                  </TableRow>
                }
              />
            </CardDialog>
          </div>
        )}
      </CardDialog>
      {showNewMedicalDamageDialog && (
        <MedicalDamageDialog
          title="New Medical Damage"
          onSubmit={async (values) => {
            await handleAddMedicalDamage(values);
            setShowNewMedicalDamageDialog(false);
          }}
          onClose={() => setShowNewMedicalDamageDialog(false)}
          isGlClaim={isGlClaim}
        />
      )}
      {medicalDamageToEdit && (
        <MedicalDamageDialog
          title="Edit Medical Damage"
          medicalDamage={medicalDamageToEdit}
          onSubmit={async (values) => {
            await handleUpdateMedicalDamage(values);
            setMedicalDamageToEdit(false);
          }}
          onClose={() => setMedicalDamageToEdit(false)}
          isGlClaim={isGlClaim}
        />
      )}
      {showNewWagesAndOtherExpensesDialog && (
        <WagesAndOtherExpensesDialog
          title={isGlClaim ? 'New Lost Wage' : 'New Lost Wage/Other Expense'}
          onSubmit={async (values) => {
            await handleAddWagesAndOtherExpenses(values);
            setShowNewWagesAndOtherExpensesDialog(false);
          }}
          onClose={() => setShowNewWagesAndOtherExpensesDialog(false)}
          isGlClaim={isGlClaim}
          forceType={isGlClaim && 'Lost Wage'}
        />
      )}
      {wagesAndOtherExpensesToEdit && (
        <WagesAndOtherExpensesDialog
          title={isGlClaim ? 'Edit Lost Wage' : 'Edit Lost Wage/Other Expense'}
          wagesAndOtherExpenses={wagesAndOtherExpensesToEdit}
          onSubmit={async (values) => {
            await handleUpdateWagesAndOtherExpenses(values, wagesAndOtherExpensesToEdit);
            setWagesAndOtherExpensesToEdit(false);
          }}
          onClose={() => setWagesAndOtherExpensesToEdit(false)}
          isGlClaim={isGlClaim}
          forceType={isGlClaim && 'Lost Wage'}
        />
      )}
      {isGlClaim && (
        <>
          {showNewOtherExpensesDialog && (
            <WagesAndOtherExpensesDialog
              title="New Other Expense"
              onSubmit={async (values) => {
                await handleAddWagesAndOtherExpenses(values);
                setShowNewOtherExpensesDialog(false);
              }}
              onClose={() => setShowNewOtherExpensesDialog(false)}
              isGlClaim
              forceType="Other Expense"
            />
          )}
          {otherExpensesToEdit && (
            <WagesAndOtherExpensesDialog
              title="Edit Other Expense"
              wagesAndOtherExpenses={otherExpensesToEdit}
              onSubmit={async (values) => {
                await handleUpdateWagesAndOtherExpenses(values, otherExpensesToEdit);
                setOtherExpensesToEdit(false);
              }}
              onClose={() => setOtherExpensesToEdit(false)}
              isGlClaim
              forceType="Other Expense"
            />
          )}
        </>
      )}
      {isGlClaim ? (
        // GLClaim - Show Punitive / General Damage dialog
        <>
          {showNewGeneralDamageDialog && (
            <PunitiveOrGeneralDamageDialog
              title="New General Damage"
              onSubmit={async (values) => {
                await handleAddGeneralDamage(values);
                setShowNewGeneralDamageDialog(false);
              }}
              onClose={() => setShowNewGeneralDamageDialog(false)}
            />
          )}
          {generalDamageToEdit && (
            <PunitiveOrGeneralDamageDialog
              title="Edit General Damage"
              generalDamage={generalDamageToEdit}
              onSubmit={async (values) => {
                await handleUpdateGeneralDamage(values);
                setGeneralDamageToEdit(false);
              }}
              onClose={() => setGeneralDamageToEdit(false)}
            />
          )}
        </>
      ) : (
        // Show General Damage dialog
        <>
          {showNewGeneralDamageDialog && (
            <GeneralDamageDialog
              title="New General Damage"
              onSubmit={async (values) => {
                await handleAddGeneralDamage(values);
                setShowNewGeneralDamageDialog(false);
              }}
              onClose={() => setShowNewGeneralDamageDialog(false)}
              isGlClaim={isGlClaim}
            />
          )}
          {generalDamageToEdit && (
            <GeneralDamageDialog
              title="Edit General Damage"
              generalDamage={generalDamageToEdit}
              onSubmit={async (values) => {
                await handleUpdateGeneralDamage(values);
                setGeneralDamageToEdit(false);
              }}
              onClose={() => setGeneralDamageToEdit(false)}
              isGlClaim={isGlClaim}
            />
          )}
        </>
      )}
      {showNewPunitiveDamageDialog && (
        <PunitiveOrGeneralDamageDialog
          title="New Punitive Damage"
          onSubmit={async (values) => {
            await handleAddPunitiveDamage(values);
            setShowNewPunitiveDamageDialog(false);
          }}
          onClose={() => setShowNewPunitiveDamageDialog(false)}
        />
      )}
      {punitiveDamageToEdit && (
        <PunitiveOrGeneralDamageDialog
          title="Edit Punitive Damage"
          punitiveDamage={punitiveDamageToEdit}
          onSubmit={async (values) => {
            await handleUpdatePunitiveDamage(values);
            setPunitiveDamageToEdit(false);
          }}
          onClose={() => setPunitiveDamageToEdit(false)}
        />
      )}
    </>
  );
}

BiEvaluationDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  liabilityDashboardDetails: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function EditablePaymentAmount({ exposure, onUpdate, label, automaticAmount, manualAmountKey, viewOnly }) {
  const manualAmountValue = exposure[manualAmountKey] !== null ? exposure[manualAmountKey] : undefined;
  const displayAmount = manualAmountValue ? manualAmountValue : automaticAmount;
  const { currencyFormatter } = useCurrencyFormatter();

  return (
    <HoverChangeField
      name={manualAmountKey}
      value={displayAmount}
      label={label}
      onUpdate={({ [manualAmountKey]: manualAmount }) => onUpdate(manualAmountKey, manualAmount)}
      disabled={viewOnly}
      specialFieldType="monetary"
    >
      <DisableableToolTip
        title={`Manually edited, calculated value: ${currencyFormatter.format(automaticAmount)}`}
        disabled={manualAmountValue !== undefined}
      >
        <span style={{ fontStyle: manualAmountValue !== undefined ? 'italic' : undefined }}>
          {currencyFormatter.format(displayAmount)}
        </span>
      </DisableableToolTip>
    </HoverChangeField>
  );
}

EditablePaymentAmount.propTypes = {
  exposure: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  automaticAmount: PropTypes.number.isRequired,
  manualAmountKey: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
};

function GeneralDamageDialog({ title, onSubmit, generalDamage, onClose, isGlClaim }) {
  const classes = useStyles();
  const { bodyPartsDict } = useOrganization();

  const requiredIfTreatment = (schema) =>
    schema.when('treatment_surgery', (treatment, currSchema) =>
      treatment.length && !treatment.includes('no_treatment') ? currSchema.required('Required') : currSchema
    );

  const validationSchema = isGlClaim
    ? Yup.object().shape({
        rationale: Yup.string().max(128),
        low_range: Yup.number().required('Required'),
        high_range: Yup.number().required('Required'),
      })
    : Yup.object().shape({
        body_part: Yup.string().required('Required'),
        other_body_part: Yup.string().when('body_part', { is: 'other', then: Yup.string().required('Required') }),
        extent_of_injury: Yup.array().of(Yup.string()).required('Required').min(1, 'Required'),
        treatment_surgery: Yup.array().of(Yup.string()).required('Required').min(1, 'Required'),
        other_discectomy: Yup.string().nullable(),
        other_surgery: Yup.string().nullable(),
        threshold_type: requiredIfTreatment(Yup.string()),
        threshold_assessment: requiredIfTreatment(Yup.string()),
        rationale: requiredIfTreatment(Yup.string().max(128)),
        low_range: requiredIfTreatment(Yup.number()),
        high_range: requiredIfTreatment(Yup.number()),
      });

  return (
    <Formik
      initialValues={
        generalDamage
          ? generalDamage
          : {
              body_part: '',
              other_body_part: '',
              extent_of_injury: [],
              treatment_surgery: [],
              other_discectomy: '',
              other_surgery: '',
              threshold_type: '',
              threshold_assessment: '',
              rationale: '',
              low_range: '',
              high_range: '',
            }
      }
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <CardDialog title={title} onClose={onClose} preventClose={isSubmitting} isDialog maxWidth="xs" fullWidth>
          <Grid container spacing={1}>
            {!isGlClaim && (
              <>
                <Grid item xs={6}>
                  <TextFieldFormik id="body_part" label="Body Part" fullWidth select>
                    {Object.keys(bodyPartsDict).map((body_part) => (
                      <MenuItem key={body_part} value={body_part}>
                        {bodyPartsDict[body_part]['desc']}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </Grid>
                <Grid item xs={6}>
                  {values.body_part === 'other' && (
                    <TextFieldFormik id="other_body_part" label="Other Part" fullWidth />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <MultiSelectTextFieldFormik
                    id="extent_of_injury"
                    label="Injury Type"
                    options={Object.keys(EXTENT_OF_INJURY_TYPES_DICT)}
                    renderValue={(selected) =>
                      selected.map((type) => EXTENT_OF_INJURY_TYPES_DICT[type]['desc']).join(', ')
                    }
                    className={classes.textField}
                    renderOption={(type) => EXTENT_OF_INJURY_TYPES_DICT[type]['desc']}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultiSelectTextFieldFormik
                    id="treatment_surgery"
                    label="Treatment"
                    options={Object.keys(TREATMENT_SURGERIES_DICT)}
                    renderValue={(selected) =>
                      selected.map((type) => TREATMENT_SURGERIES_DICT[type]['desc']).join(', ')
                    }
                    className={classes.textField}
                    renderOption={(type) => TREATMENT_SURGERIES_DICT[type]['desc']}
                    emptyChoiceValue="no_treatment"
                    fullWidth
                  />
                </Grid>
                {values.treatment_surgery.includes('other_surgery') && (
                  <Grid item xs={6}>
                    <TextFieldFormik id="other_surgery" label="Other Surgery" fullWidth />
                  </Grid>
                )}
                {values.treatment_surgery.includes('other_discectomy') && (
                  <Grid item xs={6}>
                    <TextFieldFormik id="other_discectomy" label="Other Discectomy" fullWidth />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextFieldFormik id="threshold_type" label="Threshold Type" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldFormik id="threshold_assessment" label="Threshold assessment" fullWidth />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextFieldFormik id="rationale" label="Rationale" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <MonetaryValueTextFieldFormik className={classes.textField} id="low_range" label="Low" />
            </Grid>
            <Grid item xs={6}>
              <MonetaryValueTextFieldFormik className={classes.textField} id="high_range" label="High" />
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
              Set
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

GeneralDamageDialog.propTypes = {
  title: PropTypes.string.isRequired,
  generalDamage: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isGlClaim: PropTypes.bool,
};

function PunitiveOrGeneralDamageDialog({ title, onSubmit, punitiveDamage, onClose }) {
  // Relevant only for GL Claim
  const classes = useStyles();

  return (
    <Formik
      initialValues={
        punitiveDamage
          ? punitiveDamage
          : {
              rationale: '',
              low_range: '',
              high_range: '',
              body_part: '', // For the BE to have this values and not crash
              other_body_part: '', // For the BE to have this values and not crash
              extent_of_injury: [], // For the BE to have this values and not crash
              treatment_surgery: [], // For the BE to have this values and not crash
              other_discectomy: '', // For the BE to have this values and not crash
              other_surgery: '', // For the BE to have this values and not crash
              threshold_type: '', // For the BE to have this values and not crash
              threshold_assessment: '', // For the BE to have this values and not crash
            }
      }
      validationSchema={Yup.object().shape({
        rationale: Yup.string().max(128),
        low_range: Yup.number().required('Required'),
        high_range: Yup.number().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <CardDialog title={title} onClose={onClose} preventClose={isSubmitting} isDialog maxWidth="xs" fullWidth>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextFieldFormik id="rationale" label="Rationale" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <MonetaryValueTextFieldFormik className={classes.textField} id="low_range" label="Low" />
            </Grid>
            <Grid item xs={6}>
              <MonetaryValueTextFieldFormik className={classes.textField} id="high_range" label="High" />
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
              Set
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

PunitiveOrGeneralDamageDialog.propTypes = {
  title: PropTypes.string.isRequired,
  punitiveDamage: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function MedicalDamageDialog({ title, onSubmit, medicalDamage, onClose, isGlClaim }) {
  const classes = useStyles();

  const validationSchema = isGlClaim
    ? Yup.object().shape({
        medical_record_bill_document_id: Yup.number().nullable(),
        provider: Yup.string(),
        start_date_of_treatment: Yup.date().nullable(),
        end_date_of_treatment: Yup.date()
          .nullable()
          .when('start_date_of_treatment', {
            is: (val) => !!val,
            then: Yup.date().nullable().min(Yup.ref('start_date_of_treatment'), "Can't be before the start date"),
          }),
        number_of_visits: Yup.number().nullable(),
        amount_submitted: Yup.number().nullable(),
        amount_considered: Yup.number().nullable(),
        rationale: Yup.string().nullable(),
        treatment: Yup.string().nullable(),
        time_type: Yup.string().nullable().oneOf(['Incurred', 'Future', null]),
      })
    : Yup.object().shape({
        medical_record_bill_document_id: Yup.number().required('Required'),
        provider: Yup.string().required('Required'),
        start_date_of_treatment: Yup.date().required('Required'),
        end_date_of_treatment: Yup.date()
          .required('Required')
          .when('start_date_of_treatment', {
            is: (val) => !!val,
            then: Yup.date().min(Yup.ref('start_date_of_treatment'), "Can't be before the start date"),
          }),
        number_of_visits: Yup.number().nullable().required('Required'),
        disability_assessment: Yup.string().required('Required'),
        start_date_of_disability: Yup.date().when('disability_assessment', (disability, currSchema) =>
          disability && disability !== 'No Disability' ? currSchema.required('Required') : currSchema
        ),
        end_date_of_disability: Yup.date().when('disability_assessment', (disability, currSchema) =>
          disability && disability !== 'No Disability'
            ? currSchema.required('Required').min(Yup.ref('start_date_of_disability'), "Can't be before the start date")
            : currSchema
        ),
        amount_submitted: Yup.number().nullable().required('Required'),
        amount_considered: Yup.number().nullable().required('Required'),
        rationale: Yup.string().nullable().required('Required'),
      });

  return (
    <Formik
      initialValues={
        medicalDamage
          ? medicalDamage
          : {
              medical_record_bill_document_id: '',
              provider: '',
              start_date_of_treatment: '',
              end_date_of_treatment: '',
              number_of_visits: '',
              disability_assessment: '',
              start_date_of_disability: '',
              end_date_of_disability: '',
              amount_submitted: '',
              amount_considered: '',
              rationale: '',
              treatment: '', // relevant for GLClaim only
              time_type: '', // relevant for GLClaim only
              treatment_extra: '', // relevant for GLClaim only
            }
      }
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <CardDialog title={title} onClose={onClose} preventClose={isSubmitting} isDialog maxWidth="sm" fullWidth>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DocumentTextFieldFormik id="medical_record_bill_document_id" label="Medical Info" />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik id="provider" label="Provider" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <DatePickerTextFieldFormik
                id="start_date_of_treatment"
                label="Start Date of Treatment"
                className={classes.textField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerTextFieldFormik
                id="end_date_of_treatment"
                label="End Date of Treatment"
                className={classes.textField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik
                id="number_of_visits"
                label="Number of Visits"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                fullWidth
              />
            </Grid>
            {isGlClaim && (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextFieldFormik id="treatment" label="Treatment" fullWidth select>
                    {['Other'].map((val) => (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </Grid>
                {values.treatment === 'Other' && (
                  <Grid item xs={6}>
                    <TextFieldFormik id="treatment_extra" label="Extra" fullWidth />
                  </Grid>
                )}
              </Grid>
            )}
            {!isGlClaim && (
              <>
                <Grid item xs={6}>
                  <TextFieldFormik id="disability_assessment" label="Disability Assessment" fullWidth select>
                    {['No Disability', 'Partial Disability', 'Permanent Disability'].map((da) => (
                      <MenuItem key={da} value={da}>
                        {da}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </Grid>
                <Grid item xs={6}>
                  <DatePickerTextFieldFormik
                    id="start_date_of_disability"
                    label="Start Date of Disability"
                    className={classes.textField}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePickerTextFieldFormik
                    id="end_date_of_disability"
                    label="End Date of Disability"
                    className={classes.textField}
                    fullWidth
                  />
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <MonetaryValueTextFieldFormik
                className={classes.textField}
                id="amount_submitted"
                label="Amount Submitted"
              />
            </Grid>
            <Grid item xs={6}>
              <MonetaryValueTextFieldFormik
                className={classes.textField}
                id="amount_considered"
                label="Amount Considered"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormik id="rationale" label={isGlClaim ? 'Description of Treatment' : 'Rationale'} fullWidth />
          </Grid>
          {isGlClaim && (
            <Grid item xs={6}>
              <TextFieldFormik id="time_type" label="Type" fullWidth select>
                {['Incurred', 'Future'].map((val) => (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </Grid>
          )}
          <div className={classes.buttonsContainer}>
            <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
              Set
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

MedicalDamageDialog.propTypes = {
  title: PropTypes.string.isRequired,
  medicalDamage: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isGlClaim: PropTypes.bool,
};

function WagesAndOtherExpensesDialog({ title, onSubmit, wagesAndOtherExpenses, onClose, forceType, isGlClaim }) {
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  const validationSchema = isGlClaim
    ? Yup.object().shape({
        type: Yup.string().nullable(),
        employer_oe_provider: Yup.string().nullable(),
        lost_wage_other_expense_record_document_id: Yup.number().nullable(),
        start_date_of_request: Yup.date().nullable(),
        end_date_of_request: Yup.date()
          .nullable()
          .when('start_date_of_request', {
            is: (val) => !!val,
            then: Yup.date().nullable().min(Yup.ref('start_date_of_request'), "Can't be before the start date"),
          }),
        number_of_days: Yup.number().nullable(),
        amount_submitted: Yup.number().nullable(),
        amount_considered: Yup.number().nullable(),
        rationale: Yup.string().nullable(),
        work_life_expectancy: Yup.number().nullable(),
        time_type: Yup.string().nullable().oneOf(['Incurred', 'Future', null]),
        provider_contact_id: Yup.number().nullable(),
      })
    : Yup.object().shape({
        type: Yup.string().nullable().required('Required'),
        employer_oe_provider: Yup.string().nullable().required('Required'),
        lost_wage_other_expense_record_document_id: Yup.number().required('Required'),
        expense_type: Yup.string().required('Required'),
        start_date_of_request: Yup.date().required('Required'),
        end_date_of_request: Yup.date()
          .required('Required')
          .when('start_date_of_request', {
            is: (val) => !!val,
            then: Yup.date().min(Yup.ref('start_date_of_request'), "Can't be before the start date"),
          }),
        number_of_days: Yup.number().nullable().required('Required'),
        amount_submitted: Yup.number().required('Required'),
        amount_considered: Yup.number().required('Required'),
        rationale: Yup.string().nullable().required('Required'),
      });

  return (
    <Formik
      initialValues={
        wagesAndOtherExpenses
          ? wagesAndOtherExpenses
          : {
              type: forceType ? forceType : '',
              employer_oe_provider: '',
              lost_wage_other_expense_record_document_id: '',
              expense_type: '',
              start_date_of_request: '',
              end_date_of_request: '',
              number_of_days: '',
              amount_submitted: '',
              amount_considered: '',
              rationale: '',
              work_life_expectancy: '', // relevant for GLClaim only
              time_type: '', // relevant for GLClaim only
              provider_contact_id: '', // relevant for GLClaim only
            }
      }
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <CardDialog title={title} onClose={onClose} preventClose={isSubmitting} isDialog maxWidth="sm" fullWidth>
          <Grid container spacing={1}>
            {!forceType && (
              <>
                <Grid item xs={6}>
                  <TextFieldFormik id="type" label="Type" fullWidth select>
                    {['Lost Wage', 'Other Expense'].map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </Grid>
                <Grid item xs={6} />
              </>
            )}
            {(!isGlClaim || forceType === 'Lost Wage') && (
              <Grid item xs={6}>
                {/* Include if not GL claim, or for GL & LostWage */}
                <TextFieldFormik
                  id="employer_oe_provider"
                  label={isGlClaim ? 'Employer' : 'Employer/OE Provider'}
                  fullWidth
                />
              </Grid>
            )}
            {isGlClaim && forceType === 'Other Expense' && (
              <Grid item xs={6}>
                <ContactTextFieldFormik
                  id="provider_contact"
                  label="Provider"
                  acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
                  fullWidth
                  fixedSearchResults
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <DocumentTextFieldFormik id="lost_wage_other_expense_record_document_id" label="Wages/OE Documentation" />
            </Grid>
            <Grid item xs={6}>
              <DatePickerTextFieldFormik
                id="start_date_of_request"
                label="Start Date of Request"
                className={classes.textField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerTextFieldFormik
                id="end_date_of_request"
                label="End Date of Request"
                className={classes.textField}
                fullWidth
              />
            </Grid>
            {!isGlClaim && (
              <Grid item xs={6}>
                <TextFieldFormik id="expense_type" label="Expense Type" fullWidth />
              </Grid>
            )}
            {isGlClaim && forceType === 'Lost Wage' && (
              <Grid item xs={6}>
                <TextFieldFormik
                  id="work_life_expectancy"
                  label="Work-Life expectancy"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TextFieldFormik
                id="number_of_days"
                label="Number of Days"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MonetaryValueTextFieldFormik
                className={classes.textField}
                id="amount_submitted"
                label="Amount Submitted"
              />
            </Grid>
            <Grid item xs={6}>
              <MonetaryValueTextFieldFormik
                className={classes.textField}
                id="amount_considered"
                label="Amount Considered"
              />
            </Grid>
            {isGlClaim && forceType === 'Lost Wage' && (
              <Grid item xs={6}>
                <TextFieldFormik id="time_type" label="Type" fullWidth select>
                  {['Incurred', 'Future'].map((val) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextFieldFormik
                id="rationale"
                label={isGlClaim ? (forceType === 'Lost Wage' ? 'Occupation' : 'Description') : 'Rationale'}
                fullWidth
              />
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
              Set
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

WagesAndOtherExpensesDialog.propTypes = {
  title: PropTypes.string.isRequired,
  wagesAndOtherExpenses: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isGlClaim: PropTypes.bool,
  forceType: PropTypes.oneOf(['Lost Wage', 'Other Expense']),
};

function LiabilityDashboardLiensSummaryCard({ claim, exposure, liens, onUpdate, viewOnly }) {
  const classes = useStyles();
  const [showNewLienDialog, setShowNewLienDialog] = useState(false);
  const [lienToEdit, setLienToEdit] = useState();
  const { currencyFormatter } = useCurrencyFormatter();

  async function handleAddLien(values) {
    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/liens`, values);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateLien(values) {
    try {
      await axios.put(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/liability/liens/${values.id}`, values);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  const lienColumns = [
    { id: 'internal_id', label: '#', width: 10 },
    {
      id: 'lien_type',
      label: 'Lien Type',
      specialCell: (lien) =>
        lien.lien_type !== 'other'
          ? `${LIABILITY_LIEN_TYPES_DICT[lien.lien_type].desc}`
          : `Other - ${lien.other_lien_type}`,
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'document',
      label: 'Document',
      specialCell: (lien) => {
        // eslint-disable-next-line react/prop-types
        const document = claim.documents.find((d) => d.id === lien.document_id);
        return <DocumentLink document={document} text={document.document_name} />;
      },
    },
    { id: 'lien_holder', label: 'Lien Holder' },
    {
      id: 'amount',
      label: 'Amount',
      disablePadding: true,
      specialCell: (lien) => currencyFormatter.format(lien.amount),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'edit',
      label: '',
      width: 10,
      disableSort: true,
      disablePadding: true,
      specialCell: (lien) => <HoverActionField onAction={() => setLienToEdit(lien)} permanent />,
    },
  ];

  return (
    <>
      <CardDialog title="Liens" outlinedCard>
        <Button color="primary" onClick={() => setShowNewLienDialog(true)} disabled={viewOnly}>
          <AddIcon className={classes.leftButtonIcon} />
          Add New
        </Button>
        <SortableTable
          rows={addInternalIdToObjectsArray(liens)}
          columns={lienColumns}
          defaultOrderColumn={0}
          footerRow={
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align="center" padding="none">
                <strong>Total:</strong>
              </TableCell>
              <TableCell align="left" padding="none">
                <strong>{currencyFormatter.format(liens.reduce((acc, curr) => acc + curr.amount, 0))}</strong>
              </TableCell>
            </TableRow>
          }
        />
      </CardDialog>
      {showNewLienDialog && (
        <LienDialog title="Add New Lien" onSubmit={handleAddLien} onClose={() => setShowNewLienDialog(false)} />
      )}
      {lienToEdit && (
        <LienDialog
          title="Edit lien"
          lien={lienToEdit}
          onSubmit={handleUpdateLien}
          onClose={() => setLienToEdit(undefined)}
        />
      )}
    </>
  );
}

LiabilityDashboardLiensSummaryCard.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  liens: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

function LienDialog({ title, lien, onSubmit, onClose }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={
        lien
          ? lien
          : {
              lien_type: '',
              other_lien_type: '',
              document_id: '',
              lien_holder: '',
              amount: '',
            }
      }
      validationSchema={Yup.object().shape({
        lien_type: Yup.string().required('Required'),
        other_lien_type: Yup.string().when('lien_type', {
          is: 'other',
          then: Yup.string().max(128).required('Required'),
        }),
        document_id: Yup.number().required('Required'),
        lien_holder: Yup.string().max(128).required('Required'),
        amount: Yup.number().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
          onClose();
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <CardDialog title={title} maxWidth="xs" fullWidth isDialog onClose={onClose} preventClose={isSubmitting}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextFieldFormik id="lien_type" label="Lien Type" fullWidth select>
                {Object.keys(LIABILITY_LIEN_TYPES_DICT).map((type) => (
                  <MenuItem key={type} value={type}>
                    {LIABILITY_LIEN_TYPES_DICT[type]['desc']}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </Grid>
            {values.lien_type === 'other' && (
              <Grid item xs={12}>
                <TextFieldFormik id="other_lien_type" label="Other Type" fullWidth />
              </Grid>
            )}
            <Grid item xs={12}>
              <DocumentTextFieldFormik id="document_id" label="Document" />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik id="lien_holder" label="Lien Holder" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <MonetaryValueTextFieldFormik className={classes.textField} id="amount" label="Amount" />
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
              Set
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

LienDialog.propTypes = {
  title: PropTypes.string.isRequired,
  lien: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function NegotiationAndLitigationDialog({ claim, exposure, liabilityDashboardDetails, onUpdate, onClose, viewOnly }) {
  const classes = useStyles();
  const { currencyFormatter } = useCurrencyFormatter();

  const [showNewProsAndConsDialog, setShowNewProsAndConsDialog] = useState(false);
  const [prosAndConsToEdit, setProsAndConsToEdit] = useState();

  const [showNewNegotiationDialog, setShowNewNegotiationDialog] = useState(false);
  const [negotiationToEdit, setNegotiationToEdit] = useState();

  const [showNewLitigationDialog, setShowNewLitigationDialog] = useState(false);
  const [litigationToEdit, setLitigationToEdit] = useState();

  const { litigationStatusDict } = useOrganization();

  async function handleAddProsCons(values) {
    try {
      await axios.post(
        `/api/v1/auto_claims/${exposure.claim_id}/exposures/${exposure.id}/liability/negotiation_pros_cons`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateProsCons(values) {
    try {
      await axios.put(
        `/api/v1/auto_claims/${exposure.claim_id}/exposures/${exposure.id}/liability/negotiation_pros_cons/${values.id}`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  const prosAndConsColumns = [
    { id: 'internal_id', label: '#', width: 60 },
    { id: 'pros_and_cons', label: 'Pros & Cons', overflow: true, maxWidthPercentage: '35' },
    { id: 'rationale', label: 'Rationale', overflow: true, maxWidthPercentage: '35' },
    // eslint-disable-next-line react/display-name
    {
      id: 'edit',
      label: '',
      width: 10,
      disableSort: true,
      disablePadding: true,
      specialCell: (prosAndCons) => <HoverActionField onAction={() => setProsAndConsToEdit(prosAndCons)} permanent />,
    },
  ];

  async function handleAddNegotiation(values) {
    try {
      await axios.post(
        `/api/v1/auto_claims/${exposure.claim_id}/exposures/${exposure.id}/liability/negotiations`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateNegotiation(values) {
    try {
      await axios.put(
        `/api/v1/auto_claims/${exposure.claim_id}/exposures/${exposure.id}/liability/negotiations/${values.id}`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  const negotiationColumns = [
    { id: 'internal_id', label: '#', width: 60 },
    { id: 'date', label: 'Date', width: 110, specialCell: (negotiation) => isoDateToUs(negotiation.date) },
    {
      id: 'claimant_demand',
      label: 'Claimant Demand',
      width: 160,
      specialCell: (negotiation) => currencyFormatter.format(negotiation.claimant_demand_amount),
    },
    {
      id: 'org_offer',
      label: `${claim.policy.client.toUpperCase()} Offer`,
      width: 150,
      specialCell: (negotiation) => currencyFormatter.format(negotiation.org_offer),
    },
    { id: 'note', label: 'Notes', overflow: true, maxWidthPercentage: '30' },
    // eslint-disable-next-line react/display-name
    {
      id: 'edit',
      label: '',
      width: 10,
      disableSort: true,
      disablePadding: true,
      specialCell: (negotiation) => <HoverActionField onAction={() => setNegotiationToEdit(negotiation)} permanent />,
    },
  ];

  async function handleAddLitigation(values) {
    try {
      await axios.post(
        `/api/v1/auto_claims/${exposure.claim_id}/exposures/${exposure.id}/liability/litigations`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateLitigation(values) {
    try {
      await axios.put(
        `/api/v1/auto_claims/${exposure.claim_id}/exposures/${exposure.id}/liability/litigations/${values.id}`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  const litigationColumns = [
    { id: 'internal_id', label: '#', width: 60 },
    { id: 'date', label: 'Date', width: 110, specialCell: (litigation) => isoDateToUs(litigation.date) },
    {
      id: 'status_of_litigation',
      label: 'Status of Litigation',
      specialCell: (litigation) => litigationStatusDict[litigation.status_of_litigation]?.['desc'],
    },
    { id: 'dc_update', label: 'DC Update' },
    {
      id: 'dc_report_document',
      label: 'DC Report',
      // eslint-disable-next-line react/display-name
      specialCell: (litigation) => {
        // eslint-disable-next-line react/prop-types
        const document = claim.documents.find((d) => d.id === litigation.dc_report_document_id);
        return document ? <DocumentLink document={document} text={document.document_name} /> : <></>;
      },
    },
    { id: 'next_action', label: 'Plan of next Action' },
    // eslint-disable-next-line react/display-name
    {
      id: 'edit',
      label: '',
      width: 10,
      disableSort: true,
      disablePadding: true,
      specialCell: (litigation) => <HoverActionField onAction={() => setLitigationToEdit(litigation)} permanent />,
    },
  ];

  return (
    <>
      <CardDialog title="Negotiation & Litigation" isDialog maxWidth="xl" fullWidth onClose={onClose}>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <LiabilityDashboardEvaluationSummaryCard
              claim={claim}
              exposure={exposure}
              liabilityDashboardDetails={liabilityDashboardDetails}
              onUpdate={onUpdate}
              viewOnly={viewOnly}
            />
          </Grid>
          <Grid item xs={7}>
            <ExposureNotesCard claim={claim} exposure={exposure} onUpdate={onUpdate} />
          </Grid>
          <Grid item xs={5}>
            <CardDialog
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Pros & Cons</span>
                  <span style={{ paddingLeft: 8 }}>
                    <Button color="primary" onClick={() => setShowNewProsAndConsDialog(true)} disabled={viewOnly}>
                      <AddIcon className={classes.leftButtonIcon} />
                      Add New
                    </Button>
                  </span>
                </div>
              }
              outlinedCard
            >
              <SortableTable
                rows={addInternalIdToObjectsArray(liabilityDashboardDetails.liability_negotiation_pros_cons)}
                columns={prosAndConsColumns}
                defaultOrderColumn={0}
                maxHeight="35vh"
              />
            </CardDialog>
          </Grid>
          <Grid item xs={7}>
            <CardDialog
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Negotiation</span>
                  <span style={{ paddingLeft: 8 }}>
                    <Button color="primary" onClick={() => setShowNewNegotiationDialog(true)} disabled={viewOnly}>
                      <AddIcon className={classes.leftButtonIcon} />
                      Add New
                    </Button>
                  </span>
                </div>
              }
              outlinedCard
            >
              <SortableTable
                rows={addInternalIdToObjectsArray(liabilityDashboardDetails.liability_negotiations)}
                columns={negotiationColumns}
                defaultOrderColumn={0}
                maxHeight="35vh"
              />
            </CardDialog>
          </Grid>
          <Grid item xs={12}>
            <CardDialog
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Litigations</span>
                  <span style={{ paddingLeft: 8 }}>
                    <Button color="primary" onClick={() => setShowNewLitigationDialog(true)} disabled={viewOnly}>
                      <AddIcon className={classes.leftButtonIcon} />
                      Add New
                    </Button>
                  </span>
                </div>
              }
              outlinedCard
            >
              <SortableTable
                rows={addInternalIdToObjectsArray(liabilityDashboardDetails.liability_litigations)}
                columns={litigationColumns}
                defaultOrderColumn={0}
              />
            </CardDialog>
          </Grid>
        </Grid>
      </CardDialog>

      {showNewProsAndConsDialog && (
        <BiProsAndConsDialog
          title="Add New Pros/Cons"
          onSubmit={handleAddProsCons}
          onClose={() => setShowNewProsAndConsDialog(false)}
        />
      )}
      {prosAndConsToEdit && (
        <BiProsAndConsDialog
          title="Edit Pros/Cons"
          prosAndCons={prosAndConsToEdit}
          onSubmit={handleUpdateProsCons}
          onClose={() => setProsAndConsToEdit(null)}
        />
      )}

      {showNewNegotiationDialog && (
        <BiNegotiationDialog
          claim={claim}
          exposure={exposure}
          liabilityDashboardDetails={liabilityDashboardDetails}
          title="Add New Negotiation"
          isFirst={liabilityDashboardDetails.liability_negotiations.length === 0}
          onSubmit={handleAddNegotiation}
          onClose={() => setShowNewNegotiationDialog(false)}
        />
      )}
      {negotiationToEdit && (
        <BiNegotiationDialog
          claim={claim}
          exposure={exposure}
          liabilityDashboardDetails={liabilityDashboardDetails}
          title="Edit Negotiation"
          negotiation={negotiationToEdit}
          onSubmit={handleUpdateNegotiation}
          onClose={() => setNegotiationToEdit(null)}
        />
      )}

      {showNewLitigationDialog && (
        <BiLitigationDialog
          claim={claim}
          title="Add New litigation"
          onSubmit={handleAddLitigation}
          onClose={() => setShowNewLitigationDialog(false)}
        />
      )}
      {litigationToEdit && (
        <BiLitigationDialog
          claim={claim}
          title="Edit litigation"
          litigation={litigationToEdit}
          onSubmit={handleUpdateLitigation}
          onClose={() => setLitigationToEdit(null)}
        />
      )}
    </>
  );
}

NegotiationAndLitigationDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  liabilityDashboardDetails: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

function BiProsAndConsDialog({ title, prosAndCons, onSubmit, onClose }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={
        prosAndCons
          ? prosAndCons
          : {
              pros_and_cons: '',
              rationale: '',
            }
      }
      validationSchema={Yup.object().shape({
        pros_and_cons: Yup.string().required('Required'),
        rationale: Yup.string().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
          onClose();
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <CardDialog title={title} maxWidth="xs" fullWidth isDialog onClose={onClose} preventClose={isSubmitting}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextFieldFormik id="pros_and_cons" label="Pros & Cons" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik id="rationale" label="Rationale" fullWidth />
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
              Set
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

BiProsAndConsDialog.propTypes = {
  title: PropTypes.string.isRequired,
  prosAndCons: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function BiNegotiationDialog({
  claim,
  exposure,
  liabilityDashboardDetails,
  title,
  negotiation,
  isFirst,
  onSubmit,
  onClose,
}) {
  const classes = useStyles();

  const updateNotesIfNeeded = (claimantDemand, orgOffer, note, setFieldValue) => {
    if (orgOffer && claimantDemand === orgOffer && note === '') {
      setFieldValue('note', 'Settled');
    }
  };

  const netLowEvaluation =
    ((100 - parseFloat(exposure.percentage_of_claimant_liability)) * liabilityDashboardDetails.total_low) / 100;

  return (
    <Formik
      initialValues={
        negotiation
          ? negotiation
          : {
              date: '',
              claimant_demand_amount: '',
              org_offer: isFirst ? netLowEvaluation : '',
              note: '',
            }
      }
      validationSchema={Yup.object().shape({
        date: Yup.date().required('Required'),
        claimant_demand_amount: Yup.number().required('Required'),
        org_offer: Yup.number().required('Required'),
        note: Yup.string().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
          onClose();
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <CardDialog title={title} maxWidth="xs" fullWidth isDialog onClose={onClose} preventClose={isSubmitting}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DatePickerTextFieldFormik id="date" label="Date" className={classes.textField} />
            </Grid>
            <Grid item xs={12}>
              <MonetaryValueTextFieldFormik
                className={classes.textField}
                id="claimant_demand_amount"
                label="Claimant Demand"
                onChange={(amount) => updateNotesIfNeeded(amount, values.org_offer, values.note, setFieldValue)}
              />
            </Grid>
            <Grid item xs={12}>
              <MonetaryValueTextFieldFormik
                className={classes.textField}
                id="org_offer"
                label={`${capitalize(claim.policy.client)} Offer`}
                onChange={(amount) =>
                  updateNotesIfNeeded(values.claimant_demand_amount, amount, values.note, setFieldValue)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik id="note" label="Notes" fullWidth />
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
              Set
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

BiNegotiationDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  liabilityDashboardDetails: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  negotiation: PropTypes.object,
  isFirst: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function BiLitigationDialog({ title, litigation, onSubmit, onClose }) {
  const classes = useStyles();
  const { litigationStatusDict } = useOrganization();

  return (
    <Formik
      initialValues={
        litigation
          ? litigation
          : {
              date: '',
              status_of_litigation: '',
              dc_update: '',
              dc_report_document_id: '',
              next_action: '',
            }
      }
      validationSchema={Yup.object().shape({
        date: Yup.date().required('Required'),
        status_of_litigation: Yup.string().required('Required'),
        dc_update: Yup.string().required('Required'),
        dc_report_document_id: Yup.number(),
        next_action: Yup.string().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
          onClose();
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <CardDialog title={title} maxWidth="xs" fullWidth isDialog onClose={onClose} preventClose={isSubmitting}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DatePickerTextFieldFormik id="date" label="Date" className={classes.textField} />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik id="status_of_litigation" label="Status of Litigation" fullWidth select>
                {Object.keys(litigationStatusDict).map((status) => (
                  <MenuItem key={status} value={status}>
                    {litigationStatusDict[status]['desc']}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik id="dc_update" label="DC Update" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <DocumentTextFieldFormik id="dc_report_document_id" label="DC Report" />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik id="next_action" label="Plan of Next Action" fullWidth />
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
              Set
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

BiLitigationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  litigation: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function addInternalIdToObjectsArray(objects) {
  return objects.sort((o1, o2) => (o1.id < o2.id ? -1 : 1)).map((object, idx) => ({ ...object, internal_id: idx + 1 }));
}

export default LiabilityDashboardContainer;
