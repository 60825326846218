import React from 'react';

import { useClaim } from '~/components/ClaimContainer';
import CommunicationLink from '~/components/communications/CommunicationLink';
import ShowAllCollapsible from '~/components/core/Molecules/Collapsible/ShowAllCollapsible';
import type { NoteContentProps } from '~/components/Notes/NoteContent/types';
import type { NotePartProps } from '~/components/Notes/NoteMiniCard';
import { COMMUNICATION_CHANNEL_DICT } from '~/Types';

import Text from '../../core/TextComponents/Text';

const CommunicationNote: React.FC<NoteContentProps> = ({
  note,
  isCollapsible,
  isExpanded,
  onCollapseClick,
  maxRows,
}) => {
  return isCollapsible ? (
    <>
      <CommunicationNoteStaticSection note={note} />
      <ShowAllCollapsible
        visibleLines={maxRows || 1}
        showAsInlineButton
        isOpen={isExpanded}
        onCollapseClick={onCollapseClick}
      >
        <CommunicationNotePossiblyCollapsedSection note={note} />
      </ShowAllCollapsible>
    </>
  ) : (
    <>
      <CommunicationNotePossiblyCollapsedSection note={note} />
      <CommunicationNoteStaticSection note={note} />
    </>
  );
};

const CommunicationNotePossiblyCollapsedSection: React.FC<NoteContentProps> = ({ note }) => {
  if (!note.communication) {
    return <></>;
  }

  return (
    <>
      {note.is_detached && (
        <Text
          className="mb-12"
          colorVariant={Text.COLOR_VARIANTS.ERROR}
          weight={Text.WEIGHTS.REGULAR}
          variant={Text.VARIANTS.SM}
        >
          Communication was removed from claim
        </Text>
      )}
      <div className="whitespace-pre-line">
        <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
          {note.note_text}
        </Text>
      </div>
      {['email', 'sms'].includes(note.communication.channel) && note.communication.is_delivery_issue_error_exists && (
        <Text colorVariant={Text.COLOR_VARIANTS.ERROR} weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
          {COMMUNICATION_CHANNEL_DICT[note.communication.channel]} delivery failed
        </Text>
      )}
    </>
  );
};

const CommunicationNoteStaticSection: React.FC<NotePartProps> = ({ note }) => {
  const { onClaimUpdate } = useClaim();
  if (!note.communication) {
    return <></>;
  }
  return (
    <div className="my-12">
      <CommunicationLink
        text="View communication"
        communication={note.communication}
        onUpdate={onClaimUpdate}
        displayLinkAsButton
      />
    </div>
  );
};

export default CommunicationNote;
