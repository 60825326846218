import React from 'react';

import Button from '~/components/core/Atomic/Buttons/Button';
import { CheckIcon } from '~/components/icons';
import cn from '~/Utils/cn';

interface ExecuteButtonProps {
  onExecute: () => void;
  isDone: boolean;
}

export const ExecuteButton: React.FC<ExecuteButtonProps> = ({ onExecute, isDone }) => {
  return (
    <Button
      variant="contained"
      classes={{
        root: 'ml-auto bg-green-700 text-white',
        label: 'leading-none',
        disabled: '[&&]:bg-green-700/10 [&&]:text-green-700',
      }}
      onClick={onExecute}
      disabled={isDone}
    >
      <CheckIcon
        height={16}
        width={16}
        className={cn('mr-12 rounded-full border-1 border-solid border-white fill-white px-2 pb-2', {
          ['border-green-700 fill-green-700']: isDone,
        })}
      />
      {isDone ? 'Done' : 'Execute'}
    </Button>
  );
};
