import React from 'react';

import ShowAllCollapsible from '~/components/core/Molecules/Collapsible/ShowAllCollapsible';
import type { NoteContentProps } from '~/components/Notes/NoteContent/types';
import type { NotePartProps } from '~/components/Notes/NoteMiniCard';

import Text from '../../core/TextComponents/Text';

const GeneralNote: React.FC<NoteContentProps> = ({ note, isExpanded, onCollapseClick, isCollapsible, maxRows }) => {
  return isCollapsible ? (
    <ShowAllCollapsible
      visibleLines={maxRows || 1}
      showAsInlineButton
      isOpen={isExpanded}
      onCollapseClick={onCollapseClick}
    >
      <GeneralNoteInner note={note} />
    </ShowAllCollapsible>
  ) : (
    <GeneralNoteInner note={note} />
  );
};

const GeneralNoteInner: React.FC<NotePartProps> = ({ note }) => {
  return (
    <div className="whitespace-pre-line">
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
        {note.note_text}
      </Text>
    </div>
  );
};

export default GeneralNote;
