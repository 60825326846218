import React, { useState } from 'react';
import { default as MuiNoteIcon } from '@material-ui/icons/Note';
import axios from 'axios';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { useClaim } from '~/components/ClaimContainer';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import ExposureMultiSelectTextFieldFormik from '~/components/ExposureMultiSelectTextFieldFormik';
import FailedTokensWarningBanner from '~/components/GenericTemplates/FailedTokensWarningBanner';
import GenericTemplateSelectionContainerFormik from '~/components/GenericTemplates/FromTemplate/GenericTemplateSelectionContainerFormik';
import { FROM_TEMPLATE_FORM_KEYS } from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import { useCms } from '~/components/hooks/useCms';
import NoteIcon from '~/components/icons/NoteIcon';
import { ConfidentialityButton } from '~/components/Note';
import TextFieldFormik from '~/components/TextFieldFormik';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, reportAxiosError } from '~/Utils';

import Text from '../core/TextComponents/Text';

import type { Note } from './Notes';

const noteValidation = Yup.object().shape({
  title: Yup.string().required('Required'),
  note_text: Yup.string().required('Required'),
  exposure_ids: Yup.array().required('Required').min(1, 'Required'),
  [FROM_TEMPLATE_FORM_KEYS.TEMPLATE_ID]: Yup.number().nullable(),
  [FROM_TEMPLATE_FORM_KEYS.TEMPLATE_CONTEXT]: Yup.object().nullable(),
});

interface CreateEditNoteDialogProps {
  notesThreadId?: number;
  onClose: () => void;
  onSubmitNote: (noteId: number) => Promise<void> | void;
  defaultExposureIds?: number[];
  initialTitle?: string;
  initialNoteText?: string;
  open?: boolean;
  disableMinimized?: boolean;
  onMinimized?: () => void;
  note?: Note;
}

const CreateEditNoteDialog: React.FC<CreateEditNoteDialogProps> = (props) => {
  const {
    onSubmitNote,
    onClose,
    defaultExposureIds,
    initialTitle = '',
    initialNoteText = '',
    open = true,
    onMinimized,
    disableMinimized,
    note,
  } = props;

  const { claim } = useClaim();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();

  const [isFromTemplateDialogOpen, setIsFromTemplateDialogOpen] = useState(false);
  const [failedTokens, setFailedTokens] = useState(null);

  const classes = useStyles();
  const isEdit = !!note;

  const handleOpenFromTemplateDialog = () => {
    setIsFromTemplateDialogOpen(true);
  };

  const handleCloseFromTemplateDialog = () => {
    setIsFromTemplateDialogOpen(false);
  };

  const handleSubmit = async (values: Partial<Note>, formikHelpers: FormikHelpers<Partial<Note>>) => {
    try {
      const method = isEdit ? 'put' : 'post';
      const url = `/api/v1/claims/${claim.id}/notes${isEdit ? `/${note.id}` : ''}`;
      const { data }: { data: { note_id: number } } = await axios(url, { method, data: values });
      await onSubmitNote(data.note_id);
    } catch (error) {
      await reportAxiosError(error);
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        title: note?.title || initialTitle,
        note_text: note?.note_text || initialNoteText,
        exposure_ids: note?.exposure_ids || defaultExposureIds || [],
        is_confidential: note?.is_confidential || false,
        [FROM_TEMPLATE_FORM_KEYS.TEMPLATE_ID]: null,
        [FROM_TEMPLATE_FORM_KEYS.TEMPLATE_CONTEXT]: null,
      }}
      validationSchema={noteValidation}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => (
        <CardDialog
          open={open}
          isDialog
          title={isEdit ? 'Edit Note' : 'Create Note'}
          maxWidth="sm"
          onClose={onClose}
          preventClose={formikProps.isSubmitting}
          onMinimized={onMinimized}
          disableMinimized={disableMinimized}
          action={
            !isEdit ? (
              <RestrictedPermissions action={PERMISSION_ACTIONS.NOTE} verb={PERMISSION_VERBS.FULL}>
                <ConfidentialityButton
                  isConfidential={formikProps.values['is_confidential'] as boolean}
                  onChangeConfidentiality={() =>
                    formikProps.setFieldValue('is_confidential', !formikProps.values['is_confidential'])
                  }
                />
              </RestrictedPermissions>
            ) : null
          }
          footerActions={
            <DialogFooterActions
              primaryLabel={isEdit ? 'Update' : 'Create'}
              onClickPrimary={formikProps.handleSubmit}
              disabled={formikProps.isSubmitting}
              showSecondary={false}
            />
          }
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="flex justify-between">
                <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD}>
                  <span className="flex inline-flex items-center">
                    {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NOTES_2_0) ? (
                      <NoteIcon iconColor="inherit" size={20} />
                    ) : (
                      <MuiNoteIcon />
                    )}
                    <span className="mx-8">Note</span>
                    {claim.claim_id_display}
                  </span>
                </Text>
                {!isEdit ? (
                  <div className="flex items-center justify-end">
                    <Button color="primary" onClick={handleOpenFromTemplateDialog}>
                      <AddIcon />
                      From template
                    </Button>
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik id="title" label="Title" fullWidth className={classes.textField} disabled={isEdit} />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik id="note_text" label="Note" className={classes.textField} fullWidth multiline />
            </Grid>
            <Grid item xs={12}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore*/}
              <FailedTokensWarningBanner failedTokens={failedTokens} />
            </Grid>
            <Grid item xs={12}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore*/}
              <ExposureMultiSelectTextFieldFormik claim={claim} />
            </Grid>
          </Grid>
          {isFromTemplateDialogOpen ? (
            <GenericTemplateSelectionContainerFormik
              handleClose={handleCloseFromTemplateDialog}
              templateType="note"
              titleFieldId="title"
              bodyFieldId="note_text"
              shouldConvertBodyHtmlToText
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setFailedTokens={setFailedTokens}
            />
          ) : null}
        </CardDialog>
      )}
    </Formik>
  );
};

export default CreateEditNoteDialog;
