import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { reportAxiosError } from '../Utils';

import PencilIcon from './icons/PencilIcon';
import CardDialog from './CardDialog';
import { withClaim } from './ClaimContainer';
import WithConfirm from './ConfirmModal';
import { ContactEntity } from './Contact';
import ContactTextFieldFormik from './ContactTextFieldFormik';
import { FsButton, FsIconButton } from './core';
import { ContactIcon, TrashIcon } from './icons';
import PlainTable from './PlainTable';
import TextFieldFormik from './TextFieldFormik';

const spacing = 1;

const witnessFields = {
  contact_id: '',
  contact_full_name: '',
  description: '',
};

const WitnessFragment = (props) => {
  const { classes, buttonsComponent } = props;

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={12}>
        <ContactTextFieldFormik
          id="contact"
          label="Witness"
          className={classes.textField}
          fullWidth
          acceptedRoles={['witness']}
          fixedSearchResults
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik id="description" label="Description" fullWidth multiline className={classes.textField} />
      </Grid>
      {buttonsComponent && (
        <Grid item xs={12}>
          <div className={classes.buttonsContainer}>{buttonsComponent}</div>
        </Grid>
      )}
    </Grid>
  );
};

WitnessFragment.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonsComponent: PropTypes.node,
};

const WitnessFragmentWithClaim = withClaim(WitnessFragment);

const AddWitnessFormikInner = (props) => {
  const {
    onCancel,
    isSubmitting, // comes from Formik
    handleSubmit, // comes from Formik
  } = props;

  const buttonsComponentEdit = (
    <Fragment>
      <CancelButton disabled={isSubmitting} onClick={onCancel} />
      <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
        Add
      </Button>
    </Fragment>
  );

  return (
    <CardDialog isDialog={true} title="Add Witness" maxWidth="sm" onClose={onCancel} preventClose={isSubmitting}>
      <WitnessFragmentWithClaim {...props} buttonsComponent={buttonsComponentEdit} />
    </CardDialog>
  );
};

AddWitnessFormikInner.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired, // comes from Formik
  isSubmitting: PropTypes.bool.isRequired, // comes from Formik
};

const AddWitnessDialog = (props) => {
  const { classes, onCancel, onAddWitness } = props;
  return (
    <Formik
      initialValues={{ ...witnessFields }}
      validationSchema={Yup.object().shape({
        contact_id: Yup.number().required('Required'),
      })}
      onSubmit={(values, formikProps) => {
        onAddWitness(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => <AddWitnessFormikInner {...formikProps} onCancel={onCancel} classes={classes} />}
    </Formik>
  );
};

AddWitnessDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAddWitness: PropTypes.func.isRequired,
};

const EditWitnessFormikInner = (props) => {
  const {
    onCancel,
    onDeleteWitness,
    isSubmitting, // comes from Formik
    handleSubmit, // comes from Formik
  } = props;

  const dialogAction = (
    <WithConfirm title="Delete Witness?" primaryButtonName="Delete" shouldCloseOnPrimary={false}>
      <FsIconButton onClick={onDeleteWitness} disabled={isSubmitting} icon={TrashIcon} />
    </WithConfirm>
  );

  const buttonsComponentEdit = (
    <Fragment>
      <CancelButton disabled={isSubmitting} onClick={onCancel} />
      <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
        Save
      </Button>
    </Fragment>
  );

  return (
    <CardDialog
      isDialog={true}
      title="Edit Witness"
      maxWidth="sm"
      onClose={onCancel}
      preventClose={isSubmitting}
      action={dialogAction}
    >
      <WitnessFragmentWithClaim {...props} buttonsComponent={buttonsComponentEdit} />
    </CardDialog>
  );
};

EditWitnessFormikInner.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDeleteWitness: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired, // comes from Formik
  isSubmitting: PropTypes.bool.isRequired, // comes from Formik
};

const EditWitnessDialog = (props) => {
  const { classes, onCancel, witness, onEditWitness, onDeleteWitness } = props;
  return (
    <Formik
      initialValues={{ ...witnessFields, ...witness }}
      onSubmit={(values, formikProps) => {
        onEditWitness(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <EditWitnessFormikInner
          {...formikProps}
          onCancel={onCancel}
          onDeleteWitness={onDeleteWitness}
          classes={classes}
        />
      )}
    </Formik>
  );
};

EditWitnessDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEditWitness: PropTypes.func.isRequired,
  onDeleteWitness: PropTypes.func.isRequired,
  witness: PropTypes.object.isRequired,
};

class WitnessesSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addDialogOpen: false,
      editDialogOpen: false,
      curWitnessToEdit: null,
    };
  }

  handleAddWitness = (witness) => {
    const { onAddWitness } = this.props;

    return Promise.resolve(onAddWitness(witness)).then(() => {
      this.setState({ addDialogOpen: false });
    });
  };

  handleEditWitness = (witnessWithNewValues) => {
    const { onEditWitness } = this.props;

    return Promise.resolve(onEditWitness(witnessWithNewValues)).then(() => {
      this.setState({ editDialogOpen: false });
    });
  };

  handleDeleteWitness = (witnessId) => {
    const { onDeleteWitness } = this.props;

    return Promise.resolve(onDeleteWitness(witnessId)).then(() => {
      this.setState({ editDialogOpen: false });
    });
  };

  render() {
    const { classes, readOnly, witnesses } = this.props;
    const { addDialogOpen, editDialogOpen, curWitnessToEdit } = this.state;

    const witnessesColumnData = [
      {
        id: 'contact_full_name',
        disablePadding: true,
        numeric: false,
        label: 'Witness',
        specialCell: (row) =>
          row.contact_id ? (
            <ContactEntity
              classes={classes}
              disabled={readOnly}
              contactId={row.contact_id}
              contactDisplayName={row.contact_full_name}
            />
          ) : (
            <ContactIcon />
          ),
      },
      { id: 'description', disablePadding: true, numeric: false, label: 'Description' },
      {
        id: 'edit witness',
        disablePadding: true,
        disableSort: true,
        specialCell: (witness) => (
          <IconButton onClick={() => this.setState({ editDialogOpen: true, curWitnessToEdit: witness })} size="small">
            <PencilIcon className={classes.smallIcon} />
          </IconButton>
        ),
      },
    ];

    return (
      <Fragment>
        <CardDialog title="Witnesses">
          <PlainTable classes={classes} columns={witnessesColumnData} rows={witnesses} />
          <FsButton color="primary" disabled={readOnly} onClick={() => this.setState({ addDialogOpen: true })}>
            <AddIcon />
            Add witness
          </FsButton>
        </CardDialog>
        {addDialogOpen && (
          <AddWitnessDialog
            classes={classes}
            onCancel={() => this.setState({ addDialogOpen: false })}
            onAddWitness={this.handleAddWitness}
          />
        )}
        {editDialogOpen && (
          <EditWitnessDialog
            classes={classes}
            witness={curWitnessToEdit}
            onCancel={() => this.setState({ editDialogOpen: false })}
            onEditWitness={this.handleEditWitness}
            onDeleteWitness={() => this.handleDeleteWitness(curWitnessToEdit.id)}
          />
        )}
      </Fragment>
    );
  }
}

WitnessesSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  witnesses: PropTypes.array.isRequired,
  onAddWitness: PropTypes.func.isRequired,
  onEditWitness: PropTypes.func.isRequired,
  onDeleteWitness: PropTypes.func.isRequired,
};

class WitnessesSummaryContainer extends Component {
  handleAddWitness = (witness) => {
    const { claim, onUpdateWitnesses } = this.props;
    return axios
      .post(`/api/v1/claims/${claim.id}/witnesses`, witness)
      .then(() => onUpdateWitnesses())
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleEditWitness = (witness) => {
    const { claim, onUpdateWitnesses } = this.props;
    return axios
      .put(`/api/v1/claims/${claim.id}/witnesses/${witness.id}`, witness)
      .then(() => onUpdateWitnesses())
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  handleDeleteWitness = (witnessId) => {
    const { claim, onUpdateWitnesses } = this.props;
    return axios
      .delete(`/api/v1/claims/${claim.id}/witnesses/${witnessId}`)
      .then(() => onUpdateWitnesses())
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      });
  };

  render() {
    const { classes, witnesses, onUpdateWitnesses } = this.props;

    const readOnly = !onUpdateWitnesses;

    return (
      <WitnessesSummary
        classes={classes}
        witnesses={witnesses}
        readOnly={readOnly}
        onAddWitness={this.handleAddWitness}
        onEditWitness={this.handleEditWitness}
        onDeleteWitness={this.handleDeleteWitness}
      />
    );
  }
}

WitnessesSummaryContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  witnesses: PropTypes.array.isRequired, // TODO add shape
  onUpdateWitnesses: PropTypes.func,
};

const WitnessesSummaryContainerWithClaim = withClaim(WitnessesSummaryContainer);

export { WitnessesSummary, WitnessesSummaryContainerWithClaim as WitnessesSummaryContainer };
