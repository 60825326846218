import type { ReactElement } from 'react';
import React from 'react';
import type { SelectProps } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';

import type { TextFieldProps } from '../TextField';
import TextField from '../TextField';

export interface SingleSelectFieldProps<T> extends TextFieldProps<T> {
  clearSelection?: () => void;
  clearable?: boolean;
  SelectProps?: Partial<SelectProps>;
  children?: React.ReactNode;
}

const SingleSelectField = <T,>({
  id,
  clearable,
  value,
  disabled,
  clearSelection,
  children,
  SelectProps,
  ...rest
}: SingleSelectFieldProps<T>): ReactElement => {
  return (
    <TextField<T>
      value={value}
      disabled={disabled}
      InputProps={{
        id,
        ...(clearable
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{
                      padding: 4,
                      visibility: value && !disabled ? 'visible' : 'hidden',
                      marginRight: 15,
                    }}
                    title="Clear"
                    onClick={() => (clearSelection ? clearSelection() : null)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {}),
      }}
      select
      name={id}
      SelectProps={{
        ...SelectProps,
      }}
      {...rest}
    >
      {children}
    </TextField>
  );
};

export default SingleSelectField;
