import React from 'react';

import SvgIcon from '~/components/core/SvgIcon';

const DataPointIcon = (props) => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <path d="M449.6 121.78a9.6 9.6 0 0 0 7.85-2.75l51.74-51.75a9.6 9.6 0 0 0-5.92-16.34l-38.69-3.52-3.52-38.7a9.6 9.6 0 0 0-16.34-5.9l-51.75 51.74a9.6 9.6 0 0 0-2.75 7.84l4.58 41.23-9.7 9.69a212.7 212.7 0 0 0-134.37-55.76V39.33a9.6 9.6 0 0 0-19.2 0v18.23c-110.12 4.9-198.82 93.59-203.71 203.71H9.6a9.6 9.6 0 0 0 0 19.2h18.22c4.9 110.12 93.59 198.81 203.71 203.71v18.22a9.6 9.6 0 0 0 19.2 0v-18.22c110.12-4.9 198.82-93.59 203.71-203.72h18.22a9.6 9.6 0 0 0 0-19.19h-18.22a212.7 212.7 0 0 0-55.76-134.38l9.7-9.7 41.22 4.59zm-5.8-90.9 2.39 26.24a9.6 9.6 0 0 0 8.69 8.7l26.25 2.38-34 34-33.6-3.74-3.72-33.6 34-33.99zm-8.56 230.4H421.7a9.6 9.6 0 0 0 0 19.19h13.53c-4.87 99.54-84.97 179.64-184.51 184.5v-13.52a9.6 9.6 0 0 0-19.2 0v13.52C132 460.11 51.9 380.01 47.03 280.47h13.52a9.6 9.6 0 0 0 0-19.2H47.03c4.86-99.54 84.96-179.64 184.5-184.5v13.51a9.6 9.6 0 0 0 19.2 0V76.76a193.59 193.59 0 0 1 120.8 50.13l-28.88 28.88a152.91 152.91 0 0 0-101.52-38.46c-84.67 0-153.56 68.89-153.56 153.56s68.89 153.56 153.56 153.56 153.56-68.89 153.56-153.56c0-38.89-14.54-74.44-38.46-101.53l28.88-28.87a193.6 193.6 0 0 1 50.13 120.8zm-200.9 16.38a9.57 9.57 0 0 0 13.58 0l52.12-52.13a73.99 73.99 0 0 1 15.47 45.34c0 41.01-33.37 74.38-74.38 74.38s-74.38-33.37-74.38-74.38 33.37-74.38 74.38-74.38a74 74 0 0 1 45.33 15.47l-52.12 52.12a9.6 9.6 0 0 0 0 13.57zm6.8-100.36c-51.6 0-93.58 41.97-93.58 93.57s41.97 93.57 93.57 93.57c51.6 0 93.57-41.97 93.57-93.57a93.13 93.13 0 0 0-21-59l28.94-28.94a133.8 133.8 0 0 1 32.85 87.94c0 74.09-60.27 134.36-134.36 134.36s-134.36-60.27-134.36-134.36S167.05 136.5 241.13 136.5c33.6 0 64.36 12.4 87.94 32.85l-28.94 28.94a93.13 93.13 0 0 0-59-21z" />
  </SvgIcon>
);

export default DataPointIcon;
