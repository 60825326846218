import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import CardDialog from '~/components/CardDialog';
import type { Communication, DocumentMetadata, InternalCommunication } from '~/components/communications/types';
import EmptyState from '~/components/core/EmptyState';
import RoundEmptyStateWrapper from '~/components/core/EmptyState/RoundEmptyStateWrapper';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import TablePaginationSection from '~/components/core/Tables/SortableTable/TablePaginationSection';
import type { PaginationProps } from '~/components/core/Tables/SortableTable/TablePaginationSection/types';
import useNotesConfiguration from '~/components/hooks/useNotesConfiguration';
import CreateEditNoteDialog from '~/components/Notes/CreateEditNoteDialog';
import ExpandCollapseAllButtons from '~/components/Notes/ExpandCollapseAllButtons';
import useDataFetcher from '~/components/useDataFetcher';
import { NOTE_SUBJECT } from '~/Types';

import NoteIcon from '../icons/NoteIcon';

import NoteMiniCard from './NoteMiniCard';
import type { NotesFiltersValues } from './NotesFilter';
import NotesFilter from './NotesFilter';

export interface Note {
  id: number;
  type: string;
  claim_id: number;
  claim_internal_id: number;
  claim_id_display: string;
  exposure_ids: number[];
  title: string;
  subject: keyof typeof NOTE_SUBJECT;
  creation_date: string;
  is_automatic: boolean;
  is_confidential: boolean;
  creator: string;
  creator_id: string;
  note_text: string;
  is_labeled_general_exposure: boolean; // GenericNote
  communication?: Communication; // CommunicationNote
  is_detached?: boolean; // CommunicationNote
  document?: DocumentMetadata; // DocumentNote
  document_id?: number; // DocumentNote
  documents?: DocumentMetadata[]; // PhotosNote / MultipleDocumentsNote
  document_ids?: number[]; // PhotosNote / MultipleDocumentsNote
  action: string; // DocumentNote / CommunicationNote
  internal_communication: InternalCommunication; // InternalCommunicationNote
}

interface NotesProps {
  isActive?: boolean;
  noCard?: boolean;
  hideTypeFilter?: boolean;
  claim: { id: number };
  exposureOptionsIds?: number[];
}

const Notes: React.FC<NotesProps> = ({ isActive = true, noCard, hideTypeFilter, claim, exposureOptionsIds }) => {
  const [pagination, setPagination] = useState<Omit<PaginationProps, 'onChangePage' | 'count'>>({
    page: 0,
    rowsPerPage: 20,
  });
  const { page, rowsPerPage } = pagination;
  const rowsPerPageOptions = [5, 10, 20, 30, 100];

  const [filters, setFilters] = useState<NotesFiltersValues>({
    exposure_ids: [],
    adjuster_id: null,
    creation_mode_type: null,
    start_date: null,
    end_date: null,
  });
  const [noteToEdit, setNoteToEdit] = useState<Note | null>(null);
  const [expandedNotesMap, setExpandedNotesMap] = useState<{ [id: number]: boolean }>({});

  const params = useMemo(
    () => ({
      params: {
        page_number: page + 1,
        results_per_page: rowsPerPage,
        ...filters,
      },
    }),
    [filters, page, rowsPerPage]
  );

  const handleEditClicked = (note: Note) => setNoteToEdit(note);

  const handleEditDialogClosed = () => {
    setNoteToEdit(null);
  };

  const handleNoteSubmitted = () => {
    handleEditDialogClosed();
    reloadData();
  };

  const {
    isLoading,
    isError,
    data = { notes: [], total_notes: 0, adjuster_ids: {}, note_subjects: [] },
    reloadData,
  }: {
    isLoading: boolean;
    isError: boolean;
    data?: {
      notes: Note[];
      total_notes: number;
      adjuster_ids: { [id: string]: string };
      note_subjects: (keyof typeof NOTE_SUBJECT)[];
    };
    reloadData: () => void;
  } = useDataFetcher(`/api/v1/claims/${claim.id}/notes/paginated`, params);

  const isFirstRun = React.useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    reloadData();
  }, [claim, reloadData]);

  const { configuration } = useNotesConfiguration();
  const displayLoading = isLoading || isError;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(
    debounce((values) => {
      setFilters(values);
      setPagination({ rowsPerPage, page: 0 });
    }, 300),
    [setFilters, rowsPerPage]
  );

  if (!isActive) {
    return null;
  }

  return (
    <CardDialog title="Notes" passThrough={noCard}>
      <NotesFilter
        hideTypeFilter={hideTypeFilter}
        onSearch={onSearch}
        adjusterOptionsIds={data.adjuster_ids}
        noteSubjectsOptions={data.note_subjects.filter((option) => !!NOTE_SUBJECT[option])}
        exposureOptionsIds={exposureOptionsIds}
      />
      <div className="flex items-center justify-between">
        <ExpandCollapseAllButtons
          setForceExpandCollapse={(shouldOpen) => {
            const notesExpandMap: { [id: number]: boolean } = {};
            data.notes.forEach((note) => {
              notesExpandMap[note.id] = shouldOpen;
            });

            setExpandedNotesMap(notesExpandMap);
          }}
        />
        <TablePaginationSection
          combinedPaginationProps={{
            rowsPerPage,
            page,
            rowsPerPageOptions,
            count: data.total_notes,
            onChangePage: (_e, nextPage) => setPagination({ rowsPerPage, rowsPerPageOptions, page: nextPage }),
            onChangeRowsPerPage: (e) => setPagination({ rowsPerPageOptions, page: 0, rowsPerPage: e.target.value }),
          }}
        />
      </div>
      {displayLoading && <SkeletonTable rowsCount={10} columnsCount={1} cellClassName="h-[120px]" />}
      {!displayLoading &&
        data.notes.length > 0 &&
        data.notes.map((note) => (
          <NoteMiniCard
            key={note.id}
            note={note}
            claim={claim}
            onNoteUpdate={reloadData}
            configuration={configuration}
            onEdit={() => handleEditClicked(note)}
            isExpanded={
              expandedNotesMap?.[note.id] === undefined
                ? !!configuration?.is_notes_expanded_by_default
                : expandedNotesMap?.[note.id]
            }
            onCollapseClick={(isExpanded: boolean) =>
              setExpandedNotesMap({ ...expandedNotesMap, [note.id]: isExpanded })
            }
            isCollapsible
          />
        ))}
      {!displayLoading && data.notes.length === 0 && (
        <EmptyState
          title="No notes were found, try to update the filters"
          illustration={
            <RoundEmptyStateWrapper>
              <NoteIcon iconColor="inherit" />
            </RoundEmptyStateWrapper>
          }
        />
      )}
      {noteToEdit && (
        <CreateEditNoteDialog note={noteToEdit} onClose={handleEditDialogClosed} onSubmitNote={handleNoteSubmitted} />
      )}
    </CardDialog>
  );
};

export default Notes;
