import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@material-ui/core';

import { useStyles } from '~/assets/styles';
import { useClaim } from '~/components/ClaimContainer';
import mixpanel from '~/components/CmsMain/mixpanel';
import DocumentPhoneCallCommunicationCardContainer, {
  NewPhoneCallCommunicationCard,
} from '~/components/communications/PhoneCallCommunicationCard';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context';
import { useCms } from '~/components/hooks/useCms';
import { FaxIcon, PhoneIcon } from '~/components/icons/notifications';
import InlineIconButton from '~/components/InlineIconButton';
import { MIXPANEL_EVENT_SOURCES, MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import cn from '~/Utils/cn';

const PhoneCallCommunicationPopover = ({
  contact,
  communicationDetails,
  onUpdate,
  mixpanelSource,
  ignorePermissions = false,
}) => {
  const classes = useStyles();
  const { user } = useCms();
  const { claim } = useClaim();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [phoneCallAnchorEl, setPhoneCallAnchorEl] = useState(null);

  const { add } = useMinimizedDialogs();

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleIconClick = (event) => {
    mixpanel.track(MIXPANEL_EVENTS.NEW_PHONE_CALL_COMMUNICATION_CLICKED, { source: mixpanelSource });

    if (user.is_call_center_allowed) {
      setPhoneCallAnchorEl(event.currentTarget);
    } else {
      handleOpenDocumentPhoneCallDialog();
    }
  };

  const handleOpenDocumentPhoneCallDialog = () => {
    const type = 'PHONE';
    const { handleCloseDialog } = add({
      barHeader: `Document Phone Call: ${contact.full_name}`,
      type,
      dialogComponent: DocumentPhoneCallCommunicationCardContainer,
      dialogProps: {
        claim,
        contact,
        contactPhoneId: communicationDetails.id,
        onMinimized: () => {
          mixpanel.track(MIXPANEL_EVENTS.MINIMIZED_DIALOG, {
            source: mixpanelSource,
            type,
          });
        },
        onCancel: () => handleCloseDialog(),
        onDocumentPhoneCall: () => {
          handleCloseDialog();
          if (onUpdate) {
            onUpdate();
          }
        },
      },
    });
  };

  const handleDocumentCallMenuClick = () => {
    handleOpenDocumentPhoneCallDialog();
    setPhoneCallAnchorEl(null);
  };

  const handleMakeCallMenuClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.NEW_PHONE_CALL_COMMUNICATION_CLICKED, {
      source: MIXPANEL_EVENT_SOURCES.CONTACT_MINI_CARD_NEW_PHONE_CALL_FROM_DROPDOWN,
    });

    setPhoneCallAnchorEl(null);
    setIsDialogOpen(true);
  };

  const isDisabled = user.role.is_view_only || communicationDetails.phone_type === 'fax';

  return (
    <>
      <InlineIconButton
        icon={communicationDetails.phone_type !== 'fax' ? PhoneIcon : FaxIcon}
        onClick={handleIconClick}
        disabled={isDisabled}
        className={cn({ [classes.hoverableNonFilledIcon]: !isDisabled })}
        ignorePermissions={ignorePermissions}
      />

      {isDialogOpen ? (
        <NewPhoneCallCommunicationCard
          contact={contact}
          contactPhoneId={communicationDetails.id}
          onCancel={handleCloseDialog}
          onNewPhoneCallCreated={() => {
            onUpdate();
            handleCloseDialog();
          }}
        />
      ) : null}
      <Menu
        id="menu-appbar"
        anchorEl={phoneCallAnchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={!!phoneCallAnchorEl}
        onClose={() => setPhoneCallAnchorEl(null)}
      >
        <MenuItem onClick={() => handleDocumentCallMenuClick()}>Document call</MenuItem>
        <MenuItem onClick={() => handleMakeCallMenuClick(false)}>Make a call</MenuItem>
      </Menu>
    </>
  );
};

PhoneCallCommunicationPopover.propTypes = {
  contact: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  communicationDetails: PropTypes.object.isRequired,
  mixpanelSource: PropTypes.string,
  ignorePermissions: PropTypes.bool,
};

export default PhoneCallCommunicationPopover;
