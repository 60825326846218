import React from 'react';

import { MultiSelectFilter, SecondaryCard } from '~/components/core';
import TextField from '~/components/core/Molecules/Fields/TextField';
import { fixAdminUserName } from '~/components/ReportsPage/helpers/ReportsUtils';
import type { User } from '~/components/ReportsPage/types';

interface ReportsFilterProps {
  searchText: string;
  onSearchTextChange: (value: string) => void;
  selectedOwners: User[];
  onSelectedOwnersChange: (selected: User[]) => void;
  owners: User[];
  isDrafts: boolean;
}

export const ReportsFilter: React.FC<ReportsFilterProps> = ({
  searchText,
  onSearchTextChange,
  selectedOwners,
  onSelectedOwnersChange,
  owners,
  isDrafts,
}) => {
  return (
    <SecondaryCard key="filled" title="Search Reports and Dashboards" type="filled">
      <div className="mt-20 flex w-full items-end gap-32">
        <TextField
          className="w-[220px] flex-shrink-0"
          label="Name"
          InputLabelProps={{
            shrink: true,
            className: 'opacity-50 text-lg',
          }}
          value={searchText}
          onChange={onSearchTextChange}
        />
        {!isDrafts && (
          <>
            <MultiSelectFilter
              className="w-[220px]"
              label="Owner"
              value={selectedOwners}
              onChange={onSelectedOwnersChange}
              options={owners}
              renderOption={(option) => fixAdminUserName(option?.name)}
              renderValue={(selectedValues) =>
                selectedValues?.map((val?: User) => fixAdminUserName(val?.name) || '').join(', ')
              }
            />
          </>
        )}
      </div>
    </SecondaryCard>
  );
};
