import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import axios from 'axios';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { useClaim } from '~/components/ClaimContainer';
import { ContactEntity } from '~/components/Contact';
import { SelectMultipleSearchValues } from '~/components/ContactSearch/AdvancedSearchContact/SelectMultipleSearchValues';
import { SortableTable } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import TextField from '~/components/core/Molecules/Fields/TextField';
import LoadingIndicator from '~/components/LoadingIndicator';
import useOrganization from '~/components/OrganizationContext';
import { getAxiosParamsSerializer, reportAxiosError } from '~/Utils';

const CONTACTS_PER_PAGE = 20;

export const AdvancedSearchContact = ({
  inputText = '',
  onSelectContact,
  onCancel,
  acceptedRoles,
  acceptedExpertises = [],
  organizationId,
  restrictAcceptedEntities = false,
}) => {
  const classes = useStyles();
  const { claim } = useClaim();
  const { organizationContactRolesDict, organizationMoiExpertises = {} } = useOrganization();

  const [inputSearchText, setInputSearchText] = useState(inputText);
  const [inputSelectedRoles, setInputSelectedRoles] = useState(acceptedRoles);
  const [inputSelectedExpertise, setInputSelectedExpertise] = useState(acceptedExpertises);

  const [lastSearchedText, setLastSearchedText] = useState('');
  const [lastSearchedRoles, setLastSearchedRoles] = useState([]);
  const [lastSearchedExpertise, setLastSearchedExpertise] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(CONTACTS_PER_PAGE);
  const [contactsCount, setContactsCount] = useState(0);
  const [contacts, setContacts] = useState();
  const [sortBy, setSortBy] = useState('full_name');
  const [orderBy, setOrderBy] = useState('asc');
  const [showAcceptedRolesWarning, setShowAcceptedRolesWarning] = useState(false);
  const [isFetchingContacts, setIsFetchingContacts] = useState(false);
  const [isErrorFetchingContacts, setIsErrorFetchingContacts] = useState(false);

  const columnData = [
    {
      id: 'related_to_claim',
      disableSort: true,
      label: '',
      specialCell: (contact_with_claim_indication) =>
        contact_with_claim_indication.isClaimRelated ? (
          <Tooltip title="Exists in claim">
            <StarIcon style={{ color: 'orange' }} fontSize="small" />
          </Tooltip>
        ) : (
          ''
        ),
      shouldDisplay: true,
    },
    {
      id: 'full_name',
      label: 'Name',
      specialCell: (contact) => <ContactEntity contactId={contact.id} contactDisplayName={contact.full_name} />,
      shouldDisplay: true,
    },
    {
      id: 'role',
      label: 'Role',
      specialCell: (contact) => organizationContactRolesDict[contact.role].desc,
      shouldDisplay: true,
    },
    {
      id: 'moi_expertises',
      label: 'Expertises',
      specialCell: (contact) =>
        contact?.moi_expertises?.map((expertise) => organizationMoiExpertises[expertise].desc).join(', '),
      shouldDisplay: true,
      width: '200px',
    },
    {
      id: 'email',
      disableSort: true,
      label: 'Email',
      specialCell: (contact) =>
        contact.emails.length === 0
          ? ''
          : contact.emails[0].email + (contact.emails.length === 1 ? '' : ` (+${contact.emails.length - 1})`),
      shouldDisplay: true,
    },
    {
      id: 'phone',
      disableSort: true,
      label: 'Phone',
      specialCell: (contact) =>
        contact.phones.length === 0
          ? ''
          : contact.phones[0].phone + (contact.phones.length === 1 ? '' : ` (+${contact.phones.length - 1})`),
      shouldDisplay: true,
    },
    { id: 'full_address', disableSort: true, label: 'Address', shouldDisplay: true },
    {
      id: 'select_contact',
      disableSort: true,
      numeric: false,
      rightPaddingOnly: true,
      width: 20,
      specialCell: (contact) => (
        <Button variant="contained" color="primary" onClick={() => onSelectContact(contact)}>
          Select
        </Button>
      ),
      shouldDisplay: true,
    },
  ].filter((col) => col?.shouldDisplay);

  const handleFetchContacts = async ({
    searchText,
    selectedRoles,
    selectedExpertise,
    limit,
    currPage = 0,
    sortBy,
    orderBy,
  }) => {
    const params = {
      search: searchText,
      accepted_roles: selectedRoles,
      accepted_expertise: selectedExpertise,
      limit,
      offset: currPage * limit,
      sort_by: sortBy,
      order_by: orderBy,
      claim_id: claim?.id,
    };

    try {
      setIsFetchingContacts(true);
      const res = await axios.get(`/api/v1/organizations/${organizationId}/contacts`, {
        params,
        paramsSerializer: getAxiosParamsSerializer('none'),
      });
      const { data } = res;
      if (data) {
        const { count, contacts, contacts_related_to_claim } = data;
        setContactsCount(count);
        setContacts(
          contacts.map((contact) => ({
            ...contact,
            isClaimRelated: contacts_related_to_claim[contact.id],
          }))
        );

        setLastSearchedText(searchText);
        setLastSearchedRoles(selectedRoles);
        setLastSearchedExpertise(selectedExpertise);
      }
    } catch (err) {
      setIsErrorFetchingContacts(true);
      await reportAxiosError(err);
    } finally {
      setIsFetchingContacts(false);
    }
  };

  const SELECTED_ACCEPTED_ROLES_ID = 'selected_accepted_roles';
  const SELECTED_ACCEPTED_EXPERTISE_ID = 'selected_accepted_expertise';
  const SELECTED_ACCEPTED_ROLES_LABEL = 'Contact Roles';
  const SELECTED_ACCEPTED_EXPERTISE_LABEL = 'Contact Expertises';
  const SEARCH_TEXT_ID = 'search_text';
  const SEARCH_TEXT_LABEL = 'Search Text';
  const SELECTED_ACCEPTED_ROLES_WARN_MSG = 'Must choose at least one contact role!';

  acceptedRoles.sort((role1, role2) =>
    organizationContactRolesDict[role1].desc > organizationContactRolesDict[role2].desc ? 1 : -1
  );

  return (
    <CardDialog isDialog={true} title="Advanced Contact Search" onClose={onCancel} maxWidth="lg">
      <div style={{ display: 'flex' }}>
        <TextField
          value={inputSearchText}
          className={classes.textFieldRow}
          onChange={(value) => setInputSearchText(value)}
          id={SEARCH_TEXT_ID}
          disabled={isFetchingContacts}
          label={SEARCH_TEXT_LABEL}
          autoFocus
          fullWidth
          InputProps={{
            placeholder: 'Search contact by name',
            autoFocus: true,
            onKeyDown: (e) => {
              if (e.code === 'Enter') {
                setPage(0);
                handleFetchContacts({
                  searchText: inputSearchText,
                  selectedRoles: inputSelectedRoles,
                  selectedExpertise: inputSelectedExpertise,
                  limit: rowsPerPage,
                  sortBy,
                  orderBy,
                });
              }
            },
            endAdornment: (
              <IconButton style={{ padding: 4 }} title="Clear" onClick={() => setInputSearchText('')}>
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
        <SelectMultipleSearchValues
          id={SELECTED_ACCEPTED_ROLES_ID}
          label={SELECTED_ACCEPTED_ROLES_LABEL}
          setState={setInputSelectedRoles}
          selectedValues={inputSelectedRoles}
          entitiesMap={organizationContactRolesDict}
          disabled={isFetchingContacts}
          showWarning={showAcceptedRolesWarning}
          warningMessage={SELECTED_ACCEPTED_ROLES_WARN_MSG}
          setWarning={setShowAcceptedRolesWarning}
          acceptedEntities={acceptedRoles}
        />
        <SelectMultipleSearchValues
          id={SELECTED_ACCEPTED_EXPERTISE_ID}
          label={SELECTED_ACCEPTED_EXPERTISE_LABEL}
          setState={setInputSelectedExpertise}
          selectedValues={inputSelectedExpertise}
          entitiesMap={organizationMoiExpertises}
          disabled={isFetchingContacts}
          acceptedEntities={acceptedExpertises}
          allowNone={restrictAcceptedEntities}
        />
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setPage(0);
            handleFetchContacts({
              searchText: inputSearchText,
              selectedRoles: inputSelectedRoles,
              selectedExpertise: inputSelectedExpertise,
              limit: rowsPerPage,
              sortBy,
              orderBy,
            });
          }}
          disabled={isFetchingContacts || showAcceptedRolesWarning}
        >
          Search
        </Button>
      </div>
      {!contacts && (isFetchingContacts || isErrorFetchingContacts) && (
        <LoadingIndicator isError={isErrorFetchingContacts} />
      )}
      {contacts && contacts.length > 0 && (
        <SortableTable
          rows={contacts}
          columns={columnData}
          defaultOrderColumn={columnData.findIndex((column) => column.id === 'full_name')}
          order={orderBy}
          stickyHeader
          paginationProps={{
            page,
            rowsPerPage,
            onChangePage: (_, newPage) => {
              setPage(newPage);
              handleFetchContacts({
                searchText: lastSearchedText,
                selectedRoles: lastSearchedRoles,
                selectedExpertise: lastSearchedExpertise,
                limit: rowsPerPage,
                currPage: newPage,
                sortBy,
                orderBy,
              });
            },
            count: contactsCount,
            onChangeRowsPerPage: (event) => {
              const newRowsPerPage = event.target.value;
              setPage(0);
              setRowsPerPage(newRowsPerPage);
              handleFetchContacts({
                searchText: lastSearchedText,
                selectedRoles: lastSearchedRoles,
                selectedExpertise: lastSearchedExpertise,
                limit: newRowsPerPage,
                sortBy,
                orderBy,
              });
            },
            rowsPerPageOptions: [10, 20, 30],
          }}
          onSortByColumn={(_, { id, order }) => {
            setPage(0);
            setSortBy(id);
            setOrderBy(order);
            handleFetchContacts({
              searchText: lastSearchedText,
              selectedRoles: lastSearchedRoles,
              selectedExpertise: lastSearchedExpertise,
              limit: rowsPerPage,
              sortBy: id,
              orderBy: order,
            });
          }}
        />
      )}
      {contacts && contacts.length === 0 && (
        <Typography style={{ margin: '5px 0 0 16px' }} color="textSecondary" display="block" variant="caption">
          No contacts matched your search
        </Typography>
      )}
    </CardDialog>
  );
};

AdvancedSearchContact.propTypes = {
  inputText: PropTypes.string,
  onSelectContact: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  acceptedRoles: PropTypes.array.isRequired,
  acceptedExpertises: PropTypes.array,
  restrictAcceptedEntities: PropTypes.bool,
  organizationId: PropTypes.number,
};
