import React from 'react';

import { useStyles } from '~/assets/styles';
import Autocomplete from '~/components/core/Molecules/Fields/AutoComplete';
import LoadingIndicator from '~/components/LoadingIndicator';
import { ShowOnlyTextField } from '~/components/TextFieldFormik';

import type { OrganizationUnit } from './types';

interface OrgUnitSelectProps {
  label: string;
  showOnly?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<unknown>, value: OrganizationUnit | null) => void;
  units: OrganizationUnit[];
  isLoading: boolean;
  isError: boolean;
  value: OrganizationUnit | undefined;
  textFieldProps?: Record<string, unknown>;
}

const OrgUnitSelect: React.FC<OrgUnitSelectProps> = ({
  label,
  showOnly,
  disabled,
  onChange,
  units,
  isLoading,
  isError,
  value,
  textFieldProps,
}) => {
  const classes = useStyles();

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  if (showOnly) {
    return <ShowOnlyTextField classes={classes} showOnlyValueComponent={value} label={label} />;
  }

  return (
    <Autocomplete<OrganizationUnit | undefined>
      id="org_unit"
      options={units}
      getOptionLabel={(unit) => unit?.name || ''}
      getOptionSelected={(option, value) => option?.id === value?.id}
      loading={isLoading || isError}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={onChange}
      value={value}
      disabled={disabled}
      label={label}
      textFieldProps={textFieldProps}
    />
  );
};

export default OrgUnitSelect;
