import React from 'react';

import SvgIcon from '~/components/core/SvgIcon';

const DataExtractionIcon = (props) => (
  <SvgIcon viewBox="0 0 134 134" {...props}>
    <path d="M78 126.2H21.7c-8.6 0-15.6-7-15.6-15.5V42c0-8.6 7-15.6 15.6-15.6H78c7.6 0 13.9 5.3 15.3 12.4 2-.5 4-1 6.1-1.2-2-10-10.8-17.5-21.4-17.5H45A15.6 15.6 0 0 1 60 7.8h30.3c8.6 0 15.6 7 15.6 15.6v14c2 .1 4.2.4 6.2.8V23.4c0-12-9.8-21.9-21.8-21.9H60.1c-11 0-20 8.2-21.5 18.7H21.8C9.8 20.2 0 30 0 42.1v68.6c0 12 9.8 21.8 21.8 21.8H78c9.5 0 17.5-6.1 20.5-14.5-2-.3-4-.8-6-1.4a15.6 15.6 0 0 1-14.5 9.6z" />
    <path d="M18.7 42.9h41.5v4.7H18.7zM18.7 63.6h32v4.7h-32zM18.7 84.4h32v4.7h-32zM18.7 105.2h41.5v4.7H18.7zM127.3 76.2 133 74c.8-.3 1.2-1.2 1-2l-4.8-12.9a1.5 1.5 0 0 0-2-.9l-5.7 2.1c-.8.3-1.7.2-2.1-.2-.4-.5-1.2-2.2-.8-3l2.5-5.5c.4-.8 0-1.7-.8-2L108 43.9c-.8-.3-1.7 0-2 .8l-2.6 5.5c-.3.8-1.1 1.4-1.7 1.3-.6 0-2.4-.7-2.7-1.5l-2-5.7a1.5 1.5 0 0 0-2-.9L82 48.2c-.8.3-1.2 1.1-1 2l2.2 5.6c.3.8.2 1.8-.3 2.2-.4.4-2.2 1.2-3 .8l-5.4-2.5c-.8-.4-1.7 0-2 .7l-5.8 12.5c-.4.7 0 1.7.7 2L73 74c.8.4 1.4 1.2 1.4 1.7 0 .6-.7 2.4-1.5 2.7L67 80.5c-.8.3-1.2 1.2-1 2L71 95.4c.3.8 1.2 1.2 2 .9l5.7-2.1c.8-.3 1.7-.2 2.1.2.4.5 1.2 2.2.9 3l-2.6 5.5c-.3.8 0 1.7.8 2l12.4 5.8c.8.3 1.7 0 2-.8l2.6-5.5c.3-.8 1.1-1.3 1.7-1.3.6 0 2.4.7 2.7 1.5l2 5.7c.4.8 1.2 1.2 2 .9l12.9-4.8c.8-.3 1.2-1.1 1-2l-2.2-5.6c-.3-.8-.1-1.8.3-2.2.4-.4 2.2-1.2 3-.8l5.4 2.5c.8.4 1.7 0 2-.7l5.8-12.5c.4-.7 0-1.7-.7-2l-5.5-2.5c-.8-.4-1.3-2.2-1.3-2.8 0-.6.6-1.3 1.4-1.6zm-22 15.4A15.3 15.3 0 1 1 94.8 63a15.3 15.3 0 0 1 10.5 28.6z" />
  </SvgIcon>
);

export default DataExtractionIcon;
