import axios from 'axios';

interface UploadStoredFileParams {
  urlPrefix: string;
  file: File;
  onUpdatePercent: (progressEvent: ProgressEvent) => void;
}

export const uploadStoredFile = async ({
  urlPrefix,
  file,
  onUpdatePercent,
}: UploadStoredFileParams): Promise<string | undefined> => {
  const { data } = await axios.post(`${urlPrefix}/upload_url`, {
    file_size: file.size,
    file_type: file.type,
    file_name: file.name,
  });

  const uploadUrl = data.upload_url;
  const storageFileName = data.storage_filename;

  const config = {
    onUploadProgress: onUpdatePercent,
  };

  await axios.put(uploadUrl, file, config);
  return storageFileName;
};

export const fileNameToDocumentName = (fileName: string): string => {
  return fileName.replace(/\.[^/.]+$/, '');
};
