import React from 'react';
import * as PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import { connect, getIn, useFormikContext } from 'formik';

import Typography from '~/components/core/Atomic/Typography';

import PencilIcon from './icons/PencilIcon';
import InlineIconButton from './InlineIconButton';

import styles, { useStyles } from '../assets/styles';

function CheckboxFormik(props) {
  const {
    id,
    label,
    isOuterLabel,
    showOnly,
    disabled,
    onEdit,
    onChange,
    containerClassName,
    checkboxStyle = {},
  } = props;

  const { values, touched, errors, setFieldTouched, setFieldValue } = useFormikContext();

  if (showOnly) {
    return (
      <ShowOnlyCheckbox label={label} showOnlyValueComponent={getIn(values, id)} disabled={disabled} onEdit={onEdit} />
    );
  }

  return (
    <>
      <FormControl className={containerClassName} component="fieldset" error={getIn(errors, id) && getIn(touched, id)}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: getIn(values, id) && !disabled ? '#1A9C9E' : 'primary', ...checkboxStyle }}
                checked={!!getIn(values, id)}
                disabled={showOnly || disabled}
                onChange={onChange ? onChange : (event) => setFieldValue(id, event.target.checked)}
                onBlur={() => setFieldTouched(id, true)}
              />
            }
            label={!isOuterLabel ? label : undefined}
          />
        </FormGroup>
        {getIn(errors, id) && getIn(touched, id) && <FormHelperText>{getIn(errors, id)}</FormHelperText>}
      </FormControl>
      {isOuterLabel && <span style={{ marginLeft: -16 }}>{label}</span>}
    </>
  );
}

CheckboxFormik.propTypes = {
  id: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  isOuterLabel: PropTypes.bool,
  label: PropTypes.node.isRequired,
  showOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onEdit: PropTypes.func,
  onChange: PropTypes.func,
  checkboxStyle: PropTypes.object,
};

function ShowOnlyCheckbox(props) {
  const { label, disabled, onEdit, showOnlyValueComponent } = props;
  const classes = useStyles();

  const isEditable = onEdit && !disabled;

  return (
    <div className={classes.showField}>
      <div className="inline-flex items-center whitespace-pre-wrap">
        <Checkbox checked={showOnlyValueComponent} disabled={true} />
        <Typography display="block" variant="caption">
          {label}
        </Typography>
        {isEditable && (
          <InlineIconButton className={classes.inlineEditIcon} icon={PencilIcon} onClick={(e) => onEdit(e)} />
        )}
      </div>
    </div>
  );
}

ShowOnlyCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  showOnlyValueComponent: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onEdit: PropTypes.func,
};

export { ShowOnlyCheckbox };
export default connect(withStyles(styles)(CheckboxFormik));
