import React from 'react';
import { Collapse } from '@material-ui/core';

import { DocumentReferenceDialog } from '~/components/AiChat/components/Message/DocumentReferenceDialog';
import { NoteReferenceDialog } from '~/components/AiChat/components/Message/NoteReferenceDIalog';
import type { AiChatReference } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import ViewCommunicationCardContainer from '~/components/communications/ViewCommunicationCardContainer';
import { FsIconButton, Text } from '~/components/core';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { useCollapsible } from '~/components/core/Molecules/Collapsible/useCollapsible';
import { CommunicationIcon, DocumentIcon, NoteIcon } from '~/components/icons';
import { EmailIcon, PhoneIcon, PhysicalMailIcon, SmsIcon, VendorIcon } from '~/components/icons/notifications';
import { isoDateTimeToLocal } from '~/DateTimeUtils';
import colors from '~/theme/tailwind/colors';

interface MessageReferencesProps {
  referencesIds?: string[];
  references?: Record<string, AiChatReference>;
}

const ICONS_MAP = {
  document: DocumentIcon,
  communication_phone_call: PhoneIcon,
  communication_email: EmailIcon,
  communication_physical_mail: PhysicalMailIcon,
  communication_sms: SmsIcon,
  communication_vendor: VendorIcon,
  communication: CommunicationIcon,
  note: NoteIcon,
};

export const MessageReferences: React.FC<MessageReferencesProps> = ({ referencesIds, references }) => {
  const { claim } = useClaim();

  const validReferencesIds = referencesIds?.filter((id) => !!references?.[id]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedReference, setSelectedReference] = React.useState<AiChatReference | undefined>();

  const { isOpenCombined, onClick, getChevron } = useCollapsible({
    collapsible: true,
    openByDefault: false,
    onCollapseClick: setIsOpen,
    isOpen,
  });

  const getTypeText = (reference: AiChatReference) => {
    const { type, sub_type } = reference;
    return type === 'communication' ? sub_type.replace('_', ' ') : type.replace('_', ' ');
  };

  const getIcon = (reference: AiChatReference) => {
    const { type, sub_type } = reference;
    let iconKey = type;
    if (type === 'communication') {
      iconKey = `${type}_${sub_type}`;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const Icon = ICONS_MAP[iconKey];
    return Icon ? <Icon className="h-[18px] w-[18px]" iconColor={colors.aiV2.pink} /> : null;
  };

  if (!validReferencesIds?.length) {
    return null;
  }

  const getName = (reference: AiChatReference) => {
    const { type, name, claim_internal_id, sub_type } = reference;
    if (type === 'note') {
      return `${name} ${claim_internal_id}`;
    }

    if (type === 'communication') {
      return sub_type;
    }

    return name;
  };

  const getSummary = (reference: AiChatReference) => {
    const { type, name, sub_type } = reference;
    if (type === 'communication') {
      return `${name} ${sub_type}`;
    }

    if (type === 'document') {
      return '';
    }

    return name;
  };

  const handleRefClick = (reference: AiChatReference) => {
    setSelectedReference(reference);
  };

  const closeDialogHandler = () => {
    setSelectedReference(undefined);
  };

  return (
    <div className="__REFERENCES__ border-4 mb-12 flex flex-col rounded-lg border-solid border-slate-100 bg-white pl-12">
      <div className="flex items-center justify-between p-4">
        <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.SEMI_BOLD}>
          {`References (${validReferencesIds.length})`}
        </Text>
        <FsIconButton onClick={onClick} className="bg-opacity-75">
          {getChevron({ className: 'w-[14px] h-[14px]' })}
        </FsIconButton>
      </div>
      <Collapse in={isOpenCombined} timeout="auto" unmountOnExit>
        <ul className="m-0 pb-20 pl-0 pr-12 text-sm">
          {validReferencesIds.map((referenceId, index) => {
            const reference = references?.[referenceId];
            if (!reference) return null;
            return (
              <li
                key={index}
                className="list-none p-0 hover:cursor-pointer hover:underline"
                onClick={() => handleRefClick(reference)}
              >
                <div className="flex w-full items-center gap-4 overflow-hidden truncate p-4">
                  <Tooltip title={getTypeText(reference)} placement="left" classes={{ tooltip: 'capitalize' }} arrow>
                    <span className="flex items-center">{getIcon(reference)}</span>
                  </Tooltip>
                  <span className="w-[120px] overflow-hidden truncate font-semibold">{getName(reference)}</span>
                  <span className="w-[200px] overflow-hidden truncate">{getSummary(reference)}</span>
                  <span className="ml-auto text-sm text-slate-800">{isoDateTimeToLocal(reference.date)}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </Collapse>
      {selectedReference?.type === 'communication' && (
        <ViewCommunicationCardContainer
          isDialog
          key={selectedReference.id}
          communicationId={Number(selectedReference.id)}
          onClose={() => closeDialogHandler()}
          disableAdditionalActions
        />
      )}
      {selectedReference?.type === 'document' && (
        <DocumentReferenceDialog
          documentReference={selectedReference}
          onClose={closeDialogHandler}
          claimId={claim.id}
        />
      )}
      {selectedReference?.type === 'note' && (
        <NoteReferenceDialog noteReference={selectedReference} onClose={closeDialogHandler} claimId={claim.id} />
      )}
    </div>
  );
};
