import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  capitalize,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import axios from 'axios';
import filenamify from 'filenamify';
import { FieldArray, Formik, useFormikContext } from 'formik';
import _ from 'lodash';
import { MailboxOpenUp } from 'mdi-material-ui';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { getClaimantLocationInAutoIncident } from '../../AutoClaimUtils.jsx';
import { isoDateToUs, serverDateToLocalMoment } from '../../DateTimeUtils';
import { MIXPANEL_EVENT_SOURCES, MIXPANEL_EVENTS } from '../../pocs/mixpanel';
import {
  AUTO_LIABILITY_INDICATORS_DICT,
  AUTO_LIABILITY_PERCENTAGE_OF_INSURE_LIABILITY_LIST,
  COUNTRIES_DICT,
  COUNTRY_TO_STATE_MAP,
  INDEPENDENT_MEDICAL_SPECIALISTS_DICT,
  TSC_AUTO_LIABILITY_INDICATORS_DICT,
  WC_COVERAGE_DICT,
} from '../../Types';
import { isProductionEnv, isTscClaim, reportAxiosError, reportErrorInProductionOrThrow, stringCmp } from '../../Utils';
import { findIncidentParty } from '../../Utils/ClaimUtils';
import { SimulateInshurMedlogixPipAutomation } from '../Admin/TestActions';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import mixpanel from '../CmsMain/mixpanel';
import { getAllSearchableContactRoles } from '../communications/ContactUtils';
import DocumentPhysicalMailCommunicationCardContainer from '../communications/PhysicalMailCommunicationCard';
import { ContactEntity } from '../Contact';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import DocumentLink from '../Documents/DocumentLink';
import { createAttachedDocumentEntry } from '../Documents/DocumentsAttachment';
import DocumentTextFieldFormik from '../Documents/DocumentTextFieldFormik';
import WorldTripMedicalBillAdjudicationContainer from '../exposures/WorldTripMedicalBillAdjudicationContainer';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import HoverChangeField, { SelectHoverChangeField } from '../HoverChangeField';
import { CreditCardIcon } from '../icons';
import PencilIcon from '../icons/PencilIcon';
import InlineIconButton from '../InlineIconButton';
import { REPRESENTATIVE_CONTACT_TYPES } from '../InvolvedPerson';
import LoadingDialog from '../LoadingDialog';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import PlainTable from '../PlainTable';
import {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  ShowOnlyTextField,
  TextFieldFormik,
} from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import PaymentRequestContainer from './PaymentRequestContainer/PaymentRequestContainer';
import { isExposureWriteDisabled, PENDING_COLOR } from './ExposureUtils';
import IcdCodesFieldContainer from './IcdCodesContainer';
import { ExposureLitigationStatusDialog } from './LitigationStatusDialog';

import { useStyles } from '../../assets/styles';

function PipDashboardContainer({ exposure, viewOnly }) {
  const { user } = useCms();
  const { claim: claimInContext, onClaimUpdate } = useClaim();
  const [showDashboardDialog, setShowDashboardDialog] = useState(false);

  if (!claimInContext || claimInContext.id !== exposure.claim_id) {
    reportErrorInProductionOrThrow('PipDashboardContainer should be within claim context');
    return <></>;
  }

  const viewOnlyPipDashboard = viewOnly || isExposureWriteDisabled(exposure, user);

  return (
    <>
      <HoverActionField icon={OpenInNewIcon} onAction={() => setShowDashboardDialog(true)} permanent>
        <span style={{ whiteSpace: 'nowrap' }}>{exposure.pip_settlement_status}</span>
      </HoverActionField>

      {showDashboardDialog && (
        <PipDashboardDialog
          claim={claimInContext}
          onUpdate={onClaimUpdate}
          exposure={exposure}
          onClose={() => setShowDashboardDialog(false)}
          viewOnly={viewOnlyPipDashboard}
        />
      )}
    </>
  );
}

PipDashboardContainer.propTypes = {
  exposure: PropTypes.object.isRequired,
  viewOnly: PropTypes.bool,
};

function PipDashboardDialog({ claim, exposure, onUpdate, onClose, viewOnly }) {
  const {
    isLoading,
    isError,
    data: pipDashboardDetails,
    reloadData,
  } = useDataFetcher(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/pip/pip_dashboard_details`);
  const [isAllDocumentsExistenceVerified, setIsAllDocumentsExistenceVerified] = useState(false);
  const classes = useStyles();

  // For now, the default is Auto Claim, and we add special treat for WC Claim. Maybe later we split the code...
  const isWcClaim = 'wc_claim' === claim.type;
  const isAutoClaim = claim.type === 'auto_claim';
  // special treat for medicals dashboard
  const isMedicalExposure = ['medical', 'worldtrip_demo', 'worldtrip_demo_line'].includes(
    exposure.damage_assessment_type
  );

  const handleUpdate = async () => {
    await onUpdate();
    await reloadData();
  };

  // In case a new pip medical payment was added after the last time claim was fetched, we need to reload the documents (otherwise it will crash)
  useEffect(() => {
    const updateClaimIfNeeded = async () => {
      if (pipDashboardDetails) {
        const pipManagedPaymentsDocIds = pipDashboardDetails.pip_medical_treatments
          .map((pipPayment) => pipPayment.medical_eob_nf10_document_id)
          .filter((id) => !!id);
        // To prevent infinite loop (in case a document somehow missing in claim.documents), we don't want to run the effect every time claim is updated
        if (
          _.difference(
            pipManagedPaymentsDocIds,
            claim.documents.map((doc) => doc.id)
          ).length > 0
        ) {
          await onUpdate();
        }
        setIsAllDocumentsExistenceVerified(true);
      }
    };

    updateClaimIfNeeded();
    // we don't want to run if claim (or claim.documents) is updated - we ran onUpdate and this is enough
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUpdate, pipDashboardDetails]);

  if (isLoading || isError || !isAllDocumentsExistenceVerified) {
    return <LoadingDialog isError={isError} onClose={onClose} track=" LOADING PIP Dashboard - Damage Management" />;
  }

  const titlePrefix = isMedicalExposure
    ? 'Medical'
    : isWcClaim
    ? WC_COVERAGE_DICT[exposure.coverage_type]['desc']
    : 'PIP';

  return (
    <CardDialog
      title={
        <span>
          {`${titlePrefix} Dashboard - Damage Management`}
          {exposure.is_medicare_eligible === 'Positive' && (
            <span>
              <Tooltip title="Medicare Eligibility Confirmed">
                <VerifiedUserIcon style={{ marginLeft: '10px' }} color="primary" />
              </Tooltip>
            </span>
          )}
        </span>
      }
      trackAlt="PIP Dashboard - Damage Management"
      onClose={onClose}
      action={
        <MarkAsSettledCheckboxContainer
          exposure={exposure}
          onUpdate={onUpdate}
          arePaymentsExists={
            pipDashboardDetails.pip_medical_treatments.length !== 0 ||
            pipDashboardDetails.pip_lost_wages.length !== 0 ||
            pipDashboardDetails.pip_other_expenses.length !== 0
          }
        />
      }
      isDialog
      maxWidth="xl"
      fullWidth
    >
      <Grid container>
        <Grid item xs={5}>
          <div className={classes.cardDivRow}>
            <PipDashboardIncidentDetailsCard
              claim={claim}
              exposure={exposure}
              involvedPerson={pipDashboardDetails.involved_person}
              onUpdate={handleUpdate}
              viewOnly={viewOnly}
            />
          </div>
          {isAutoClaim && (
            <div className={classes.cardDivRow}>
              <ThirdPartiesLiability
                claim={claim}
                exposure={exposure}
                thirdPartiesLiability={pipDashboardDetails.pip_third_parties_liability}
                onUpdate={handleUpdate}
                viewOnly={viewOnly}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={7}>
          <div className={classes.cardDivRow}>
            <PaymentsSummaryCard
              claim={claim}
              exposure={exposure}
              involvedPerson={pipDashboardDetails.involved_person}
              onUpdate={handleUpdate}
              viewOnly={viewOnly}
              medicalTreatments={pipDashboardDetails.pip_medical_treatments}
              lostWages={pipDashboardDetails.pip_lost_wages}
              otherExpenses={pipDashboardDetails.pip_other_expenses}
              totalDeathBenefitsPaid={pipDashboardDetails.total_pip_death_benefits_paid}
            />
          </div>
          {isAutoClaim && (
            <div className={classes.cardDivRow}>
              <IndependentMedicalExaminationLogCard
                claim={claim}
                exposure={exposure}
                onUpdate={handleUpdate}
                viewOnly={viewOnly}
                imeLogs={pipDashboardDetails.ime_logs}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </CardDialog>
  );
}

PipDashboardDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired, // TODO only if not view only
  onClose: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

function MarkAsSettledCheckboxContainer({ exposure, onUpdate, arePaymentsExists }) {
  const [isUpdating, setIsUpdating] = useState(false);
  async function updateSettleStatus(value) {
    setIsUpdating(true);
    try {
      await axios.post(`/api/v1/auto_claims/${exposure.claim_id}/exposures/${exposure.id}/pip/settlement_status`, {
        pip_settlement_status: value ? 'Settled' : arePaymentsExists ? 'In Progress' : '',
      });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setIsUpdating(false);
  }

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <Checkbox
        checked={exposure.pip_settlement_status === 'Settled'}
        onChange={(event) => updateSettleStatus(event.target.checked)}
        disabled={isUpdating}
        color="primary"
      />
      <Typography display="block" variant="subtitle1">
        Mark As Settled
      </Typography>
    </div>
  );
}

MarkAsSettledCheckboxContainer.propTypes = {
  exposure: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  arePaymentsExists: PropTypes.bool.isRequired,
};

function PipDashboardIncidentDetailsCard({ claim, exposure, involvedPerson, onUpdate, viewOnly }) {
  const classes = useStyles();
  const [showEditInLitigation, setShowEditInLitigation] = useState(false);
  const liabilityDictValues = isTscClaim(claim) ? TSC_AUTO_LIABILITY_INDICATORS_DICT : AUTO_LIABILITY_INDICATORS_DICT;

  // For now, the default is Auto Claim, and we add special treat for WC Claim. Maybe later we split the code...
  const isWcClaim = 'wc_claim' === claim.type;
  // special treat for medicals dashboard
  const isMedicalExposure = ['medical', 'worldtrip_demo', 'worldtrip_demo_line'].includes(
    exposure.damage_assessment_type
  );

  async function handleUpdateVenue(venue) {
    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/venue`, { venue });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateAttorney(attorney_contact_id) {
    try {
      await axios.post(`/api/v1/claims/${claim.id}/exposures/${exposure.id}/involved_person/attorney`, {
        attorney_contact_id,
      });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateRepresentative(representative_contact_id) {
    try {
      await axios.post(`/api/v1/claims/${claim.id}/exposures/${exposure.id}/involved_person/representative`, {
        representative_contact_id,
      });
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  const isEligibilityConfirmed = exposure.coverage_decision?.is_eligibility_confirmed;

  return (
    <>
      <CardDialog
        title="Incident Details"
        outlinedCard
        subheader={
          typeof isEligibilityConfirmed === 'boolean' && (
            <span style={{ color: isEligibilityConfirmed ? 'green' : 'red' }}>
              Eligibility {isEligibilityConfirmed ? '' : 'not '}confirmed
            </span>
          )
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={involvedPerson.contact.id}
              contactDisplayName={involvedPerson.contact.full_name}
              label="Claimant"
              fullWidth
              showOnly
            />
          </Grid>
          {!(isWcClaim || isMedicalExposure) && (
            <Grid item xs={6}>
              <ShowOnlyTextField
                classes={classes}
                label="State of Loss"
                showOnlyValueComponent={
                  claim.incident.loss_location.country === 'US'
                    ? COUNTRY_TO_STATE_MAP['US'][claim.incident.loss_location.state]
                    : ''
                }
              />
            </Grid>
          )}
          <Grid item xs={6}>
            {!involvedPerson.is_company && (
              <div style={{ paddingLeft: '8px' }}>
                <Typography display="block" color="inherit" variant="caption">
                  DoB: {involvedPerson.contact.date_of_birth && isoDateToUs(involvedPerson.contact.date_of_birth)}
                </Typography>
                <Typography display="block" color="inherit" variant="caption">
                  Gender: {involvedPerson.contact.sex}
                </Typography>
                <Typography display="block" color="inherit" variant="caption">
                  SSN: {involvedPerson.contact?.government_id}
                </Typography>
              </div>
            )}
          </Grid>
          {!(isWcClaim || isMedicalExposure) && (
            <>
              <Grid item xs={6}>
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={COUNTRIES_DICT[claim.incident.loss_location.country]}
                  disabled
                  label="Loss Country"
                />
              </Grid>
              <Grid item xs={6}>
                <ShowOnlyTextField
                  classes={classes}
                  label="Claimant Location"
                  showOnlyValueComponent={getClaimantLocationInAutoIncident(claim, exposure)}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <HoverChangeField
              name="attorney_contact_id"
              customFieldIdName="attorney_contact"
              value={involvedPerson.attorney_contact_id || ''}
              label="Attorney"
              onUpdate={async ({ attorney_contact_id }) => await handleUpdateAttorney(attorney_contact_id)}
              disabled={viewOnly}
              specialFieldType="contact"
              specialFieldAdditionalProps={{ acceptedRoles: ['attorney'] }}
            >
              <ContactShowOnlyTextField
                contactId={involvedPerson.attorney_contact_id}
                contactDisplayName={involvedPerson.attorney_contact_full_name}
                label="Attorney"
                fullWidth
                showOnly
              />
            </HoverChangeField>
          </Grid>
          <Grid item xs={6}>
            <HoverChangeField
              name="representative_contact_id"
              customFieldIdName="representative_contact"
              value={involvedPerson.representative_contact_id || ''}
              label="Representative"
              onUpdate={async ({ representative_contact_id }) =>
                await handleUpdateRepresentative(representative_contact_id)
              }
              disabled={viewOnly}
              specialFieldType="contact"
              specialFieldAdditionalProps={{ acceptedRoles: REPRESENTATIVE_CONTACT_TYPES }}
            >
              <ContactShowOnlyTextField
                contactId={involvedPerson.representative_contact_id || null}
                contactDisplayName={involvedPerson.representative_contact_full_name}
                label="Representative"
                fullWidth
                showOnly
              />
            </HoverChangeField>
          </Grid>
          {!(isWcClaim || isMedicalExposure) && (
            <Grid item xs={6}>
              <ShowOnlyTextField
                label="Liability Indicator"
                classes={classes}
                showOnlyValueComponent={liabilityDictValues[claim.incident.liability_indicator]}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <ShowOnlyTextField
              label="In Suit?"
              classes={classes}
              showOnlyValueComponent={
                exposure.is_in_litigation !== null &&
                (exposure.is_in_litigation ? (exposure.is_litigation_closed ? 'Closed' : 'Yes') : 'No')
              }
              onEdit={!viewOnly && (() => setShowEditInLitigation(true))}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <HoverChangeField
              name="venue"
              value={exposure.venue || ''}
              label="Venue"
              onUpdate={async ({ venue }) => await handleUpdateVenue(venue)}
              disabled={viewOnly}
            >
              <ShowOnlyTextField classes={classes} label="Venue" showOnlyValueComponent={exposure.venue || ''} />
            </HoverChangeField>
          </Grid>
          {!(isWcClaim || isMedicalExposure) && (
            <Grid item xs={6}>
              <IcdCodesFieldContainer
                onUpdate={onUpdate}
                viewOnly={viewOnly}
                involvedPerson={involvedPerson}
                claimId={exposure.claim_id}
              />
            </Grid>
          )}
        </Grid>
      </CardDialog>
      {showEditInLitigation && (
        <ExposureLitigationStatusDialog
          exposure={exposure}
          onUpdate={onUpdate}
          onClose={() => setShowEditInLitigation(false)}
        />
      )}
    </>
  );
}

PipDashboardIncidentDetailsCard.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  involvedPerson: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

function ThirdPartiesLiability({ claim, exposure, thirdPartiesLiability, onUpdate, viewOnly }) {
  const classes = useStyles();
  const [addThirdPartyLiability, setAddThirdPartyLiability] = useState(false);

  const handleAddParty = async (values) => {
    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/pip/third_party_liability`, values);
      await onUpdate();
      setAddThirdPartyLiability(false);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleUpdateField = async (thirdParty, values) => {
    try {
      await axios.patch(
        `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/pip/third_party_liability/${thirdParty.id}`,
        values
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  return (
    <>
      {thirdPartiesLiability.length === 0 ? (
        <Button color="primary" onClick={() => setAddThirdPartyLiability(true)} disabled={viewOnly}>
          <AddIcon className={classes.leftButtonIcon} />
          Set Third Party Liability
        </Button>
      ) : (
        <>
          {thirdPartiesLiability.map((thirdPartyLiability) => (
            <div key={thirdPartyLiability.id} className={classes.cardDivRow}>
              <ThirdPartyLiabilityCard
                claim={claim}
                thirdPartyLiability={thirdPartyLiability}
                onUpdateField={(values) => handleUpdateField(thirdPartyLiability, values)}
                viewOnly={viewOnly}
              />
            </div>
          ))}
          <Button color="primary" onClick={() => setAddThirdPartyLiability(true)} disabled={viewOnly}>
            <AddIcon className={classes.leftButtonIcon} />
            Add Another Party
          </Button>
        </>
      )}

      {addThirdPartyLiability && (
        <AddThirdPartyLiabilityDialog
          claim={claim}
          onSubmit={handleAddParty}
          existingPartyIds={thirdPartiesLiability.map((thirdParty) => thirdParty.third_party_id)}
          onClose={() => setAddThirdPartyLiability(false)}
        />
      )}
    </>
  );
}

ThirdPartiesLiability.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  thirdPartiesLiability: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

function ThirdPartyLiabilityCard({ claim, thirdPartyLiability, onUpdateField, viewOnly }) {
  const thirdParty = findIncidentParty(claim, thirdPartyLiability.third_party_id);
  const classes = useStyles();
  let claimantContact;
  let dialogTitle;

  switch (thirdParty.type) {
    case 'vehicle_party':
      claimantContact = thirdParty.involved_vehicle.owner_contact;
      dialogTitle = `Third Party Vehicle - ${thirdParty.involved_vehicle.display_name}`;
      break;
    case 'non_motorist_party':
      claimantContact = thirdParty.involved_non_motorist.contact;
      dialogTitle = `Third Party ${capitalize(thirdParty.non_motorist_type)}`;
      break;
    case 'generic_property_party':
      claimantContact = thirdParty.generic_property_involved.owner_contact;
      dialogTitle = `Third Party Other Property - ${thirdParty.generic_property_involved.display_name}`;
      break;
    default:
      throw Error(`Unknown incident party type ${thirdParty.type}`);
  }

  return (
    <CardDialog title={dialogTitle} outlinedCard>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <ContactShowOnlyTextField
            contactId={claimantContact && claimantContact.id}
            contactDisplayName={claimantContact && claimantContact.full_name}
            label="Claimant"
          />
        </Grid>
        <Grid item xs={6}>
          <SelectHoverChangeField
            label="% Third Party Liability"
            fieldId="party_liability_percentage"
            value={thirdPartyLiability.party_liability_percentage}
            keys={AUTO_LIABILITY_PERCENTAGE_OF_INSURE_LIABILITY_LIST}
            displayValueFunc={(key) => key}
            disabled={viewOnly}
            onUpdate={(values) => onUpdateField({ party_liability_percentage: values.party_liability_percentage })}
            overrideOnEdit
          >
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={thirdPartyLiability.party_liability_percentage}
              label="% Third Party Liability"
            />
          </SelectHoverChangeField>
        </Grid>
        <Grid item xs={6}>
          <SelectHoverChangeField
            label="Loss Transfer Eligible"
            fieldId="is_loss_transfer_eligible"
            value={thirdPartyLiability.is_loss_transfer_eligible}
            keys={[true, false]}
            displayValueFunc={(key) => (key ? 'Yes' : 'No')}
            disabled={viewOnly}
            onUpdate={(values) => onUpdateField({ is_loss_transfer_eligible: values.is_loss_transfer_eligible })}
            overrideOnEdit
          >
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={
                thirdPartyLiability.is_loss_transfer_eligible !== null &&
                (thirdPartyLiability.is_loss_transfer_eligible ? 'Yes' : 'No')
              }
              label="Loss Transfer Eligible"
            />
          </SelectHoverChangeField>
        </Grid>
        {thirdParty.type === 'vehicle_party' && (
          <Grid item xs={6}>
            <ContactShowOnlyTextField
              contactId={thirdParty.involved_vehicle.insurer_contact_id}
              contactDisplayName={thirdParty.involved_vehicle.insurer_contact_full_name}
              label="Adverse Carrier"
            />
          </Grid>
        )}
      </Grid>
    </CardDialog>
  );
}

ThirdPartyLiabilityCard.propTypes = {
  claim: PropTypes.object.isRequired,
  thirdPartyLiability: PropTypes.object.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

function AddThirdPartyLiabilityDialog({ claim, existingPartyIds, onSubmit, onClose }) {
  const classes = useStyles();
  let thirdPartiesInClaim = [];

  claim.incident.vehicle_parties.forEach((vehicleParty) => {
    if (!vehicleParty.is_first_party && vehicleParty.involved_vehicle) {
      thirdPartiesInClaim.push({
        third_party_id: vehicleParty.id,
        title: `Vehicle - ${vehicleParty.involved_vehicle.display_name} - ${
          vehicleParty.involved_vehicle.owner_contact ? vehicleParty.involved_vehicle.owner_contact.full_name : ''
        }`,
      });
    }
  });

  claim.incident.non_motorist_parties.forEach((nonMotoristParty) => {
    if (nonMotoristParty.involved_non_motorist) {
      thirdPartiesInClaim.push({
        third_party_id: nonMotoristParty.id,
        title: `${capitalize(nonMotoristParty.non_motorist_type)} - ${
          nonMotoristParty.involved_non_motorist.contact.full_name
        }`,
      });
    }
  });

  claim.incident.other_properties_parties.forEach((otherPropertyParty) => {
    if (otherPropertyParty.generic_property_involved) {
      thirdPartiesInClaim.push({
        third_party_id: otherPropertyParty.id,
        title: `Other Property - ${otherPropertyParty.generic_property_involved.display_name} - ${
          otherPropertyParty.generic_property_involved.owner_contact
            ? otherPropertyParty.generic_property_involved.owner_contact.full_name
            : ''
        }`,
      });
    }
  });

  thirdPartiesInClaim = thirdPartiesInClaim.filter(
    (thirdParty) => !existingPartyIds.includes(thirdParty.third_party_id)
  );
  return (
    <Formik
      initialValues={{
        third_party_id: '',
        party_liability_percentage: '',
        is_loss_transfer_eligible: '',
      }}
      validationSchema={Yup.object().shape({
        third_party_id: Yup.number().required('Required'),
        party_liability_percentage: Yup.string().required('Required'),
        is_loss_transfer_eligible: Yup.boolean().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
          onclose();
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <CardDialog title="Add Third Party Liability" isDialog maxWidth="xs" fullWidth onClose={onClose}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextFieldFormik id="third_party_id" label="Third Party" fullWidth select>
                {thirdPartiesInClaim.map((thirdParty) => (
                  <MenuItem key={thirdParty.third_party_id} value={thirdParty.third_party_id}>
                    {thirdParty.title}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik
                id="party_liability_percentage"
                select
                label="% Third Party Liability"
                className={classes.textField}
                fullWidth
              >
                {AUTO_LIABILITY_PERCENTAGE_OF_INSURE_LIABILITY_LIST.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormik
                id="is_loss_transfer_eligible"
                select
                label="Loss Transfer Eligible"
                className={classes.textField}
                fullWidth
              >
                {['Yes', 'No'].map((option) => (
                  <MenuItem key={option} value={option === 'Yes'}>
                    {option}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
              Add
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

AddThirdPartyLiabilityDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  existingPartyIds: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function IndependentMedicalExaminationLogCard({ claim, exposure, onUpdate, imeLogs, viewOnly }) {
  const classes = useStyles();
  const [showNewExaminationLogDialog, setShowNewExaminationLogDialog] = useState(false);
  const [examinationLogToEdit, setExaminationLogToEdit] = useState(false);

  async function handleNewImeLog(imeLogValues) {
    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/pip/ime_logs`, imeLogValues);
      await onUpdate();
      setShowNewExaminationLogDialog(false);
    } catch (error) {
      reportAxiosError(error);
    }
  }

  async function handleUpdateImeLog(imeLogValues) {
    try {
      await axios.patch(
        `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/pip/ime_logs/${imeLogValues.id}`,
        imeLogValues
      );
      await onUpdate();
      setExaminationLogToEdit(false);
    } catch (error) {
      reportAxiosError(error);
    }
  }

  let imeColumns = [
    {
      id: 'specialist',
      label: 'Specialty',
      specialCell: (imeLog) =>
        imeLog.specialist !== 'other'
          ? INDEPENDENT_MEDICAL_SPECIALISTS_DICT[imeLog.specialist].desc
          : `${INDEPENDENT_MEDICAL_SPECIALISTS_DICT[imeLog.specialist].desc} - ${imeLog.other_specialist}`,
    },
    { id: 'date_referred', label: 'Date Referred', specialCell: (imeLog) => isoDateToUs(imeLog.date_referred) },
    // eslint-disable-next-line react/display-name
    {
      id: 'examinations',
      label: 'Examinations',
      specialCell: (imeLog) =>
        imeLog.examinations.map(({ date_of_examination, status }, idx) => (
          <div key={idx} style={{ whiteSpace: 'nowrap', padding: 2 }}>
            {isoDateToUs(date_of_examination)} - {status}
          </div>
        )),
    },
    { id: 'result', label: 'Result' },
    { id: 'effective_date', label: 'Effective Date', specialCell: (imeLog) => isoDateToUs(imeLog.effective_date) },
    // eslint-disable-next-line react/display-name
    {
      id: 'ime_report',
      label: 'IME Report',
      specialCell: (imeLog) => {
        // eslint-disable-next-line react/prop-types
        if (!imeLog.ime_report_document_id) {
          return null;
        }
        const document = claim.documents.find((d) => d.id === imeLog.ime_report_document_id);
        return <DocumentLink document={document} text={document.document_name} />;
      },
    },
  ];

  if (!viewOnly) {
    // eslint-disable-next-line react/display-name
    imeColumns.push({
      id: 'edit_ime_log',
      width: 20,
      specialCell: (imeLog) => (
        <span>
          <IconButton size="small" onClick={() => setExaminationLogToEdit(imeLog)}>
            <PencilIcon />
          </IconButton>
        </span>
      ),
    });
  }

  return (
    <>
      <CardDialog title="Independent Medical Examination Log" outlinedCard>
        <Button color="primary" onClick={() => setShowNewExaminationLogDialog(true)} disabled={viewOnly}>
          <AddIcon className={classes.leftButtonIcon} />
          Add New
        </Button>
        <PlainTable columns={imeColumns} rows={imeLogs} />
      </CardDialog>
      {showNewExaminationLogDialog && (
        <IndependentMedicalExaminationLogDialog
          onSubmit={handleNewImeLog}
          onClose={() => setShowNewExaminationLogDialog(false)}
          isNewLog
        />
      )}
      {examinationLogToEdit && (
        <IndependentMedicalExaminationLogDialog
          imeLog={examinationLogToEdit}
          onSubmit={handleUpdateImeLog}
          onClose={() => setExaminationLogToEdit(false)}
        />
      )}
    </>
  );
}

IndependentMedicalExaminationLogCard.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  imeLogs: PropTypes.array.isRequired,
  onUpdate: PropTypes.func,
  viewOnly: PropTypes.bool,
};

function IndependentMedicalExaminationLogDialog({ onSubmit, onClose, isNewLog, imeLog }) {
  const classes = useStyles();

  const initialValues = {
    specialist: '',
    other_specialist: '',
    date_referred: '',
    examinations: [{ date_of_examination: '', status: '' }],
    result: '',
    effective_date: '',
    ime_report_document_id: '',
  };

  return (
    <Formik
      initialValues={isNewLog ? initialValues : imeLog}
      validationSchema={Yup.object().shape({
        specialist: Yup.string().required('Required'),
        other_specialist: Yup.string().when('specialist', { is: 'other', then: Yup.string().required('Required') }),
        date_referred: Yup.date().required('Required'),
        examinations: Yup.array()
          .of(
            Yup.object().shape({
              date_of_examination: Yup.date().required('Required'),
              status: Yup.string().required('Required'),
            })
          )
          .required('Required')
          .min(1, 'Required'),
        result: Yup.string().required('Required'),
        effective_date: Yup.date().required('Required'),
        ime_report_document_id: Yup.number().nullable(),
      })}
      onSubmit={(values) => onSubmit(values)}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values } = formikProps;

        return (
          <CardDialog
            title={isNewLog ? 'Add New Independent Medical Examination' : 'Edit Independent Medical Examination'}
            onClose={onClose}
            isDialog
            maxWidth="xs"
            fullWidth
          >
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12}>
                <TextFieldFormik id="specialist" label="Specialty" fullWidth select>
                  {Object.keys(INDEPENDENT_MEDICAL_SPECIALISTS_DICT).map((specialist) => (
                    <MenuItem key={specialist} value={specialist}>
                      {INDEPENDENT_MEDICAL_SPECIALISTS_DICT[specialist].desc}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              {values.specialist === 'other' && (
                <Grid item xs={12}>
                  <TextFieldFormik id="other_specialist" label="Other Specialist" fullWidth />
                </Grid>
              )}
              <Grid item xs={12}>
                <DatePickerTextFieldFormik id="date_referred" label="Date Referred" disableFuture fullWidth />
              </Grid>
              <FieldArray name="examinations">
                {({ remove, push }) => (
                  <>
                    {values['examinations'].map((item, idx) => (
                      <Fragment key={item.id}>
                        <Grid item xs={6}>
                          <DatePickerTextFieldFormik
                            id={`examinations.${idx}.date_of_examination`}
                            label="Date of Examination"
                            disableFuture
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <TextFieldFormik id={`examinations.${idx}.status`} label="Status" fullWidth />
                        </Grid>
                        <Grid item xs={1}>
                          {idx !== 0 && (
                            <IconButton size="small" onClick={() => remove(idx)} disabled={isSubmitting}>
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Fragment>
                    ))}
                    <Button size="small" onClick={() => push({ id: uuidv4(), date_of_examination: '', status: '' })}>
                      <AddIcon />
                      Add Examination
                    </Button>
                  </>
                )}
              </FieldArray>
              <Grid item xs={12}>
                <TextFieldFormik id="result" label="Result" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <DatePickerTextFieldFormik id="effective_date" label="Effective Date" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <DocumentTextFieldFormik id="ime_report_document_id" label="IME Report Document" />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
                {isNewLog ? 'Add' : 'Save'}
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

IndependentMedicalExaminationLogDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isNewLog: PropTypes.bool,
  imeLog: PropTypes.object,
};

function PaymentsSummaryCard({
  claim,
  exposure,
  involvedPerson,
  onUpdate,
  viewOnly,
  medicalTreatments,
  lostWages,
  otherExpenses,
  totalDeathBenefitsPaid,
}) {
  const { organizationContactRolesDict } = useOrganization();
  const classes = useStyles();
  const [showMedicalTreatmentsSummaryDialog, setShowMedicalTreatmentsSummaryDialog] = useState(false);
  const [showLostWagesSummaryDialog, setShowLostWagesSummaryDialog] = useState(false);
  const [showOtherExpensesSummaryDialog, setShowOtherExpensesSummaryDialog] = useState(false);
  const totalMedicalTreatmentsPaid = medicalTreatments.reduce((acc, curr) => acc + curr.amount_paid, 0);
  const totalLostWagesPaid = lostWages.reduce((acc, curr) => acc + curr.amount_paid, 0);
  const totalOtherExpensesPaid = otherExpenses.reduce((acc, curr) => acc + curr.amount_paid, 0);
  const { currencyFormatter } = useCurrencyFormatter();

  // For now, the default is Auto Claim, and we add special treat for WC Claim. Maybe later we split the code...
  const isWcClaim = 'wc_claim' === claim.type;
  let [hideTreatments, hideLostWages, hideOtherExpenses, hideDeathBenefit] = [false, false, false, false];
  if (isWcClaim) {
    hideTreatments = hideLostWages = hideOtherExpenses = hideDeathBenefit = true;
    if (exposure.coverage_type === 'coverage_wc_medicals') {
      hideTreatments = false;
    } else if (exposure.coverage_type === 'coverage_wc_compensation') {
      hideLostWages = false;
    } else if (['coverage_wc_other_expense', 'coverage_wc_voc_rehab'].includes(exposure.coverage_type)) {
      hideOtherExpenses = false;
    }
  }

  // special treat for medicals dashboard
  const isMedicalExposure = ['medical', 'worldtrip_demo', 'worldtrip_demo_line'].includes(
    exposure.damage_assessment_type
  );
  if (isMedicalExposure) {
    hideLostWages = hideOtherExpenses = hideDeathBenefit = true;
    hideTreatments = false;
  }

  async function handleNewMedicalTreatment(medicalTreatmentValues) {
    let url = `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/pip/pip_medical_treatments`;
    if (exposure.damage_assessment_type === 'worldtrip_demo_line') {
      url = `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/medical_treatments`;
    }
    try {
      await axios.post(url, medicalTreatmentValues);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateMedicalTreatment(medicalTreatmentValues) {
    try {
      let url = `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/pip/pip_medical_treatments/${medicalTreatmentValues.id}`;
      if (exposure.damage_assessment_type === 'worldtrip_demo_line') {
        url = `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/medical_treatments/${medicalTreatmentValues.id}`;
      }
      await axios.put(url, medicalTreatmentValues);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleNewLostWage(lostWageValues) {
    try {
      await axios.post(`/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/pip/lost_wages`, lostWageValues);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateLostWage(lostWageValues) {
    try {
      await axios.put(
        `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/pip/lost_wages/${lostWageValues.id}`,
        lostWageValues
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleNewOtherExpenses(otherExpensesValues) {
    try {
      await axios.post(
        `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/pip/other_expenses`,
        otherExpensesValues
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  async function handleUpdateOtherExpenses(otherExpensesValues) {
    try {
      await axios.put(
        `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/pip/other_expenses/${otherExpensesValues.id}`,
        otherExpensesValues
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  }

  return (
    <>
      <CardDialog
        title="Payments Summary"
        subheader={
          <span>
            Total benefits - {currencyFormatter.format(exposure.policy_coverage_limit)}
            <br />
            Remained balance -{' '}
            {currencyFormatter.format(
              exposure.policy_coverage_limit -
                (totalMedicalTreatmentsPaid + totalLostWagesPaid + totalOtherExpensesPaid + totalDeathBenefitsPaid)
            )}
          </span>
        }
        outlinedCard
      >
        <Grid container>
          {!hideTreatments && (
            <Grid item xs={3}>
              <ShowOnlyTextField
                classes={classes}
                label="Medical Treatments"
                showOnlyValueComponent={
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {currencyFormatter.format(totalMedicalTreatmentsPaid)}&nbsp;
                    <InlineIconButton
                      useIconButton
                      size="small"
                      className={classes.inlineEditIcon}
                      icon={OpenInNewIcon}
                      onClick={() => setShowMedicalTreatmentsSummaryDialog(true)}
                    />
                  </span>
                }
              />
            </Grid>
          )}

          {!hideLostWages && (
            <Grid item xs={3}>
              <ShowOnlyTextField
                classes={classes}
                label="Lost Wages"
                showOnlyValueComponent={
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {currencyFormatter.format(totalLostWagesPaid)}&nbsp;
                    <InlineIconButton
                      useIconButton
                      size="small"
                      className={classes.inlineEditIcon}
                      icon={OpenInNewIcon}
                      onClick={() => setShowLostWagesSummaryDialog(true)}
                    />
                  </span>
                }
              />
            </Grid>
          )}

          {!hideOtherExpenses && (
            <Grid item xs={3}>
              <ShowOnlyTextField
                classes={classes}
                label="Other Expenses"
                showOnlyValueComponent={
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {currencyFormatter.format(totalOtherExpensesPaid)}&nbsp;
                    <InlineIconButton
                      useIconButton
                      size="small"
                      className={classes.inlineEditIcon}
                      icon={OpenInNewIcon}
                      onClick={() => setShowOtherExpensesSummaryDialog(true)}
                    />
                  </span>
                }
              />
            </Grid>
          )}

          {!hideDeathBenefit && (
            <Grid item xs={3}>
              <PipDeathBenefitsContainer
                claim={claim}
                exposure={exposure}
                totalDeathBenefitsPaid={totalDeathBenefitsPaid}
                onUpdate={onUpdate}
                viewOnly={viewOnly}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <ShowOnlyTextField
              classes={classes}
              label="Total Paid"
              showOnlyValueComponent={
                <strong>
                  {currencyFormatter.format(
                    totalMedicalTreatmentsPaid + totalLostWagesPaid + totalOtherExpensesPaid + totalDeathBenefitsPaid
                  )}
                </strong>
              }
            />
          </Grid>
        </Grid>
      </CardDialog>
      {showMedicalTreatmentsSummaryDialog && (
        <PipManagedPaymentsSummaryDialog
          claim={claim}
          exposure={exposure}
          involvedPerson={involvedPerson}
          onUpdate={onUpdate}
          managedPayments={medicalTreatments}
          onAddManagedPayment={handleNewMedicalTreatment}
          onUpdateManagedPayment={handleUpdateMedicalTreatment}
          onClose={() => setShowMedicalTreatmentsSummaryDialog(false)}
          title="Medical Treatments"
          newPaymentTitle="Add New Medical Treatment"
          editPaymentTitle="Edit New Medical Treatment"
          documentRecordLabel="Record"
          originalDocumentRecordLabel="Medical Bill"
          paymentContactLabel="Provider"
          paymentContactRoles={['medical_provider', 'provider']}
          viewOnly={viewOnly}
          includeAmountConsidered
          includeService
          includeMedicalEobNf10Document
          includeAutomaticPayment
        />
      )}

      {showLostWagesSummaryDialog && (
        <PipManagedPaymentsSummaryDialog
          claim={claim}
          exposure={exposure}
          involvedPerson={involvedPerson}
          onUpdate={onUpdate}
          managedPayments={lostWages}
          onAddManagedPayment={handleNewLostWage}
          onUpdateManagedPayment={handleUpdateLostWage}
          onClose={() => setShowLostWagesSummaryDialog(false)}
          title="Lost Wages"
          newPaymentTitle="Add New Lost Wage"
          editPaymentTitle="Edit Lost Wage"
          documentRecordLabel="Lost Wage Request"
          paymentContactLabel="Payee"
          paymentContactRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
          viewOnly={viewOnly}
          includeAmountConsidered
          includeReason
          isWcLostWage={isWcClaim}
        />
      )}

      {showOtherExpensesSummaryDialog && (
        <PipManagedPaymentsSummaryDialog
          claim={claim}
          exposure={exposure}
          involvedPerson={involvedPerson}
          onUpdate={onUpdate}
          managedPayments={otherExpenses}
          onAddManagedPayment={handleNewOtherExpenses}
          onUpdateManagedPayment={handleUpdateOtherExpenses}
          onClose={() => setShowOtherExpensesSummaryDialog(false)}
          title="Other Expenses"
          newPaymentTitle="Add New Other Expense"
          editPaymentTitle="Edit Other Expense"
          documentRecordLabel="Other Expense Request"
          paymentContactLabel="Payee"
          paymentContactRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
          viewOnly={viewOnly}
          includeReason
        />
      )}
    </>
  );
}

PaymentsSummaryCard.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  involvedPerson: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  medicalTreatments: PropTypes.array.isRequired,
  lostWages: PropTypes.array.isRequired,
  otherExpenses: PropTypes.array.isRequired,
  totalDeathBenefitsPaid: PropTypes.number.isRequired,
  viewOnly: PropTypes.bool,
};

function PipManagedPaymentInitialFields({
  includeService,
  documentRecordLabel,
  paymentContactLabel,
  paymentContactRoles,
  isWcLostWage,
}) {
  const classes = useStyles();

  return (
    <>
      {includeService && (
        <Grid item xs={6}>
          <TextFieldFormik id="service" label="Specialist" fullWidth />
        </Grid>
      )}
      <Grid item xs={6}>
        <DocumentTextFieldFormik id="record_document_id" label={documentRecordLabel} />
      </Grid>
      {
        !includeService && (
          <Grid item xs={6} />
        ) /* so the date of service would be in the same line when there is no service */
      }
      {!isWcLostWage && (
        <>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="start_date_of_service"
              label="Start Date of Service"
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="end_date_of_service"
              label="End Date of Service"
              className={classes.textField}
              fullWidth
            />
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <ContactTextFieldFormik
          id="payment_contact"
          label={paymentContactLabel}
          className={classes.textField}
          acceptedRoles={paymentContactRoles}
          fixedSearchResults
          fullWidth
        />
      </Grid>
      {!isWcLostWage && (
        <Grid item xs={6}>
          <MonetaryValueTextFieldFormik id="amount_submitted" label="Amount Submitted" className={classes.textField} />
        </Grid>
      )}
      {isWcLostWage && (
        <>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <DocumentTextFieldFormik id="d5_document_id" label="D5 Document" />
          </Grid>
          <Grid item xs={6}>
            <DocumentTextFieldFormik id="d8_document_id" label="D8 Document" />
          </Grid>
        </>
      )}
    </>
  );
}

PipManagedPaymentInitialFields.propTypes = {
  includeService: PropTypes.bool,
  documentRecordLabel: PropTypes.string.isRequired,
  paymentContactLabel: PropTypes.string.isRequired,
  paymentContactRoles: PropTypes.array.isRequired,
  isWcLostWage: PropTypes.bool,
};

function PipManagedPaymentActionFields({
  includeMedicalEobNf10Document,
  includeAmountConsidered,
  excludeReason,
  amountConsideredLabel,
}) {
  const classes = useStyles();
  const { values } = useFormikContext();

  const currAction = values['action'];

  return (
    <>
      <Grid item xs={6}>
        <TextFieldFormik id="action" label="Action" fullWidth select>
          {['pay', 'defer', 'deny'].map((action) => (
            <MenuItem key={action} value={action}>
              {capitalize(action)}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>

      {currAction && (
        <>
          {!excludeReason && (
            <Grid item xs={6}>
              <TextFieldFormik id="reason" label="Reason" fullWidth />
            </Grid>
          )}
          {includeMedicalEobNf10Document && ['pay', 'deny'].includes(currAction) && (
            <Grid item xs={6}>
              <DocumentTextFieldFormik id="medical_eob_nf10_document_id" label="Add Medical EOB/NF10" />
            </Grid>
          )}
          {includeAmountConsidered && currAction === 'pay' && (
            <Grid item xs={6}>
              <MonetaryValueTextFieldFormik
                id="amount_considered"
                label={amountConsideredLabel ? amountConsideredLabel : 'Amount Considered'}
                className={classes.textField}
              />
            </Grid>
          )}
        </>
      )}
    </>
  );
}

PipManagedPaymentActionFields.propTypes = {
  includeMedicalEobNf10Document: PropTypes.bool,
  includeAmountConsidered: PropTypes.bool,
  excludeReason: PropTypes.bool,
  amountConsideredLabel: PropTypes.string,
};

function PipManagedPaymentsTable({
  claim,
  managedPayments,
  recordDocumentLabel,
  originalDocumentRecordLabel,
  paymentContactLabel,
  includeService,
  includeMedicalEobNf10Document,
  includeAmountConsidered,
  includeReason,
  hideCompletePayments,
  actionSpecialCellFunc,
  autoFilledPaymentSpecialCellFunc,
  isWcLostWage,
  isWorldTripDemo,
}) {
  const classes = useStyles();
  const { currencyFormatter } = useCurrencyFormatter();

  const renderDocumentLink = (document) => (
    <DocumentLink document={document} text={`#${document.claim_internal_id} - ${document.document_name}`} />
  );

  let pipManagedPaymentsColumns = [];

  if (originalDocumentRecordLabel) {
    pipManagedPaymentsColumns.push(
      ...[
        {
          id: 'original_record_document',
          label: originalDocumentRecordLabel,
          specialCell: (pipManagedPayment) => {
            const originalRecordDocument =
              pipManagedPayment.original_record_document_id &&
              claim.documents.find((d) => d.id === pipManagedPayment.original_record_document_id);
            if (!originalRecordDocument) {
              return null; // Shouldn't happen on new payments, can happen on old payments (before medlogix automation), protection not to crash in case of a bug
            } else {
              return renderDocumentLink(originalRecordDocument);
            }
          },
        },
      ]
    );
  }

  pipManagedPaymentsColumns.push(
    ...[
      {
        id: 'record_document',
        label: recordDocumentLabel,
        specialCell: (pipManagedPayment) => {
          const recordDocument =
            pipManagedPayment.record_document_id &&
            claim.documents.find((d) => d.id === pipManagedPayment.record_document_id);
          if (!recordDocument) {
            return null;
          } else {
            return renderDocumentLink(recordDocument);
          }
        },
      },
    ]
  );

  if (includeService) {
    pipManagedPaymentsColumns.push({ id: 'service', label: 'Specialty' });
  }

  pipManagedPaymentsColumns.push(
    ...[
      {
        id: 'payment_contact',
        label: paymentContactLabel,
        // eslint-disable-next-line react/display-name
        specialCell: (pipManagedPayment) =>
          pipManagedPayment.payment_contact_id && (
            <ContactEntity
              maxWidth="180px"
              contactId={pipManagedPayment.payment_contact_id}
              contactDisplayName={pipManagedPayment.payment_contact_full_name}
            />
          ),
      },
      { id: 'status', label: 'Status' },
      {
        id: 'last_updated',
        label: 'Last updated',
        specialCell: (row) => serverDateToLocalMoment(row.last_updated_datetime, false),
        specialCmpFunc: (row1, row2) => stringCmp(row1.last_updated_datetime, row2.last_updated_datetime),
      },
    ]
  );

  if (includeMedicalEobNf10Document) {
    // eslint-disable-next-line react/display-name
    pipManagedPaymentsColumns.push({
      id: 'medical_eob_nf10_document',
      label: 'Medical EOB/NF10',
      specialCell: (pipManagedPayment) => {
        if (!pipManagedPayment.medical_eob_nf10_document_id) {
          return <></>;
        }
        // eslint-disable-next-line react/prop-types
        const document = claim.documents.find((d) => d.id === pipManagedPayment.medical_eob_nf10_document_id);
        const billData = pipManagedPayment?.pip_managed_payment_extra?.medlogix_dict?.Bill;
        const eobNumber = billData ? `${billData.BillNumber}${billData.BillVersion}` : '';
        return (
          <span>
            {renderDocumentLink(document)}
            {eobNumber && <Typography>EOB ID: {eobNumber}</Typography>}
          </span>
        );
      },
    });
  }

  pipManagedPaymentsColumns.push(
    ...[
      {
        id: 'dates_of_service',
        label: 'Dates of Service',
        specialCell: (pipManagedPayment) =>
          pipManagedPayment.start_date_of_service &&
          pipManagedPayment.end_date_of_service &&
          `${isoDateToUs(pipManagedPayment.start_date_of_service)} - ${isoDateToUs(
            pipManagedPayment.end_date_of_service
          )}`,
      },
      {
        id: 'amount_submitted',
        label: 'Amount Submitted',
        numeric: true,
        specialCell: (pipManagedPayment) =>
          pipManagedPayment.amount_submitted !== null && currencyFormatter.format(pipManagedPayment.amount_submitted),
      },
    ]
  );

  if (includeAmountConsidered) {
    pipManagedPaymentsColumns.push({
      id: 'amount_considered',
      label: 'Amount Considered',
      numeric: true,
      // eslint-disable-next-line react/display-name
      specialCell: (pipManagedPayment) => {
        return (
          <>
            {pipManagedPayment.amount_considered !== null &&
              currencyFormatter.format(pipManagedPayment.amount_considered)}
            {pipManagedPayment.interest_considered > 0 && (
              <Typography variant="body2">
                Interest:&nbsp;{currencyFormatter.format(pipManagedPayment.interest_considered)}
              </Typography>
            )}
          </>
        );
      },
    });
  }

  pipManagedPaymentsColumns.push(
    ...[
      {
        id: 'amount_paid',
        label: 'Amount Paid',
        numeric: true,
        // eslint-disable-next-line react/display-name
        specialCell: autoFilledPaymentSpecialCellFunc
          ? autoFilledPaymentSpecialCellFunc
          : (pipManagedPayment) => {
              if (pipManagedPayment.amount_paid) {
                return <strong>{currencyFormatter.format(pipManagedPayment.amount_paid)}</strong>;
              } else if (pipManagedPayment.pending_payment_amount) {
                return (
                  <span style={{ color: PENDING_COLOR }}>
                    <strong>{currencyFormatter.format(pipManagedPayment.pending_payment_amount)}</strong>
                  </span>
                );
              }
            },
      },
    ]
  );

  if (includeReason) {
    pipManagedPaymentsColumns.push({ id: 'reason', label: 'Reason' });
  }

  if (actionSpecialCellFunc) {
    pipManagedPaymentsColumns.push({ id: 'action', label: '', specialCell: actionSpecialCellFunc });
  }

  if (isWcLostWage) {
    pipManagedPaymentsColumns = pipManagedPaymentsColumns.filter(
      (col) => !['last_updated', 'dates_of_service', 'amount_submitted', 'amount_considered', 'reason'].includes(col.id)
    );

    const statusId = pipManagedPaymentsColumns.findIndex((column) => column.id === 'status');
    // eslint-disable-next-line react/display-name
    pipManagedPaymentsColumns.splice(statusId + 1, 0, {
      id: 'd5_document',
      label: 'D5',
      specialCell: (lostWage) => {
        const document = claim.documents.find((d) => d.id === lostWage.d5_document_id);
        return document && <DocumentLink document={document} text={document.document_name} />;
      },
    });
    // eslint-disable-next-line react/display-name
    pipManagedPaymentsColumns.splice(statusId + 1, 0, {
      id: 'd8_document',
      label: 'D8',
      specialCell: (lostWage) => {
        const document = claim.documents.find((d) => d.id === lostWage.d8_document_id);
        return document && <DocumentLink document={document} text={document.document_name} />;
      },
    });
  }

  if (isWorldTripDemo) {
    pipManagedPaymentsColumns = [
      {
        id: 'record_document_id',
        label: originalDocumentRecordLabel,
        specialCell: (pipManagedPayment) => {
          const originalRecordDocument =
            pipManagedPayment.record_document_id &&
            claim.documents.find((d) => d.id === pipManagedPayment.record_document_id);
          if (!originalRecordDocument) {
            return null; // Shouldn't happen on new payments, can happen on old payments (before medlogix automation), protection not to crash in case of a bug
          } else {
            return renderDocumentLink(originalRecordDocument);
          }
        },
      },
      {
        id: 'start_date_of_service',
        label: 'Date of Service',
        specialCell: (pipManagedPayment) =>
          pipManagedPayment?.start_date_of_service && isoDateToUs(pipManagedPayment.start_date_of_service),
      },
      {
        id: 'payment_contact',
        label: paymentContactLabel,
        // eslint-disable-next-line react/display-name
        specialCell: (pipManagedPayment) =>
          pipManagedPayment.payment_contact_id && (
            <ContactEntity
              maxWidth="180px"
              contactId={pipManagedPayment.payment_contact_id}
              contactDisplayName={pipManagedPayment.payment_contact_full_name}
            />
          ),
      },
      { id: 'status', label: 'Status' },
      {
        id: 'last_updated',
        label: 'Last updated',
        specialCell: (row) => serverDateToLocalMoment(row.last_updated_datetime, false),
        specialCmpFunc: (row1, row2) => stringCmp(row1.last_updated_datetime, row2.last_updated_datetime),
      },
      { id: 'provider_type', label: 'Provider Type' },
      { id: 'icd_10', label: 'ICD 10' },
      { id: 'cpt', label: 'CPT' },
      {
        id: 'amount_submitted',
        label: 'Amount Submitted',
        numeric: true,
        specialCell: (pipManagedPayment) =>
          pipManagedPayment.amount_submitted !== null && currencyFormatter.format(pipManagedPayment.amount_submitted),
      },
      {
        id: 'usual_customary_amount',
        label: 'Usual Customary Amount',
        numeric: true,
        specialCell: (pipManagedPayment) =>
          pipManagedPayment.usual_customary_amount !== null &&
          currencyFormatter.format(pipManagedPayment.usual_customary_amount),
      },
      {
        id: 'mid_amount_considered',
        label: 'Considered Amount',
        numeric: true,
        specialCell: (pipManagedPayment) =>
          pipManagedPayment.mid_amount_considered !== null &&
          currencyFormatter.format(pipManagedPayment.mid_amount_considered),
      },
      {
        id: 'amount_considered',
        label: 'Amount Paid',
        numeric: true,
        // eslint-disable-next-line react/display-name
        specialCell: (pipManagedPayment) => {
          return (
            <>
              {pipManagedPayment.amount_considered !== null &&
                currencyFormatter.format(pipManagedPayment.amount_considered)}
              {pipManagedPayment.interest_considered > 0 && (
                <Typography variant="body2">
                  Interest:&nbsp;{currencyFormatter.format(pipManagedPayment.interest_considered)}
                </Typography>
              )}
            </>
          );
        },
      },
      { id: 'action', label: '', specialCell: actionSpecialCellFunc },
    ];
  }

  /////////////////////////////////////////////////////////////////////////////
  // Order the payments for the table, in order to group related payments (same original medical bill) together:
  // Group payments by original_record_document_id
  // Order group of payments by each group first payment.id - the order they were added to the dashboard
  // Inside each group order by the payment.id (This will happen because first we order all the payments by theirs id)

  let rows = managedPayments;
  if (hideCompletePayments) {
    rows = rows.filter((payment) => !payment.is_complete);
  }
  rows.sort((r1, r2) => r1.id - r2.id);

  // Group by original_record_document_id. for types other than medical payments all payments will be group together.
  let paymentsGroupedIndexDict = {};
  let paymentsGroupedOrdered = [];
  for (const payment of rows) {
    if (payment.original_record_document_id && payment.original_record_document_id in paymentsGroupedIndexDict) {
      paymentsGroupedOrdered[paymentsGroupedIndexDict[payment.original_record_document_id]].push(payment);
    } else {
      paymentsGroupedOrdered.push([payment]);
      if (payment.original_record_document_id) {
        paymentsGroupedIndexDict[payment.original_record_document_id] = paymentsGroupedOrdered.length - 1;
      }
    }
  }

  // paymentsGroupedOrdered is of the shape:
  //  [ [record #1 payment #1, record #1 payment #2], [record #2 payment #1,record #2 payment #2, record #2 payment #3], ...]
  // each sub-list of payments should be spanned together in the table

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          {pipManagedPaymentsColumns.map((column) => (
            <TableCell
              className={classes.tableCell}
              style={{ paddingLeft: '6px' }}
              align={column.align ? column.align : column.numeric ? 'right' : 'left'}
              key={column.id}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {paymentsGroupedOrdered.map((groupRecordPayments) =>
          groupRecordPayments.map((payment, idx) => (
            <TableRow key={payment.id}>
              {idx === 0 && pipManagedPaymentsColumns.find((col) => col.id === 'original_record_document') && (
                <TableCell rowSpan={groupRecordPayments.length} style={{ paddingLeft: '6px' }}>
                  {pipManagedPaymentsColumns.find((col) => col.id === 'original_record_document').specialCell(payment)}
                </TableCell>
              )}

              {pipManagedPaymentsColumns
                .filter((col) => col.id !== 'original_record_document')
                .map((column) => (
                  <TableCell
                    className={classes.tableCell}
                    padding="none"
                    style={{ paddingLeft: '6px' }}
                    align={column.align ? column.align : column.numeric ? 'right' : 'left'}
                    key={column.id}
                  >
                    {column.specialCell ? column.specialCell(payment) : payment[column.id]}
                  </TableCell>
                ))}
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
}

PipManagedPaymentsTable.propTypes = {
  claim: PropTypes.object.isRequired,
  managedPayments: PropTypes.array.isRequired,
  recordDocumentLabel: PropTypes.string.isRequired,
  originalDocumentRecordLabel: PropTypes.string,
  paymentContactLabel: PropTypes.string.isRequired,
  includeService: PropTypes.bool,
  includeMedicalEobNf10Document: PropTypes.bool,
  includeAmountConsidered: PropTypes.bool,
  autoFilledPaymentSpecialCellFunc: PropTypes.func,
  actionSpecialCellFunc: PropTypes.func,
  includeReason: PropTypes.bool,
  hideCompletePayments: PropTypes.bool,
  isWcLostWage: PropTypes.bool,
  isWorldTripDemo: PropTypes.bool,
};

function PipManagedPaymentsSummaryDialog({
  claim,
  exposure,
  involvedPerson,
  onUpdate,
  managedPayments,
  onAddManagedPayment,
  onUpdateManagedPayment,
  onClose,
  title,
  newPaymentTitle,
  editPaymentTitle,
  documentRecordLabel,
  originalDocumentRecordLabel,
  paymentContactLabel,
  paymentContactRoles,
  viewOnly,
  includeService,
  includeMedicalEobNf10Document,
  includeAmountConsidered,
  includeReason,
  includeAutomaticPayment,
  isWcLostWage,
}) {
  const classes = useStyles();
  const [showNewManagedPaymentDialog, setShowManagedPaymentDialog] = useState(false);
  const [managedPaymentToEdit, setManagedPaymentToEdit] = useState(null);
  const [valuesAndFunctionToSubmitAfterPayment, setValuesAndFunctionToSubmitAfterPayment] = useState(null);
  const [hideCompletePayments, setHideCompletePayments] = useState(false);

  async function handleSubmitManagedPayment(values, onSubmit) {
    if (values.action === 'pay') {
      const isPaymentContactInClaim = !!claim.contacts.find((contact) => contact.id === values.payment_contact_id);
      if (!isPaymentContactInClaim) {
        await axios.post(`/api/v1/claims/${claim.id}/contacts`, {
          contact_id: values.payment_contact_id,
          exposure_ids: [exposure.id],
        });
        await onUpdate();
      }
      setValuesAndFunctionToSubmitAfterPayment({ values, onSubmit });
      return; // onSubmit will be called after payment is set
    }

    await onSubmit(values);
  }

  const handleUpdateWcCompensation = async (values) => {
    try {
      await axios.patch(`/api/v1/wc_claims/${claim.id}`, values);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  return (
    <>
      <CardDialog title={title} onClose={onClose} isDialog maxWidth="xl" fullWidth>
        <Grid container justify="space-between">
          {isWcLostWage && (
            <Grid item xs={12}>
              <SelectHoverChangeField
                label="Compensation Type"
                fieldId="compensation_type"
                value={claim.compensation_type}
                keys={['PPD', 'PD', 'TTD', 'TPD']}
                displayValueFunc={(key) => key}
                onUpdate={async (values) =>
                  await handleUpdateWcCompensation({ compensation_type: values.compensation_type })
                }
                width="300px"
              >
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={claim.compensation_type}
                  label="Compensation Type"
                />
              </SelectHoverChangeField>
            </Grid>
          )}
          <Button color="primary" onClick={() => setShowManagedPaymentDialog(true)} disabled={viewOnly}>
            <AddIcon className={classes.leftButtonIcon} />
            Add New
          </Button>
          {includeMedicalEobNf10Document && (
            <FormControlLabel
              // HACK: includeMedicalEobNf10Document actually indicates if we dealing with medical treatment payments (and hid complete only relevant for this)
              control={
                <Checkbox
                  checked={hideCompletePayments}
                  onChange={() => setHideCompletePayments(!hideCompletePayments)}
                  name="hideComplete"
                  color="primary"
                />
              }
              label="Hide Complete"
              labelPlacement="start"
            />
          )}
        </Grid>
        <PipManagedPaymentsTable
          claim={claim}
          recordDocumentLabel={documentRecordLabel}
          originalDocumentRecordLabel={originalDocumentRecordLabel}
          managedPayments={managedPayments}
          paymentContactLabel={paymentContactLabel}
          includeService={includeService}
          includeMedicalEobNf10Document={includeMedicalEobNf10Document}
          includeAmountConsidered={includeAmountConsidered}
          includeReason={includeReason}
          hideCompletePayments={hideCompletePayments}
          isWcLostWage={isWcLostWage}
          isWorldTripDemo={['worldtrip_demo'].includes(exposure?.damage_assessment_type)}
          actionSpecialCellFunc={(managedPayment) => (
            <>
              {!viewOnly && managedPayment.status === 'Deferred' ? (
                <IconButton onClick={() => setManagedPaymentToEdit(managedPayment)}>
                  <PencilIcon />
                </IconButton>
              ) : null}
              {managedPayment.medical_eob_nf10_document_id && (
                <SendPipPaymentEobPhysicalMailContainer
                  managedPayment={managedPayment}
                  claim={claim}
                  exposure={exposure}
                  involvedPerson={involvedPerson}
                  onUpdate={onUpdate}
                />
              )}
              {/* This is for DEMO purpose only */}
              {!isProductionEnv() && (
                <SimulateInshurMedlogixPipAutomation
                  claim={claim}
                  exposure={exposure}
                  managedPayment={managedPayment}
                  onSimulated={onUpdate}
                />
              )}
            </>
          )}
          autoFilledPaymentSpecialCellFunc={
            includeAutomaticPayment
              ? (managedPayment) => (
                  <AutoFillPaymentCell
                    claim={claim}
                    exposure={exposure}
                    paymentContactLabel={paymentContactLabel}
                    managedPayment={managedPayment}
                    viewOnly={viewOnly}
                    onSubmit={async (values) => await handleSubmitManagedPayment(values, onUpdateManagedPayment)}
                  />
                )
              : undefined
          }
        />
      </CardDialog>

      {showNewManagedPaymentDialog && (
        <ManagedPipPaymentDialog
          title={newPaymentTitle}
          onSubmit={async (values) => {
            await handleSubmitManagedPayment(values, onAddManagedPayment);
            setShowManagedPaymentDialog(false);
          }}
          onClose={() => setShowManagedPaymentDialog(false)}
          documentRecordLabel={documentRecordLabel}
          paymentContactLabel={paymentContactLabel}
          paymentContactRoles={paymentContactRoles}
          includeService={includeService}
          includeMedicalEobNf10Document={includeMedicalEobNf10Document}
          includeAmountConsidered={includeAmountConsidered}
          isWcLostWage={isWcLostWage}
          isWorldTripDemo={['worldtrip_demo', 'worldtrip_demo_line'].includes(exposure?.damage_assessment_type)}
          exposure={exposure}
        />
      )}

      {managedPaymentToEdit && (
        <ManagedPipPaymentDialog
          title={editPaymentTitle}
          managedPipPayment={managedPaymentToEdit}
          onSubmit={async (values) => {
            await handleSubmitManagedPayment(values, onUpdateManagedPayment);
            setManagedPaymentToEdit(false);
          }}
          onClose={() => setManagedPaymentToEdit(null)}
          documentRecordLabel={documentRecordLabel}
          paymentContactLabel={paymentContactLabel}
          paymentContactRoles={paymentContactRoles}
          includeService={includeService}
          includeMedicalEobNf10Document={includeMedicalEobNf10Document}
          includeAmountConsidered={includeAmountConsidered}
          isWcLostWage={isWcLostWage}
          isWorldTripDemo={['worldtrip_demo', 'worldtrip_demo_line'].includes(exposure?.damage_assessment_type)}
          exposure={exposure}
        />
      )}

      {valuesAndFunctionToSubmitAfterPayment && (
        <PaymentRequestContainer
          claim={claim}
          exposure={exposure}
          payableWithReserve={exposure.indemnity}
          payableType="indemnity"
          cardDialogProps={{
            isDialog: true,
            maxWidth: 'sm',
            fullWidth: true,
          }}
          onClose={() => setValuesAndFunctionToSubmitAfterPayment(null)}
          onUpdate={async (paymentRequest) => {
            await valuesAndFunctionToSubmitAfterPayment.onSubmit({
              ...valuesAndFunctionToSubmitAfterPayment.values,
              payment_issued_request_id: paymentRequest.id,
            });
            setValuesAndFunctionToSubmitAfterPayment(null);
            setShowManagedPaymentDialog(false);
          }}
          overrideInitialValues={{
            payee: [],
            payment_reason: valuesAndFunctionToSubmitAfterPayment.values.reason,
            request_currency: valuesAndFunctionToSubmitAfterPayment.values?.request_currency,
          }}
          overridePaymentRequestStore={[
            {
              type: 'contact_search',
              contact_id: valuesAndFunctionToSubmitAfterPayment.values.payment_contact_id,
              contact_full_name: valuesAndFunctionToSubmitAfterPayment.values.payment_contact_full_name,
              payee_type: '',
              field_name: 'payee',
              label: 'Payee',
            },
          ]}
          defaultAmount={valuesAndFunctionToSubmitAfterPayment.values.amount_considered}
        />
      )}
    </>
  );
}

PipManagedPaymentsSummaryDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  involvedPerson: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  managedPayments: PropTypes.array.isRequired,
  onAddManagedPayment: PropTypes.func.isRequired,
  onUpdateManagedPayment: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  newPaymentTitle: PropTypes.string.isRequired,
  editPaymentTitle: PropTypes.string.isRequired,
  documentRecordLabel: PropTypes.string.isRequired,
  originalDocumentRecordLabel: PropTypes.string,
  paymentContactLabel: PropTypes.string.isRequired,
  paymentContactRoles: PropTypes.array.isRequired,
  viewOnly: PropTypes.bool,
  includeService: PropTypes.bool,
  includeMedicalEobNf10Document: PropTypes.bool,
  includeAmountConsidered: PropTypes.bool,
  includeAutomaticPayment: PropTypes.bool,
  includeReason: PropTypes.bool,
  isWcLostWage: PropTypes.bool,
};

function SendPipPaymentEobPhysicalMailContainer({ claim, onUpdate, exposure, involvedPerson, managedPayment }) {
  const { outgoingPhysicalMailMethod } = useOrganization();
  const [showNewPhysicalCommunicationDialog, setShowNewPhysicalCommunicationDialog] = useState(false);
  const providerContact = managedPayment.payment_contact;
  const attorneyContact = involvedPerson.attorney_contact;
  const claimantContact = involvedPerson.contact;

  const wasEobPhysicalMailSent =
    managedPayment.pip_managed_payment_extra.eob_sending_data &&
    managedPayment.pip_managed_payment_extra.eob_sending_data.was_sent;

  const handlePhysicalMailSent = async (res) => {
    try {
      await axios.post(
        `/api/v1/auto_claims/${claim.id}/exposures/${exposure.id}/pip/pip_medical_treatments/${managedPayment.id}/eob_was_sent`,
        { batch_id: res[0].sending_batch_id }
      );
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
    setShowNewPhysicalCommunicationDialog(false);
  };

  const recipientContacts = [];
  // provider contact will get EOB with payment, if such an option is supported
  if (managedPayment.status !== 'Partial Payment' && managedPayment.status !== 'Full Payment') {
    recipientContacts.push(providerContact);
  }

  recipientContacts.push(claimantContact);
  if (attorneyContact) {
    recipientContacts.push(attorneyContact);
  }
  const eobDoc = claim.documents.find((d) => d.id === managedPayment.medical_eob_nf10_document_id);

  let summaryText = '';
  if (
    managedPayment.pip_managed_payment_extra &&
    managedPayment.pip_managed_payment_extra.medlogix_dict &&
    managedPayment.pip_managed_payment_extra.medlogix_dict.Bill
  ) {
    summaryText = `${managedPayment.status} EOB ID ${managedPayment.pip_managed_payment_extra.medlogix_dict.Bill.BillNumber}${managedPayment.pip_managed_payment_extra.medlogix_dict.Bill.BillVersion}`;
  }

  const handlePhysicalEmailClick = () => {
    setShowNewPhysicalCommunicationDialog(true);
    mixpanel.track(MIXPANEL_EVENTS.NEW_PHYSICAL_MAIL_COMMUNICATION_CLICKED, {
      source: MIXPANEL_EVENT_SOURCES.NEW_PHYSICAL_MAIL_COMMUNICATION_CLICKED_FROM_PIP_DASHBOARD,
    });
  };

  return (
    <>
      {recipientContacts.length > 0 && (
        <IconButton onClick={handlePhysicalEmailClick} disabled={wasEobPhysicalMailSent}>
          <MailboxOpenUp style={{ color: wasEobPhysicalMailSent && 'green' }} />
        </IconButton>
      )}
      {showNewPhysicalCommunicationDialog && (
        <DocumentPhysicalMailCommunicationCardContainer
          claim={claim}
          onCancel={() => setShowNewPhysicalCommunicationDialog(false)}
          contact={recipientContacts[0]}
          cc_contacts={recipientContacts.slice(1)}
          onDocumentPhysicalMail={handlePhysicalMailSent}
          overrideInitialValues={{
            attached_documents: [
              { ...eobDoc, attachment_filename: filenamify(eobDoc.document_name_with_ext, { replacement: '' }) },
            ],
            summary: summaryText,
            mail_type: outgoingPhysicalMailMethod === 'lob' ? 'usps_standard' : undefined,
          }}
          exposureIds={[exposure.id]}
        />
      )}
    </>
  );
}

SendPipPaymentEobPhysicalMailContainer.propTypes = {
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  exposure: PropTypes.object.isRequired,
  involvedPerson: PropTypes.object.isRequired,
  managedPayment: PropTypes.object.isRequired,
};

function WorldTripDemoManagedPipPaymentDialog({
  title,
  managedPipPayment,
  onSubmit,
  onClose,
  documentRecordLabel,
  paymentContactLabel,
  paymentContactRoles,
  exposure,
}) {
  const classes = useStyles();
  const { claim } = useClaim();

  if (exposure.damage_assessment_type === 'worldtrip_demo_line') {
    return (
      <WorldTripMedicalBillAdjudicationContainer
        title={title}
        claimId={claim.id}
        exposure={exposure}
        managedPipPayment={managedPipPayment}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    );
  }

  const actionValues = {
    action: managedPipPayment?.status ?? '',
    amount_considered: '',
    reason: '',
  };

  const initialValues = {
    start_date_of_service: '',
    record_document_id: '',
    payment_contact_id: '',
    provider_type: '',
    icd_10: '',
    cpt: '',
    amount_submitted: '',
    amount_considered: '',
    usual_customary_amount: '',
    mid_amount_considered: '',
    ...actionValues,
  };

  return (
    <Formik
      initialValues={managedPipPayment ? { ...initialValues, ...managedPipPayment, ...actionValues } : initialValues}
      validationSchema={Yup.object().shape({
        record_document_id: Yup.number().required('Required'),
        payment_contact_id: Yup.number().required('Required'),
        start_date_of_service: Yup.date().required('Required'),
        action: Yup.string().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { values, isSubmitting, handleSubmit } = formikProps;
        const currAction = values['action'];

        return (
          <CardDialog title={title} isDialog maxWidth="sm" onClose={onClose} fullWidth>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <DatePickerTextFieldFormik
                  id="start_date_of_service"
                  label="Date of Service"
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <DocumentTextFieldFormik id="record_document_id" label={documentRecordLabel} />
              </Grid>
              <Grid item xs={6}>
                <ContactTextFieldFormik
                  id="payment_contact"
                  label={paymentContactLabel}
                  className={classes.textField}
                  acceptedRoles={paymentContactRoles}
                  fixedSearchResults
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormik id="provider_type" label="Provider Type" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormik id="icd_10" label="ICD 10" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormik id="cpt" label="CPT" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="amount_submitted"
                  label="Amount Submitted"
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="usual_customary_amount"
                  label="Usual Customary Amount"
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="mid_amount_considered"
                  label="Considered Amount"
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
              <PipManagedPaymentActionFields includeAmountConsidered amountConsideredLabel="Amount Paid" />
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
                {currAction === 'pay' ? 'Proceed to payment' : 'Set'}
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

WorldTripDemoManagedPipPaymentDialog.propTypes = {
  title: PropTypes.string.isRequired,
  managedPipPayment: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  documentRecordLabel: PropTypes.string.isRequired,
  paymentContactLabel: PropTypes.string.isRequired,
  paymentContactRoles: PropTypes.array.isRequired,
  includeService: PropTypes.bool,
  includeMedicalEobNf10Document: PropTypes.bool,
  includeAmountConsidered: PropTypes.bool,
  isWcLostWage: PropTypes.bool,
  exposure: PropTypes.object,
};

function ManagedPipPaymentDialog({
  title,
  managedPipPayment,
  onSubmit,
  onClose,
  documentRecordLabel,
  paymentContactLabel,
  paymentContactRoles,
  includeService,
  includeMedicalEobNf10Document,
  includeAmountConsidered,
  isWcLostWage,
  isWorldTripDemo,
  exposure,
}) {
  const classes = useStyles();

  if (isWorldTripDemo) {
    return (
      <WorldTripDemoManagedPipPaymentDialog
        title={title}
        managedPipPayment={managedPipPayment}
        onSubmit={onSubmit}
        onClose={onClose}
        documentRecordLabel={documentRecordLabel}
        paymentContactLabel={paymentContactLabel}
        paymentContactRoles={paymentContactRoles}
        exposure={exposure}
      />
    );
  }

  const actionValues = {
    action: '',
    medical_eob_nf10_document_id: '',
    amount_considered: '',
    reason: '',
  };

  const initialValues = {
    service: '',
    record_document_id: '',
    payment_contact_id: '',
    start_date_of_service: '',
    end_date_of_service: '',
    amount_submitted: '',
    ...actionValues,

    // for WC lost wages:
    d5_document_id: '',
    d8_document_id: '',
  };

  return (
    <Formik
      initialValues={managedPipPayment ? { ...initialValues, ...managedPipPayment, ...actionValues } : initialValues}
      validationSchema={Yup.object().shape({
        service: includeService ? Yup.string().required('Required') : undefined,
        record_document_id: Yup.number().required('Required'),
        payment_contact_id: Yup.number().required('Required'),
        start_date_of_service: isWcLostWage ? undefined : Yup.date().required('Required'),
        end_date_of_service: isWcLostWage
          ? undefined
          : Yup.date()
              .required('Required')
              .when('start_date_of_service', {
                is: (val) => !!val,
                then: Yup.date().min(Yup.ref('start_date_of_service'), "Can't be before the start date"),
              }),
        amount_submitted: isWcLostWage ? undefined : Yup.number().required('Required'),
        action: Yup.string().required('Required'),
        medical_eob_nf10_document_id: includeMedicalEobNf10Document
          ? Yup.number().when('action', {
              is: (val) => val && ['pay', 'deny'].includes(val),
              then: Yup.number().required('Required'),
            })
          : undefined,
        amount_considered: includeAmountConsidered
          ? Yup.number().when('action', {
              is: (val) => val && val === 'pay',
              then: Yup.number().required('Required'),
            })
          : undefined,
        reason: !isWcLostWage ? Yup.string().required('Required') : undefined,
        d5_document_id: isWcLostWage ? Yup.number().nullable() : undefined,
        d8_document_id: isWcLostWage ? Yup.number().nullable() : undefined,
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { values, isSubmitting, handleSubmit } = formikProps;
        const currAction = values['action'];

        return (
          <CardDialog title={title} isDialog maxWidth="sm" onClose={onClose} fullWidth>
            <Grid container spacing={1}>
              <PipManagedPaymentInitialFields
                documentRecordLabel={documentRecordLabel}
                paymentContactLabel={paymentContactLabel}
                paymentContactRoles={paymentContactRoles}
                includeService={includeService}
                isWcLostWage={isWcLostWage}
              />
              <PipManagedPaymentActionFields
                includeMedicalEobNf10Document={includeMedicalEobNf10Document}
                includeAmountConsidered={includeAmountConsidered}
                excludeReason={isWcLostWage}
                amountConsideredLabel="Amount Paid"
              />
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting} color="primary">
                {currAction === 'pay' ? 'Proceed to payment' : 'Set'}
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

ManagedPipPaymentDialog.propTypes = {
  title: PropTypes.string.isRequired,
  managedPipPayment: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  documentRecordLabel: PropTypes.string.isRequired,
  paymentContactLabel: PropTypes.string.isRequired,
  paymentContactRoles: PropTypes.array.isRequired,
  includeService: PropTypes.bool,
  includeMedicalEobNf10Document: PropTypes.bool,
  includeAmountConsidered: PropTypes.bool,
  isWcLostWage: PropTypes.bool,
  isWorldTripDemo: PropTypes.object,
  exposure: PropTypes.object,
};

function AutoFillPaymentCell({ claim, exposure, paymentContactLabel, managedPayment, onSubmit, viewOnly }) {
  const [showAutoFilledPayment, setShowAutoFilledPayment] = useState(false);
  const [showAutoFilledInterestPayment, setShowAutoFilledInterestPayment] = useState(false);
  const [isLoadingPdf, setIsLoadingPdf] = useState(true);

  const eobLink =
    managedPayment.medical_eob_nf10_document_id &&
    `/api/v1/claims/${claim.id}/media/${managedPayment.medical_eob_nf10_document_id}#page=2&zoom=85`; // The oeb payments table is on page 2
  const { currencyFormatter } = useCurrencyFormatter();

  let amountPaidComponent = undefined;
  if (managedPayment.amount_paid !== null) {
    amountPaidComponent = <strong>{currencyFormatter.format(managedPayment.amount_paid)}</strong>;
  } else if (managedPayment.pending_payment_amount) {
    amountPaidComponent = (
      <span style={{ color: PENDING_COLOR }}>
        <strong>{currencyFormatter.format(managedPayment.pending_payment_amount)}</strong>
      </span>
    );
  }

  let interestAmountPaidComponent = undefined;
  if (managedPayment.interest_amount_paid !== null) {
    interestAmountPaidComponent = (
      <Typography variant="body2">
        Interest:&nbsp;<strong>{currencyFormatter.format(managedPayment.interest_amount_paid)}</strong>
      </Typography>
    );
  } else if (managedPayment.interest_pending_payment_amount) {
    interestAmountPaidComponent = (
      <Typography variant="body2" style={{ color: PENDING_COLOR }}>
        Interest:&nbsp;<strong>{currencyFormatter.format(managedPayment.interest_pending_payment_amount)}</strong>
      </Typography>
    );
  }

  // No payment made and no payment suggestion
  if (
    !amountPaidComponent &&
    !interestAmountPaidComponent &&
    !managedPayment.pip_managed_payment_extra.payments_auto_fill
  ) {
    return null;
  }

  let paymentsAutoFill = managedPayment.pip_managed_payment_extra.payments_auto_fill || [];
  const paymentAutoFillValues = paymentsAutoFill.find((payment) => payment.name === 'allowance');
  const interestPaymentAutoFillValues = paymentsAutoFill.find((payment) => payment.name === 'interest'); // can be undefined
  const shouldShowInterestPaymentButton =
    !interestAmountPaidComponent && managedPayment.is_interest_payment_required && interestPaymentAutoFillValues;

  const handleSubmit = async (paymentRequest, automaticType) => {
    await onSubmit({
      ...managedPayment,
      action: 'pay_autofilled',
      payment_issued_request_id: paymentRequest.id,
      automatic_type: automaticType,
    });
  };

  if (!['Partial Payment', 'Full Payment'].includes(managedPayment.status)) {
    return null;
  }

  let paymentComponent = undefined;
  if (showAutoFilledPayment) {
    paymentComponent = (
      <PaymentRequestContainer
        claim={claim}
        exposure={exposure}
        payableWithReserve={exposure.indemnity}
        payableType="indemnity"
        cardDialogProps={{
          isDialog: false,
          maxWidth: 'sm',
          fullWidth: true,
        }}
        onUpdate={async (paymentRequest) => await handleSubmit(paymentRequest, paymentAutoFillValues.name)}
        onClose={() => setShowAutoFilledPayment(false)}
        overrideInitialValues={{
          amount: paymentAutoFillValues.amount,
          [`should_include_${paymentContactLabel.toLowerCase()}`]: true,
          payee: [],
          mail_to_contact: claim.contacts.find((contact) => contact.id === paymentAutoFillValues.mail_to_contact_id),
          mail_to_contact_id: paymentAutoFillValues.mail_to_contact_id,
          mail_to_contact_full_name: paymentAutoFillValues.mail_to_contact_full_name,
          attached_documents: paymentAutoFillValues.attached_documents_ids
            ? paymentAutoFillValues.attached_documents_ids.map((docId) => createAttachedDocumentEntry(claim, docId))
            : [],
          note: paymentAutoFillValues.note,
          hcra: paymentAutoFillValues.hcra,
          request_extra: paymentAutoFillValues.request_extra,
          recommendation_extra: {
            recommendation_id: paymentAutoFillValues.recommendation_id,
            recommendation_name: paymentAutoFillValues.recommendation_name,
            recommendation_type: paymentAutoFillValues.recommendation_type,
            recommendation_orig_communication_id: paymentAutoFillValues.recommendation_orig_communication_id,
          },
        }}
        overridePaymentRequestStore={[
          {
            type: 'contact_search',
            payee_type: '',
            contact_id: paymentAutoFillValues.primary_contact_id,
            contact_full_name: paymentAutoFillValues.primary_contact_full_name,
            field_name: 'payee',
            label: 'Payee',
          },
        ]}
      />
    );
  } else if (showAutoFilledInterestPayment) {
    paymentComponent = (
      <PaymentRequestContainer
        claim={claim}
        exposure={exposure}
        payableWithReserve={exposure.expenses}
        payableType="expenses"
        cardDialogProps={{
          isDialog: false,
          maxWidth: 'sm',
          fullWidth: true,
        }}
        onUpdate={async (paymentRequest) => await handleSubmit(paymentRequest, interestPaymentAutoFillValues.name)}
        onClose={() => setShowAutoFilledInterestPayment(false)}
        overrideInitialValues={{
          amount: interestPaymentAutoFillValues.amount,
          vendor_payee_id: interestPaymentAutoFillValues.primary_contact_id,
          vendor_payee: claim.contacts.find(
            (contact) => contact.id === interestPaymentAutoFillValues.primary_contact_id
          ),
          expense_type: 'interest',
          attached_documents: interestPaymentAutoFillValues.attached_documents_ids
            ? interestPaymentAutoFillValues.attached_documents_ids.map((docId) =>
                createAttachedDocumentEntry(claim, docId)
              )
            : [],
          note: interestPaymentAutoFillValues.note,
          request_extra: paymentAutoFillValues.request_extra,
          recommendation_extra: {
            recommendation_id: interestPaymentAutoFillValues.recommendation_id,
            recommendation_name: interestPaymentAutoFillValues.recommendation_name,
            recommendation_type: interestPaymentAutoFillValues.recommendation_type,
            recommendation_orig_communication_id: interestPaymentAutoFillValues.recommendation_orig_communication_id,
          },
        }}
      />
    );
  }

  return (
    <>
      {amountPaidComponent || (
        <Button color="secondary" onClick={() => setShowAutoFilledPayment(true)} disabled={viewOnly} size="small">
          Set&nbsp;Payment
        </Button>
      )}
      {interestAmountPaidComponent ||
        (shouldShowInterestPaymentButton && (
          <div>
            <Button
              color="secondary"
              onClick={() => setShowAutoFilledInterestPayment(true)}
              disabled={viewOnly}
              size="small"
            >
              Pay&nbsp;interest
            </Button>
          </div>
        ))}

      {paymentComponent && (
        <CardDialog
          isDialog
          title="Make payment"
          onClose={() =>
            showAutoFilledPayment ? setShowAutoFilledPayment(false) : setShowAutoFilledInterestPayment(false)
          }
          maxWidth="xl"
          fullWidth
        >
          <Grid container>
            <Grid item xs={4}>
              {paymentComponent}
            </Grid>
            <Grid item xs={8}>
              {isLoadingPdf && <LoadingIndicator />}
              {eobLink && (
                <object
                  onLoad={() => setIsLoadingPdf(false)}
                  data={eobLink}
                  style={{ height: '100%', width: '100%', zIndex: '1' }}
                  type="application/pdf"
                >
                  EOB
                </object>
              )}
            </Grid>
          </Grid>
        </CardDialog>
      )}
    </>
  );
}

AutoFillPaymentCell.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  managedPayment: PropTypes.object.isRequired,
  paymentContactLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

function PipDeathBenefitsContainer({ claim, exposure, onUpdate, totalDeathBenefitsPaid, viewOnly }) {
  const classes = useStyles();
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const { currencyFormatter } = useCurrencyFormatter();

  async function handleDeathBenefitsPaid(paymentRequest) {
    await axios.post(`/api/v1/auto_claims/${exposure.claim_id}/exposures/${exposure.id}/pip/death_benefit`, {
      payment_request_id: paymentRequest.id,
    });
    await onUpdate();
  }

  return (
    <>
      <ShowOnlyTextField
        classes={classes}
        label="Death Benefits"
        doNotRenderInTypography
        showOnlyValueComponent={
          <HoverActionField onAction={() => setShowPaymentDialog(true)} icon={CreditCardIcon} disabled={viewOnly}>
            {currencyFormatter.format(totalDeathBenefitsPaid)}
          </HoverActionField>
        }
      />

      {showPaymentDialog && (
        <PaymentRequestContainer
          claim={claim}
          exposure={exposure}
          payableWithReserve={exposure.indemnity}
          payableType="indemnity"
          cardDialogProps={{
            isDialog: true,
            maxWidth: 'sm',
            fullWidth: true,
          }}
          onClose={() => setShowPaymentDialog(false)}
          onUpdate={handleDeathBenefitsPaid}
        />
      )}
    </>
  );
}

PipDeathBenefitsContainer.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  totalDeathBenefitsPaid: PropTypes.number.isRequired,
  viewOnly: PropTypes.bool,
};

export default PipDashboardContainer;
