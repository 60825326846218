import React from 'react';

import { useInterval } from '~/components/AiChat/components/Message/useInterval';
import type { AiAction } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import { useCms } from '~/components/hooks/useCms';
import useDataFetcher from '~/components/useDataFetcher';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

export interface IActionsContextData {
  actions: AiAction[];
  isLoading: boolean;
  isError: boolean;
  reloadData: () => Promise<void>;
}

const ActionsContext = React.createContext<IActionsContextData>({
  actions: [],
  isLoading: true,
  isError: false,
  reloadData: Promise.resolve,
});

export const ActionsContextProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const { claim } = useClaim();
  const itcEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.ITC_PRESENTATION);
  const {
    isLoading,
    isError,
    data: responseData,
    reloadData,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/actions`, {}, itcEnabled);

  const { actions }: { actions: AiAction[] } = responseData || {};

  const { start: startPolling, stop: stopPolling } = useInterval(reloadData, 5000, true);

  React.useEffect(() => {
    if (!itcEnabled) {
      stopPolling();
      return;
    }
    startPolling();
  }, [startPolling, stopPolling, actions, itcEnabled]);

  return (
    <ActionsContext.Provider value={{ actions, isLoading, isError, reloadData }}>{children}</ActionsContext.Provider>
  );
};

export const useActions = (): IActionsContextData => {
  return React.useContext(ActionsContext);
};
