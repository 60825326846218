import React from 'react';

import { useClaim } from '~/components/ClaimContainer';
import type { DocumentMetadata } from '~/components/communications/types';
import ShowAllCollapsible from '~/components/core/Molecules/Collapsible/ShowAllCollapsible';
import { getInternalCommunicationObjectDescription } from '~/components/InternalCommunication';
import type { NoteContentProps } from '~/components/Notes/NoteContent/types';

import Text from '../../core/TextComponents/Text';

const InternalCommunicationNote: React.FC<NoteContentProps> = ({
  note,
  isExpanded,
  onCollapseClick,
  isCollapsible,
  maxRows,
}) => {
  return isCollapsible ? (
    <ShowAllCollapsible
      visibleLines={maxRows || 1}
      showAsInlineButton
      isOpen={isExpanded}
      onCollapseClick={onCollapseClick}
    >
      <InternalCommunicationNoteInner note={note} />
    </ShowAllCollapsible>
  ) : (
    <InternalCommunicationNoteInner note={note} />
  );
};

const InternalCommunicationNoteInner: React.FC<NoteContentProps> = ({ note }) => {
  const { claim, onClaimUpdate } = useClaim() as {
    claim: { documents: DocumentMetadata[] };
    onClaimUpdate: () => void;
  };
  const internalCommunication = note.internal_communication;
  const objectDescription = getInternalCommunicationObjectDescription(
    internalCommunication,
    claim,
    onClaimUpdate,
    true
  );
  return (
    <>
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
        Title: {internalCommunication.title}
      </Text>
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
        Details:
      </Text>
      <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM} className="whitespace-pre-wrap">
        {internalCommunication.details}
      </Text>
      {objectDescription && (
        <Text className="mt-12" weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
          {objectDescription}
        </Text>
      )}
    </>
  );
};

export default InternalCommunicationNote;
