import React from 'react';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import FileDropZone from '~/components/FileDropZone';
import { reportErrorInProductionOrThrow } from '~/Utils';

export type onUploadFinishFunc = (
  file: File,
  config: { onUploadProgress: (progressEvent: { loaded: number; total: number }) => void }
) => Promise<string> | string;

interface UploadImageDialogProps {
  onClose: () => void;
  onFileUploaded: (url: string) => Promise<void> | void;
  onUploadFinish?: onUploadFinishFunc;
}

interface FormValues {
  file: File | null;
}

const UploadImageDialog: React.FC<UploadImageDialogProps> = ({ onClose, onFileUploaded, onUploadFinish }) => {
  const [uploadPercent, setUploadPercent] = React.useState(0);

  const onUpdatePercent = (progressEvent: { loaded: number; total: number }) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadPercent(percentCompleted);
  };

  const config = {
    onUploadProgress: onUpdatePercent,
  };

  const uploadStoredFile = async (values: FormValues) => {
    const file = values['file'];
    if (!file) {
      reportErrorInProductionOrThrow('No file chosen');
      return;
    }

    if (!onUploadFinish) {
      reportErrorInProductionOrThrow('Missing on upload finish callback');
      return;
    }
    const publicUrl = await onUploadFinish(file, config);
    onFileUploaded(publicUrl);
  };

  return (
    <Formik<FormValues>
      initialValues={{
        file: null,
      }}
      validationSchema={Yup.object().shape({
        file: Yup.mixed().required('Required'),
      })}
      onSubmit={uploadStoredFile}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue, errors, setFieldTouched, touched }) => (
        <CardDialog isDialog title="Upload Image" onClose={onClose} preventClose={isSubmitting}>
          <FileDropZone
            uploadPercent={uploadPercent}
            onFileSelect={(file) => {
              setFieldValue('file', file);
              setFieldTouched('file', true);
            }}
            onCancelFileSelect={() => setFieldValue('file', null)}
            file={values['file']}
            error={getIn(errors, 'file') && getIn(touched, 'file')}
            errorMessage={getIn(errors, 'file')}
            dropZoneOptions={{ accept: 'image/*' }}
            baseStyleOverride={{
              width: '100%',
              height: '100%',
              padding: '25px',
            }}
            gridContainerAlignItems="stretch"
          />

          <div className="mt-20 flex w-full justify-end">
            <CancelButton disabled={isSubmitting} onClick={onClose} />
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
              Save
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
};

export default UploadImageDialog;
