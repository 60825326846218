import React from 'react';

import cn from '~/Utils/cn';

interface RoundedPanelProps {
  variant?:
    | 'outlined'
    | 'contained'
    | 'contained-warn'
    | 'contained-error'
    | 'insight-pink'
    | 'clear'
    | 'contained-blue';
}
export const RoundedPanel: React.FC<RoundedPanelProps> = ({ children, variant = 'contained' }) => {
  return (
    <div
      className={cn('w-full rounded-lg px-20 py-12', {
        'bg-white p-[5px] text-slate-800': variant === 'clear',
        'bg-slate-300 text-slate-700': variant === 'contained',
        'bg-yellow-100 text-slate-700': variant === 'contained-warn',
        'bg-yellow-100 text-status-failed': variant === 'contained-error',
        'text-bg-white border-1 border-solid border-blue-600 bg-blue-600': variant === 'contained-blue',

        'border-1 border-solid border-blue-600 bg-white': variant === 'outlined',
        'border-1 border-solid border-pink-600 bg-white': variant === 'insight-pink',
      })}
    >
      {children}
    </div>
  );
};
