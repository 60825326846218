import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';

import {
  isBoostOrganization,
  isFeatureEnabled,
  isHospitalityUser,
  isMgmOrganization,
  isRwOrganization,
  isZennerOrganization,
} from '../../Utils';
import CardDialog from '../CardDialog';
import ToggleButtonGroup from '../core/Molecules/Fields/ToggleButtonGroup';
import { useCms } from '../hooks/useCms';
import OrganizationSelectFormik from '../OrganizationSelect';

import BoostFnolScreen from './BoostFnolScreen';
import FnolAiScreen from './FnolAiScreen';
import FnolScreen from './FnolScreen';
import MgmFnolScreen from './MgmFnolScreen';
import TravelFnolScreen from './TravelFnolScreen';

import { useStyles } from '../../assets/styles';
import styles from './FnolContainer.module.scss';

const FnolContainerInner = () => {
  const { user, userOrganization, setPageTitle } = useCms();
  const [organization] = useState(user.is_super_organization ? undefined : userOrganization);
  const [fnolInputMethod, setFnolInputMethod] = useState(false);

  useEffect(() => {
    setPageTitle('New Loss', 'FNOL — Five Sigma CMS');
  }, [setPageTitle]);

  if (!organization) {
    return null;
  }

  if (isMgmOrganization(organization) || isRwOrganization(organization) || isHospitalityUser(user)) {
    return <MgmFnolScreen organization={organization} />;
  } else if (isBoostOrganization(organization)) {
    return <BoostFnolScreen organization={organization} />;
  } else if (isZennerOrganization(organization)) {
    return <TravelFnolScreen organization={organization} />;
  } else {
    return (
      <>
        {isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.FNOL_AI) && (
          <div className="flex w-full px-24">
            <ToggleButtonGroup
              exclusive
              options={[
                { key: 'ai-text', value: 'ai-text', label: 'Unstructured: Text' },
                { key: 'ai-audio', value: 'ai-audio', label: 'Unstructured: Audio' },
                { key: 'manual', value: false, label: 'Structured' },
              ]}
              value={fnolInputMethod}
              onChange={(event, value) => value !== null && setFnolInputMethod(value)}
            />
          </div>
        )}
        {fnolInputMethod === 'ai-text' ? (
          <FnolAiScreen inputMethod="text" />
        ) : fnolInputMethod === 'ai-audio' ? (
          <FnolAiScreen inputMethod="audio" />
        ) : (
          <FnolScreen />
        )}
      </>
    );
  }
};

const FnolContainer = () => (
  <div className={styles.fnolContainer}>
    <FnolContainerInner />
  </div>
);

function OrganizationSelectDialog(props) {
  const { onCancel, onSelectOrganization, isDialog } = props;
  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        organization: '',
      }}
      validationSchema={Yup.object().shape({
        organization: Yup.object().required('Required'),
      })}
      onSubmit={(values) => onSelectOrganization(values['organization'])}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;
        return (
          <CardDialog
            isDialog={isDialog}
            title="Select Organization"
            maxWidth="sm"
            width="400px"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <OrganizationSelectFormik id="organization" label="Organization" />
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Select
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

OrganizationSelectDialog.propTypes = {
  onSelectOrganization: PropTypes.func.isRequired,
  isDialog: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
};

export default FnolContainer;
export { OrganizationSelectDialog };
