import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';

import cn from '~/Utils/cn';

import { ShowOnlyTextField } from '../../../../TextFieldFormik';
import SortableTableCell from '../SortableTableCell';
import SortableTableHead from '../SortableTableHead';

import { useStyles } from '../../../../../assets/styles';
import styles from '../sortableTable.module.scss';

const ExtendableInnerTable = ({
  columns,
  selectedColumnId,
  orderBy,
  order,
  selected,
  highlightOnHover,
  getCellProps,
  shouldRenderAsTable,
}) => {
  const classes = useStyles();

  return shouldRenderAsTable ? (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <SortableTableHead
              key={column.id}
              column={column}
              selectedColumnId={selectedColumnId}
              orderBy={orderBy}
              order={order}
              disableSort
              handleRequestSort={() => null}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow selected={selected} hover={highlightOnHover} className={cn({ [styles.selectedRow]: selected })}>
          {columns.map((column) => (
            <SortableTableCell key={column.id} column={column} {...getCellProps(column)} />
          ))}
        </TableRow>
      </TableBody>
    </Table>
  ) : (
    <div className={styles.nonTableWrapper}>
      {columns.map((column) => (
        <ShowOnlyTextField
          key={column.id}
          classes={classes}
          showOnlyValueComponent={
            column.specialCell ? column.specialCell(getCellProps(column).row) : getCellProps(column).row[column.id]
          }
          label={column.label}
        />
      ))}
    </div>
  );
};

ExtendableInnerTable.propTypes = {
  columns: PropTypes.array.isRequired,
  selectedColumnId: PropTypes.string,
  order: PropTypes.oneOf(['asc', 'desc']),
  selected: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  highlightOnHover: PropTypes.bool,
  getCellProps: PropTypes.func.isRequired,
  shouldRenderAsTable: PropTypes.bool,
};

export default ExtendableInnerTable;
