import React from 'react';
import { Fab } from '@mui/material';

import { ClaimChatDialog } from '~/components/AiChat/ClaimChat/ClaimChatDialog';
import { JusterImage } from '~/components/AiChat/components/JusterImage';
import { BetaTag } from '~/components/AiChat/components/Message/BetaTag';
import { ChatProvider } from '~/components/AiChat/hooks/useChat';
import { useClaimChatAuth } from '~/components/AiChat/hooks/useClaimChatAuth';
import { ActionsContextProvider } from '~/components/AiChat/SideBarDialog/Actions/useActions';
import { InsightsContextProvider } from '~/components/AiChat/SideBarDialog/Insights/useInsights';
import { useMinimizeBars } from '~/components/core/MinimizedBar/Context/ToastifyBarContext';

export const AiChat: React.FC = () => {
  const [isAiChatOpen, setIsAiChatOpen] = React.useState(false);
  const { isClaimChatEnabled } = useClaimChatAuth();

  // this is for preventing collision between AiChat icon and MinimizedBar
  const { setIsChatEnabled } = useMinimizeBars();
  setIsChatEnabled(isClaimChatEnabled);

  return (
    <>
      {isAiChatOpen ? (
        <ChatProvider routeSuffix="/chat">
          <InsightsContextProvider>
            <ActionsContextProvider>
              <ClaimChatDialog onClose={() => setIsAiChatOpen(false)} />
            </ActionsContextProvider>
          </InsightsContextProvider>
        </ChatProvider>
      ) : null}

      {isClaimChatEnabled && !isAiChatOpen ? (
        <Fab
          className="z-1000 fixed bottom-32 right-32 h-[72px] w-[72px] transition-opacity hover:bg-slate-400 hover:bg-opacity-50 "
          onClick={() => setIsAiChatOpen(true)}
        >
          <JusterImage width={72} height={72} />
          <BetaTag className="absolute right-[-16px] top-[-5px]" />
        </Fab>
      ) : null}
    </>
  );
};
