import React from 'react';
import { Tab, Tabs } from '@mui/material';

import { ChatEmptyState } from '~/components/AiChat/ChatEmptyState';
import { useChatContext } from '~/components/AiChat/hooks/useChat';
import { Actions } from '~/components/AiChat/SideBarDialog/Actions/Actions';
import { useActions } from '~/components/AiChat/SideBarDialog/Actions/useActions';
import { ChatDialogGreetingBar } from '~/components/AiChat/SideBarDialog/components/ChatDialogGreetingBar';
import { ChatDialogHeaderContainer } from '~/components/AiChat/SideBarDialog/components/ChatDialogHeaderContainer';
import { ChatDialogStatusBar } from '~/components/AiChat/SideBarDialog/components/ChatDialogStatusBar';
import { ChatDialogTitleBar } from '~/components/AiChat/SideBarDialog/components/ChatDialogTitleBar';
import { ChatTitleBar } from '~/components/AiChat/SideBarDialog/components/ChatTitleBar';
import { ClaimSummaryCollapsible } from '~/components/AiChat/SideBarDialog/components/ClaimSummaryCollapsible';
import { SidebarDialogActionButton } from '~/components/AiChat/SideBarDialog/components/SidebarDialogAction';
import { Insights } from '~/components/AiChat/SideBarDialog/Insights/Insights';
import { useInsights } from '~/components/AiChat/SideBarDialog/Insights/useInsights';
import WithConfirm from '~/components/ConfirmModal';
import { CloseIcon, MinimizeIcon, RefreshIcon } from '~/components/icons';
import cn from '~/Utils/cn';

const TABS_INDICATOR_STYLE = 'h-[3px] bg-blue-600 ';
const TAB_STYLES = {
  selected: '[&&]:text-slate-900 font-bold',
  textColorPrimary: 'text-slate-700 font-bold',
  textColorSecondary: 'text-slate-700 font-bold',
};

export const ChatSidebarDialog: React.FC<{
  onClose: () => void;
  dialogTitle?: string;
  chatTitle?: string;
}> = ({ children, onClose, dialogTitle, chatTitle }) => {
  const { chat, restartChat, isChatLoading, isMinimized, handleMinimize, exchanges, itcEnabled } = useChatContext();
  const { insights, references, isLoading: isInsightsLoading } = useInsights();
  const { actions, isLoading: isActionsLoading, reloadData: reloadActions } = useActions();
  const claimSummary = chat?.greeting;
  const [selectedTab, setSelectedTab] = React.useState('actions_tab');
  const isHeaderCollapsed = React.useMemo(
    () =>
      (selectedTab === 'insights_tab' && !!insights?.length) ||
      (selectedTab === 'actions_tab' && !!actions?.length) ||
      ((!itcEnabled || selectedTab === 'chat_tab') && !!exchanges?.length && !isChatLoading),
    [selectedTab, itcEnabled, insights, actions, exchanges, isChatLoading]
  );
  const onTabChange = React.useCallback((event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  }, []);

  return (
    <div
      className={cn('__DIALOG__ z-20000 fixed right-0 top-0 flex h-full w-[600px] flex-col bg-white', {
        hidden: isMinimized,
      })}
      style={{
        boxShadow: '-7px 4px 72.6px 0px rgba(61, 74, 166, 0.23)',
      }}
    >
      <ChatDialogHeaderContainer isCollapsed={isHeaderCollapsed}>
        <ChatDialogTitleBar
          withAiIcon={isHeaderCollapsed}
          dialogTitle={dialogTitle}
          actions={[
            <WithConfirm
              primaryButtonName="restart"
              title="Are you sure?"
              contentText="This will delete all messages and restart the chat"
              shouldCloseOnPrimary={true}
              key="restart"
            >
              <SidebarDialogActionButton onClick={restartChat} tooltipText="Restart chat" icon={RefreshIcon} />
            </WithConfirm>,
            <SidebarDialogActionButton
              onClick={handleMinimize}
              tooltipText="Minimize"
              key="minimize"
              icon={MinimizeIcon}
            />,
            <SidebarDialogActionButton onClick={onClose} tooltipText="Close" key="close" icon={CloseIcon} />,
          ]}
        />
        <ChatDialogGreetingBar isCollapsed={isHeaderCollapsed} />
        <ClaimSummaryCollapsible
          summary={claimSummary}
          isCollapsible={isHeaderCollapsed}
          isOpen={!isHeaderCollapsed}
          isChatLoading={isChatLoading}
          actions={
            isChatLoading
              ? {}
              : {
                  onCopy: () => navigator.clipboard.writeText(claimSummary ?? ''),
                }
          }
        />
      </ChatDialogHeaderContainer>
      <ChatDialogStatusBar />

      {!itcEnabled && (
        <>
          <ChatTitleBar title={chatTitle} onNewChat={restartChat} isChatLoading={isChatLoading} actions={{}} />
          <div className={cn('__CHAT_CONTAINER__', 'h-full overflow-y-auto')}>
            {isChatLoading ? <ChatEmptyState /> : children}
          </div>
        </>
      )}

      {itcEnabled && (
        <>
          <Tabs
            value={selectedTab}
            onChange={onTabChange}
            variant="fullWidth"
            classes={{ indicator: TABS_INDICATOR_STYLE, root: 'shadow-xs shadow-slate-600' }}
          >
            <Tab label="Actions" value="actions_tab" classes={TAB_STYLES} />
            <Tab label="Insights" value="insights_tab" classes={TAB_STYLES} />
            <Tab label="Chat" value="chat_tab" classes={TAB_STYLES} />
          </Tabs>

          {selectedTab === 'chat_tab' && (
            <>
              <ChatTitleBar title={chatTitle} onNewChat={restartChat} isChatLoading={isChatLoading} actions={{}} />
              <div className={cn('__CHAT_CONTAINER__', 'h-full overflow-y-auto')}>
                {isChatLoading ? <ChatEmptyState /> : children}
              </div>
            </>
          )}
          {selectedTab === 'insights_tab' && (
            <Insights insights={insights} references={references} isLoading={isInsightsLoading} />
          )}
          {selectedTab === 'actions_tab' && (
            <Actions actions={actions} isLoading={isActionsLoading} reloadActions={reloadActions} />
          )}
        </>
      )}
    </div>
  );
};
