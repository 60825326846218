import React from 'react';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';

import type { TextFieldProps } from '~/components/core/Molecules/Fields/TextField';
import { reportErrorInProductionOrThrow } from '~/Utils';

export interface DatePickerFieldProps extends Omit<TextFieldProps<string | null>, 'onChange' | 'variant'> {
  onChange: (newValue: PickerDate) => void;
  isDateTimeSelection?: boolean;
  isOnlyTimeSelection?: boolean;
  format?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  containerClassName?: string;
  placeholder?: string;
  onError?: (error: React.ReactNode) => void;
  clearable?: boolean;
  nullIfEmpty?: boolean;
  open?: boolean;
  onClose?: () => void;
  fullWidth?: boolean;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  isDateTimeSelection = false,
  isOnlyTimeSelection = false,
  format,
  value,
  placeholder,
  onChange,
  onBlur,
  error,
  onError,
  helperText,
  containerClassName,
  open,
  onClose,
  disablePast,
  disableFuture,
  clearable,
  fullWidth,
  label,
  className,
  id,
  disabled,
  ...rest
}) => {
  if (Object.keys(rest).length > 0) {
    reportErrorInProductionOrThrow(
      `Additional unimplemented props was passed to DatePickerField component ${Object.keys(rest)}`
    );
  }

  return (
    <div className={containerClassName}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {isDateTimeSelection ? (
          <DateTimePicker
            format={format}
            value={value}
            open={open}
            placeholder={placeholder || ''}
            onClose={onClose}
            onChange={onChange}
            disablePast={disablePast}
            disableFuture={disableFuture}
            clearable={clearable}
            fullWidth={fullWidth}
            label={label}
            className={className}
            id={id}
            disabled={disabled}
          />
        ) : isOnlyTimeSelection ? (
          <KeyboardTimePicker
            format={format}
            value={value ? moment(value, 'HH:mm') : null}
            onChange={onChange} // date is a moment object
            onBlur={onBlur}
            error={error}
            onError={onError}
            helperText={helperText}
            InputProps={{
              margin: 'dense', // to match other TextField components
            }}
            clearable={clearable}
            fullWidth={fullWidth}
            label={label}
            className={className}
            id={id}
            disabled={disabled}
          />
        ) : (
          <KeyboardDatePicker
            format={format ? format : moment.localeData().longDateFormat('L')}
            value={value ? moment(value, 'YYYY-MM-DD') : null}
            placeholder={placeholder || ''}
            onChange={onChange}
            animateYearScrolling={false}
            onBlur={onBlur}
            error={error}
            onError={onError}
            helperText={helperText}
            InputProps={{
              margin: 'dense', // to match other TextField components
            }}
            clearable={clearable}
            fullWidth={fullWidth}
            label={label}
            className={className}
            id={id}
            disabled={disabled}
          />
        )}
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePickerField;
export type PickerDate = MaterialUiPickersDate | null;
