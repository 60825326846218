import React from 'react';
import type { DropzoneInputProps, DropzoneRef, DropzoneRootProps } from 'react-dropzone';

import CancelButton from '~/components/core/Buttons/CancelButton';
import Text from '~/components/core/TextComponents/Text';
import UploadIcon from '~/components/icons/UploadIcon';
import cn from '~/Utils/cn';

const DRAGGABLE_ACTIVE_CLASS = 'border-1 border-solid border-green-700 bg-slate-400';

export interface ChooseFilesSectionProps {
  dropzoneRef: React.RefObject<DropzoneRef>;
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
  isDragActive?: boolean;
  disabled: boolean;
}

const disableDropZoneClickAndKeydownBehavior = () =>
  ({
    onClick: (event) => event.stopPropagation(),
    onKeyDown: (event) => {
      if (event.keyCode === 32 || event.keyCode === 13) {
        event.stopPropagation();
      }
    },
  } as DropzoneRootProps);

const ChooseFilesSection: React.FC<ChooseFilesSectionProps> = ({
  dropzoneRef,
  getRootProps,
  getInputProps,
  isDragActive = false,
  disabled,
}) => {
  return (
    <div
      {...getRootProps(disableDropZoneClickAndKeydownBehavior())}
      className={cn('w-full rounded-lg border-2 border-dashed border-slate-600 p-24', {
        [DRAGGABLE_ACTIVE_CLASS]: isDragActive,
      })}
    >
      <div className="flex justify-center p-4">
        <UploadIcon size={16} />
      </div>
      <div className="pt-8">
        <div className="flex justify-center p-4">
          <Text colorVariant={Text.COLOR_VARIANTS.SECONDARY} weight={Text.WEIGHTS.REGULAR}>
            Drag & Drop your file here
          </Text>
        </div>
        <div className="flex justify-center p-4">
          <Text colorVariant={Text.COLOR_VARIANTS.SECONDARY} weight={Text.WEIGHTS.REGULAR}>
            or
          </Text>
        </div>
      </div>
      <div className="flex justify-center p-4 pb-12">
        <input {...getInputProps()} />
        <CancelButton
          onClick={() => dropzoneRef?.current?.open()}
          content="BROWSE"
          disabled={disabled}
          withMarginRight={false}
        />
      </div>
    </div>
  );
};

export default ChooseFilesSection;
