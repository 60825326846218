import React from 'react';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import type { DocumentMetadata } from '~/components/communications/types';
import type { Claim } from '~/components/GenericTemplates/types';
import { useCms } from '~/components/hooks/useCms';
import { getLocalDateToday } from '~/DateTimeUtils';
import { reportAxiosError, reportGenericException } from '~/Utils';

import { DOCUMENT_NAME_MAX_SIZE } from '../DocumentCard';

import UploadDocumentInner from './UploadDocumentsInner/UploadDocumentsInner';
import type { FileData, PartialValuesFormik } from './types';

const uploadStoredFile = async ({ urlPrefix, file }: { urlPrefix: string; file: File }) => {
  const { data } = await axios.post(`${urlPrefix}/upload_url`, {
    file_size: file.size,
    file_type: file.type,
    file_name: file.name,
  });

  const uploadUrl = data.upload_url;
  const storageFileName = data.storage_filename;

  await axios.put(uploadUrl, file);
  return storageFileName;
};

const getUploadDocumentInitialValues = (claim?: Claim, exposure_ids?: number[], allowNullClaimId = false) => ({
  claim_id: allowNullClaimId && !claim ? undefined : claim?.id,
  exposure_ids,
  type: '',
  document_name: '',
  document_date: getLocalDateToday(),
  document_date_received: getLocalDateToday(),
  summary: '',
  file: undefined,
  last_modified: getLocalDateToday(),
  files: [],
});

const documentFieldsValidation = (isClaimIdRequired = true) => ({
  claim_id: isClaimIdRequired ? Yup.number().required() : Yup.number(),
  document_name: Yup.string().nullable().max(DOCUMENT_NAME_MAX_SIZE),
  document_date: Yup.date().required('Required'),
  document_date_received: Yup.date()
    .nullable()
    .when('document_date', {
      is: (val: string) => !!val,
      then: Yup.date().nullable().min(Yup.ref('document_date'), "Can't be before document creation date"),
    }),
  exposure_ids: Yup.array().required('Required').min(1, 'Required'),
  type: Yup.string().required('Required'),
  files: Yup.array()
    .required('Required')
    .min(1, 'Required')
    .test('no-failed-uploads', 'Please remove all failed upload documents', (files) => {
      return files ? !files.some((file) => file.isError === true) : true;
    }),
});

interface UploadDocumentProps {
  claim?: Claim;
  allowedDocumentTypes?: string[];
  onCancel: () => void;
  onSubmitDocument: (document: DocumentMetadata) => void;
  showFileBoxOnly?: boolean;
  initialValues?: {
    exposure_ids?: number[];
    type?: string;
  };
  alternativeUploadUrl?: string;
  uploadOutsideOfClaim?: boolean;
}

const UploadDocument: React.FC<UploadDocumentProps> = ({
  claim,
  allowedDocumentTypes,
  onCancel,
  onSubmitDocument,
  showFileBoxOnly,
  initialValues,
  alternativeUploadUrl,
  uploadOutsideOfClaim,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = useCms();
  const urlPrefix = alternativeUploadUrl ? alternativeUploadUrl : `/api/v1/claims/${claim?.id}/documents`;

  const handleSubmitUploadedFiles = async (
    values: PartialValuesFormik,
    {
      setSubmitting,
      setFieldValue,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
      setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
    }
  ) => {
    setSubmitting(true);
    const getDocumentName = (file: FileData, i: number) => {
      if (values['document_name']) {
        return (values?.files?.length || 0) === 1 ? values['document_name'] : `${values['document_name']} (${i + 1})`;
      }
      return file?.name;
    };

    const documents = [];

    for (let i = 0; i < (values?.files?.length ?? 0); i++) {
      try {
        const fileData = values?.files?.[i];
        const file = fileData?.file;

        const storedFileId = fileData?.stored_file_id;
        const storageFileName = fileData?.storage_file_name || (await uploadStoredFile({ urlPrefix, file }));
        const payload = {
          exposure_ids: values['exposure_ids'],
          last_modified: values['last_modified'],
          type: values['type'],
          summary: values['summary'],
          file_size: file?.size,
          document_name: getDocumentName(fileData, i),
          document_date: values['document_date'],
          document_date_received: values['document_date_received'],
          stored_file_id: storedFileId,
          storage_filename: storageFileName,
          file_type: file?.type,
          file_name: file?.name,
        };

        documents.push(payload);
      } catch (error) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError?.response?.data?.message?.replace('Notify User:', '');

        setFieldValue(`files[${i}].isError`, true);
        setFieldValue(`files[${i}].errorMessage`, errorMessage);
        reportGenericException(error);
      }
    }

    try {
      const isStoredFiles = documents?.every((document) => document?.stored_file_id);
      const { data } = await axios.post(
        `${urlPrefix}/${
          isStoredFiles ? 'bulk_update_document_stored_files' : 'bulk_documents_upload_finished_callback'
        }`,
        {
          documents,
        }
      );

      // NGTPA-17165 TODO: change onSubmitDocument to receive multiple documents ids
      onSubmitDocument(data?.documents[0]);
      onCancel();
    } catch (error) {
      await reportAxiosError(error);
    }
    setSubmitting(false);
  };

  const relatedExposure = !uploadOutsideOfClaim
    ? claim?.exposures?.find((exposure) => exposure?.handling_adjuster_id === user.id)
    : null;

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...getUploadDocumentInitialValues(
          uploadOutsideOfClaim ? undefined : claim,
          [relatedExposure ? relatedExposure.id : 0],
          !!uploadOutsideOfClaim
        ),
      }}
      validationSchema={Yup.object().shape(documentFieldsValidation(!uploadOutsideOfClaim))}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        await handleSubmitUploadedFiles(values, { setSubmitting, setFieldValue });
      }}
      enableReinitialize
    >
      <UploadDocumentInner
        claim={claim}
        onCancel={onCancel}
        urlPrefix={urlPrefix}
        allowedDocumentTypes={allowedDocumentTypes}
        showFileBoxOnly={showFileBoxOnly}
      />
    </Formik>
  );
};

export default UploadDocument;
