import { isNil } from 'lodash';

export const getOverrideInitialValues = ({ paymentInitialValues, payableType }) => {
  const overrideInitialValues = {};
  if (paymentInitialValues) {
    if (!isNil(paymentInitialValues.amount)) {
      overrideInitialValues.amount = paymentInitialValues.amount;
    }

    if (!isNil(paymentInitialValues.invoice_number) && payableType === 'expenses') {
      overrideInitialValues.invoice_number = paymentInitialValues.invoice_number;
    }

    if (!isNil(paymentInitialValues.note)) {
      overrideInitialValues.note = paymentInitialValues.note;
    }
  }
  return overrideInitialValues;
};
