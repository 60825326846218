import React from 'react';
import PropTypes from 'prop-types';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import { useCms } from '~/components/hooks/useCms';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import TextFieldFormik from '../../../../TextFieldFormik';
import OrganizationAdjusterTextFieldFormik from '../../../OrganizationAdjusterTextFieldFormik';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { isOrgHierarchyWorkflowsFeatureEnabled } from '../../../Users/OrganizationUnits/Utils';
import { AdjusterLicenses } from '../Licenses/AdjusterLicenses';
import { isLicensesEnabled } from '../utils';

import { useStyles } from '../../../../../assets/styles';

export const AdjusterFragment = (props) => {
  const { values, user, integrationsExtraDetailsFields } = props;
  const { organization: sysConfigOrganization } = useSysconfig();
  const { user: currentUser } = useCms();
  const { shouldDisableAdjusterModifyingSelfPaymentLimits } = usePaymentsConfiguration();
  const isOrgHierarchyEnabled = isOrgHierarchyWorkflowsFeatureEnabled({ organization: sysConfigOrganization });
  const classes = useStyles();

  const disableLimits = shouldDisableAdjusterModifyingSelfPaymentLimits && user.id === currentUser.id;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography display="block" variant="subtitle2" style={{ paddingTop: '8px', fontWeight: 'bold' }}>
          Personal Details
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <TextFieldFormik id="first_name" label="First Name" fullWidth className={classes.textField} showOnly />
      </Grid>
      <Grid item xs={5}>
        <TextFieldFormik id="last_name" label="Last Name" fullWidth className={classes.textField} showOnly />
      </Grid>

      <Grid item xs={12}>
        <Typography display="block" variant="subtitle2" style={{ paddingTop: '8px', fontWeight: 'bold' }}>
          Organization Details
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <TextFieldFormik id="title" label="Title" fullWidth className={classes.textField} />
      </Grid>
      <Grid item xs={12} style={{ marginLeft: '-10px', marginBottom: '-18px' }}>
        <SwitchFormik
          className={classes.marginedFormsSwitch}
          id="is_org_level_supervisor"
          label="Is adjuster organization-level supervisor"
          disabled={user.role.role_type !== 'claim_rep'}
        />
      </Grid>
      {sysConfigOrganization.are_payment_limits_split ? (
        <>
          <Grid item xs={5}>
            <TextFieldFormik
              id="indemnity_reserves_limit"
              label="Indemnity Reserves Limit"
              type="number"
              className={classes.textField}
              fullWidth
              disabled={disableLimits}
            />
          </Grid>
          <Grid item xs={5}>
            <TextFieldFormik
              id="indemnity_payments_limit"
              label="Indemnity Payments Limit"
              type="number"
              className={classes.textField}
              fullWidth
              disabled={disableLimits}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <TextFieldFormik
              id="expenses_reserves_limit"
              label="Expenses Reserves limit"
              type="number"
              className={classes.textField}
              fullWidth
              disabled={disableLimits}
            />
          </Grid>
          <Grid item xs={5}>
            <TextFieldFormik
              id="expenses_payments_limit"
              label="Expenses Payments Limit"
              type="number"
              className={classes.textField}
              fullWidth
              disabled={disableLimits}
            />
          </Grid>
          <Grid item xs={2} />
        </>
      ) : (
        <>
          <Grid item xs={5}>
            <TextFieldFormik
              id="reserves_limit"
              label="Reserves Limit"
              type="number"
              className={classes.textField}
              fullWidth
              disabled={disableLimits}
            />
          </Grid>
          <Grid item xs={5}>
            <TextFieldFormik
              id="payments_limit"
              label="Payments Limit"
              type="number"
              className={classes.textField}
              fullWidth
              disabled={disableLimits}
            />
          </Grid>
          <Grid item xs={2} />
        </>
      )}
      {!isOrgHierarchyEnabled && (
        <Grid item xs={5}>
          <OrganizationAdjusterTextFieldFormik
            id="supervisor_id"
            label="Supervisor"
            organizationId={values['organization_id']}
            fullWidth
            allowNone
            className={classes.textField}
          />
        </Grid>
      )}
      <Grid item xs={12} style={{ marginLeft: '-10px' }}>
        <SwitchFormik
          className={classes.marginedFormsSwitch}
          id="extra_settings.email_notification_assignment"
          label="Notify on new assignments via email"
        />
      </Grid>
      {isLicensesEnabled(sysConfigOrganization) ? <AdjusterLicenses /> : null}

      {integrationsExtraDetailsFields && (
        <>
          <Grid item xs={10}>
            <Grid container spacing={3}>
              {integrationsExtraDetailsFields
                .filter(({ isEnabled }) => isEnabled)
                .map(({ integrationName, fieldName, label }) => (
                  <>
                    <Grid item xs={6} key={fieldName}>
                      <TextFieldFormik
                        id={`extra_integration_details.${integrationName}.${fieldName}`}
                        label={label}
                        className={classes.textField}
                        fullWidth
                      />
                    </Grid>
                  </>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={2} />
        </>
      )}
    </Grid>
  );
};

AdjusterFragment.propTypes = {
  values: PropTypes.object.isRequired, // comes from Formik
  user: PropTypes.object.isRequired,
  integrationsExtraDetailsFields: PropTypes.array,
};
