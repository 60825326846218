import React from 'react';
import { noop } from 'lodash';

import SelectedFileStatusBar from '~/components/Documents/UploadDocumentsCard/SelectedFileStatusBar/SelectedFileStatusBar';

import type { FileData } from '../types';

export interface SelectedFilesListProps {
  filesData: FileData[];
  onDelete?: (index: number) => void;
}

const SelectedFilesList: React.FC<SelectedFilesListProps> = ({ filesData = [], onDelete = noop }) => {
  return (
    <div>
      {filesData?.map(({ file, isLoading = false, percent = 0, isError = false, errorMessage = undefined }, index) => (
        <SelectedFileStatusBar
          key={index}
          fileName={file?.name}
          isLoading={isLoading}
          isError={isError}
          errorMessage={errorMessage}
          percent={percent}
          onDelete={() => onDelete(index)}
          disableDelete={filesData?.some((file) => file?.isLoading)}
        />
      ))}
    </div>
  );
};

export default SelectedFilesList;
