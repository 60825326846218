import React from 'react';
import { noop } from 'lodash';

import ProgressBar from '~/components/core/Atomic/ProgressBar/ProgressBar';
import AttachmentIcon from '~/components/icons/AttachmentIcon';
import TrashIcon from '~/components/icons/TrashIcon';
import InlineIconButton from '~/components/InlineIconButton';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';
import cn from '~/Utils/cn';

export interface SelectedFileStatusBarProps {
  fileName: string;
  isLoading: boolean;
  percent?: number;
  onDelete?: () => void;
  disableDelete?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

const SelectedFileStatusBar: React.FC<SelectedFileStatusBarProps> = ({
  fileName,
  isLoading = false,
  isError = false,
  errorMessage,
  percent = 0,
  onDelete = noop,
  disableDelete = false,
}) => {
  return (
    <div className={cn('flex w-full items-center justify-between bg-slate-200 p-4', { ['bg-opacity-50']: isLoading })}>
      <div className={cn('flex items-center p-4', { ['opacity-50']: isLoading })}>
        <AttachmentIcon />
        <div className="ml-8">
          <OverflowTextWithToolTip maxWidth="150px">{fileName}</OverflowTextWithToolTip>
        </div>
        {isError && <div className={cn('ml-8 text-status-failed')}>{`Upload Failed: ${errorMessage}`}</div>}
      </div>
      {isLoading ? (
        <span className="w-1/3">
          <ProgressBar percent={percent} />
        </span>
      ) : (
        <InlineIconButton
          icon={TrashIcon}
          tooltipTitle="Remove"
          iconColor="currentColor"
          className={cn('mt-2 cursor-pointer hover:text-teal-700', { ['hover:text-status-failed']: isError })}
          onClick={onDelete}
          wrapWithSpan
          defaultColor={undefined}
          disabled={disableDelete}
          iconStyle={undefined}
          useIconButton={undefined}
          ignorePermissions={undefined}
          ariaLabel={undefined}
        />
      )}
    </div>
  );
};

export default SelectedFileStatusBar;
