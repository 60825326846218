import React from 'react';

import Button from '~/components/core/Atomic/Buttons/Button';

interface ExpandCollapseAllButtonsProps {
  setForceExpandCollapse: (shouldExpandAll: boolean) => void;
}

const ExpandCollapseAllButtons: React.FC<ExpandCollapseAllButtonsProps> = ({ setForceExpandCollapse }) => {
  return (
    <div className="flex gap-4">
      <Button color="primary" onClick={() => setForceExpandCollapse(true)}>
        Expand All
      </Button>
      <Button color="primary" onClick={() => setForceExpandCollapse(false)}>
        Collapse All
      </Button>
    </div>
  );
};

export default ExpandCollapseAllButtons;
