import React from 'react';

import SvgIcon from '~/components/core/SvgIcon';

const BulbIcon = (props) => (
  <SvgIcon viewBox="0 0 40 40" {...props}>
    <path d="M22.7 31.2h-5.5a1 1 0 0 1-.9-1V28.6c-.4-1.2-1.2-2.1-2-3a23 23 0 0 1-.8-1 8.2 8.2 0 1 1 12.9 0l-.6.9c-.8 1-1.8 2-2 3.1l-.1 1.1v.7c0 .5-.4.9-1 .9zm-4.6-1.9h3.7c0-.7.1-1 .2-1.2.4-1.6 1.4-2.8 2.4-3.9l.6-.8a6.4 6.4 0 1 0-10 0l.7.9c.8 1 1.9 2.1 2.3 3.7l.1 1.3zM22.7 33.6h-5.5a1 1 0 0 1 0-1.8h5.5c.5 0 1 .4 1 .9s-.4.9-1 .9zM21.4 36h-2.8a1 1 0 1 1 0-1.8h2.8a1 1 0 0 1 0 1.8zM34.6 20.4h-3.7a1 1 0 1 1 0-1.8h3.7a1 1 0 1 1 0 1.8zM9 20.4H5.5a1 1 0 1 1 0-1.8h3.7a1 1 0 1 1 0 1.8zM20 9.5a1 1 0 0 1-1-1V5a1 1 0 0 1 2 0v3.7c0 .5-.5.9-1 .9zM30.3 30.7a1 1 0 0 1-.6-.2L27 27.9a1 1 0 0 1 1.3-1.3l2.6 2.6a1 1 0 0 1-.7 1.5zM12.3 12.7a1 1 0 0 1-.7-.3L9 9.8a1 1 0 0 1 1.3-1.3l2.6 2.6a1 1 0 0 1-.6 1.6zM9.7 30.7a1 1 0 0 1-.6-1.6l2.5-2.5a1 1 0 0 1 1.3 1.3l-2.6 2.6a1 1 0 0 1-.6.2zM27.7 12.7a1 1 0 0 1-.6-1.6l2.6-2.6A1 1 0 1 1 31 9.8l-2.6 2.6a1 1 0 0 1-.7.3z" />
  </SvgIcon>
);

export default BulbIcon;
