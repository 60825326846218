import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import axios from 'axios';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import CreateEditNoteDialog from '~/components/Notes/CreateEditNoteDialog';
import { serverDateTimeToLocal, serverDateToLocal } from '~/DateTimeUtils';
import { COMMUNICATION_CHANNEL_DICT, NOTE_SUBJECT } from '~/Types';
import { getExposuresLabels, reportAxiosError } from '~/Utils';
import { isClaimWriteDisabled } from '~/Utils/ClaimUtils';

import CommunicationLink from './communications/CommunicationLink';
import DocumentLink from './Documents/DocumentLink';
import PhotosLink from './Gallery/PhotosLink';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from './core';
import InlineIconButton from './InlineIconButton';
import { getInternalCommunicationObjectDescription } from './InternalCommunication';
import LoadingIndicator from './LoadingIndicator';
import useOrganization from './OrganizationContext';
import useDataFetcher from './useDataFetcher';

const spacing = 1;

function ConfidentialityButton({ isConfidential, onChangeConfidentiality, disabled }) {
  const { user } = useCms();
  const classes = useStyles();
  const { claim } = useClaim();

  const tooltipPrefix = isConfidential ? 'Note is confidential. ' : '';
  return (
    <InlineIconButton
      useIconButton
      icon={isConfidential ? LockIcon : LockOpenIcon}
      tooltipTitle={tooltipPrefix + 'Confidential notes will not appear in claim export'}
      defaultColor={isConfidential ? 'secondary' : undefined}
      onClick={onChangeConfidentiality}
      className={classes.leftButtonIcon}
      disabled={disabled || isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
    />
  );
}
ConfidentialityButton.propTypes = {
  isConfidential: PropTypes.bool.isRequired,
  onChangeConfidentiality: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

function DocumentNote({ document }) {
  const { documentTypesDict } = useOrganization();

  return (
    <>
      {document.is_removed && (
        <>
          <Typography style={{ color: 'red' }}>Document was removed from claim</Typography>
          <div>{document.removed_reason}</div>
          <div>
            <br />
          </div>
        </>
      )}
      <DocumentLink text={`View document #${document.claim_internal_id}`} document={document} />
      <div>
        Name: <em>{document.document_name}</em>, Type:{' '}
        <em>{documentTypesDict?.[document.type]?.desc || document.type}</em>
      </div>
      <div>
        Summary: <em>{document.summary}</em>
      </div>
    </>
  );
}

DocumentNote.propTypes = {
  document: PropTypes.object.isRequired,
};

function NoteMiniCard({ note, onNoteUpdated, viewOnly }) {
  const classes = useStyles();
  const { claim, onClaimUpdate } = useClaim();

  // if type is unknown, use the regular note type
  const noteSubject = NOTE_SUBJECT[note.subject] ? NOTE_SUBJECT[note.subject] : NOTE_SUBJECT['note'];
  const exposureLabels = getExposuresLabels(claim);

  const handleChangeConfidentiality = async () => {
    if (viewOnly) return;
    try {
      await axios.post(`/api/v1/claims/${claim.id}/notes/${note.id}/confidentiality`, {
        is_confidential: !note.is_confidential,
      });
      await onNoteUpdated();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  function noteToText(claim, onClaimUpdate, note) {
    if (note.type === 'communication_note') {
      return (
        <>
          {note.is_detached && (
            <>
              <Typography style={{ color: 'red' }}>Communication was removed from claim</Typography>
              <div>
                <br />
              </div>
            </>
          )}
          <CommunicationLink text="View communication" communication={note.communication} onUpdate={onClaimUpdate} />
          {['email', 'sms'].includes(note.communication.channel) &&
            note.communication.is_delivery_issue_error_exists && (
              <Typography variant="body2" style={{ color: 'red' }}>
                {COMMUNICATION_CHANNEL_DICT[note.communication.channel]} delivery failed
              </Typography>
            )}
          <div>
            Summary: <em>{note.communication.summary}</em>
          </div>
        </>
      );
    } else if (note.type === 'document_note') {
      return <DocumentNote document={note.document} />;
    } else if (note.type === 'multiple_documents_note') {
      return note.documents.map((document, i) => <DocumentNote document={document} key={i} />);
    } else if (note.type === 'photos_note') {
      return (
        <PhotosLink
          photos={claim.documents.filter((doc) => note.document_ids.includes(doc.id))}
          text="View media files"
          galleryDialogTitle="Media Uploaded"
        />
      );
    } else if (note.type === 'internal_communication_note') {
      const internalCommunication = note.internal_communication;
      const objectDescription = getInternalCommunicationObjectDescription(internalCommunication, claim, onClaimUpdate);
      return (
        <>
          {objectDescription && <span>Regarding {objectDescription}</span>}
          <div>
            Title: <em>{internalCommunication.title}</em>
          </div>
          <div>Details:</div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{internalCommunication.details}</div>
        </>
      );
    }

    return (
      <Typography display="block" style={{ whiteSpace: 'pre-wrap' }}>
        {note.note_text}
      </Typography>
    );
  }

  return (
    <CardDialog noCardTitle outlinedCard>
      <Grid container spacing={spacing}>
        <Grid item xs={12}>
          <div className={classes.container} style={{ alignItems: 'center' }}>
            <Icon className={classes.leftButtonIcon}>{noteSubject.icon}</Icon>
            <Typography variant="caption">{noteSubject.display}</Typography>
            <div className={classes.chips}>
              {exposureLabels
                .filter((exposure) => note.exposure_ids.includes(exposure.id))
                .map((exposure) => (
                  <Chip
                    size="small"
                    color="primary"
                    key={exposure.id}
                    label={exposure.label}
                    className={classes.chip}
                  />
                ))}
            </div>
            <span className={classes.spacer} />
            {/* { note.is_automatic && <Icon className={classes.leftButtonDialog}>{'settings'}</Icon> } */}
            <RestrictedPermissions action={PERMISSION_ACTIONS.NOTE} verb={PERMISSION_VERBS.FULL}>
              <ConfidentialityButton
                isConfidential={note.is_confidential}
                onChangeConfidentiality={handleChangeConfidentiality}
                disabled={viewOnly}
              />
            </RestrictedPermissions>
            <Typography display="block" variant="caption" className={classes.leftButtonDialog}>
              {note?.document?.document_extra?.created_by_ai ? 'Clive' : note.creator}
            </Typography>
            <Tooltip title={serverDateTimeToLocal(note.creation_date)}>
              <div>
                <Typography display="block" variant="caption">
                  {serverDateToLocal(note.creation_date)}
                </Typography>
              </div>
            </Tooltip>
          </div>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={6}>
          <Typography display="block" variant="h6">{`NOTE-${note.claim_internal_id} - ${note.title}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          {noteToText(claim, onClaimUpdate, note)}
        </Grid>
      </Grid>
    </CardDialog>
  );
}

NoteMiniCard.propTypes = {
  note: PropTypes.object.isRequired,
  onNoteUpdated: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

function ExposureNotesCard({ claim, exposure, onUpdate }) {
  const classes = useStyles();
  const { add } = useMinimizedDialogs();
  const {
    isLoading,
    isError,
    data: notes,
    reloadData,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/notes`, { params: { exposure_ids: [exposure.id] } });

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  const handleSubmit = async () => {
    await onUpdate();
    await reloadData();
  };

  const openCreateNoteDialog = () => {
    const { handleCloseDialog } = add({
      barHeader: 'New Exposure Note',
      type: 'NOTE',
      dialogComponent: CreateEditNoteDialog,
      dialogProps: {
        claimId: claim.id,
        onClose: () => handleCloseDialog(),
        onSubmitNote: async () => {
          await handleSubmit();
          handleCloseDialog();
        },
        defaultExposureIds: [exposure.id],
      },
    });
  };

  return (
    <>
      <CardDialog
        title="Exposure Notes"
        action={
          <Button color="primary" onClick={openCreateNoteDialog}>
            <AddIcon className={classes.leftButtonIcon} />
            Add New Note
          </Button>
        }
      >
        <div style={{ maxHeight: '35vh', overflowY: 'scroll' }}>
          {notes
            .sort((note1, note2) => (note1.id < note2.id ? -1 : 1))
            .reverse()
            .map((note) => (
              <div className={classes.cardDivRow} key={note.id}>
                <NoteMiniCard note={note} onNoteUpdated={reloadData} />
              </div>
            ))}
        </div>
      </CardDialog>
    </>
  );
}

ExposureNotesCard.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export { ConfidentialityButton, ExposureNotesCard, NoteMiniCard };
