import React, { useState } from 'react';

import CardDialog from '~/components/CardDialog';
import type { DocumentMetadata } from '~/components/communications/types';
import Button from '~/components/core/Atomic/Buttons/Button';
import { DocumentsTable } from '~/components/Documents/DocumentsContainer';
import { DocumentIcon } from '~/components/icons';

interface DocumentsButtonLinkProp {
  documents: DocumentMetadata[];
}
const DocumentsButtonLink: React.FC<DocumentsButtonLinkProp> = ({ documents }) => {
  const [showDocumentsTable, setShowDocumentsTable] = useState(false);

  return (
    <>
      <Button
        className="flex max-w-max cursor-pointer items-center rounded border border-solid border-slate-500 bg-slate-100 p-12 normal-case underline hover:border-teal-700	hover:underline"
        variant="outlined"
        onClick={() => setShowDocumentsTable(true)}
        startIcon={<DocumentIcon className="stroke-teal-700" size={16} />}
        classes={{ label: 'font-normal' }}
      >
        Documents Details
      </Button>

      {showDocumentsTable ? (
        <CardDialog
          isDialog
          title="Uploaded documents"
          onClose={() => setShowDocumentsTable(false)}
          fullWidth
          maxWidth="lg"
        >
          <DocumentsTable documents={documents} />
        </CardDialog>
      ) : null}
    </>
  );
};

export default DocumentsButtonLink;
