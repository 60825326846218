import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Tooltip } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { FileDocument } from 'mdi-material-ui';

import { useInterval } from '~/components/AiChat/components/Message/useInterval';
import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';
import { Sparkle } from '~/components/icons';

import { getLocalDateToday, serverDateTimeToLocalDate } from '../../DateTimeUtils';
import { isUserReadOnly } from '../../UserUtils';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { UploadDocumentFromStoredFileDialog } from '../Documents/DocumentCard';
import { extractDisplayNameAndExtensionFromFileName, isDocumentAPhoto } from '../Documents/DocumentUtils';
import { GalleryContainerInner } from '../Gallery/GalleryScreen';
import { useCms } from '../hooks/useCms';
import InlineIconButton from '../InlineIconButton';
import useOrganization from '../OrganizationContext';
import PlainTable from '../PlainTable';

import UploadAndPayInvoiceContainer from './UploadAndPayInvoiceDialog';

import { useStyles } from '../../assets/styles';

const AI_EMAIL_ATTACHMENT_PROCESSING_POLLING_INTERVAL = 5000;

function CommunicationAttachmentTable(props) {
  const { communication, communicationType, onUpdate, maxHeight, getAttachmentFileUrl } = props;
  const classes = useStyles();
  const { user } = useCms();
  const attachments = communication.attachments.filter((attachment) => !attachment.is_blocked);
  const attachmentsBlocked = communication.attachments.filter((attachment) => attachment.is_blocked);
  const [attachmentToUploadId, setAttachmentToUploadId] = useState(undefined);
  const [showUploadPhotos, setShowUploadPhotos] = useState(false);

  const isAttachedToClaim = !!communication.claim_id;
  const shouldAllowUploadAttachments =
    isAttachedToClaim && (communicationType === 'video' || communication.direction !== 'Outgoing');
  const invoiceRecommendation =
    !!communication.automation_recommendations &&
    communication.automation_recommendations.find((recommendation) => recommendation.type === 'invoice_upload_and_pay');
  const recommendationAttachment = invoiceRecommendation
    ? communication.attachments.find((attachment) => attachment.id === invoiceRecommendation.attachment_id)
    : undefined;
  const { documentTypesDict } = useOrganization();

  const stopWhenWindowInactive = false;
  const { start: startPolling, stop: stopPolling } = useInterval(
    onUpdate,
    AI_EMAIL_ATTACHMENT_PROCESSING_POLLING_INTERVAL,
    stopWhenWindowInactive
  );

  function isAttachmentInAiProcessing({ attachment }) {
    return attachment?.communication_attachment_extra?.ai_processing_status === 'processing';
  }

  const isCommunicationHasAiProcessingAttachments = ({ communication }) => {
    return communication.attachments.some((attachment) => isAttachmentInAiProcessing({ attachment }));
  };

  React.useEffect(() => {
    if (isCommunicationHasAiProcessingAttachments({ communication })) {
      startPolling();
    }

    return () => {
      stopPolling();
    };
  });

  const handleUploadDocument = async (values, attachmentId) => {
    try {
      await axios.post(
        `/api/v1/claims/${communication.claim_id}/communications/${communicationType}/${communication.id}/attachments/${attachmentId}/upload_attachment`,
        values
      );
      await onUpdate();
      setAttachmentToUploadId(undefined);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const handleUploadPhotos = async (values) => {
    try {
      await axios.post(
        `/api/v1/claims/${communication.claim_id}/communications/${communicationType}/${communication.id}/attachments/upload_attachments`,
        values
      );
      await onUpdate();
      setAttachmentToUploadId(undefined);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const isAttachmentUploaded = (attachment) => {
    return attachment.document && !attachment.document.is_removed;
  };

  let attachmentsColumnsData = [
    // eslint-disable-next-line react/display-name
    {
      id: 'view_action',
      width: 10,
      leftPaddingOnly: true,
      disableSort: true,
      specialCell: (attachment) => (
        <InlineIconButton
          icon={FileDocument}
          onClick={() => window.open(getAttachmentFileUrl(attachment), '_target')}
        />
      ),
    },
    {
      id: 'file_name',
      numeric: false,
      disablePadding: false,
      label: 'File Name',
      // eslint-disable-next-line react/display-name
      specialCell: (attachment) =>
        attachment.document ? (
          <span>{attachment.file_name}</span>
        ) : (
          <a href={getAttachmentFileUrl(attachment)} target="_blank" rel="noopener noreferrer">
            {attachment.file_name}
          </a>
        ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'upload',
      numeric: false,
      label: '',
      specialCell: (attachment) => {
        if (isAttachmentUploaded(attachment)) {
          return 'uploaded';
        }

        return (
          <div className="flex-end flex w-full items-center">
            {recommendationAttachment && attachment.id === recommendationAttachment.id && (
              <UploadAndPayInvoiceContainer
                invoiceData={invoiceRecommendation}
                attachment={recommendationAttachment}
                getAttachmentFileUrl={getAttachmentFileUrl}
                claimId={communication.claim_id}
                onUploadDocument={async (values, attachmentId) => {
                  await handleUploadDocument(values, attachmentId);
                  await onUpdate();
                }}
              />
            )}
            <Button
              color="primary"
              onClick={() => setAttachmentToUploadId(attachment.id)}
              classes={{ root: 'min-w-0 px-24', label: 'w-12' }}
              disableFocusRipple
            >
              <CloudUploadIcon />
            </Button>
            {isAttachmentInAiProcessing({ attachment }) && (
              <Tooltip title="Ai Processing..." placement="top">
                <span>
                  <Sparkle animated size={24} />
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      id: 'document_id',
      numeric: true,
      disablePadding: false,
      label: '#',
      // eslint-disable-next-line react/display-name
      specialCell: (attachment) =>
        isAttachmentUploaded(attachment) && (
          <a href={getAttachmentFileUrl(attachment)} target="_blank" rel="noopener noreferrer">
            {attachment.document.claim_internal_id}
          </a>
        ),
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      specialCell: (attachment) =>
        isAttachmentUploaded(attachment) &&
        (documentTypesDict?.[attachment.document.type]?.desc || attachment.document.type),
    },
    {
      id: 'document_name',
      numeric: false,
      maxWidthPercentage: 25,
      overflow: true,
      disablePadding: false,
      label: 'Document Name',
      specialCell: (attachment) => isAttachmentUploaded(attachment) && attachment.document.document_name,
    },
  ];

  if (!shouldAllowUploadAttachments) {
    attachmentsColumnsData = attachmentsColumnsData.filter((attachmentsColumn) => attachmentsColumn.id !== 'upload');
  }

  const attachmentToUpload =
    attachmentToUploadId && attachments.find((attachment) => attachment.id === attachmentToUploadId);
  const attachedPhotos = attachments.filter((attachment) => isDocumentAPhoto(attachment) && !attachment.document);
  return (
    <>
      <PlainTable classes={classes} columns={attachmentsColumnsData} rows={attachments} maxHeight={maxHeight} />

      {attachmentsBlocked.length > 0 && (
        <div className={classes.textField}>
          <Typography variant="body1" style={{ color: 'red' }}>
            Blocked for security reasons:
          </Typography>
          {attachmentsBlocked.map((attachmentBlocked) => (
            <Typography key={attachmentBlocked.id} variant="body2" style={{ color: 'red' }}>
              {attachmentBlocked.file_name}
            </Typography>
          ))}
        </div>
      )}
      {shouldAllowUploadAttachments && (
        <>
          <div className={classes.buttonsContainer}>
            <Button
              size="small"
              color="primary"
              onClick={() => setShowUploadPhotos(true)}
              disabled={isUserReadOnly(user)}
            >
              View and upload photos
            </Button>
          </div>
          {!!attachmentToUploadId && (
            <UploadDocumentFromStoredFileDialog
              open={!!attachmentToUploadId}
              attachment={attachmentToUpload}
              claimId={communication.claim_id}
              onUploadAttachment={(values) => handleUploadDocument(values, attachmentToUploadId)}
              onCancel={() => setAttachmentToUploadId(null)}
              isDocumentNameRequired
              initialValues={{
                type: '',
                document_name: attachmentToUpload
                  ? extractDisplayNameAndExtensionFromFileName(attachmentToUpload.file_name).displayName
                  : '',
                document_date: attachmentToUploadId
                  ? serverDateTimeToLocalDate(attachmentToUpload.stored_file.datetime_uploaded)
                  : undefined,
                document_date_received: communication ? serverDateTimeToLocalDate(communication.datetime) : undefined,
                exposure_ids: [0],
                summary: '',
              }}
            />
          )}

          {showUploadPhotos && (
            <UploadAttachmentsPhotosGalleryDialog
              photos={attachedPhotos}
              claimId={communication.claim_id}
              onClose={() => setShowUploadPhotos(false)}
              onUploadPhotos={async (photosIdsWithDetails) => await handleUploadPhotos(photosIdsWithDetails)}
              onUpdate={onUpdate}
              getAttachmentFileUrl={getAttachmentFileUrl}
              communicationType={communicationType}
            />
          )}
        </>
      )}
    </>
  );
}

const communicationTypePropType = PropTypes.oneOf(['sms', 'emails', 'video']).isRequired;

CommunicationAttachmentTable.propTypes = {
  communication: PropTypes.object.isRequired,
  communicationType: communicationTypePropType,
  onUpdate: PropTypes.func.isRequired,
  maxHeight: PropTypes.string,
  getAttachmentFileUrl: PropTypes.func.isRequired, // (attachment object) => url to the attachment
};

function UploadAttachmentsPhotosGalleryDialog({
  photos,
  claimId,
  onUploadPhotos,
  onClose,
  onUpdate,
  getAttachmentFileUrl,
}) {
  const classes = useStyles();
  const allPhotosIds = photos.map((photo) => photo.id);
  const [selectedPhotoIds, setSelectedPhotoIds] = useState(allPhotosIds);
  const [openUploadPhotosDetailsDialog, setOpenUploadPhotosDetailsDialog] = useState(false);

  const handlePhotoClicked = (isSelected, photo) => {
    if (isSelected) {
      setSelectedPhotoIds([...selectedPhotoIds, photo.id]);
    } else {
      setSelectedPhotoIds(_.without(selectedPhotoIds, photo.id));
    }
  };

  const handlePhotosUpload = async (documentValues) => {
    await onUploadPhotos({ attachments_ids: selectedPhotoIds, document_details: documentValues });
    setOpenUploadPhotosDetailsDialog(false);
    setSelectedPhotoIds([]);

    const notIncludedPhotos = _.difference(allPhotosIds, selectedPhotoIds);

    if (notIncludedPhotos.length === 0) {
      onClose();
    }
  };

  const handleRotatePhoto = async (photo) => {
    try {
      await axios.post(`${getAttachmentFileUrl(photo)}/rotate_photo`);
      await onUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const actionsRow = (
    <div className={classes.buttonsContainer}>
      <ButtonGroup variant="text" size="small" className={classes.leftButtonDialog}>
        <Button onClick={() => setSelectedPhotoIds(photos.map((photo) => photo.id))}>All</Button>
        <Button onClick={() => setSelectedPhotoIds([])}>None</Button>
      </ButtonGroup>
    </div>
  );

  return (
    <>
      <CardDialog title="Upload Photos" open isDialog maxWidth="xl" fullWidth onClose={onClose}>
        <div style={{ height: '80vh' }}>
          <GalleryContainerInner
            allMedia={photos}
            actionsRow={actionsRow}
            onPhotoClicked={handlePhotoClicked}
            shouldAllowSelect
            selectedPhotoIds={selectedPhotoIds}
            onPhotosSelected={() => setOpenUploadPhotosDetailsDialog(true)}
            selectButtonText="Add selected photos"
            noPhotosFoundText="No photos to upload"
            isStoredFile
            getPhotoUrl={getAttachmentFileUrl}
            onRotatePhoto={handleRotatePhoto}
          />
        </div>
      </CardDialog>

      <UploadDocumentFromStoredFileDialog
        open={openUploadPhotosDetailsDialog}
        claimId={claimId}
        onUploadAttachment={handlePhotosUpload}
        onCancel={() => setOpenUploadPhotosDetailsDialog(false)}
        isMultiplePhotos
        initialValues={{
          type: 'damage_photo',
          document_name: '',
          document_date: getLocalDateToday(),
          exposure_ids: [0],
          summary: '',
        }}
      />
    </>
  );
}

UploadAttachmentsPhotosGalleryDialog.propTypes = {
  photos: PropTypes.array.isRequired,
  claimId: PropTypes.number.isRequired,
  onUploadPhotos: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  getAttachmentFileUrl: PropTypes.func.isRequired, // (attachment object) => url to the attachment
};

export default CommunicationAttachmentTable;
