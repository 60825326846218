import React from 'react';

import type { DocumentMetadata } from '~/components/communications/types';
import ShowAllCollapsible from '~/components/core/Molecules/Collapsible/ShowAllCollapsible';
import DocumentLink from '~/components/Documents/DocumentLink';
import type { NoteContentProps } from '~/components/Notes/NoteContent/types';
import type { Note } from '~/components/Notes/Notes';
import { reportErrorInProductionOrThrow } from '~/Utils';

import Text from '../../core/TextComponents/Text';

const SingleDocumentNote: React.FC<NoteContentProps> = ({
  note,
  isCollapsible,
  isExpanded,
  onCollapseClick,
  maxRows,
}) => {
  if (!note.document) {
    reportErrorInProductionOrThrow(`Document Note is missing document, noteId: ${note?.id}`);
    return <></>;
  }

  return (
    <DocumentNote
      note={note}
      isCollapsible={isCollapsible}
      isExpanded={isExpanded}
      onCollapseClick={onCollapseClick}
      maxRows={maxRows}
    />
  );
};

export const DocumentNote: React.FC<NoteContentProps> = ({
  note,
  isCollapsible,
  isExpanded,
  onCollapseClick,
  maxRows,
}) => {
  const document = note.document;
  if (!document) {
    return <></>;
  }

  return isCollapsible ? (
    <>
      <DocumentNoteStaticSection document={document} />
      <ShowAllCollapsible
        visibleLines={maxRows || 1}
        showAsInlineButton
        isOpen={isExpanded}
        onCollapseClick={onCollapseClick}
      >
        <DocumentNotePossiblyCollapsedSection note={note} />
      </ShowAllCollapsible>
    </>
  ) : (
    <>
      <DocumentNotePossiblyCollapsedSection note={note} />
      <DocumentNoteStaticSection document={document} />
    </>
  );
};

interface DocumentNoteInnerProps {
  note: Note;
}
const DocumentNotePossiblyCollapsedSection: React.FC<DocumentNoteInnerProps> = ({ note }) => {
  const document = note.document;

  if (!document) {
    return <></>;
  }

  return (
    <>
      {document.is_removed && (
        <Text
          className="mb-12"
          colorVariant={Text.COLOR_VARIANTS.ERROR}
          weight={Text.WEIGHTS.REGULAR}
          variant={Text.VARIANTS.SM}
        >
          Document was removed from claim, Reason: {document.removed_reason}
        </Text>
      )}
      <a
        href={`/api/v1/claims/${document.claim_id}/documents/${document.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Document #{document.claim_internal_id}
      </a>
      <div className="whitespace-pre-line">
        <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
          {note.note_text}
        </Text>
      </div>
    </>
  );
};

interface DocumentNoteStaticSectionProps {
  document: DocumentMetadata;
}
const DocumentNoteStaticSection: React.FC<DocumentNoteStaticSectionProps> = ({ document }) => {
  return (
    <div className="my-12">
      <DocumentLink text="Document details" document={document} displayLinkAsButton />
    </div>
  );
};

export default SingleDocumentNote;
