const PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3 = {
  payment_method: 'check',
  payment_method_external_id: '',
  payment_method_display_name: '',
  method_configuration: {
    mail_to_limits: {
      pay_to_line_max_length: 120,
    },
    enable_record_only: false,
    enable_reissued: true,
    use_indemnity_labels: false,
    indemnity_labels: {},
    prefill_indemnity_reference_with_claim_number: false,
    prefill_expenses_reference_with_invoice_number: false,
    enable_paying_expenses_if_exposure_is_closed: false,
    prefill_indemnity_payee_with_claimant: false,
    home_only_should_force_mortgagee: false,
    force_mortgagee_options: {
      coverage_keys: [],
      amount: 0,
    },
    use_expenses_labels: true,
    expenses_labels: {
      other: {
        desc: 'Other',
      },
    },
    payment_provider: null,
    payment_provider_specific: {},
    required_fields: {},
  },
} as const;

export default PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3;
