import React from 'react';

import SvgIcon from '~/components/core/SvgIcon';

const WarningIcon = (props) => (
  <SvgIcon viewBox="0 0 27.96 27.96" {...props}>
    <path d="M13.98 0a13.98 13.98 0 1 0 0 27.96 13.98 13.98 0 0 0 0-27.96Zm-.11 25.21c-6.57.19-11.1-4.45-11.13-11.13C2.72 7.4 7.38 2.64 13.9 2.55c6.52-.1 11.78 5.06 11.53 11.5-.25 6.46-5 10.97-11.56 11.16Z" />
    <path d="m15.09 17.21.59-12.54-3.16.18.35 12.33zM14 19.68c-1.48 0-1.71.57-1.71 2.07 0 1.44.2 1.85 1.65 1.85H14c1.5 0 1.83-.49 1.83-1.93-.03-1.5-.37-1.99-1.84-1.99Z" />
  </SvgIcon>
);

export default WarningIcon;
