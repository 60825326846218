import React from 'react';
import axios from 'axios';
import { noop } from 'lodash';

import { useInterval } from '~/components/AiChat/components/Message/useInterval';
import type {
  AiChatApiResponse,
  AiChatExchangeApiResponse,
  AiChatExchangesAndReferencesApiResponse,
  AiChatReferences,
  AiChatStatusApiResponse,
  UpdateExchangeUserFeedbackCallback,
} from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import { useCms } from '~/components/hooks/useCms';
import useDataFetcher from '~/components/useDataFetcher';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, reportAxiosError } from '~/Utils';

export interface UseAiChatReturn {
  chat: AiChatApiResponse | undefined;
  reloadChat: () => Promise<AiChatApiResponse>;
  isChatLoading: boolean;
  isChatError: boolean;
  getChatResponse: (userInput: string) => Promise<void>;
  updateExchangeUserFeedback: UpdateExchangeUserFeedbackCallback;
  restartChat: () => Promise<void>;
  regenerateResponse: (exchangeId: number) => Promise<void>;
  isFetchingResponse: boolean;
  exchanges: AiChatExchangeApiResponse[];
  references?: AiChatReferences;
  chatStatus?: AiChatStatusApiResponse;
  isMinimized: boolean;
  handleMinimize: () => void;
  itcEnabled?: boolean;
}

export const useChat = ({
  routeSuffix,
  shouldStart = true,
}: {
  routeSuffix: string;
  shouldStart?: boolean;
}): UseAiChatReturn => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const { claim } = useClaim();
  const [isMinimized, setIsMinimized] = React.useState(false);
  const chatStatusPollingInterval =
    userOrganization.configuration?.claim_chat?.chat_status_polling_interval_seconds * 1000 || 10000; // 10 seconds
  const baseRoute = `/api/v1/claims/${claim.id}${routeSuffix}`;
  const chatStatusRoute = `${baseRoute}/status`;
  const disablePolling = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.DISABLE_CLAIM_CHAT_POLLING);
  const itcEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.ITC_PRESENTATION);

  const { data, reloadData, isLoading, isError } = useDataFetcher(baseRoute, {}, shouldStart);
  const { data: chatStatus, reloadData: reloadChatStatus } = useDataFetcher(
    chatStatusRoute,
    {},
    shouldStart && !disablePolling
  );
  const [isFetchingResponse, setIsFetchingResponse] = React.useState(false);
  const [exchanges, setExchanges] = React.useState<AiChatExchangeApiResponse[]>([]);
  const [references, setReferences] = React.useState<AiChatReferences>({});
  const [isRestarting, setIsRestarting] = React.useState(false);
  const stopWhenWindowInactive = true;
  const { start: startPolling, stop: stopPolling } = useInterval(
    reloadChatStatus,
    chatStatusPollingInterval,
    stopWhenWindowInactive
  );

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleReloadData = React.useCallback(async () => {
    const chatResponse: AiChatApiResponse = await reloadData();
    setExchanges(chatResponse.exchanges);
    setReferences(chatResponse.references);
    return chatResponse;
  }, [reloadData]);

  const getChatResponse = React.useCallback(
    async (userInput: string) => {
      setIsFetchingResponse(true);
      try {
        const { data } = await axios.patch<AiChatExchangesAndReferencesApiResponse>(baseRoute, {
          user_input: userInput,
        });
        const { exchanges: newExchanges, references: newReferences } = data;
        setExchanges(newExchanges);
        setReferences(newReferences);
      } catch (error) {
        await reportAxiosError(error);
        throw error;
      } finally {
        setIsFetchingResponse(false);
      }
    },
    [baseRoute]
  );

  const updateExchangeUserFeedback = React.useCallback(
    async (exchangeId: number, feedbackScore: number, feedbackText: string) => {
      try {
        await axios.patch(`${baseRoute}/exchanges/${exchangeId}`, {
          user_feedback_score: feedbackScore,
          user_feedback_text: feedbackText,
        });
        await handleReloadData();
        return true;
      } catch (error) {
        await reportAxiosError(error);
        return false;
      }
    },
    [baseRoute, handleReloadData]
  );

  const restartChat = React.useCallback(async () => {
    try {
      setIsRestarting(true);
      try {
        await axios.delete(baseRoute);
      } catch {
        // Ignore error
      }
      await handleReloadData();
      setIsRestarting(false);
    } catch (e) {
      await reportAxiosError(e);
    }
  }, [baseRoute, handleReloadData]);

  const regenerateResponse = React.useCallback(
    async (exchangeId: number) => {
      try {
        setIsFetchingResponse(true);
        const { data } = await axios.post(`${baseRoute}/exchanges/${exchangeId}/regenerate`);
        const { exchanges: newExchanges, references: newReferences } = data;
        setExchanges(newExchanges);
        setReferences(newReferences);
      } catch (error) {
        await reportAxiosError(error);
        throw error;
      } finally {
        setIsFetchingResponse(false);
      }
    },
    [baseRoute]
  );

  React.useEffect(() => {
    if (data && !exchanges?.length) {
      setExchanges(data.exchanges);
      setReferences(data.references);
    }
  }, [data, exchanges]);

  React.useEffect(() => {
    if (isMinimized || disablePolling) {
      stopPolling();
      return;
    }
    startPolling();
  }, [startPolling, stopPolling, disablePolling, isMinimized]);

  return {
    chat: data as AiChatApiResponse | undefined,
    reloadChat: handleReloadData as () => Promise<AiChatApiResponse>,
    isChatLoading: shouldStart ? isLoading || isRestarting : true,
    isChatError: isError as boolean,
    getChatResponse,
    isFetchingResponse,
    exchanges,
    references,
    updateExchangeUserFeedback,
    restartChat,
    regenerateResponse,
    chatStatus,
    isMinimized,
    handleMinimize,
    itcEnabled,
  };
};

export const ChatContext = React.createContext<UseAiChatReturn>({
  chat: undefined,
  reloadChat: () => Promise.resolve({} as AiChatApiResponse),
  isChatLoading: true,
  isChatError: false,
  getChatResponse: () => Promise.resolve(),
  isFetchingResponse: false,
  exchanges: [],
  references: undefined,
  updateExchangeUserFeedback: () => Promise.resolve(false),
  restartChat: () => Promise.resolve(),
  regenerateResponse: () => Promise.resolve(),
  chatStatus: undefined,
  isMinimized: false,
  handleMinimize: noop,
  itcEnabled: false,
});

export const ChatProvider: React.FC<{ routeSuffix: string; shouldStart?: boolean }> = ({
  routeSuffix,
  shouldStart = true,
  children,
}) => {
  const value = useChat({ routeSuffix, shouldStart });
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChatContext = (): UseAiChatReturn => {
  return React.useContext(ChatContext);
};
