import React from 'react';

import colors from '~/theme/tailwind/colors';
import cn from '~/Utils/cn';

import { CalledOffIcon, CheckIcon, ClockIcon, ErrorIcon, InfoIcon, WarningIcon } from '../../icons';

const ALERT_TYPES = {
  DONE: 'done',
  ERROR: 'error',
  PENDING: 'pending',
  WARNING: 'warning',
  CALLED_OFF: 'called off',
  INFO: 'info',
} as const;

const ICONS = {
  [ALERT_TYPES.DONE]: CheckIcon,
  [ALERT_TYPES.ERROR]: ErrorIcon,
  [ALERT_TYPES.PENDING]: ClockIcon,
  [ALERT_TYPES.WARNING]: WarningIcon,
  [ALERT_TYPES.CALLED_OFF]: CalledOffIcon,
  [ALERT_TYPES.INFO]: InfoIcon,
} as const;

interface AlertBannerProps {
  note: React.ReactNode;
  alertType: (typeof ALERT_TYPES)[keyof typeof ALERT_TYPES];
  title?: string;
  withIcon?: boolean;
  className?: string;
}

type ComponentWithStaticProperties<TProps> = React.FC<TProps> & {
  ALERT_TYPES: typeof ALERT_TYPES;
};

const AlertBanner: ComponentWithStaticProperties<AlertBannerProps> = ({
  note,
  alertType,
  title,
  withIcon = false,
  className,
}) => {
  const containerClassNames = cn('p-12 rounded-md', {
    ['bg-green-100 border border-solid border-green-700']: alertType === ALERT_TYPES.DONE,
    ['bg-red-100 border border-solid border-red-700']: alertType === ALERT_TYPES.ERROR,
    ['bg-orange-100 border border-solid border-orange-700']: alertType === ALERT_TYPES.PENDING,
    ['bg-yellow-100 border border-solid border-yellow-700']: alertType === ALERT_TYPES.WARNING,
    ['bg-slate-300 border border-solid border-slate-700']: alertType === ALERT_TYPES.CALLED_OFF,
    ['bg-blueSky-100 border border-solid border-blue-300']: alertType === ALERT_TYPES.INFO,
  });
  const AlertIcon = ICONS[alertType];
  const icon = withIcon && AlertIcon ? <AlertIcon size={20} iconColor={colors.slate[900]} /> : undefined;

  return (
    <div className={cn(containerClassNames, className)}>
      {title ? (
        <div className="flex pt-12 font-bold">
          {icon && <div className="flex pr-12">{icon}</div>}
          {title}
        </div>
      ) : null}
      <div className={cn('flex pt-12', title && icon ? 'pl-32' : 'pl-0')}>
        {!title && icon && <div className="flex pr-12">{icon}</div>}
        {note}
      </div>
    </div>
  );
};

AlertBanner.ALERT_TYPES = ALERT_TYPES;

export default AlertBanner;
