import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import axios from 'axios';
import { Formik } from 'formik';
import { reduce } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';

import { TWILIO_ADJUSTER_STATUS_DICT } from '../../Types';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import { ReplyIcon } from '../icons';
import LoadingDialog from '../LoadingDialog';
import LoadingIndicator from '../LoadingIndicator';
import PlainTable from '../PlainTable';
import { MultiSelectTextFieldFormik } from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';
import useYearMonthFilter from '../YearMonthFilter';

import { useStyles } from '../../assets/styles';

function PhoneCallsDashboardPage({ organizationId }) {
  const classes = useStyles();
  const { user, setPageTitle } = useCms();
  const [isReloadingQueuesStatus, setIsReloadingQueuesStatus] = useState(false);
  const [isReloadingAdjustersStatus, setIsReloadingAdjustersStatus] = useState(false);

  useEffect(() => setPageTitle('Phone Calls Dashboard', 'Phone Calls Dashboard - Five Sigma CMS'), [setPageTitle]);

  const targetOrganizationIdDashboard = organizationId || user.organization_id;

  const {
    isLoading: isLoadingQueuesStatus,
    isError: isErrorQueuesStatus,
    data: queuesStatus,
    reloadData: reloadQueuesStatus,
  } = useDataFetcher(`/api/v1/organizations/${targetOrganizationIdDashboard}/queues_current_status`);

  const {
    isLoading: isLoadingAdjustersStatus,
    isError: isErrorAdjustersStatus,
    data: adjustersStatus,
    reloadData: reloadAdjustersStatus,
  } = useDataFetcher(`/api/v1/organizations/${targetOrganizationIdDashboard}/adjusters_availability_status`);

  const handleUpdateAdjusterQueues = async (adjusterId, values) => {
    try {
      await axios.put(`/api/v1/calls/${targetOrganizationIdDashboard}/users/${adjusterId}/active_queues`, {
        ...values,
      });
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }

    await reloadAdjustersStatus();
    // The APi may take time, so we update them in the background
    reloadQueuesStatus();
  };

  return (
    <div className={classes.pageBody}>
      <CardDialog title="Phone Calls Dashboard" passThrough>
        <Grid style={{ margin: 6 }} container spacing={1}>
          <Grid item xs={12} md={10} lg={7}>
            <QueuesStatusCard
              organizationId={organizationId}
              isLoading={isLoadingQueuesStatus}
              isError={isErrorQueuesStatus}
              queuesStatus={queuesStatus}
              reloadData={reloadQueuesStatus}
              isReloading={isReloadingQueuesStatus}
              setIsReloading={setIsReloadingQueuesStatus}
            />
          </Grid>

          <Grid item xs={12} md={10} lg={7}>
            <AdjustersStatusCard
              isLoading={isLoadingAdjustersStatus}
              isError={isErrorAdjustersStatus}
              adjustersStatus={adjustersStatus}
              reloadData={reloadAdjustersStatus}
              isReloading={isReloadingAdjustersStatus}
              setIsReloading={setIsReloadingAdjustersStatus}
              onUpdateAdjusterQueues={handleUpdateAdjusterQueues}
            />
          </Grid>

          <Grid item xs={12}>
            <AdjustersStatisticsCard organizationId={organizationId} />
          </Grid>

          <Grid item xs={12} md={10} lg={7}>
            <QueuesStatisticsCard organizationId={organizationId} />
          </Grid>
        </Grid>
      </CardDialog>
    </div>
  );
}

PhoneCallsDashboardPage.propTypes = {
  organizationId: PropTypes.number,
};

function QueuesStatusCard({
  organizationId,
  isLoading,
  isError,
  queuesStatus,
  reloadData,
  isReloading,
  setIsReloading,
}) {
  const [queueToShowAdjustersList, setQueueToShowAdjustersList] = useState(null);
  const queuesStatusColumns = [
    { id: 'queue_name', label: 'Queue' },
    {
      id: 'active_adjusters',
      label: 'Active Adjusters',
      // eslint-disable-next-line react/display-name
      specialCell: (queueStatus) => {
        return (
          <Link
            onClick={(e) => {
              e.preventDefault();
              setQueueToShowAdjustersList(queueStatus.queue_sid);
            }}
            href=""
            style={{ margin: 0, textDecoration: 'underline' }}
          >
            {queueStatus.num_of_active_workers}
          </Link>
        );
      },
    },
    {
      id: 'num_of_pending_tasks',
      label: 'Pending Tasks',
      specialCell: (queueStatus) => queueStatus.num_of_pending_tasks,
    },
    {
      id: 'longest_relative_task_age_in_queue_sec',
      label: 'Longest Waiting Task',
      specialCell: (queueStatus) => secondsTimeDisplay(queueStatus.longest_relative_task_age_in_queue_sec),
    },
    {
      id: 'recently_average_hold_time_sec',
      label: 'Recent Average Hold Time',
      specialCell: (queueStatus) => secondsTimeDisplay(queueStatus.recently_average_hold_time_sec),
    },
  ];

  return (
    <>
      <CardDialog
        title="Queues Status"
        action={
          <ReloadTableContainer
            reloadFunc={reloadData}
            isLoading={isLoading}
            isReloading={isReloading}
            setIsReloading={setIsReloading}
          />
        }
      >
        {isLoading ? (
          <LoadingIndicator isError={isError} />
        ) : (
          <PlainTable columns={queuesStatusColumns} rows={queuesStatus} />
        )}
      </CardDialog>
      {queueToShowAdjustersList && (
        <QueueAdjustersList
          organizationId={organizationId}
          queueSid={queueToShowAdjustersList}
          onClose={() => setQueueToShowAdjustersList(null)}
        />
      )}
    </>
  );
}

QueuesStatusCard.propTypes = {
  organizationId: PropTypes.number,
  queuesStatus: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  reloadData: PropTypes.func.isRequired,
  isReloading: PropTypes.bool.isRequired,
  setIsReloading: PropTypes.func.isRequired,
};

function AdjustersStatusCard({
  isLoading,
  isError,
  adjustersStatus,
  reloadData,
  isReloading,
  setIsReloading,
  onUpdateAdjusterQueues,
}) {
  const [editAdjusterQueues, setEditAdjusterQueues] = useState(null);

  const adjustersStatusColumns = [
    { id: 'adjuster_name', label: 'Adjuster' },
    {
      id: 'direct_calls_status',
      label: 'Direct Calls',
      specialCell: (adjusterStatus) =>
        TWILIO_ADJUSTER_STATUS_DICT[adjusterStatus.receiving_call_status].can_accept_direct_incoming_calls
          ? 'Available'
          : 'Not Available',
    },
    {
      id: 'queue_calls_status',
      label: 'Queue Calls',
      specialCell: (adjusterStatus) =>
        TWILIO_ADJUSTER_STATUS_DICT[adjusterStatus.receiving_call_status].can_accept_queue_calls
          ? 'Available'
          : 'Not Available',
    },
    {
      id: 'active_queues',
      label: 'Active Queues',
      // eslint-disable-next-line react/display-name
      specialCell: (adjusterStatus) => (
        <HoverActionField onAction={() => setEditAdjusterQueues(adjusterStatus)} permanent>
          {adjusterStatus.active_queues.join(', ')}
        </HoverActionField>
      ),
    },
    {
      id: 'status',
      label: 'Status',
      specialCell: (adjusterStatus) => {
        if (TWILIO_ADJUSTER_STATUS_DICT[adjusterStatus.status].in_call_status) {
          return 'Busy';
        } else if (
          TWILIO_ADJUSTER_STATUS_DICT[adjusterStatus.status].can_accept_direct_incoming_calls ||
          TWILIO_ADJUSTER_STATUS_DICT[adjusterStatus.status].can_accept_queue_calls
        ) {
          return 'Free';
        } else {
          return 'Offline';
        }
      },
    },
  ];

  return (
    <>
      <CardDialog
        title="Adjusters Status"
        action={
          <ReloadTableContainer
            reloadFunc={reloadData}
            isLoading={isLoading}
            isReloading={isReloading}
            setIsReloading={setIsReloading}
          />
        }
      >
        {isLoading ? (
          <LoadingIndicator isError={isError} />
        ) : (
          <PlainTable columns={adjustersStatusColumns} rows={adjustersStatus} />
        )}
      </CardDialog>
      {editAdjusterQueues && (
        <EditAdjusterQueues
          adjusterId={editAdjusterQueues.adjuster_id}
          onClose={() => setEditAdjusterQueues(null)}
          possibleQueues={editAdjusterQueues.possible_queues}
          currentActiveQueues={editAdjusterQueues.active_queues}
          onUpdateAdjusterQueues={async (values) => {
            try {
              await onUpdateAdjusterQueues(editAdjusterQueues.adjuster_id, values);
            } catch (error) {
              reportAxiosError(error);
            }
            setEditAdjusterQueues(null);
          }}
        />
      )}
    </>
  );
}

AdjustersStatusCard.propTypes = {
  adjustersStatus: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  reloadData: PropTypes.func.isRequired,
  isReloading: PropTypes.bool.isRequired,
  setIsReloading: PropTypes.func.isRequired,
  onUpdateAdjusterQueues: PropTypes.func.isRequired,
};

function EditAdjusterQueues({ currentActiveQueues, possibleQueues, onUpdateAdjusterQueues, onClose }) {
  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        active_queues: currentActiveQueues,
        possible_queues: possibleQueues,
      }}
      validationSchema={Yup.object().shape({ active_queues: Yup.array().min(1, 'At least one queue required') })}
      onSubmit={onUpdateAdjusterQueues}
    >
      {({ isSubmitting, handleSubmit }) => (
        <CardDialog title="Edit Adjuster Active Queues" isDialog onClose={onClose}>
          <MultiSelectTextFieldFormik
            id="active_queues"
            label="Active Queues"
            options={possibleQueues}
            renderValue={(selected) => selected.join(', ')}
            className={classes.textField}
            fullWidth
            renderOption={(o) => o}
          />
          <div className={classes.buttonsContainer}>
            <Button size="small" disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
}

EditAdjusterQueues.propTypes = {
  currentActiveQueues: PropTypes.array.isRequired,
  possibleQueues: PropTypes.array.isRequired,
  onUpdateAdjusterQueues: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function AdjustersStatisticsCard({ organizationId }) {
  const { user } = useCms();
  const { filterByMonth, yearFilter, monthFilter, yearFilterMonthFilterProps, YearMonthFilter } = useYearMonthFilter();

  const targetOrganizationIdDashboard = organizationId || user.organization_id;

  const {
    isLoading,
    isError,
    data: adjustersCallsStatistics,
  } = useDataFetcher(`/api/v1/organizations/${targetOrganizationIdDashboard}/adjusters_calls_statistics`, {
    params: { year: yearFilter, month: filterByMonth ? monthFilter + 1 : undefined },
  });

  const getCallColumns = () => {
    let adjustersCallsStatisticsColumns = [
      { id: 'adjuster', label: 'Adjuster', specialCell: (adjusterStats) => adjusterStats.username },
      {
        id: 'total_answered_missed',
        label: 'Answered/Missed Calls',
        specialCell: (adjusterStats) => {
          let totalAnswered = 0;
          let totalNotAnswered = 0;

          Object.keys(adjustersCallsStatistics.task_queues).forEach((task_queue_sid) => {
            totalAnswered += adjusterStats[task_queue_sid].answered;
            totalNotAnswered += adjusterStats[task_queue_sid].not_answered;
          });

          totalAnswered += adjusterStats.direct_call.answered;
          totalNotAnswered += adjusterStats.direct_call.not_answered;

          return `${totalAnswered}/${totalNotAnswered}`;
        },
      },
    ];

    Object.keys(adjustersCallsStatistics.task_queues).forEach((task_queue_sid) => {
      adjustersCallsStatisticsColumns = adjustersCallsStatisticsColumns.concat([
        {
          id: task_queue_sid,
          label: adjustersCallsStatistics.task_queues[task_queue_sid],
          specialCell: (adjusterStats) => {
            return `${adjusterStats[task_queue_sid].answered}/${adjusterStats[task_queue_sid].not_answered}`;
          },
        },
      ]);
    });

    adjustersCallsStatisticsColumns = adjustersCallsStatisticsColumns.concat([
      {
        id: 'direct_calls',
        label: 'Direct Calls',
        specialCell: (adjusterStats) =>
          `${adjusterStats.direct_call.answered}/${adjusterStats.direct_call.not_answered}`,
      },
      {
        id: 'average_call_duration_sec',
        label: 'Average Call Duration',
        specialCell: (adjusterStats) => secondsTimeDisplay(adjusterStats.average_call_duration_sec),
      },
    ]);

    return adjustersCallsStatisticsColumns;
  };

  const getSummaryRow = () => {
    const adjustersStats = adjustersCallsStatistics.adjusters_stats;
    let summaryRow = {
      id: targetOrganizationIdDashboard,
      username: user.organization_name,
      direct_call: {
        answered: reduce(adjustersStats, (sum, adjusterStat) => adjusterStat.direct_call.answered + sum, 0),
        not_answered: reduce(adjustersStats, (sum, adjusterStat) => adjusterStat.direct_call.not_answered + sum, 0),
      },
    };

    Object.keys(adjustersCallsStatistics.task_queues).forEach((task_queue_sid) => {
      summaryRow[task_queue_sid] = {
        answered: reduce(adjustersStats, (sum, adjusterStat) => adjusterStat[task_queue_sid].answered + sum, 0),
        not_answered: reduce(adjustersStats, (sum, adjusterStat) => adjusterStat[task_queue_sid].not_answered + sum, 0),
      };
    });

    summaryRow.average_call_duration_sec =
      Math.floor(
        reduce(
          adjustersStats,
          (average_call_duration_sum, adjusterStat) =>
            adjusterStat.average_call_duration_sec * adjusterStat.num_of_answered_calls + average_call_duration_sum,
          0
        ) /
          reduce(
            adjustersStats,
            (answered_count, adjusterStat) => adjusterStat.num_of_answered_calls + answered_count,
            0
          )
      ) || 0;

    return summaryRow;
  };

  return (
    <CardDialog title="Adjusters Statistics">
      <YearMonthFilter {...yearFilterMonthFilterProps} />
      {isLoading || isError ? (
        <LoadingIndicator isError={isError} />
      ) : (
        <PlainTable
          columns={getCallColumns()}
          rows={adjustersCallsStatistics.adjusters_stats.concat([getSummaryRow()])}
        />
      )}
    </CardDialog>
  );
}

AdjustersStatisticsCard.propTypes = {
  organizationId: PropTypes.number,
};

function QueuesStatisticsCard({ organizationId }) {
  const { user } = useCms();
  const { filterByMonth, yearFilter, monthFilter, yearFilterMonthFilterProps, YearMonthFilter } =
    useYearMonthFilter(true);

  const targetOrganizationIdDashboard = organizationId || user.organization_id;

  const {
    isLoading,
    isError,
    data: queuesStatistics,
  } = useDataFetcher(`/api/v1/organizations/${targetOrganizationIdDashboard}/queues_statistics`, {
    params: { year: yearFilter, month: filterByMonth ? monthFilter + 1 : undefined },
  });

  const queuesStatusColumns = [
    { id: 'queue_name', label: 'Queue' },
    { id: 'total_calls_received', label: 'Calls Received' },
    { id: 'total_calls_handled', label: 'Calls Handled' },
    {
      id: 'average_answered_hold_time',
      label: 'Average Answered Hold Time',
      specialCell: (queueStatus) => secondsTimeDisplay(queueStatus.average_answered_hold_time),
    },
    {
      id: 'average_cancelled_hold_time',
      label: 'Average Unanswered Hold Time',
      specialCell: (queueStatus) => secondsTimeDisplay(queueStatus.average_cancelled_hold_time),
    },
  ];

  return (
    <>
      <CardDialog title="Queues Statistics">
        <YearMonthFilter {...yearFilterMonthFilterProps} />
        {isLoading || isError ? (
          <LoadingIndicator isError={isError} />
        ) : (
          <PlainTable columns={queuesStatusColumns} rows={queuesStatistics} />
        )}
      </CardDialog>
    </>
  );
}

QueuesStatisticsCard.propTypes = {
  organizationId: PropTypes.number,
};

function QueueAdjustersList({ organizationId, queueSid, onClose }) {
  const { user } = useCms();
  const targetOrganizationIdDashboard = organizationId || user.organization_id;

  const {
    isLoading,
    isError,
    data: adjustersList,
  } = useDataFetcher(`/api/v1/organizations/${targetOrganizationIdDashboard}/queue_adjusters/${queueSid}`);

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} onClose track={`Queue Adjusters List, queue sid: ${queueSid}`} />;
  }

  const adjustersListColumn = [
    { id: 'adjuster_name', label: 'Adjuster' },
    // eslint-disable-next-line react/display-name
    {
      id: 'status',
      label: 'Status',
      specialCell: (adjusterDetails) => (
        <span>
          {TWILIO_ADJUSTER_STATUS_DICT[adjusterDetails.receiving_call_status].desc}
          {adjusterDetails.receiving_call_status !== adjusterDetails.status && (
            <span> ({TWILIO_ADJUSTER_STATUS_DICT[adjusterDetails.status].desc})</span>
          )}
        </span>
      ),
    },
  ];

  return (
    <CardDialog isDialog title="Adjusters" maxWidth="xs" fullWidth onClose={onClose}>
      {adjustersList.length === 0 ? (
        <Typography>No Adjusters Assigned To Queue</Typography>
      ) : (
        <PlainTable columns={adjustersListColumn} rows={adjustersList} />
      )}
    </CardDialog>
  );
}

QueueAdjustersList.propTypes = {
  organizationId: PropTypes.number,
  queueSid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

function secondsTimeDisplay(totalSeconds) {
  if (totalSeconds === 0) {
    return '00:00';
  }

  const formatTwoDigits = (val) => `0${Math.floor(val)}`.slice(-2);

  const hours = Math.floor(totalSeconds / (60 * 60));
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const seconds = totalSeconds % 60;

  if (hours !== 0) {
    return [hours, minutes, seconds].map(formatTwoDigits).join(':');
  } else {
    return [minutes, seconds].map(formatTwoDigits).join(':');
  }
}

function ReloadTableContainer({ reloadFunc, isLoading, setIsReloading, isReloading }) {
  const handleReload = async () => {
    setIsReloading(true);
    await reloadFunc();
    setIsReloading(false);
  };

  if (isLoading) {
    return null;
  }

  if (isReloading === false) {
    return (
      <Tooltip title="Reload Data">
        <IconButton size="small" onClick={handleReload}>
          <ReplyIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <IconButton size="small" disabled>
      <HourglassEmptyIcon />
    </IconButton>
  );
}

ReloadTableContainer.propTypes = {
  reloadFunc: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsReloading: PropTypes.func.isRequired,
  isReloading: PropTypes.bool.isRequired,
};

export default PhoneCallsDashboardPage;
