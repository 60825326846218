import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import AutoComplete from '~/components/core/Molecules/Fields/AutoComplete';

import { ShowOnlyTextField } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

function AutocompleteFormik(props) {
  const {
    id,
    label,
    getOptionLabel,
    options,
    showOnly,
    textFieldProps,
    disabled,
    fullWidth,
    sortAlphabetic,
    onChange,
    onInputChange,
    autoSelect = false,
    autoHighlight = false,
    autoComplete = false,
    className,
  } = props;

  const { values, touched, errors, setFieldValue, setFieldTouched } = useFormikContext();
  const classes = useStyles();

  const value = getIn(values, id);

  let autocompleteOptions = options;
  if (sortAlphabetic) {
    autocompleteOptions = options.sort((option1, option2) =>
      getOptionLabel(option1).localeCompare(getOptionLabel(option2))
    );
  }

  if (showOnly) {
    return (
      <ShowOnlyTextField classes={classes} showOnlyValueComponent={value ? getOptionLabel(value) : ''} label={label} />
    );
  }

  return (
    <AutoComplete
      className={className}
      id={id}
      options={autocompleteOptions}
      getOptionLabel={getOptionLabel}
      onChange={onChange ? onChange : (_, newVal) => setFieldValue(id, newVal ? newVal : '')}
      filterOptions={(_, value) =>
        autocompleteOptions.filter((option) =>
          getOptionLabel(option).toLowerCase().includes(value.inputValue.toLowerCase())
        )
      }
      value={value || null}
      disabled={disabled}
      autoSelect={autoSelect}
      autoHighlight={autoHighlight}
      autoComplete={autoComplete}
      onInputChange={onInputChange}
      label={label}
      fullWidth={fullWidth}
      textFieldProps={{
        error: getIn(errors, id) && getIn(touched, id),
        helperText: getIn(errors, id) && getIn(touched, id) && getIn(errors, id),
        onBlur: () => setFieldTouched(id, true),
        ...textFieldProps,
      }}
    />
  );
}

AutocompleteFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  textFieldProps: PropTypes.object,
  showOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  sortAlphabetic: PropTypes.bool,
  onChange: PropTypes.func,
  autoSelect: PropTypes.bool,
  autoComplete: PropTypes.bool,
  autoHighlight: PropTypes.bool,
  onInputChange: PropTypes.func,
  className: PropTypes.string,
};

export default AutocompleteFormik;
