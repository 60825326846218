import React from 'react';

import type { ActionFooterProps } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionTypes';
import { ExecuteButton } from '~/components/AiChat/SideBarDialog/Actions/Action/ExecuteButton';
import type { PaymentTaskData } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import InvoicePaymentDialog from '~/components/Documents/GenAiPaymentSuggestion/InvoicePaymentDialog';

export const PaymentFooter: React.FC<ActionFooterProps> = ({ action, onExecute, isDone }) => {
  const { claim } = useClaim();
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    invoice_doc_id,
    invoice_number,
    payment_amount,
    payment_description,
    related_exposure_id = '',
  } = action.task_data as PaymentTaskData;
  const document = claim?.documents.find((doc: { id: number }) => String(doc.id) === invoice_doc_id);
  return (
    <div className="flex">
      <ExecuteButton onExecute={() => setIsOpen(true)} isDone={isDone} />

      <InvoicePaymentDialog
        open={!isDone && isOpen}
        setOpen={() => setIsOpen(!isOpen)}
        document={document}
        claim={claim}
        paymentInitialValues={{
          amount: payment_amount,
          invoice_number,
          note: payment_description,
        }}
        onPayment={() => onExecute()}
        exposureId={parseInt(related_exposure_id)}
      />
    </div>
  );
};
