import React, { useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { isNil, mapKeys } from 'lodash';

import type { ParsedAddressComponentsType } from '~/components/core/AddressAutocomplete/utils';
import { parseAddressComponents, SUPPORTED_COUNTRIES } from '~/components/core/AddressAutocomplete/utils';
import Autocomplete from '~/components/core/Molecules/Fields/AutoComplete';
import LoadingIndicator from '~/components/LoadingIndicator';
import useOrganization from '~/components/OrganizationContext';

interface AddressAutocompleteProps {
  onStreetAddress1Changed?: (streetAddress1: string) => void;
  onCityChanged?: (city: string) => void;
  onCountyChanged?: (county: string) => void;
  onCountryChanged?: (country: string) => void;
  onStateChanged?: (state: string) => void;
  onZipcodeChanged?: (zipcode: string) => void;
  className?: string;
  id: string;
  label: string;
  disabled?: boolean;
  fullWidth?: boolean;
  value?: string;
  error?: string;
  helperText?: string;
  onInputChange?: (event: React.ChangeEvent<Record<string, unknown>>, value: string) => void;
  onBlur?: () => void;
  region?: string;
}

const AddressAutocompleteInner: React.FC<AddressAutocompleteProps> = ({
  id,
  label,
  value,
  className = '',
  helperText,
  error,
  disabled,
  onBlur,
  onInputChange,
  onStreetAddress1Changed,
  onCityChanged,
  onCountyChanged,
  onCountryChanged,
  onStateChanged,
  onZipcodeChanged,
  region,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { googlePlacesApiKey } = useOrganization();

  const { placesService, placePredictions, getPlacePredictions, refreshSessionToken, autocompleteSessionToken } =
    usePlacesService({
      apiKey: googlePlacesApiKey,
      sessionToken: true,
      debounce: 500,
    });
  const [predictionsDict, setPredictionsDict] = useState<Record<string, google.maps.places.AutocompletePrediction>>({});

  useEffect(() => {
    if (placePredictions.length) {
      //  options need to be string because autocomplete is freeSolo
      setPredictionsDict(mapKeys(placePredictions, (place) => place.place_id));
    }
  }, [placePredictions]);

  const getPlaceDetails = (placeId: string): Promise<google.maps.places.PlaceResult | null> => {
    return new Promise((resolve) => {
      placesService?.getDetails(
        {
          placeId,
          fields: ['address_components'],
          sessionToken: autocompleteSessionToken,
        },
        (placeDetails) => {
          refreshSessionToken();
          resolve(placeDetails);
        }
      );
    });
  };

  const handleInputChanged = (event: React.ChangeEvent<Record<string, unknown>>, value: string) => {
    if (value) {
      getPlacePredictions({
        input: value,
        types: ['address'],
        region,
        // TODO restricted due to address formatting, need to add support for other countries
        componentRestrictions: { country: SUPPORTED_COUNTRIES },
        sessionToken: autocompleteSessionToken,
      });
    } else {
      setPredictionsDict({});
    }

    if (onInputChange) {
      onInputChange(event, value);
    }
  };

  const triggerFieldsChanged = (parsedDetailsDict: ParsedAddressComponentsType) => {
    if (onCountryChanged && parsedDetailsDict.country) {
      onCountryChanged(parsedDetailsDict.country);
    }

    if (onStreetAddress1Changed && parsedDetailsDict.route) {
      // This is true for US & UK, will need additional support for other countries
      let address1 = parsedDetailsDict.street_number
        ? `${parsedDetailsDict.street_number} ${parsedDetailsDict.route}`
        : `${parsedDetailsDict.route}`;

      if (parsedDetailsDict.subpremise) {
        address1 = `${address1} ${parsedDetailsDict.subpremise}`;
      }
      onStreetAddress1Changed(address1);
    }

    const city = parsedDetailsDict.locality || parsedDetailsDict.postal_town;
    if (onCityChanged && city) {
      onCityChanged(city);
    }

    if (onCountyChanged && parsedDetailsDict.administrative_area_level_2) {
      onCountyChanged(parsedDetailsDict.administrative_area_level_2);
    }

    if (onStateChanged && parsedDetailsDict.administrative_area_level_1) {
      onStateChanged(parsedDetailsDict.administrative_area_level_1);
    }

    if (onZipcodeChanged && parsedDetailsDict.postal_code) {
      let postalCode = parsedDetailsDict.postal_code;

      if (parsedDetailsDict.postal_code_suffix) {
        postalCode = `${postalCode}-${parsedDetailsDict.postal_code_suffix}`;
      }
      onZipcodeChanged(postalCode);
    }
  };

  // TODO Formik - after selection, address1 is updated which causes another trigger of get predictions
  //  but the session is already closed and the new session might not every get place details
  const handleSelectionMade = async (placeId: string) => {
    const placeDetails = await getPlaceDetails(placeId);
    if (placeDetails?.address_components) {
      const parsedDetails = parseAddressComponents(placeDetails.address_components);
      triggerFieldsChanged(parsedDetails);
    }
  };

  const handlePredictionSelected = async (_: React.ChangeEvent<Record<string, unknown>>, placeId: string | null) => {
    if (placeId) {
      await handleSelectionMade(placeId);
    }
  };

  return (
    <Autocomplete
      className={className}
      id={id}
      freeSolo
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options={Object.keys(predictionsDict)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getOptionLabel={(value) => (isNil(value) ? '' : predictionsDict[value]?.description || value)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={handlePredictionSelected}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value={value || null}
      disabled={disabled}
      onInputChange={handleInputChanged}
      label={label}
      textFieldProps={{
        error: !!error,
        helperText: error || helperText,
        onBlur,
      }}
    />
  );
};

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isLoading, isError, googlePlacesApiKey } = useOrganization();

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  } else if (!googlePlacesApiKey) {
    return <div>Google API Key Missing</div>;
  }

  return <AddressAutocompleteInner {...props} />;
};

export default AddressAutocomplete;
