import React from 'react';
import PropTypes, { number } from 'prop-types';

import MenuItem from '~/components/core/Atomic/MenuItem';

import useOrganization from '../../OrganizationContext';
import TextFieldFormik from '../../TextFieldFormik';

import { useStyles } from '../../../assets/styles';

const SubOrganizationSelectFormik = ({
  fieldId,
  formatEntry = defaultFormatEntry,
  disabled = false,
  excludedSubOrganizationsIds,
}) => {
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const classes = useStyles();

  if (!subOrganizationEnabled) {
    return null;
  }

  const subOrganizationOptions = subOrganizations.filter((subOrg) => !excludedSubOrganizationsIds?.includes(subOrg.id));

  return (
    <TextFieldFormik
      id={fieldId}
      label="Sub Organization"
      className={classes.textField}
      select
      fullWidth
      disabled={disabled}
    >
      {subOrganizationOptions.map((subOrg) => (
        <MenuItem key={subOrg.id} value={subOrg.id}>
          {formatEntry(subOrg)}
        </MenuItem>
      ))}
    </TextFieldFormik>
  );
};

const defaultFormatEntry = (subOrganization) => subOrganization.name;

SubOrganizationSelectFormik.propTypes = {
  fieldId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  formatEntry: PropTypes.func,
  excludedSubOrganizationsIds: PropTypes.arrayOf(number),
};

export default SubOrganizationSelectFormik;
