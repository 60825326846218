import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FieldArray, getIn, useFormikContext } from 'formik';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import Autocomplete from '~/components/core/Molecules/Fields/AutoComplete';
import TextField from '~/components/core/Molecules/Fields/TextField';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { reportAxiosError } from '../../../../../Utils';
import { localeDetails } from '../../../../CmsMain/globals';
import { FsIconButton } from '../../../../core';
import { TrashIcon } from '../../../../icons';
import { ShowOnlyTextField } from '../../../../TextFieldFormik';

import { MedicareWarning } from './medicareIcdCodesUtils';

import { useStyles } from '../../../../../assets/styles';
import styles from './index.module.scss';

const ICD_CODES_FIELD_NAME = 'icd_codes';

const icdCodesInitialValues = () => ({
  icd_code: '',
  icd_code_description: '',
  id: `new_${uuidv4()}`,
});

const IcdCodesFragment = ({ showOnly, fields }) => {
  const { values } = useFormikContext();

  const isActive = get(fields, 'icd_codes.active', true);
  const title = get(fields, 'icd_codes.desc', 'Icd Codes');
  const isUsRegion = localeDetails.locale.region === 'US';
  if (!isUsRegion || !isActive) {
    return null;
  }

  return (
    <div className={styles.icdCodesContainer}>
      <InnerCard title={title} className={styles.icdCodesInner} titleClassName={styles.icdCodesInnerTitle}>
        <div className={styles.alertBannerContainer}>
          <AlertBanner
            alertType={AlertBanner.ALERT_TYPES.WARNING}
            withIcon
            note="ICD codes that start with the prefixes 'V', 'W', 'X' or 'Y' can not be used for Medicare reporting"
          />
        </div>
        <FieldArray
          name={ICD_CODES_FIELD_NAME}
          render={({ remove, push }) => (
            <>
              {get(values, ICD_CODES_FIELD_NAME, []).map((item, idx) => {
                return (
                  <Grid key={item.id} container spacing={2} alignItems="flex-end">
                    <Grid item xs={10}>
                      <IcdCodesAutoComplete
                        currentIcdCodes={get(values, ICD_CODES_FIELD_NAME, []).map(({ icd_code }) => icd_code)}
                        icdCodeId={`${ICD_CODES_FIELD_NAME}.${idx}.icd_code`}
                        icdCodeDescId={`${ICD_CODES_FIELD_NAME}.${idx}.icd_code_description`}
                        showOnly={showOnly}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <MedicareWarning icdCode={item.icd_code} />
                    </Grid>
                    <Grid item xs={1}>
                      {!showOnly && (
                        <FsIconButton onClick={() => remove(idx)} icon={TrashIcon} ignorePermissions={true} />
                      )}
                    </Grid>
                    <Grid item xs={12} />
                  </Grid>
                );
              })}
              {!showOnly && (
                <Button color="primary" onClick={() => push(icdCodesInitialValues())}>
                  <span className={styles.addBtn}>
                    <AddIcon />
                    <span className={styles.addBtnLabel}>Add ICD Codes</span>
                  </span>
                </Button>
              )}
            </>
          )}
        />
      </InnerCard>
    </div>
  );
};

IcdCodesFragment.propTypes = {
  showOnly: PropTypes.bool,
  fields: PropTypes.object,
};

// NOTE: copied from frontend/src/components/exposures/IcdCodesContainer.jsx (which is FNOL-deprecated)
function IcdCodesAutoComplete({ icdCodeId, icdCodeDescId, currentIcdCodes, disabled, showOnly }) {
  const limitsWarningMessage = 'Only first 100 ICD codes are displayed. Consider using more precise search.';

  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [errorDetected, setErrorDetected] = useState(false);
  const [isMoreThanLimit, setIsMoreThanLimit] = useState(false);
  const { values, errors, touched, setFieldTouched, setFieldValue } = useFormikContext();
  const [possibleOptionsDict, setPossibleOptionsDict] = useState(
    getIn(values, icdCodeId) ? { [getIn(values, icdCodeId)]: { icd_code_desc: getIn(values, icdCodeDescId) } } : {}
  );
  const classes = useStyles();

  function getOptionLabel(option) {
    if (option === limitsWarningMessage) {
      return limitsWarningMessage;
    }

    if (!option || !possibleOptionsDict[option]) {
      return '';
    }

    return `${option} ${possibleOptionsDict[option].icd_code_desc}`;
  }

  function filterOption(options) {
    return options.filter((option) => !currentIcdCodes.includes(option));
  }

  async function handleInputChange(_event, newVal) {
    setInputValue(newVal);
    if (!newVal || newVal.length < 3) {
      setPossibleOptionsDict({});
      return;
    }

    setIsLoading(true);

    try {
      const results = await axios.get('/api/v1/claims/icd_codes_search', { params: { search: newVal } });
      setPossibleOptionsDict(results.data['icd_codes']);
      setIsMoreThanLimit(results.data['is_more_than_limit']);
    } catch (error) {
      if (!errorDetected) {
        reportAxiosError(error);
        setErrorDetected(true);
      }
    }

    setIsLoading(false);
  }

  const getOptions = () => {
    const options = Object.keys(possibleOptionsDict);

    if (isMoreThanLimit) {
      options.unshift(limitsWarningMessage);
    }

    return options;
  };

  return (
    <Autocomplete
      id="icd_code"
      options={getOptions()}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={(option) => option === limitsWarningMessage}
      loading={isLoading}
      disabled={disabled}
      inputValue={inputValue}
      value={getIn(values, icdCodeId) || null}
      filterOptions={filterOption}
      onInputChange={handleInputChange}
      onChange={(e, newVal) => {
        setFieldValue(icdCodeId, newVal || '');
        setFieldValue(icdCodeDescId, (newVal && possibleOptionsDict[newVal].icd_code_desc) || '');
      }}
      renderInput={(params) =>
        showOnly ? (
          <ShowOnlyTextField classes={classes} showOnlyValueComponent={inputValue} label="ICD Code" />
        ) : (
          <TextField
            label="ICD Code"
            {...params}
            InputProps={{
              ...params.InputProps,
              margin: 'dense', // to match other TextField components
            }}
            error={getIn(errors, icdCodeId) && getIn(touched, icdCodeId)}
            helperText={getIn(errors, icdCodeId) && getIn(touched, icdCodeId) && getIn(errors, icdCodeId)}
            onBlur={() => setFieldTouched(icdCodeId, true)}
          />
        )
      }
      clearOnBlur={false}
    />
  );
}

IcdCodesAutoComplete.propTypes = {
  icdCodeId: PropTypes.string.isRequired,
  icdCodeDescId: PropTypes.string.isRequired,
  currentIcdCodes: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  showOnly: PropTypes.bool,
};

export default IcdCodesFragment;
