import React, { useEffect, useState } from 'react';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { useCurrentEditor } from '@tiptap/react';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import TextField from '~/components/core/Molecules/Fields/TextField';

import { Heading } from '../../../TextComponents';

import { useLinkModal } from './LinkModalContext';

export const LinkModal: React.FC = () => {
  const { editor } = useCurrentEditor();
  const { isOpen, closeModal } = useLinkModal();
  const [url, setUrl] = useState('');

  // Update the displayed URL onOpen - so it will display the selected URL.
  useEffect(() => {
    if (isOpen) {
      setUrl(editor?.getAttributes('link')?.href);
    }
  }, [editor, isOpen]);

  const onClose = () => {
    closeModal();
  };

  const removeLink = () => editor?.chain().focus().extendMarkRange('link').unsetLink().run();
  const saveLink = () => {
    editor?.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' }).run();
  };

  const onSave = () => {
    url ? saveLink() : removeLink();
    closeModal();
  };

  const onClear = () => {
    removeLink();
    closeModal();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <div className="inline-flex w-full items-center justify-between">
          <Heading>Edit Link</Heading>
          <IconButton aria-label="Close" className="p-0">
            <CloseIcon onClick={closeModal} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="mb-12 mt-4">
        <TextField variant="outlined" label="URL" value={url} fullWidth onChange={(value) => setUrl(value)} />
        <div className="mt-20 inline-flex w-full justify-end">
          <Button onClick={onClear}>clear</Button>
          <Button onClick={onSave} variant="contained" color="primary">
            save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
