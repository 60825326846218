import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { serverDateToLocal } from '../../DateTimeUtils';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { getAllSearchableContactRoles } from '../communications/ContactUtils';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { PermissionsButtonWrapper, SortableTable } from '../core';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import DocumentLink from '../Documents/DocumentLink';
import DocumentTextFieldFormik from '../Documents/DocumentTextFieldFormik';
import PencilIcon from '../icons/PencilIcon';
import InlineIconButton from '../InlineIconButton';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import { DatePickerTextFieldFormik, MonetaryValueTextFieldFormik } from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';

const AobDashboardButton = () => {
  const [showAobDashboard, setShowAobDashboard] = useState(false);

  const classes = useStyles();

  return (
    <>
      <span style={{ display: 'inline-flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
        Update AOB{' '}
        <PermissionsButtonWrapper>
          <InlineIconButton
            className={classes.inlineEditIcon}
            onClick={() => setShowAobDashboard(true)}
            icon={OpenInNewIcon}
          />
        </PermissionsButtonWrapper>
      </span>
      {showAobDashboard && <AobDashboard onClose={() => setShowAobDashboard(false)} />}
    </>
  );
};

const AobDashboard = ({ onClose }) => {
  const [showEditAobDialog, setShowEditAobDialog] = useState(false);
  const [selectedAob, setSelectedAob] = useState(null);

  const { claim } = useClaim();
  const { currencyFormatter } = useCurrencyFormatter();

  const { isLoading, isError, data: aobs, reloadData } = useDataFetcher(`/api/v1/claims/${claim.id}/aobs`);

  const classes = useStyles();

  const closeEditDialog = () => {
    setShowEditAobDialog(false);
    setSelectedAob(null);
  };

  const openEditDialog = (aob) => {
    setSelectedAob(aob);
    setShowEditAobDialog(true);
  };

  const handleAobSave = async (aob_id, values) => {
    try {
      const url = aob_id ? `/api/v1/claims/${claim.id}/aobs/${aob_id}` : `/api/v1/claims/${claim.id}/aobs`;
      const method = aob_id ? axios.put : axios.post;
      await method(url, values);
      await reloadData();
      closeEditDialog();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const documentIdToLink = (documentId) => {
    if (!documentId) {
      return null;
    }

    const document = claim.documents.find((d) => d.id === documentId);
    return <DocumentLink document={document} text={document.document_name} />;
  };

  const formattedDateIfExists = (date) => {
    return date && serverDateToLocal(date);
  };

  const formattedMoneyIfExists = (amount) => {
    return amount && currencyFormatter.format(amount);
  };

  const moneyRenderer = (key) => (aob) => formattedMoneyIfExists(aob[key]);

  const editCell = (aob) => (
    <InlineIconButton icon={PencilIcon} className={classes.textIcon} onClick={() => openEditDialog(aob)} />
  );
  const columnData = [
    { id: 'assignee_full_name', label: 'Assignee' },
    { id: 'aob_document_id', label: 'AOB Document', specialCell: (aob) => documentIdToLink(aob.aob_document_id) },
    { id: 'execution_date', label: 'Execution Date', specialCell: (aob) => formattedDateIfExists(aob.execution_date) },
    { id: 'report_date', label: 'Report Date', specialCell: (aob) => formattedDateIfExists(aob.report_date) },
    {
      id: 'assignee_pursuit_settlement_demand_amount',
      label: "Assignee's Pursuit Settlement Demand",
      specialCell: moneyRenderer('assignee_pursuit_settlement_demand_amount'),
    },
    {
      id: 'assignee_pursuit_settlement_demand_document_id',
      label: "Assignee's Pursuit Settlement Demand (Document)",
      specialCell: (aob) => documentIdToLink(aob.assignee_pursuit_settlement_demand_document_id),
    },
    {
      id: 'insurer_pursuit_settlement_offer_amount',
      label: "Insurer's Pursuit Settlement Offer",
      specialCell: moneyRenderer('insurer_pursuit_settlement_offer_amount'),
    },
    {
      id: 'insurer_pursuit_settlement_offer_document_id',
      label: "Insurer's Pursuit Settlement Offer (Document)",
      specialCell: (aob) => documentIdToLink(aob.insurer_pursuit_settlement_offer_document_id),
    },
    {
      id: 'judgment_obtained_by_assignee_amount',
      label: 'Judgment Obtained by Assignee',
      specialCell: moneyRenderer('judgment_obtained_by_assignee_amount'),
    },
    {
      id: 'judgment_obtained_by_assignee_document_id',
      label: 'Judgment Obtained by Assignee (Document)',
      specialCell: (aob) => documentIdToLink(aob.judgment_obtained_by_assignee_document_id),
    },
    {
      id: 'attorney_fees_awarded_to_assignee',
      label: 'Attorney Fees Awarded to Assignee',
      specialCell: moneyRenderer('attorney_fees_awarded_to_assignee'),
    },
    {
      id: 'attorney_fees_awarded_to_insurer',
      label: 'Attorney Fees Awarded to Insurer',
      specialCell: moneyRenderer('attorney_fees_awarded_to_insurer'),
    },
    { id: 'edit', disableSort: true, specialCell: editCell },
  ];

  return (
    <>
      <CardDialog title="AOB Dashboard" fullWidth isDialog open maxWidth="lg" onClose={onClose}>
        {(isLoading || isError) && <LoadingIndicator isError={isError} />}
        {aobs && (
          <>
            <Button color="primary" onClick={() => openEditDialog(null)}>
              <AddIcon className={classes.leftButtonIcon} />
              Add New
            </Button>
            <div style={{ marginBottom: 25, maxHeight: 500 }}>
              <SortableTable rows={aobs} columns={columnData} />
            </div>
          </>
        )}
      </CardDialog>
      {showEditAobDialog && <EditAobDialog aob={selectedAob} onSave={handleAobSave} onClose={closeEditDialog} />}
    </>
  );
};

AobDashboard.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const EditAobDialog = ({ aob, onClose, onSave }) => {
  const title = aob ? 'Edit AOB' : 'Add AOB';

  const { organizationContactRolesDict } = useOrganization();
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        assignee_id: aob?.assignee_id || '',
        assignee_full_name: aob?.assignee_full_name || '',
        aob_document_id: aob?.aob_document_id || '',
        execution_date: aob?.execution_date || '',
        report_date: aob?.report_date || '',
        assignee_pursuit_settlement_demand_amount: aob?.assignee_pursuit_settlement_demand_amount || '',
        assignee_pursuit_settlement_demand_document_id: aob?.assignee_pursuit_settlement_demand_document_id || '',
        insurer_pursuit_settlement_offer_amount: aob?.insurer_pursuit_settlement_offer_amount || '',
        insurer_pursuit_settlement_offer_document_id: aob?.insurer_pursuit_settlement_offer_document_id || '',
        judgment_obtained_by_assignee_amount: aob?.judgment_obtained_by_assignee_amount || '',
        judgment_obtained_by_assignee_document_id: aob?.judgment_obtained_by_assignee_document_id || '',
        attorney_fees_awarded_to_insurer: aob?.attorney_fees_awarded_to_insurer || '',
        attorney_fees_awarded_to_assignee: aob?.attorney_fees_awarded_to_assignee || '',
      }}
      validationSchema={Yup.object().shape({
        assignee_id: Yup.number().required('Required'),
        aob_document_id: Yup.number(),
        execution_date: Yup.date(),
        report_date: Yup.date(),
        assignee_pursuit_settlement_demand_amount: Yup.number(),
        assignee_pursuit_settlement_demand_document_id: Yup.number(),
        insurer_pursuit_settlement_offer_amount: Yup.number(),
        insurer_pursuit_settlement_offer_document_id: Yup.number(),
        judgment_obtained_by_assignee_amount: Yup.number(),
        judgment_obtained_by_assignee_document_id: Yup.number(),
        attorney_fees_awarded_to_insurer: Yup.number(),
        attorney_fees_awarded_to_assignee: Yup.number(),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSave(aob?.id, values);
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;
        const formikAdditionalProps = {
          className: classes.textField,
          fullWidth: true,
          disabled: isSubmitting,
        };

        return (
          <CardDialog title={title} fullWidth maxWidth="sm" isDialog open onClose={onClose} preventClose={isSubmitting}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ContactTextFieldFormik
                  id="assignee"
                  label="Assignee"
                  acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
                  contactSearchProps={{ newContactRole: 'claimant' }}
                  {...formikAdditionalProps}
                />
              </Grid>
              <Grid item xs={6}>
                <DocumentTextFieldFormik
                  id="aob_document_id"
                  label="AOB Document"
                  initialValues={{ type: 'assignment_of_benefits' }}
                  {...formikAdditionalProps}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerTextFieldFormik
                  id="execution_date"
                  label="Execution Date"
                  {...formikAdditionalProps}
                  clearable
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerTextFieldFormik id="report_date" label="Report Date" {...formikAdditionalProps} clearable />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="assignee_pursuit_settlement_demand_amount"
                  label="Assignee Pursuit Settlement Demand"
                  {...formikAdditionalProps}
                />
              </Grid>
              <Grid item xs={6}>
                <DocumentTextFieldFormik
                  id="assignee_pursuit_settlement_demand_document_id"
                  label="Assignee Pursuit Settlement Demand Document"
                />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="insurer_pursuit_settlement_offer_amount"
                  label="Insurer Pursuit Settlement Offer"
                  {...formikAdditionalProps}
                />
              </Grid>
              <Grid item xs={6}>
                <DocumentTextFieldFormik
                  id="insurer_pursuit_settlement_offer_document_id"
                  label="Insurer Pursuit Settlement Offer Document"
                  {...formikAdditionalProps}
                />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="judgment_obtained_by_assignee_amount"
                  label="Judgment Obtained by Assignee"
                  {...formikAdditionalProps}
                />
              </Grid>
              <Grid item xs={6}>
                <DocumentTextFieldFormik
                  id="judgment_obtained_by_assignee_document_id"
                  label="Judgment Obtained by Assignee Document"
                  {...formikAdditionalProps}
                />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="attorney_fees_awarded_to_assignee"
                  label="Attorney Fees Awarded to Assignee"
                  {...formikAdditionalProps}
                />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="attorney_fees_awarded_to_insurer"
                  label="Attorney Fees Awarded to Insurer"
                  {...formikAdditionalProps}
                />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onClose} />
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditAobDialog.propTypes = {
  aob: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AobDashboardButton;
